import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备类型枚举 } from '@/@types/装备'

const 项链装备数据: 装备属性信息模型[] = [
  {
    id: 39907,
    uid: 215155,
    装备名称: '爪动链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2845 },
      { 属性: 属性类型.元气, 值: 552 },
      { 属性: 属性类型.内功基础攻击, 值: 1074 },
      { 属性: 属性类型.内功破防等级, 值: 2768 },
      { 属性: 属性类型.破招值, 值: 2460 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 39906,
    uid: 215154,
    装备名称: '文通链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2845 },
      { 属性: 属性类型.根骨, 值: 552 },
      { 属性: 属性类型.内功基础攻击, 值: 1074 },
      { 属性: 属性类型.内功破防等级, 值: 2768 },
      { 属性: 属性类型.破招值, 值: 2460 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 40821,
    uid: 217129,
    装备名称: '陶然意·和时链',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2827 },
      { 属性: 属性类型.根骨, 值: 514 },
      { 属性: 属性类型.内功基础攻击, 值: 821 },
      { 属性: 属性类型.内功会心等级, 值: 2750 },
      { 属性: 属性类型.全能等级, 值: 1222 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 40820,
    uid: 217128,
    装备名称: '陶然意·星雪链',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2827 },
      { 属性: 属性类型.元气, 值: 514 },
      { 属性: 属性类型.内功基础攻击, 值: 821 },
      { 属性: 属性类型.内功会心等级, 值: 2750 },
      { 属性: 属性类型.全能等级, 值: 1222 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 40804,
    uid: 217112,
    装备名称: '陶然意·兰谷链',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2827 },
      { 属性: 属性类型.元气, 值: 514 },
      { 属性: 属性类型.内功基础攻击, 值: 821 },
      { 属性: 属性类型.内功会心等级, 值: 2750 },
      { 属性: 属性类型.全能等级, 值: 1222 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 39835,
    uid: 214971,
    装备名称: '良安链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.元气, 值: 545 },
      { 属性: 属性类型.内功基础攻击, 值: 1060 },
      { 属性: 属性类型.全会心等级, 值: 2733 },
      { 属性: 属性类型.无双等级, 值: 2429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 39834,
    uid: 214970,
    装备名称: '情义链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.根骨, 值: 545 },
      { 属性: 属性类型.内功基础攻击, 值: 1060 },
      { 属性: 属性类型.内功会心等级, 值: 2733 },
      { 属性: 属性类型.无双等级, 值: 2429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 39817,
    uid: 214911,
    装备名称: '宫阙链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.元气, 值: 545 },
      { 属性: 属性类型.内功基础攻击, 值: 1060 },
      { 属性: 属性类型.内功破防等级, 值: 2733 },
      { 属性: 属性类型.无双等级, 值: 2429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39816,
    uid: 214910,
    装备名称: '绕城链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.根骨, 值: 545 },
      { 属性: 属性类型.内功基础攻击, 值: 1060 },
      { 属性: 属性类型.内功破防等级, 值: 2733 },
      { 属性: 属性类型.无双等级, 值: 2429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39938,
    uid: 215325,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2305 },
      { 属性: 属性类型.破招值, 值: 2071 },
      { 属性: 属性类型.内功破防等级, 值: 2515 },
      { 属性: 属性类型.无双等级, 值: 1479 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 39937,
    uid: 215324,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2305 },
      { 属性: 属性类型.破招值, 值: 3107 },
      { 属性: 属性类型.全会心等级, 值: 2959 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 39936,
    uid: 215323,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2702 },
      { 属性: 属性类型.内功破防等级, 值: 5252 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 40990,
    uid: 218232,
    装备名称: '长愿链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2701 },
      { 属性: 属性类型.元气, 值: 524 },
      { 属性: 属性类型.内功基础攻击, 值: 1019 },
      { 属性: 属性类型.内功破防等级, 值: 2628 },
      { 属性: 属性类型.无双等级, 值: 2336 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40989,
    uid: 218231,
    装备名称: '思愿链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2701 },
      { 属性: 属性类型.根骨, 值: 524 },
      { 属性: 属性类型.内功基础攻击, 值: 1019 },
      { 属性: 属性类型.内功破防等级, 值: 2628 },
      { 属性: 属性类型.无双等级, 值: 2336 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39895,
    uid: 215143,
    装备名称: '爪动链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 14800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2665 },
      { 属性: 属性类型.元气, 值: 517 },
      { 属性: 属性类型.内功基础攻击, 值: 1006 },
      { 属性: 属性类型.内功破防等级, 值: 2593 },
      { 属性: 属性类型.破招值, 值: 2305 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 39894,
    uid: 215142,
    装备名称: '文通链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 14800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2665 },
      { 属性: 属性类型.根骨, 值: 517 },
      { 属性: 属性类型.内功基础攻击, 值: 1006 },
      { 属性: 属性类型.内功破防等级, 值: 2593 },
      { 属性: 属性类型.破招值, 值: 2305 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 39926,
    uid: 215283,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2176 },
      { 属性: 属性类型.破招值, 值: 1536 },
      { 属性: 属性类型.全会心等级, 值: 2654 },
      { 属性: 属性类型.全会心效果等级, 值: 1397 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 39925,
    uid: 215282,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2176 },
      { 属性: 属性类型.破招值, 值: 2863 },
      { 属性: 属性类型.无双等级, 值: 2863 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 39924,
    uid: 215281,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2551 },
      { 属性: 属性类型.全会心等级, 值: 4958 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 38843,
    uid: 212078,
    装备名称: '日倾链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 14150,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2548 },
      { 属性: 属性类型.元气, 值: 494 },
      { 属性: 属性类型.内功基础攻击, 值: 962 },
      { 属性: 属性类型.内功破防等级, 值: 2479 },
      { 属性: 属性类型.破招值, 值: 2203 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 38842,
    uid: 212077,
    装备名称: '寒霖链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 14150,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2548 },
      { 属性: 属性类型.根骨, 值: 494 },
      { 属性: 属性类型.内功基础攻击, 值: 962 },
      { 属性: 属性类型.内功破防等级, 值: 2479 },
      { 属性: 属性类型.破招值, 值: 2203 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 40758,
    uid: 217129,
    装备名称: '陶然意·和时链',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2530 },
      { 属性: 属性类型.根骨, 值: 460 },
      { 属性: 属性类型.内功基础攻击, 值: 735 },
      { 属性: 属性类型.内功会心等级, 值: 2461 },
      { 属性: 属性类型.全能等级, 值: 1094 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 40757,
    uid: 217128,
    装备名称: '陶然意·星雪链',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2530 },
      { 属性: 属性类型.元气, 值: 460 },
      { 属性: 属性类型.内功基础攻击, 值: 735 },
      { 属性: 属性类型.内功会心等级, 值: 2461 },
      { 属性: 属性类型.全能等级, 值: 1094 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 40741,
    uid: 217112,
    装备名称: '陶然意·兰谷链',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2530 },
      { 属性: 属性类型.元气, 值: 460 },
      { 属性: 属性类型.内功基础攻击, 值: 735 },
      { 属性: 属性类型.内功会心等级, 值: 2461 },
      { 属性: 属性类型.全能等级, 值: 1094 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 39805,
    uid: 214676,
    装备名称: '危影链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.元气, 值: 487 },
      { 属性: 属性类型.内功基础攻击, 值: 948 },
      { 属性: 属性类型.内功破防等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39804,
    uid: 214675,
    装备名称: '危音链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.根骨, 值: 487 },
      { 属性: 属性类型.内功基础攻击, 值: 948 },
      { 属性: 属性类型.内功破防等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38771,
    uid: 211894,
    装备名称: '壑云链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.元气, 值: 487 },
      { 属性: 属性类型.内功基础攻击, 值: 948 },
      { 属性: 属性类型.全会心等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 38770,
    uid: 211893,
    装备名称: '寒绡链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.根骨, 值: 487 },
      { 属性: 属性类型.内功基础攻击, 值: 948 },
      { 属性: 属性类型.内功会心等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 38753,
    uid: 211834,
    装备名称: '开颐链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.元气, 值: 487 },
      { 属性: 属性类型.内功基础攻击, 值: 948 },
      { 属性: 属性类型.内功破防等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38752,
    uid: 211833,
    装备名称: '扬英链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.根骨, 值: 487 },
      { 属性: 属性类型.内功基础攻击, 值: 948 },
      { 属性: 属性类型.内功破防等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39883,
    uid: 215131,
    装备名称: '爪动链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2485 },
      { 属性: 属性类型.元气, 值: 482 },
      { 属性: 属性类型.内功基础攻击, 值: 938 },
      { 属性: 属性类型.内功破防等级, 值: 2417 },
      { 属性: 属性类型.破招值, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 39882,
    uid: 215130,
    装备名称: '文通链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2485 },
      { 属性: 属性类型.根骨, 值: 482 },
      { 属性: 属性类型.内功基础攻击, 值: 938 },
      { 属性: 属性类型.内功破防等级, 值: 2417 },
      { 属性: 属性类型.破招值, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 38878,
    uid: 212262,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2054 },
      { 属性: 属性类型.破招值, 值: 1451 },
      { 属性: 属性类型.全会心等级, 值: 2506 },
      { 属性: 属性类型.无双等级, 值: 1451 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 38877,
    uid: 212261,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2054 },
      { 属性: 属性类型.内功破防等级, 值: 2637 },
      { 属性: 属性类型.无双等级, 值: 2769 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 38876,
    uid: 212260,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2409 },
      { 属性: 属性类型.无双等级, 值: 4681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 38831,
    uid: 212066,
    装备名称: '日倾链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2395 },
      { 属性: 属性类型.元气, 值: 464 },
      { 属性: 属性类型.内功基础攻击, 值: 904 },
      { 属性: 属性类型.内功破防等级, 值: 2330 },
      { 属性: 属性类型.破招值, 值: 2071 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 38830,
    uid: 212065,
    装备名称: '寒霖链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2395 },
      { 属性: 属性类型.根骨, 值: 464 },
      { 属性: 属性类型.内功基础攻击, 值: 904 },
      { 属性: 属性类型.内功破防等级, 值: 2330 },
      { 属性: 属性类型.破招值, 值: 2071 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 39871,
    uid: 215119,
    装备名称: '爪动链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2305 },
      { 属性: 属性类型.元气, 值: 447 },
      { 属性: 属性类型.内功基础攻击, 值: 870 },
      { 属性: 属性类型.内功破防等级, 值: 2242 },
      { 属性: 属性类型.破招值, 值: 1993 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 39870,
    uid: 215118,
    装备名称: '文通链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2305 },
      { 属性: 属性类型.根骨, 值: 447 },
      { 属性: 属性类型.内功基础攻击, 值: 870 },
      { 属性: 属性类型.内功破防等级, 值: 2242 },
      { 属性: 属性类型.破招值, 值: 1993 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 38862,
    uid: 212214,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 1941 },
      { 属性: 属性类型.破招值, 值: 1744 },
      { 属性: 属性类型.内功破防等级, 值: 2118 },
      { 属性: 属性类型.无双等级, 值: 1246 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 38861,
    uid: 212213,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 1941 },
      { 属性: 属性类型.破招值, 值: 2616 },
      { 属性: 属性类型.全会心等级, 值: 2491 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 38860,
    uid: 212212,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2275 },
      { 属性: 属性类型.内功破防等级, 值: 4422 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 37768,
    uid: 208144,
    装备名称: '畅光链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2269 },
      { 属性: 属性类型.元气, 值: 440 },
      { 属性: 属性类型.内功基础攻击, 值: 856 },
      { 属性: 属性类型.内功破防等级, 值: 2207 },
      { 属性: 属性类型.破招值, 值: 1962 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 37767,
    uid: 208143,
    装备名称: '游零链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2269 },
      { 属性: 属性类型.根骨, 值: 440 },
      { 属性: 属性类型.内功基础攻击, 值: 856 },
      { 属性: 属性类型.内功破防等级, 值: 2207 },
      { 属性: 属性类型.破招值, 值: 1962 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 39681,
    uid: 213703,
    装备名称: '灵空·未判链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.元气, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.内功破防等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39680,
    uid: 213702,
    装备名称: '灵空·心斋链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.根骨, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.内功破防等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38819,
    uid: 212054,
    装备名称: '日倾链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.元气, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.内功破防等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 38818,
    uid: 212053,
    装备名称: '寒霖链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.根骨, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.内功破防等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 37810,
    uid: 208366,
    装备名称: '湖烟链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.元气, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.全会心等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 37809,
    uid: 208365,
    装备名称: '湖寂链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.根骨, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.内功会心等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 37700,
    uid: 207978,
    装备名称: '沁渡链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.元气, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.全会心等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 37699,
    uid: 207977,
    装备名称: '朝华链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.根骨, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.内功会心等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 37682,
    uid: 207918,
    装备名称: '椴微链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.元气, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.内功破防等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 37681,
    uid: 207917,
    装备名称: '池泓链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.根骨, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.内功破防等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 34188,
    uid: 189024,
    装备名称: '临溪链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2233 },
      { 属性: 属性类型.元气, 值: 433 },
      { 属性: 属性类型.内功基础攻击, 值: 843 },
      { 属性: 属性类型.内功破防等级, 值: 2172 },
      { 属性: 属性类型.破招值, 值: 1931 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 34187,
    uid: 189023,
    装备名称: '临黎链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2233 },
      { 属性: 属性类型.根骨, 值: 433 },
      { 属性: 属性类型.内功基础攻击, 值: 843 },
      { 属性: 属性类型.内功破防等级, 值: 2172 },
      { 属性: 属性类型.破招值, 值: 1931 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 40912,
    uid: 217844,
    装备名称: '若寓链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 403 },
      { 属性: 属性类型.内功基础攻击, 值: 643 },
      { 属性: 属性类型.内功破防等级, 值: 2155 },
      { 属性: 属性类型.全能等级, 值: 958 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 40911,
    uid: 217843,
    装备名称: '越风链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 403 },
      { 属性: 属性类型.内功基础攻击, 值: 643 },
      { 属性: 属性类型.内功破防等级, 值: 2155 },
      { 属性: 属性类型.全能等级, 值: 958 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 40894,
    uid: 217753,
    装备名称: '踏江链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 403 },
      { 属性: 属性类型.内功基础攻击, 值: 643 },
      { 属性: 属性类型.全会心等级, 值: 2155 },
      { 属性: 属性类型.全能等级, 值: 958 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 40893,
    uid: 217752,
    装备名称: '承康链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 403 },
      { 属性: 属性类型.内功基础攻击, 值: 643 },
      { 属性: 属性类型.内功会心等级, 值: 2155 },
      { 属性: 属性类型.全能等级, 值: 958 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 34260,
    uid: 189354,
    装备名称: '藏峦链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.全会心等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 34259,
    uid: 189353,
    装备名称: '谨峰链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.内功会心等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 34242,
    uid: 189264,
    装备名称: '故芳链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.全会心等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 34241,
    uid: 189263,
    装备名称: '剪桐链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.内功会心等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 34224,
    uid: 189174,
    装备名称: '花霭链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.内功破防等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 34223,
    uid: 189173,
    装备名称: '途南链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.内功破防等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 34206,
    uid: 189084,
    装备名称: '庭澜链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.加速等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 34205,
    uid: 189083,
    装备名称: '故云链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.加速等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 34116,
    uid: 188802,
    装备名称: '忆惜链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.内功破防等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 34115,
    uid: 188801,
    装备名称: '忆安链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.内功破防等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 34098,
    uid: 188712,
    装备名称: '语阔链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.全会心等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 34097,
    uid: 188711,
    装备名称: '擒雨链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.内功会心等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 34080,
    uid: 188622,
    装备名称: '烟琐链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.破招值, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 34079,
    uid: 188621,
    装备名称: '德襄链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.破招值, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 37799,
    uid: 208313,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 1835 },
      { 属性: 属性类型.破招值, 值: 1295 },
      { 属性: 属性类型.全会心等级, 值: 2237 },
      { 属性: 属性类型.全会心效果等级, 值: 1178 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 37798,
    uid: 208312,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 1835 },
      { 属性: 属性类型.破招值, 值: 2414 },
      { 属性: 属性类型.无双等级, 值: 2414 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 37797,
    uid: 208311,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2151 },
      { 属性: 属性类型.全会心等级, 值: 4181 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
]

export default 项链装备数据
