import { 最终计算属性类型, 技能总伤害计算入参类型 } from '@/@types/计算'
import { 计算结果技能列表类型 } from '@/@types/输出'
import { 属性加成 } from '@/@types/属性'
import { 循环技能详情 } from '@/@types/循环'
import { 角色基础属性类型 } from '@/@types/角色'
import { 目标属性类型 } from '@/@types/常量'
import { 快照类型, 技能基础数据模型, 技能计算增益数据列表 } from '@/@types/技能'
import { 装备增益类型 } from '@/@types/装备'
import 获取技能等级信息 from '@/数据/数据工具/获取技能等级信息'

import {
  增益合并并排序,
  根据增益修改最终循环,
  根据增益选项获取增益集合,
  获取增益实际数据集合,
  获取装备增益,
  计算该技能下多个增益的增益集合,
  通用增益计算,
} from './统一工具函数/增益计算函数'
// import { 获取全能加成面板 } from './统一工具函数/工具函数'
import { 郭氏技能总伤害计算 } from './郭氏计算'
import { 非郭氏技能总伤害计算 } from './非郭氏计算'
import { 获取全能加成面板 } from './统一工具函数/工具函数'

// 计算技能循环总输出
export const 循环秒伤计算 = (props: 技能总伤害计算入参类型) => {
  const {
    计算循环,
    装备信息,
    当前目标,
    技能基础数据,
    增益启用,
    增益数据,
    战斗时间,
    是否郭氏计算,
    快照计算 = [],
  } = props
  // 总dps
  let 循环总伤 = 0
  // 每个技能的dps总和列表
  const 计算结果技能列表: 计算结果技能列表类型[] = []
  const 计算目标 = 当前目标

  // 获取装备增益等带来的最终增益集合
  let 总增益集合: 技能计算增益数据列表[] = 获取装备增益(装备信息, 快照计算)

  // 根据增益信息修改最终循环内容
  const 最终循环: 循环技能详情[] = 根据增益修改最终循环(装备信息, [...计算循环], 战斗时间)

  if (增益启用 && 增益数据) {
    const 团队增益增益集合 = 根据增益选项获取增益集合(增益数据)
    总增益集合 = 总增益集合.concat(团队增益增益集合)

    if (增益数据?.团队增益.find((item) => item.增益名称 === '飘黄' && !!item.启用)) {
      最终循环.push({
        技能名称: '逐云寒蕊',
        技能数量: Math.floor(战斗时间 * 0.13),
        技能增益列表: [],
      })
    }
  }

  /**
   * 第一次计算增益、包含：
   * 团队增益（小药、团辅、阵眼等）
   * 装备增益（套装双会、风水特效、大附魔、切糕等）
   * 这里不会包含特定技能的增益
   * */

  const 计算团辅和装备后属性 = 计算增益加成后最终计算属性({
    装备基础属性: 装备信息?.装备基础属性,
    增益集合: 总增益集合,
    当前目标,
  })

  // 遍历循环，获取每一个技能的总输出
  最终循环.forEach((循环技能) => {
    // 获取循环内某个技能的总dps
    const { 总伤, 总会心数量, 当前技能属性 } = 计算循环内某技能总伤害(
      循环技能,
      装备信息?.装备增益,
      计算目标,
      技能基础数据,
      是否郭氏计算,
      快照计算,
      计算团辅和装备后属性
    )
    计算结果技能列表.push({
      统计名称: 当前技能属性?.统计名称,
      技能名称: 循环技能.技能名称,
      技能总输出: 总伤,
      技能数量: 循环技能.技能数量,
      会心几率: 总会心数量 / 循环技能.技能数量,
    })
    循环总伤 = 循环总伤 + 总伤
  })

  return { 总伤: 循环总伤, 计算结果技能列表 }
}

const 获取所有技能通用的增益集合 = (该技能增益集合: string[][]) => {
  const 共同增益 = new Set(该技能增益集合[0])
  该技能增益集合.forEach((增益) => {
    const currentSet = new Set(增益)
    for (const key of 共同增益) {
      if (!currentSet.has(key)) {
        共同增益.delete(key)
      }
    }
  })

  const 全部共用增益数据 = Array.from(共同增益)
  const 共用增益数据集合 = 该技能增益集合[0].filter((item) => 全部共用增益数据?.includes(item))

  return 共用增益数据集合
}

// const 获取该技能各增益情况共用增益 = ()

// 获取循环内某个技能的总dps
export const 计算循环内某技能总伤害 = (
  循环: 循环技能详情,
  装备增益: 装备增益类型,
  计算目标: 目标属性类型,
  技能基础数据: 技能基础数据模型[],
  是否郭氏计算: boolean,
  快照计算: 快照类型[],
  计算团辅和装备后属性: 最终计算属性类型
) => {
  // 在技能数据模型中找到当前执行循环内技能的数据，获取各种系数
  const 完整技能属性 = 技能基础数据.find((item) => item.技能名称 === 循环?.技能名称)
  const 当前技能属性 = 获取技能等级信息(完整技能属性, 循环?.技能等级)

  // 如果循环带了伤害计算次数，这里直接订正
  if (循环?.伤害层数 && 循环?.伤害层数 !== 1) {
    当前技能属性.伤害计算次数 = 循环?.伤害层数 || 1
  }

  // 总输出
  let 总伤 = 0
  let 总会心数量 = 0
  let 无增益技能数 = 循环?.技能数量
  let 技能增益集合: 技能计算增益数据列表[] = []

  if (当前技能属性) {
    // 计算技能常驻固定增益（秘籍、奇穴）等
    if (当前技能属性.技能增益列表?.length) {
      当前技能属性.技能增益列表.forEach((增益) => {
        if (增益.增益启用 && 增益.增益类型 === '全局启用') {
          const 该技能增益列表: 技能计算增益数据列表[] = (增益.增益集合 || []).map((item) => {
            return {
              ...item,
              增益来源: 增益?.增益名称,
            }
          })
          技能增益集合 = 技能增益集合.concat(该技能增益列表)
        }
      })
    }

    // 判断增益技能的总伤
    if (循环?.技能增益列表?.length) {
      const 该技能循环内增益名称列表: string[][] = 循环?.技能增益列表.map((增益) => {
        const 技能增益名称列表: string[] = 计算该技能下多个增益的增益集合(
          增益,
          当前技能属性,
          装备增益,
          快照计算
        )
        return 技能增益名称列表
      })

      const 该技能通用增益名称列表 = 获取所有技能通用的增益集合(该技能循环内增益名称列表)
      const 该技能通用增益集合 = 获取增益实际数据集合(该技能通用增益名称列表, 当前技能属性)

      /**
       * 第二次计算增益
       * 包含：
       * 技能通用的增益，包括全覆盖的buff、奇穴、秘籍等
       * */
      const 计算技能全局增益后数据 = 计算增益加成后最终计算属性({
        传入计算属性: 计算团辅和装备后属性,
        增益集合: [...技能增益集合, ...该技能通用增益集合],
        当前目标: 计算目标,
      })

      循环?.技能增益列表.forEach((增益, 索引) => {
        无增益技能数 = 无增益技能数 - 增益.增益技能数
        if (增益.增益技能数) {
          // 提取为计算过的独立增益
          const 技能增益名称列表 = 该技能循环内增益名称列表[索引]?.filter(
            (item) => !该技能通用增益名称列表?.includes(item)
          )
          const 技能独立增益集合列表 = 获取增益实际数据集合(技能增益名称列表, 当前技能属性)

          let 技能最终计算数据 = 计算技能全局增益后数据
          if (技能独立增益集合列表.length) {
            /**
             * 第三次计算增益、包含：
             * 技能在本次循环内独立的快照增益
             * */
            技能最终计算数据 = 计算增益加成后最终计算属性({
              传入计算属性: 计算技能全局增益后数据,
              增益集合: 技能独立增益集合列表,
              当前目标: 计算目标,
            })
          }
          const { 期望技能总伤, 会心数量 } = 计算技能总伤(
            当前技能属性,
            技能最终计算数据,
            增益.增益技能数,
            是否郭氏计算
          )

          总伤 = 总伤 + 期望技能总伤
          总会心数量 = 总会心数量 + 会心数量
        }
      })
    }

    if (无增益技能数) {
      /**
       * 第二次计算增益、包含：
       * 技能专属的全局增益，例如秘籍、某些奇穴的增伤等
       * */
      const 计算技能全局增益后数据 = 计算增益加成后最终计算属性({
        传入计算属性: 计算团辅和装备后属性,
        增益集合: 技能增益集合,
        当前目标: 计算目标,
      })
      // 判断常规未增益技能的总伤
      const { 期望技能总伤, 会心数量 } = 计算技能总伤(
        当前技能属性,
        计算技能全局增益后数据,
        无增益技能数,
        是否郭氏计算
      )

      总伤 = 总伤 + 期望技能总伤
      总会心数量 = 总会心数量 + 会心数量
    }

    return { 总伤, 总会心数量, 当前技能属性 }
  }

  return { 总伤, 总会心数量, 当前技能属性 }
}

/**
 * 先计算所有增益的加成
 * 这里不涉及郭氏计算，可以统一计算
 * 算完后带入所有的计算信息分别给郭氏函数和非郭氏函数，进行最后统一计算
 */
export const 计算技能总伤 = (
  当前技能属性: 技能基础数据模型,
  最终计算属性: 最终计算属性类型,
  技能总数: number,
  是否郭氏计算
) => {
  const 计算技能总伤函数 = 是否郭氏计算 ? 郭氏技能总伤害计算 : 非郭氏技能总伤害计算

  const 计算属性 = 最终计算属性?.最终人物属性?.全能等级
    ? 获取全能加成面板(最终计算属性?.最终人物属性)
    : 最终计算属性?.最终人物属性

  const 计算结果 = 计算技能总伤函数(
    { ...最终计算属性, 最终人物属性: 计算属性 },
    当前技能属性,
    技能总数
  )

  return { 会心数量: 0, ...计算结果 }
}

export const 计算增益加成后最终计算属性 = ({
  装备基础属性,
  增益集合,
  当前目标,
  传入计算属性,
}: {
  增益集合: 属性加成[]
  当前目标: 目标属性类型
  装备基础属性?: 角色基础属性类型
  传入计算属性?: 最终计算属性类型
}) => {
  let 最终计算属性: 最终计算属性类型
  if (传入计算属性) {
    最终计算属性 = JSON.parse(JSON.stringify(传入计算属性))
  } else {
    最终计算属性 = {
      计算目标: 当前目标,
      最终人物属性: { ...(装备基础属性 || {}) } as any,
      气血加成: { 郭氏体质: 0, 郭氏基础气血上限: 0, 郭氏最终气血上限: 0 },
      技能增伤: {
        全局伤害因子: 1,
        目标移动状增伤: 1,
        通用增伤: 1,
        易伤增伤: 1,
        非侠增伤: 1,
        系数增伤: 1,
      },
      郭氏额外会效果值: 0,
      额外会心率: 0,
      郭氏无视防御: 0,
      郭氏属性无视防御: 0,
      郭氏额外无双等级: 0,
      郭氏破防等级: 0,
      郭氏基础攻击: 0,
      郭氏武器伤害: 0,
      郭氏力道: 0,
      郭氏身法: 0,
      郭氏根骨: 0,
      郭氏元气: 0,
      郭氏无双: 0,
      郭氏破招: 0,
    }
  }

  if (增益集合.length) {
    // 对增益集合进行排序，先计算数值。后计算百分比
    const 当前技能计算增益集合: 属性加成[] = 增益合并并排序(增益集合)

    当前技能计算增益集合.forEach((增益数值信息) => {
      最终计算属性 = 通用增益计算(增益数值信息, 最终计算属性)
    })
  }

  return 最终计算属性
}

export default 循环秒伤计算

// 加缓存
// export const 计算增益加成后最终计算属性缓存函数 = 函数缓存(
//   计算增益加成后最终计算属性,
//   (装备基础属性, 总增益集合, 当前目标) => {
//     return `${JSON.stringify(装备基础属性)}${JSON.stringify(总增益集合)}${JSON.stringify(当前目标)}`
//   }
// )
