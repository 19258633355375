import { 属性系数, 等级常数, 等级系数 } from '@/数据/常量'
import { 目标属性类型 } from '@/@types/常量'

export const 目标集合: 目标属性类型[] = [
  {
    名称: '124级木桩',
    等级: 124,
    防御系数: 属性系数.防御 * (等级系数 * 124 - 等级常数),
    防御点数: 27550,
    目标类型: '木桩',
  },
  {
    名称: '123级木桩',
    等级: 123,
    防御系数: 属性系数.防御 * (等级系数 * 123 - 等级常数),
    防御点数: 26317,
    目标类型: '木桩',
  },
  {
    名称: '122级木桩',
    等级: 122,
    防御系数: 属性系数.防御 * (等级系数 * 122 - 等级常数),
    防御点数: 15528,
    目标类型: '木桩',
  },
  {
    名称: '121级木桩',
    等级: 121,
    防御系数: 属性系数.防御 * (等级系数 * 121 - 等级常数),
    防御点数: 11073,
    目标类型: '木桩',
  },
  {
    名称: '雷神',
    等级: 122,
    防御系数: 属性系数.防御 * (等级系数 * 122 - 等级常数),
    防御点数: 15580,
    目标类型: '试炼',
  },
  {
    名称: '九色鹿',
    等级: 124,
    防御点数: 27561,
    防御系数: 属性系数.防御 * (等级系数 * 124 - 等级常数),
    目标类型: '试炼',
  },
  {
    名称: '地鼠门宝箱｜五行柱',
    等级: 120,
    防御系数: 属性系数.防御 * (等级系数 * 120 - 等级常数),
    防御点数: 7414,
    目标类型: '试炼',
  },
]

export default 目标集合
