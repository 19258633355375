import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备类型枚举 } from '@/@types/装备'

const 暗器装备数据: 装备属性信息模型[] = [
  {
    id: 38160,
    uid: 217086,
    装备名称: '陶然意·和时囊',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3392 },
      { 属性: 属性类型.根骨, 值: 617 },
      { 属性: 属性类型.内功基础攻击, 值: 985 },
      { 属性: 属性类型.内功破防等级, 值: 3300 },
      { 属性: 属性类型.全能等级, 值: 1467 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 38159,
    uid: 217085,
    装备名称: '陶然意·星雪囊',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3392 },
      { 属性: 属性类型.元气, 值: 617 },
      { 属性: 属性类型.内功基础攻击, 值: 985 },
      { 属性: 属性类型.内功破防等级, 值: 3300 },
      { 属性: 属性类型.全能等级, 值: 1467 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 38143,
    uid: 217069,
    装备名称: '陶然意·兰谷囊',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3392 },
      { 属性: 属性类型.元气, 值: 617 },
      { 属性: 属性类型.内功基础攻击, 值: 985 },
      { 属性: 属性类型.内功破防等级, 值: 3300 },
      { 属性: 属性类型.全能等级, 值: 1467 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 38589,
    uid: 218238,
    装备名称: '长秋囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3370 },
      { 属性: 属性类型.元气, 值: 654 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.内功破防等级, 值: 3279 },
      { 属性: 属性类型.无双等级, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 38588,
    uid: 218237,
    装备名称: '稚秋囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3370 },
      { 属性: 属性类型.根骨, 值: 654 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.内功破防等级, 值: 3279 },
      { 属性: 属性类型.无双等级, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 37305,
    uid: 215378,
    装备名称: '月迟囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3370 },
      { 属性: 属性类型.元气, 值: 654 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.全会心等级, 值: 3279 },
      { 属性: 属性类型.破招值, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 37304,
    uid: 215377,
    装备名称: '月纤囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3370 },
      { 属性: 属性类型.根骨, 值: 654 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.内功会心等级, 值: 3279 },
      { 属性: 属性类型.破招值, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 37186,
    uid: 214989,
    装备名称: '良安囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3370 },
      { 属性: 属性类型.元气, 值: 654 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.全会心等级, 值: 3279 },
      { 属性: 属性类型.无双等级, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 37185,
    uid: 214988,
    装备名称: '情义囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3370 },
      { 属性: 属性类型.根骨, 值: 654 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.内功会心等级, 值: 3279 },
      { 属性: 属性类型.无双等级, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 37180,
    uid: 214929,
    装备名称: '宫阙囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3370 },
      { 属性: 属性类型.元气, 值: 654 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.加速等级, 值: 3279 },
      { 属性: 属性类型.破招值, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 37179,
    uid: 214928,
    装备名称: '绕城囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3370 },
      { 属性: 属性类型.根骨, 值: 654 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.加速等级, 值: 3279 },
      { 属性: 属性类型.破招值, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 37300,
    uid: 215337,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2765 },
      { 属性: 属性类型.破招值, 值: 1953 },
      { 属性: 属性类型.全会心等级, 值: 3373 },
      { 属性: 属性类型.全会心效果等级, 值: 1775 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 37299,
    uid: 215336,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2765 },
      { 属性: 属性类型.全会心等级, 值: 4438 },
      { 属性: 属性类型.全会心效果等级, 值: 2663 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 37298,
    uid: 215335,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3242 },
      { 属性: 属性类型.内功破防等级, 值: 6302 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 37294,
    uid: 215295,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2611 },
      { 属性: 属性类型.内功破防等级, 值: 2430 },
      { 属性: 属性类型.全会心等级, 值: 2598 },
      { 属性: 属性类型.全会心效果等级, 值: 1676 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 37293,
    uid: 215294,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2611 },
      { 属性: 属性类型.破招值, 值: 3519 },
      { 属性: 属性类型.全会心等级, 值: 3352 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 37292,
    uid: 215293,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3061 },
      { 属性: 属性类型.无双等级, 值: 5949 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 38117,
    uid: 217086,
    装备名称: '陶然意·和时囊',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3036 },
      { 属性: 属性类型.根骨, 值: 552 },
      { 属性: 属性类型.内功基础攻击, 值: 881 },
      { 属性: 属性类型.内功破防等级, 值: 2954 },
      { 属性: 属性类型.全能等级, 值: 1313 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 38116,
    uid: 217085,
    装备名称: '陶然意·星雪囊',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3036 },
      { 属性: 属性类型.元气, 值: 552 },
      { 属性: 属性类型.内功基础攻击, 值: 881 },
      { 属性: 属性类型.内功破防等级, 值: 2954 },
      { 属性: 属性类型.全能等级, 值: 1313 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 38100,
    uid: 217069,
    装备名称: '陶然意·兰谷囊',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3036 },
      { 属性: 属性类型.元气, 值: 552 },
      { 属性: 属性类型.内功基础攻击, 值: 881 },
      { 属性: 属性类型.内功破防等级, 值: 2954 },
      { 属性: 属性类型.全能等级, 值: 1313 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 37406,
    uid: 215575,
    装备名称: '东方日出·当楼囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.元气, 值: 584 },
      { 属性: 属性类型.内功基础攻击, 值: 1138 },
      { 属性: 属性类型.全会心等级, 值: 2933 },
      { 属性: 属性类型.无双等级, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 37405,
    uid: 215574,
    装备名称: '东方日出·所适囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.根骨, 值: 584 },
      { 属性: 属性类型.内功基础攻击, 值: 1138 },
      { 属性: 属性类型.内功会心等级, 值: 2933 },
      { 属性: 属性类型.无双等级, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 37143,
    uid: 214688,
    装备名称: '危影囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.元气, 值: 584 },
      { 属性: 属性类型.内功基础攻击, 值: 1138 },
      { 属性: 属性类型.全会心等级, 值: 2933 },
      { 属性: 属性类型.破招值, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 37142,
    uid: 214687,
    装备名称: '危音囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.根骨, 值: 584 },
      { 属性: 属性类型.内功基础攻击, 值: 1138 },
      { 属性: 属性类型.内功会心等级, 值: 2933 },
      { 属性: 属性类型.破招值, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 35836,
    uid: 212315,
    装备名称: '泉麓囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.元气, 值: 584 },
      { 属性: 属性类型.内功基础攻击, 值: 1138 },
      { 属性: 属性类型.全会心等级, 值: 2933 },
      { 属性: 属性类型.破招值, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 35835,
    uid: 212314,
    装备名称: '泉合囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.根骨, 值: 584 },
      { 属性: 属性类型.内功基础攻击, 值: 1138 },
      { 属性: 属性类型.内功会心等级, 值: 2933 },
      { 属性: 属性类型.破招值, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 35783,
    uid: 212034,
    装备名称: '凝疏囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.元气, 值: 584 },
      { 属性: 属性类型.内功基础攻击, 值: 1138 },
      { 属性: 属性类型.内功破防等级, 值: 2933 },
      { 属性: 属性类型.破招值, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 35782,
    uid: 212033,
    装备名称: '枝绫囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.根骨, 值: 584 },
      { 属性: 属性类型.内功基础攻击, 值: 1138 },
      { 属性: 属性类型.内功破防等级, 值: 2933 },
      { 属性: 属性类型.破招值, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 35715,
    uid: 211912,
    装备名称: '壑云囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.元气, 值: 584 },
      { 属性: 属性类型.内功基础攻击, 值: 1138 },
      { 属性: 属性类型.全会心等级, 值: 2933 },
      { 属性: 属性类型.无双等级, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 35714,
    uid: 211911,
    装备名称: '寒绡囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.根骨, 值: 584 },
      { 属性: 属性类型.内功基础攻击, 值: 1138 },
      { 属性: 属性类型.内功会心等级, 值: 2933 },
      { 属性: 属性类型.无双等级, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 35709,
    uid: 211852,
    装备名称: '开颐囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.元气, 值: 584 },
      { 属性: 属性类型.内功基础攻击, 值: 1138 },
      { 属性: 属性类型.加速等级, 值: 2933 },
      { 属性: 属性类型.破招值, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 35708,
    uid: 211851,
    装备名称: '扬英囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.根骨, 值: 584 },
      { 属性: 属性类型.内功基础攻击, 值: 1138 },
      { 属性: 属性类型.加速等级, 值: 2933 },
      { 属性: 属性类型.破招值, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 35831,
    uid: 212274,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2720 },
      { 属性: 属性类型.破招值, 值: 1899 },
      { 属性: 属性类型.内功破防等级, 值: 2057 },
      { 属性: 属性类型.全会心等级, 值: 2057 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 35830,
    uid: 212273,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2465 },
      { 属性: 属性类型.破招值, 值: 3165 },
      { 属性: 属性类型.内功破防等级, 值: 3323 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 35829,
    uid: 212272,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2890 },
      { 属性: 属性类型.全会心等级, 值: 5618 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 37106,
    uid: 214579,
    装备名称: '西风漫·照',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2895 },
      { 属性: 属性类型.元气, 值: 561 },
      { 属性: 属性类型.内功基础攻击, 值: 1093 },
      { 属性: 属性类型.内功破防等级, 值: 2817 },
      { 属性: 属性类型.无双等级, 值: 2504 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 37105,
    uid: 214578,
    装备名称: '西风漫·琢',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2895 },
      { 属性: 属性类型.根骨, 值: 561 },
      { 属性: 属性类型.内功基础攻击, 值: 1093 },
      { 属性: 属性类型.内功破防等级, 值: 2817 },
      { 属性: 属性类型.无双等级, 值: 2504 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 35823,
    uid: 212230,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2329 },
      { 属性: 属性类型.破招值, 值: 1644 },
      { 属性: 属性类型.全会心等级, 值: 2840 },
      { 属性: 属性类型.全会心效果等级, 值: 1495 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 35822,
    uid: 212229,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2329 },
      { 属性: 属性类型.全会心等级, 值: 3737 },
      { 属性: 属性类型.全会心效果等级, 值: 2242 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 35821,
    uid: 212228,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2730 },
      { 属性: 属性类型.内功破防等级, 值: 5307 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 36534,
    uid: 210979,
    装备名称: '幽川囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2722 },
      { 属性: 属性类型.元气, 值: 528 },
      { 属性: 属性类型.内功基础攻击, 值: 1028 },
      { 属性: 属性类型.内功破防等级, 值: 2649 },
      { 属性: 属性类型.无双等级, 值: 2354 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 36533,
    uid: 210978,
    装备名称: '碧月囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2722 },
      { 属性: 属性类型.根骨, 值: 528 },
      { 属性: 属性类型.内功基础攻击, 值: 1028 },
      { 属性: 属性类型.内功破防等级, 值: 2649 },
      { 属性: 属性类型.无双等级, 值: 2354 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 35937,
    uid: 212512,
    装备名称: '西风北啸·离声囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.元气, 值: 522 },
      { 属性: 属性类型.内功基础攻击, 值: 1015 },
      { 属性: 属性类型.全会心等级, 值: 2617 },
      { 属性: 属性类型.无双等级, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 35936,
    uid: 212511,
    装备名称: '西风北啸·音书囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.根骨, 值: 522 },
      { 属性: 属性类型.内功基础攻击, 值: 1015 },
      { 属性: 属性类型.内功会心等级, 值: 2617 },
      { 属性: 属性类型.无双等级, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 34817,
    uid: 208384,
    装备名称: '湖烟囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.元气, 值: 522 },
      { 属性: 属性类型.内功基础攻击, 值: 1015 },
      { 属性: 属性类型.全会心等级, 值: 2617 },
      { 属性: 属性类型.破招值, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 34816,
    uid: 208383,
    装备名称: '湖寂囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.根骨, 值: 522 },
      { 属性: 属性类型.内功基础攻击, 值: 1015 },
      { 属性: 属性类型.内功会心等级, 值: 2617 },
      { 属性: 属性类型.破招值, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 34767,
    uid: 208100,
    装备名称: '道迟囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.元气, 值: 522 },
      { 属性: 属性类型.内功基础攻击, 值: 1015 },
      { 属性: 属性类型.内功破防等级, 值: 2617 },
      { 属性: 属性类型.破招值, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 34766,
    uid: 208099,
    装备名称: '溪柔囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.根骨, 值: 522 },
      { 属性: 属性类型.内功基础攻击, 值: 1015 },
      { 属性: 属性类型.内功破防等级, 值: 2617 },
      { 属性: 属性类型.破招值, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 34701,
    uid: 207996,
    装备名称: '沁渡囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.元气, 值: 522 },
      { 属性: 属性类型.内功基础攻击, 值: 1015 },
      { 属性: 属性类型.全会心等级, 值: 2617 },
      { 属性: 属性类型.无双等级, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 34700,
    uid: 207995,
    装备名称: '朝华囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.根骨, 值: 522 },
      { 属性: 属性类型.内功基础攻击, 值: 1015 },
      { 属性: 属性类型.内功会心等级, 值: 2617 },
      { 属性: 属性类型.无双等级, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 34695,
    uid: 207936,
    装备名称: '椴微囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.元气, 值: 522 },
      { 属性: 属性类型.内功基础攻击, 值: 1015 },
      { 属性: 属性类型.加速等级, 值: 2617 },
      { 属性: 属性类型.破招值, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 34694,
    uid: 207935,
    装备名称: '池泓囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.根骨, 值: 522 },
      { 属性: 属性类型.内功基础攻击, 值: 1015 },
      { 属性: 属性类型.加速等级, 值: 2617 },
      { 属性: 属性类型.破招值, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 32545,
    uid: 189042,
    装备名称: '临甘囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2679 },
      { 属性: 属性类型.元气, 值: 520 },
      { 属性: 属性类型.内功基础攻击, 值: 1011 },
      { 属性: 属性类型.全会心等级, 值: 2607 },
      { 属性: 属性类型.无双等级, 值: 2317 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 32544,
    uid: 189041,
    装备名称: '临珏囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2679 },
      { 属性: 属性类型.根骨, 值: 520 },
      { 属性: 属性类型.内功基础攻击, 值: 1011 },
      { 属性: 属性类型.内功会心等级, 值: 2607 },
      { 属性: 属性类型.无双等级, 值: 2317 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 38350,
    uid: 217862,
    装备名称: '若寓囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.元气, 值: 483 },
      { 属性: 属性类型.内功基础攻击, 值: 772 },
      { 属性: 属性类型.全会心等级, 值: 2586 },
      { 属性: 属性类型.全能等级, 值: 1149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 38349,
    uid: 217861,
    装备名称: '越风囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.根骨, 值: 483 },
      { 属性: 属性类型.内功基础攻击, 值: 772 },
      { 属性: 属性类型.内功会心等级, 值: 2586 },
      { 属性: 属性类型.全能等级, 值: 1149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 38313,
    uid: 217771,
    装备名称: '踏江囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.元气, 值: 483 },
      { 属性: 属性类型.内功基础攻击, 值: 772 },
      { 属性: 属性类型.内功破防等级, 值: 2586 },
      { 属性: 属性类型.全能等级, 值: 1149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 38312,
    uid: 217770,
    装备名称: '承康囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.根骨, 值: 483 },
      { 属性: 属性类型.内功基础攻击, 值: 772 },
      { 属性: 属性类型.内功破防等级, 值: 2586 },
      { 属性: 属性类型.全能等级, 值: 1149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 32695,
    uid: 189408,
    装备名称: '昭夏',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.元气, 值: 515 },
      { 属性: 属性类型.内功基础攻击, 值: 1003 },
      { 属性: 属性类型.全会心等级, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 32694,
    uid: 189407,
    装备名称: '思勉',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.根骨, 值: 515 },
      { 属性: 属性类型.内功基础攻击, 值: 1003 },
      { 属性: 属性类型.内功会心等级, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 32659,
    uid: 189372,
    装备名称: '藏峦囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.元气, 值: 515 },
      { 属性: 属性类型.内功基础攻击, 值: 1003 },
      { 属性: 属性类型.内功破防等级, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 32658,
    uid: 189371,
    装备名称: '谨峰囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.根骨, 值: 515 },
      { 属性: 属性类型.内功基础攻击, 值: 1003 },
      { 属性: 属性类型.内功破防等级, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 32623,
    uid: 189282,
    装备名称: '故芳囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.元气, 值: 515 },
      { 属性: 属性类型.内功基础攻击, 值: 1003 },
      { 属性: 属性类型.加速等级, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 32622,
    uid: 189281,
    装备名称: '剪桐囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.根骨, 值: 515 },
      { 属性: 属性类型.内功基础攻击, 值: 1003 },
      { 属性: 属性类型.加速等级, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 32587,
    uid: 189192,
    装备名称: '花霭囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.元气, 值: 515 },
      { 属性: 属性类型.内功基础攻击, 值: 1003 },
      { 属性: 属性类型.内功破防等级, 值: 2586 },
      { 属性: 属性类型.破招值, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 32586,
    uid: 189191,
    装备名称: '途南囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.根骨, 值: 515 },
      { 属性: 属性类型.内功基础攻击, 值: 1003 },
      { 属性: 属性类型.内功破防等级, 值: 2586 },
      { 属性: 属性类型.破招值, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 32551,
    uid: 189102,
    装备名称: '庭澜囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.元气, 值: 515 },
      { 属性: 属性类型.内功基础攻击, 值: 1003 },
      { 属性: 属性类型.破招值, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 32550,
    uid: 189101,
    装备名称: '故云囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.根骨, 值: 515 },
      { 属性: 属性类型.内功基础攻击, 值: 1003 },
      { 属性: 属性类型.破招值, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 32491,
    uid: 188820,
    装备名称: '忆惜囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.元气, 值: 515 },
      { 属性: 属性类型.内功基础攻击, 值: 1003 },
      { 属性: 属性类型.全会心等级, 值: 2586 },
      { 属性: 属性类型.破招值, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 32490,
    uid: 188819,
    装备名称: '忆安囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.根骨, 值: 515 },
      { 属性: 属性类型.内功基础攻击, 值: 1003 },
      { 属性: 属性类型.内功会心等级, 值: 2586 },
      { 属性: 属性类型.破招值, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 32455,
    uid: 188730,
    装备名称: '语阔囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.元气, 值: 515 },
      { 属性: 属性类型.内功基础攻击, 值: 1003 },
      { 属性: 属性类型.内功破防等级, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 32454,
    uid: 188729,
    装备名称: '擒雨囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.根骨, 值: 515 },
      { 属性: 属性类型.内功基础攻击, 值: 1003 },
      { 属性: 属性类型.内功破防等级, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 32419,
    uid: 188640,
    装备名称: '烟琐囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.元气, 值: 515 },
      { 属性: 属性类型.内功基础攻击, 值: 1003 },
      { 属性: 属性类型.内功破防等级, 值: 2586 },
      { 属性: 属性类型.破招值, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 32418,
    uid: 188639,
    装备名称: '德襄囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.根骨, 值: 515 },
      { 属性: 属性类型.内功基础攻击, 值: 1003 },
      { 属性: 属性类型.内功破防等级, 值: 2586 },
      { 属性: 属性类型.破招值, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 34812,
    uid: 208325,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2201 },
      { 属性: 属性类型.内功破防等级, 值: 2049 },
      { 属性: 属性类型.全会心等级, 值: 2190 },
      { 属性: 属性类型.全会心效果等级, 值: 1413 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 34811,
    uid: 208324,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2201 },
      { 属性: 属性类型.破招值, 值: 2968 },
      { 属性: 属性类型.全会心等级, 值: 2826 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 34810,
    uid: 208323,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2581 },
      { 属性: 属性类型.无双等级, 值: 5017 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
]

export default 暗器装备数据
