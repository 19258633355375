import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 护腕装备数据: 装备属性信息模型[] = [
  {
    id: 98479,
    uid: 215167,
    装备名称: '水泓袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3983 },
      { 属性: 属性类型.元气, 值: 772 },
      { 属性: 属性类型.内功基础攻击, 值: 1503 },
      { 属性: 属性类型.内功破防等级, 值: 3875 },
      { 属性: 属性类型.破招值, 值: 3444 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98478,
    uid: 215166,
    装备名称: '水川袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3983 },
      { 属性: 属性类型.根骨, 值: 772 },
      { 属性: 属性类型.内功基础攻击, 值: 1503 },
      { 属性: 属性类型.内功破防等级, 值: 3875 },
      { 属性: 属性类型.破招值, 值: 3444 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100032,
    uid: 216960,
    装备名称: '陶然意·和时护手',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3957 },
      { 属性: 属性类型.根骨, 值: 719 },
      { 属性: 属性类型.内功基础攻击, 值: 1149 },
      { 属性: 属性类型.内功破防等级, 值: 3850 },
      { 属性: 属性类型.全能等级, 值: 1711 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100031,
    uid: 216959,
    装备名称: '陶然意·星雪护手',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3957 },
      { 属性: 属性类型.元气, 值: 719 },
      { 属性: 属性类型.内功基础攻击, 值: 1149 },
      { 属性: 属性类型.内功破防等级, 值: 3850 },
      { 属性: 属性类型.全能等级, 值: 1711 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100015,
    uid: 216943,
    装备名称: '陶然意·兰谷护手',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3957 },
      { 属性: 属性类型.元气, 值: 719 },
      { 属性: 属性类型.内功基础攻击, 值: 1149 },
      { 属性: 属性类型.内功破防等级, 值: 3850 },
      { 属性: 属性类型.全能等级, 值: 1711 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98581,
    uid: 215342,
    装备名称: '月迟护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.元气, 值: 763 },
      { 属性: 属性类型.内功基础攻击, 值: 1484 },
      { 属性: 属性类型.全会心等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98580,
    uid: 215341,
    装备名称: '月纤护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.根骨, 值: 763 },
      { 属性: 属性类型.内功基础攻击, 值: 1484 },
      { 属性: 属性类型.内功会心等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98515,
    uid: 215234,
    装备名称: '客行江湖·磐气护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.元气, 值: 763 },
      { 属性: 属性类型.内功基础攻击, 值: 1484 },
      { 属性: 属性类型.内功破防等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98514,
    uid: 215233,
    装备名称: '客行江湖·风翎护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.根骨, 值: 763 },
      { 属性: 属性类型.内功基础攻击, 值: 1484 },
      { 属性: 属性类型.内功破防等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98471,
    uid: 215099,
    装备名称: '如光护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.内功基础攻击, 值: 3197 },
      { 属性: 属性类型.破招值, 值: 4464 },
      { 属性: 属性类型.内功破防等级, 值: 4039 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98470,
    uid: 215098,
    装备名称: '花绣护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.内功基础攻击, 值: 3882 },
      { 属性: 属性类型.无双等级, 值: 7121 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98456,
    uid: 215076,
    装备名称: '茂松护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3654 },
      { 属性: 属性类型.破招值, 值: 2551 },
      { 属性: 属性类型.内功破防等级, 值: 2763 },
      { 属性: 属性类型.全会心等级, 值: 2763 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98452,
    uid: 215072,
    装备名称: '分属护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3311 },
      { 属性: 属性类型.内功破防等级, 值: 3082 },
      { 属性: 属性类型.全会心等级, 值: 3295 },
      { 属性: 属性类型.全会心效果等级, 值: 2126 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98451,
    uid: 215071,
    装备名称: '赵以护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3311 },
      { 属性: 属性类型.内功破防等级, 值: 4251 },
      { 属性: 属性类型.无双等级, 值: 4464 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98450,
    uid: 215070,
    装备名称: '年月护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3882 },
      { 属性: 属性类型.全会心等级, 值: 7546 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 98407,
    uid: 214935,
    装备名称: '良安护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.元气, 值: 763 },
      { 属性: 属性类型.内功基础攻击, 值: 1484 },
      { 属性: 属性类型.内功破防等级, 值: 3826 },
      { 属性: 属性类型.无双等级, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 98406,
    uid: 214934,
    装备名称: '情义护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.根骨, 值: 763 },
      { 属性: 属性类型.内功基础攻击, 值: 1484 },
      { 属性: 属性类型.内功破防等级, 值: 3826 },
      { 属性: 属性类型.无双等级, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 98371,
    uid: 214875,
    装备名称: '宫阙护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.元气, 值: 763 },
      { 属性: 属性类型.内功基础攻击, 值: 1484 },
      { 属性: 属性类型.全会心等级, 值: 3826 },
      { 属性: 属性类型.无双等级, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98370,
    uid: 214874,
    装备名称: '绕城护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.根骨, 值: 763 },
      { 属性: 属性类型.内功基础攻击, 值: 1484 },
      { 属性: 属性类型.内功会心等级, 值: 3826 },
      { 属性: 属性类型.无双等级, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98246,
    uid: 214750,
    装备名称: '鸿辉·白林护手',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3882 },
      { 属性: 属性类型.根骨, 值: 753 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功会心等级, 值: 3777 },
      { 属性: 属性类型.破招值, 值: 3357 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98245,
    uid: 214749,
    装备名称: '鸿辉·梦辰护手',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3882 },
      { 属性: 属性类型.元气, 值: 753 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功会心等级, 值: 3777 },
      { 属性: 属性类型.破招值, 值: 3357 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98222,
    uid: 214726,
    装备名称: '鸿辉·鹿喧护手',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3882 },
      { 属性: 属性类型.元气, 值: 753 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功会心等级, 值: 3777 },
      { 属性: 属性类型.破招值, 值: 3357 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98564,
    uid: 215307,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3226 },
      { 属性: 属性类型.内功破防等级, 值: 3003 },
      { 属性: 属性类型.全会心等级, 值: 3210 },
      { 属性: 属性类型.全会心效果等级, 值: 2071 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98563,
    uid: 215306,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3226 },
      { 属性: 属性类型.全会心等级, 值: 4246 },
      { 属性: 属性类型.内功破防等级, 值: 4246 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98562,
    uid: 215305,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3783 },
      { 属性: 属性类型.全会心等级, 值: 7352 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 98540,
    uid: 215265,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3046 },
      { 属性: 属性类型.全会心等级, 值: 3715 },
      { 属性: 属性类型.全会心效果等级, 值: 1955 },
      { 属性: 属性类型.无双等级, 值: 2151 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98539,
    uid: 215264,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3046 },
      { 属性: 属性类型.破招值, 值: 3910 },
      { 属性: 属性类型.内功破防等级, 值: 4106 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 98538,
    uid: 215263,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3571 },
      { 属性: 属性类型.无双等级, 值: 6941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96365,
    uid: 212090,
    装备名称: '风绫袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3567 },
      { 属性: 属性类型.元气, 值: 692 },
      { 属性: 属性类型.内功基础攻击, 值: 1346 },
      { 属性: 属性类型.内功破防等级, 值: 3470 },
      { 属性: 属性类型.破招值, 值: 3085 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96364,
    uid: 212089,
    装备名称: '风轻袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3567 },
      { 属性: 属性类型.根骨, 值: 692 },
      { 属性: 属性类型.内功基础攻击, 值: 1346 },
      { 属性: 属性类型.内功破防等级, 值: 3470 },
      { 属性: 属性类型.破招值, 值: 3085 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 99906,
    uid: 216960,
    装备名称: '陶然意·和时护手',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3542 },
      { 属性: 属性类型.根骨, 值: 644 },
      { 属性: 属性类型.内功基础攻击, 值: 1028 },
      { 属性: 属性类型.内功破防等级, 值: 3446 },
      { 属性: 属性类型.全能等级, 值: 1531 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 99905,
    uid: 216959,
    装备名称: '陶然意·星雪护手',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3542 },
      { 属性: 属性类型.元气, 值: 644 },
      { 属性: 属性类型.内功基础攻击, 值: 1028 },
      { 属性: 属性类型.内功破防等级, 值: 3446 },
      { 属性: 属性类型.全能等级, 值: 1531 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 99889,
    uid: 216943,
    装备名称: '陶然意·兰谷护手',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3542 },
      { 属性: 属性类型.元气, 值: 644 },
      { 属性: 属性类型.内功基础攻击, 值: 1028 },
      { 属性: 属性类型.内功破防等级, 值: 3446 },
      { 属性: 属性类型.全能等级, 值: 1531 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98677,
    uid: 215539,
    装备名称: '东方日出·当楼护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.元气, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.全会心等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98676,
    uid: 215538,
    装备名称: '东方日出·所适护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.根骨, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.内功会心等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96475,
    uid: 212279,
    装备名称: '泉麓护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.元气, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.全会心等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96474,
    uid: 212278,
    装备名称: '泉合护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.根骨, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.内功会心等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96401,
    uid: 212157,
    装备名称: '客行江湖·断意护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.元气, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.内功破防等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96400,
    uid: 212156,
    装备名称: '客行江湖·意悠护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.根骨, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.内功破防等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96357,
    uid: 212022,
    装备名称: '松辉护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.内功基础攻击, 值: 2859 },
      { 属性: 属性类型.破招值, 值: 3991 },
      { 属性: 属性类型.内功破防等级, 值: 3611 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96356,
    uid: 212021,
    装备名称: '雪遥护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.内功基础攻击, 值: 3472 },
      { 属性: 属性类型.无双等级, 值: 6367 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96342,
    uid: 211999,
    装备名称: '双河护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3267 },
      { 属性: 属性类型.破招值, 值: 2281 },
      { 属性: 属性类型.内功破防等级, 值: 2471 },
      { 属性: 属性类型.全会心等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96338,
    uid: 211995,
    装备名称: '淮泽护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2961 },
      { 属性: 属性类型.内功破防等级, 值: 2756 },
      { 属性: 属性类型.全会心等级, 值: 2946 },
      { 属性: 属性类型.全会心效果等级, 值: 1901 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96337,
    uid: 211994,
    装备名称: '言寓护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2961 },
      { 属性: 属性类型.内功破防等级, 值: 3801 },
      { 属性: 属性类型.无双等级, 值: 3991 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96336,
    uid: 211993,
    装备名称: '雨杨护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3472 },
      { 属性: 属性类型.全会心等级, 值: 6748 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 96293,
    uid: 211858,
    装备名称: '壑云护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.元气, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.内功破防等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 96292,
    uid: 211857,
    装备名称: '寒绡护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.根骨, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.内功破防等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 96257,
    uid: 211798,
    装备名称: '开颐护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.元气, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.全会心等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96256,
    uid: 211797,
    装备名称: '扬英护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.根骨, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.内功会心等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98161,
    uid: 214585,
    装备名称: '寻踪觅宝·星辉袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13750,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.元气, 值: 672 },
      { 属性: 属性类型.内功基础攻击, 值: 1308 },
      { 属性: 属性类型.内功破防等级, 值: 3372 },
      { 属性: 属性类型.破招值, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98160,
    uid: 214584,
    装备名称: '寻踪觅宝·折月袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13750,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.根骨, 值: 672 },
      { 属性: 属性类型.内功基础攻击, 值: 1308 },
      { 属性: 属性类型.内功破防等级, 值: 3372 },
      { 属性: 属性类型.破招值, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96132,
    uid: 211673,
    装备名称: '灵源·采芳护手',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.根骨, 值: 672 },
      { 属性: 属性类型.内功基础攻击, 值: 1308 },
      { 属性: 属性类型.内功会心等级, 值: 3372 },
      { 属性: 属性类型.破招值, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96131,
    uid: 211672,
    装备名称: '灵源·沉辉护手',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.元气, 值: 672 },
      { 属性: 属性类型.内功基础攻击, 值: 1308 },
      { 属性: 属性类型.内功会心等级, 值: 3372 },
      { 属性: 属性类型.破招值, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96108,
    uid: 211649,
    装备名称: '灵源·月胧护手',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.元气, 值: 672 },
      { 属性: 属性类型.内功基础攻击, 值: 1308 },
      { 属性: 属性类型.内功会心等级, 值: 3372 },
      { 属性: 属性类型.破招值, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96458,
    uid: 212244,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2876 },
      { 属性: 属性类型.破招值, 值: 2031 },
      { 属性: 属性类型.全会心等级, 值: 3508 },
      { 属性: 属性类型.全会心效果等级, 值: 1846 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96457,
    uid: 212243,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2876 },
      { 属性: 属性类型.全会心等级, 值: 4616 },
      { 属性: 属性类型.全会心效果等级, 值: 2769 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96456,
    uid: 212242,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3372 },
      { 属性: 属性类型.内功破防等级, 值: 6554 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96428,
    uid: 212190,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2717 },
      { 属性: 属性类型.内功破防等级, 值: 2529 },
      { 属性: 属性类型.全会心等级, 值: 2703 },
      { 属性: 属性类型.全会心效果等级, 值: 1744 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96427,
    uid: 212189,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2717 },
      { 属性: 属性类型.全会心等级, 值: 3575 },
      { 属性: 属性类型.内功破防等级, 值: 3575 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96426,
    uid: 212188,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3185 },
      { 属性: 属性类型.全会心等级, 值: 6191 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 94462,
    uid: 208156,
    装备名称: '雪洁袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3176 },
      { 属性: 属性类型.元气, 值: 616 },
      { 属性: 属性类型.内功基础攻击, 值: 1199 },
      { 属性: 属性类型.内功破防等级, 值: 3090 },
      { 属性: 属性类型.破招值, 值: 2747 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 94461,
    uid: 208155,
    装备名称: '雪满袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3176 },
      { 属性: 属性类型.根骨, 值: 616 },
      { 属性: 属性类型.内功基础攻击, 值: 1199 },
      { 属性: 属性类型.内功破防等级, 值: 3090 },
      { 属性: 属性类型.破招值, 值: 2747 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96571,
    uid: 212476,
    装备名称: '西风北啸·离声护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.元气, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.全会心等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96570,
    uid: 212475,
    装备名称: '西风北啸·音书护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.根骨, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.内功会心等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94564,
    uid: 208330,
    装备名称: '湖烟护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.元气, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.全会心等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94563,
    uid: 208329,
    装备名称: '湖寂护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.根骨, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.内功会心等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94498,
    uid: 208222,
    装备名称: '客行江湖·千世护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.元气, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.内功破防等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 94497,
    uid: 208221,
    装备名称: '客行江湖·渐浓护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.根骨, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.内功破防等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 94451,
    uid: 208081,
    装备名称: '熠羽护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.内功基础攻击, 值: 3098 },
      { 属性: 属性类型.破招值, 值: 5683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 94447,
    uid: 208077,
    装备名称: '维则护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.内功基础攻击, 值: 2187 },
      { 属性: 属性类型.全会心等级, 值: 2205 },
      { 属性: 属性类型.无双等级, 值: 5428 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94446,
    uid: 208076,
    装备名称: '风重护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.内功基础攻击, 值: 2552 },
      { 属性: 属性类型.内功破防等级, 值: 3223 },
      { 属性: 属性类型.无双等级, 值: 3562 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94445,
    uid: 208075,
    装备名称: '狂耳护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.内功基础攻击, 值: 2552 },
      { 属性: 属性类型.破招值, 值: 3562 },
      { 属性: 属性类型.内功破防等级, 值: 3223 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94402,
    uid: 207942,
    装备名称: '沁渡护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.元气, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.内功破防等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 94401,
    uid: 207941,
    装备名称: '朝华护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.根骨, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.内功破防等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 94366,
    uid: 207882,
    装备名称: '椴微护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.元气, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.全会心等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94365,
    uid: 207881,
    装备名称: '池泓护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.根骨, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.内功会心等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 90508,
    uid: 189000,
    装备名称: '临穹护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3126 },
      { 属性: 属性类型.元气, 值: 606 },
      { 属性: 属性类型.内功基础攻击, 值: 1180 },
      { 属性: 属性类型.全会心等级, 值: 3041 },
      { 属性: 属性类型.破招值, 值: 2703 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 90507,
    uid: 188999,
    装备名称: '临心护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3126 },
      { 属性: 属性类型.根骨, 值: 606 },
      { 属性: 属性类型.内功基础攻击, 值: 1180 },
      { 属性: 属性类型.内功会心等级, 值: 3041 },
      { 属性: 属性类型.破招值, 值: 2703 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100333,
    uid: 217808,
    装备名称: '若寓袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 900 },
      { 属性: 属性类型.全会心等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100332,
    uid: 217807,
    装备名称: '越风袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 900 },
      { 属性: 属性类型.内功会心等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100297,
    uid: 217717,
    装备名称: '踏江袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 900 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 100296,
    uid: 217716,
    装备名称: '承康袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 900 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 96077,
    uid: 211587,
    装备名称: '寻踪觅宝·泻雨袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96076,
    uid: 211586,
    装备名称: '寻踪觅宝·拂雪袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 94246,
    uid: 207762,
    装备名称: '濯心·采青护手',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功会心等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94245,
    uid: 207761,
    装备名称: '濯心·天尘护手',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功会心等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94222,
    uid: 207738,
    装备名称: '濯心·松声护手',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功会心等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 90640,
    uid: 189318,
    装备名称: '藏峦袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 90639,
    uid: 189317,
    装备名称: '谨峰袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 90604,
    uid: 189228,
    装备名称: '故芳袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.全会心等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 90603,
    uid: 189227,
    装备名称: '剪桐袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功会心等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 90568,
    uid: 189138,
    装备名称: '花霭护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 90567,
    uid: 189137,
    装备名称: '途南护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 90532,
    uid: 189048,
    装备名称: '庭澜护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 90531,
    uid: 189047,
    装备名称: '故云护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 90400,
    uid: 188766,
    装备名称: '忆惜护手',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 90399,
    uid: 188765,
    装备名称: '忆安护手',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 90364,
    uid: 188676,
    装备名称: '语阔袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.破招值, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90363,
    uid: 188675,
    装备名称: '擒雨袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.破招值, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90328,
    uid: 188586,
    装备名称: '烟琐袖',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 90327,
    uid: 188585,
    装备名称: '德襄袖',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94547,
    uid: 208295,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2568 },
      { 属性: 属性类型.全会心等级, 值: 3132 },
      { 属性: 属性类型.全会心效果等级, 值: 1649 },
      { 属性: 属性类型.无双等级, 值: 1814 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94546,
    uid: 208294,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2568 },
      { 属性: 属性类型.破招值, 值: 3297 },
      { 属性: 属性类型.内功破防等级, 值: 3462 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 94545,
    uid: 208293,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3011 },
      { 属性: 属性类型.无双等级, 值: 5853 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
]

export default 护腕装备数据
