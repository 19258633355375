import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 武器装备数据: 装备属性信息模型[] = [
  {
    id: 38587,
    uid: 218230,
    装备名称: '煞·庄生晓梦',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.身法, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5633 },
      { 属性: 属性类型.外功会心等级, 值: 5880 },
      { 属性: 属性类型.外功破防等级, 值: 5880 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38586,
    uid: 218229,
    装备名称: '煞·绝地天通刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.力道, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5543 },
      { 属性: 属性类型.外功会心等级, 值: 5880 },
      { 属性: 属性类型.外功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38581,
    uid: 218224,
    装备名称: '煞·山海云涯',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.身法, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5452 },
      { 属性: 属性类型.外功会心等级, 值: 6083 },
      { 属性: 属性类型.外功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38077,
    uid: 214213,
    装备名称: '庄生晓梦',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.身法, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5633 },
      { 属性: 属性类型.外功会心等级, 值: 5880 },
      { 属性: 属性类型.外功破防等级, 值: 5880 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38076,
    uid: 200994,
    装备名称: '绝地天通刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.力道, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5543 },
      { 属性: 属性类型.外功会心等级, 值: 5880 },
      { 属性: 属性类型.外功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38071,
    uid: 200989,
    装备名称: '山海云涯',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.身法, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5452 },
      { 属性: 属性类型.外功会心等级, 值: 6083 },
      { 属性: 属性类型.外功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38046,
    uid: 214213,
    装备名称: '庄生晓梦',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 11900,
    武器伤害_最小值: 3844,
    武器伤害_最大值: 6407,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7142 },
      { 属性: 属性类型.身法, 值: 1385 },
      { 属性: 属性类型.外功基础攻击, 值: 5363 },
      { 属性: 属性类型.外功会心等级, 值: 5598 },
      { 属性: 属性类型.外功破防等级, 值: 5598 },
      { 属性: 属性类型.加速等级, 值: 1930 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38045,
    uid: 200994,
    装备名称: '绝地天通刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 11900,
    武器伤害_最小值: 3844,
    武器伤害_最大值: 6407,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7142 },
      { 属性: 属性类型.力道, 值: 1385 },
      { 属性: 属性类型.外功基础攻击, 值: 5277 },
      { 属性: 属性类型.外功会心等级, 值: 5598 },
      { 属性: 属性类型.外功破防等级, 值: 5791 },
      { 属性: 属性类型.加速等级, 值: 1930 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38040,
    uid: 200989,
    装备名称: '山海云涯',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 11900,
    武器伤害_最小值: 3844,
    武器伤害_最大值: 6407,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7142 },
      { 属性: 属性类型.身法, 值: 1385 },
      { 属性: 属性类型.外功基础攻击, 值: 5190 },
      { 属性: 属性类型.外功会心等级, 值: 5791 },
      { 属性: 属性类型.外功破防等级, 值: 5791 },
      { 属性: 属性类型.加速等级, 值: 1930 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37104,
    uid: 214571,
    装备名称: '未央之夏',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 11650,
    武器伤害_最小值: 3763,
    武器伤害_最大值: 6272,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.身法, 值: 1356 },
      { 属性: 属性类型.外功基础攻击, 值: 5250 },
      { 属性: 属性类型.外功会心等级, 值: 5480 },
      { 属性: 属性类型.外功破防等级, 值: 5480 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37103,
    uid: 214570,
    装备名称: '渊鸣',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 11650,
    武器伤害_最小值: 3763,
    武器伤害_最大值: 6272,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.力道, 值: 1356 },
      { 属性: 属性类型.外功基础攻击, 值: 5166 },
      { 属性: 属性类型.外功会心等级, 值: 5480 },
      { 属性: 属性类型.外功破防等级, 值: 5669 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37098,
    uid: 214565,
    装备名称: '槎舟渡星',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 11650,
    武器伤害_最小值: 3763,
    武器伤害_最大值: 6272,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.身法, 值: 1356 },
      { 属性: 属性类型.外功基础攻击, 值: 5081 },
      { 属性: 属性类型.外功会心等级, 值: 5669 },
      { 属性: 属性类型.外功破防等级, 值: 5669 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36797,
    uid: 214213,
    装备名称: '庄生晓梦',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 11300,
    武器伤害_最小值: 3650,
    武器伤害_最大值: 6084,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6782 },
      { 属性: 属性类型.身法, 值: 1315 },
      { 属性: 属性类型.外功基础攻击, 值: 5093 },
      { 属性: 属性类型.外功会心等级, 值: 5315 },
      { 属性: 属性类型.外功破防等级, 值: 5315 },
      { 属性: 属性类型.加速等级, 值: 1833 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36598,
    uid: 200994,
    装备名称: '绝地天通刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 11300,
    武器伤害_最小值: 3650,
    武器伤害_最大值: 6084,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6782 },
      { 属性: 属性类型.力道, 值: 1315 },
      { 属性: 属性类型.外功基础攻击, 值: 5011 },
      { 属性: 属性类型.外功会心等级, 值: 5315 },
      { 属性: 属性类型.外功破防等级, 值: 5499 },
      { 属性: 属性类型.加速等级, 值: 1833 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36593,
    uid: 200989,
    装备名称: '山海云涯',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 11300,
    武器伤害_最小值: 3650,
    武器伤害_最大值: 6084,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6782 },
      { 属性: 属性类型.身法, 值: 1315 },
      { 属性: 属性类型.外功基础攻击, 值: 4929 },
      { 属性: 属性类型.外功会心等级, 值: 5499 },
      { 属性: 属性类型.外功破防等级, 值: 5499 },
      { 属性: 属性类型.加速等级, 值: 1833 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37073,
    uid: 214571,
    装备名称: '未央之夏',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 10900,
    武器伤害_最小值: 3521,
    武器伤害_最大值: 5868,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6542 },
      { 属性: 属性类型.身法, 值: 1269 },
      { 属性: 属性类型.外功基础攻击, 值: 4912 },
      { 属性: 属性类型.外功会心等级, 值: 5127 },
      { 属性: 属性类型.外功破防等级, 值: 5127 },
      { 属性: 属性类型.加速等级, 值: 1768 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37072,
    uid: 214570,
    装备名称: '渊鸣',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 10900,
    武器伤害_最小值: 3521,
    武器伤害_最大值: 5868,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6542 },
      { 属性: 属性类型.力道, 值: 1269 },
      { 属性: 属性类型.外功基础攻击, 值: 4833 },
      { 属性: 属性类型.外功会心等级, 值: 5127 },
      { 属性: 属性类型.外功破防等级, 值: 5304 },
      { 属性: 属性类型.加速等级, 值: 1768 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37067,
    uid: 214565,
    装备名称: '槎舟渡星',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 10900,
    武器伤害_最小值: 3521,
    武器伤害_最大值: 5868,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6542 },
      { 属性: 属性类型.身法, 值: 1269 },
      { 属性: 属性类型.外功基础攻击, 值: 4754 },
      { 属性: 属性类型.外功会心等级, 值: 5304 },
      { 属性: 属性类型.外功破防等级, 值: 5304 },
      { 属性: 属性类型.加速等级, 值: 1768 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36796,
    uid: 214213,
    装备名称: '庄生晓梦',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 10800,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6482 },
      { 属性: 属性类型.身法, 值: 1257 },
      { 属性: 属性类型.外功基础攻击, 值: 4867 },
      { 属性: 属性类型.外功会心等级, 值: 5080 },
      { 属性: 属性类型.外功破防等级, 值: 5080 },
      { 属性: 属性类型.加速等级, 值: 1752 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36568,
    uid: 200994,
    装备名称: '绝地天通刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 10800,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6482 },
      { 属性: 属性类型.力道, 值: 1257 },
      { 属性: 属性类型.外功基础攻击, 值: 4789 },
      { 属性: 属性类型.外功会心等级, 值: 5080 },
      { 属性: 属性类型.外功破防等级, 值: 5255 },
      { 属性: 属性类型.加速等级, 值: 1752 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36563,
    uid: 200989,
    装备名称: '山海云涯',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 10800,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6482 },
      { 属性: 属性类型.身法, 值: 1257 },
      { 属性: 属性类型.外功基础攻击, 值: 4710 },
      { 属性: 属性类型.外功会心等级, 值: 5255 },
      { 属性: 属性类型.外功破防等级, 值: 5255 },
      { 属性: 属性类型.加速等级, 值: 1752 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37042,
    uid: 214540,
    装备名称: '未央之夏',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 10300,
    武器伤害_最小值: 3327,
    武器伤害_最大值: 5545,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6182 },
      { 属性: 属性类型.身法, 值: 1199 },
      { 属性: 属性类型.外功基础攻击, 值: 4642 },
      { 属性: 属性类型.外功会心等级, 值: 4845 },
      { 属性: 属性类型.外功破防等级, 值: 4845 },
      { 属性: 属性类型.加速等级, 值: 1671 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37041,
    uid: 214539,
    装备名称: '渊鸣',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 10300,
    武器伤害_最小值: 3327,
    武器伤害_最大值: 5545,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6182 },
      { 属性: 属性类型.力道, 值: 1199 },
      { 属性: 属性类型.外功基础攻击, 值: 4567 },
      { 属性: 属性类型.外功会心等级, 值: 4845 },
      { 属性: 属性类型.外功破防等级, 值: 5012 },
      { 属性: 属性类型.加速等级, 值: 1671 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37036,
    uid: 214534,
    装备名称: '槎舟渡星',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 10300,
    武器伤害_最小值: 3327,
    武器伤害_最大值: 5545,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6182 },
      { 属性: 属性类型.身法, 值: 1199 },
      { 属性: 属性类型.外功基础攻击, 值: 4492 },
      { 属性: 属性类型.外功会心等级, 值: 5012 },
      { 属性: 属性类型.外功破防等级, 值: 5012 },
      { 属性: 属性类型.加速等级, 值: 1671 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36795,
    uid: 214213,
    装备名称: '庄生晓梦',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 10300,
    武器伤害_最小值: 3327,
    武器伤害_最大值: 5545,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6182 },
      { 属性: 属性类型.身法, 值: 1199 },
      { 属性: 属性类型.外功基础攻击, 值: 4642 },
      { 属性: 属性类型.外功会心等级, 值: 4845 },
      { 属性: 属性类型.外功破防等级, 值: 4845 },
      { 属性: 属性类型.加速等级, 值: 1671 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 35627,
    uid: 200994,
    装备名称: '绝地天通刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 10300,
    武器伤害_最小值: 3327,
    武器伤害_最大值: 5545,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6182 },
      { 属性: 属性类型.力道, 值: 1199 },
      { 属性: 属性类型.外功基础攻击, 值: 4567 },
      { 属性: 属性类型.外功会心等级, 值: 4845 },
      { 属性: 属性类型.外功破防等级, 值: 5012 },
      { 属性: 属性类型.加速等级, 值: 1671 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 35622,
    uid: 200989,
    装备名称: '山海云涯',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 10300,
    武器伤害_最小值: 3327,
    武器伤害_最大值: 5545,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6182 },
      { 属性: 属性类型.身法, 值: 1199 },
      { 属性: 属性类型.外功基础攻击, 值: 4492 },
      { 属性: 属性类型.外功会心等级, 值: 5012 },
      { 属性: 属性类型.外功破防等级, 值: 5012 },
      { 属性: 属性类型.加速等级, 值: 1671 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38274,
    uid: 217624,
    装备名称: '花驻影',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 10100,
    武器伤害_最小值: 3263,
    武器伤害_最大值: 5438,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6062 },
      { 属性: 属性类型.身法, 值: 1175 },
      { 属性: 属性类型.外功基础攻击, 值: 4552 },
      { 属性: 属性类型.外功会心等级, 值: 4751 },
      { 属性: 属性类型.外功破防等级, 值: 4751 },
      { 属性: 属性类型.加速等级, 值: 1638 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38273,
    uid: 217623,
    装备名称: '曾逝水',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 10100,
    武器伤害_最小值: 3263,
    武器伤害_最大值: 5438,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6062 },
      { 属性: 属性类型.力道, 值: 1175 },
      { 属性: 属性类型.外功基础攻击, 值: 4479 },
      { 属性: 属性类型.外功会心等级, 值: 4751 },
      { 属性: 属性类型.外功破防等级, 值: 4915 },
      { 属性: 属性类型.加速等级, 值: 1638 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38268,
    uid: 217618,
    装备名称: '渡霄汉',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 10100,
    武器伤害_最小值: 3263,
    武器伤害_最大值: 5438,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6062 },
      { 属性: 属性类型.身法, 值: 1175 },
      { 属性: 属性类型.外功基础攻击, 值: 4405 },
      { 属性: 属性类型.外功会心等级, 值: 4915 },
      { 属性: 属性类型.外功破防等级, 值: 4915 },
      { 属性: 属性类型.加速等级, 值: 1638 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37291,
    uid: 215232,
    装备名称: '大风吟',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15800,
    武器伤害_最小值: 3675,
    武器伤害_最大值: 6125,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6827 },
      { 属性: 属性类型.身法, 值: 1324 },
      { 属性: 属性类型.外功基础攻击, 值: 5127 },
      { 属性: 属性类型.外功会心等级, 值: 6643 },
      { 属性: 属性类型.破招值, 值: 7086 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37290,
    uid: 215231,
    装备名称: '未试风霜',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15800,
    武器伤害_最小值: 3675,
    武器伤害_最大值: 6125,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6827 },
      { 属性: 属性类型.力道, 值: 1324 },
      { 属性: 属性类型.外功基础攻击, 值: 5127 },
      { 属性: 属性类型.外功会心等级, 值: 6643 },
      { 属性: 属性类型.破招值, 值: 7086 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37285,
    uid: 215226,
    装备名称: '相逢人间',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15800,
    武器伤害_最小值: 3675,
    武器伤害_最大值: 6125,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6827 },
      { 属性: 属性类型.身法, 值: 1324 },
      { 属性: 属性类型.外功基础攻击, 值: 5127 },
      { 属性: 属性类型.外功会心等级, 值: 6643 },
      { 属性: 属性类型.破招值, 值: 7086 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38184,
    uid: 217110,
    装备名称: '陶然意·旭影弓',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15700,
    武器伤害_最小值: 3652,
    武器伤害_最大值: 6087,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6784 },
      { 属性: 属性类型.身法, 值: 1233 },
      { 属性: 属性类型.外功基础攻击, 值: 4602 },
      { 属性: 属性类型.外功破防等级, 值: 6601 },
      { 属性: 属性类型.全能等级, 值: 2934 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38183,
    uid: 217109,
    装备名称: '陶然意·沉麟刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15700,
    武器伤害_最小值: 3652,
    武器伤害_最大值: 6087,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6784 },
      { 属性: 属性类型.力道, 值: 1233 },
      { 属性: 属性类型.外功基础攻击, 值: 4602 },
      { 属性: 属性类型.外功破防等级, 值: 6601 },
      { 属性: 属性类型.全能等级, 值: 2934 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38179,
    uid: 217105,
    装备名称: '陶然意·弘阔伞',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15700,
    武器伤害_最小值: 3652,
    武器伤害_最大值: 6087,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6784 },
      { 属性: 属性类型.身法, 值: 1233 },
      { 属性: 属性类型.外功基础攻击, 值: 4602 },
      { 属性: 属性类型.外功破防等级, 值: 6601 },
      { 属性: 属性类型.全能等级, 值: 2934 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 37340,
    uid: 215413,
    装备名称: '山灵语',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15600,
    武器伤害_最小值: 3629,
    武器伤害_最大值: 6048,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.身法, 值: 1307 },
      { 属性: 属性类型.外功基础攻击, 值: 5062 },
      { 属性: 属性类型.外功破防等级, 值: 6559 },
      { 属性: 属性类型.破招值, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37339,
    uid: 215412,
    装备名称: '问刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15600,
    武器伤害_最小值: 3629,
    武器伤害_最大值: 6048,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.力道, 值: 1307 },
      { 属性: 属性类型.外功基础攻击, 值: 5062 },
      { 属性: 属性类型.外功破防等级, 值: 6559 },
      { 属性: 属性类型.破招值, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37334,
    uid: 215407,
    装备名称: '横分青云',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15600,
    武器伤害_最小值: 3629,
    武器伤害_最大值: 6048,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.身法, 值: 1307 },
      { 属性: 属性类型.外功基础攻击, 值: 5062 },
      { 属性: 属性类型.外功破防等级, 值: 6559 },
      { 属性: 属性类型.破招值, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37252,
    uid: 215061,
    装备名称: '山有神通',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15600,
    武器伤害_最小值: 3629,
    武器伤害_最大值: 6048,
    装备特效: 装备特效枚举.外功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.身法, 值: 1307 },
      { 属性: 属性类型.外功基础攻击, 值: 5062 },
      { 属性: 属性类型.外功破防等级, 值: 6559 },
      { 属性: 属性类型.无双等级, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37251,
    uid: 215060,
    装备名称: '临渊问境',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15600,
    武器伤害_最小值: 3629,
    武器伤害_最大值: 6048,
    装备特效: 装备特效枚举.外功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.力道, 值: 1307 },
      { 属性: 属性类型.外功基础攻击, 值: 5062 },
      { 属性: 属性类型.外功破防等级, 值: 6559 },
      { 属性: 属性类型.无双等级, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37246,
    uid: 215055,
    装备名称: '俯望尘世',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15600,
    武器伤害_最小值: 3629,
    武器伤害_最大值: 6048,
    装备特效: 装备特效枚举.外功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.身法, 值: 1307 },
      { 属性: 属性类型.外功基础攻击, 值: 5062 },
      { 属性: 属性类型.外功破防等级, 值: 6559 },
      { 属性: 属性类型.无双等级, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37221,
    uid: 215024,
    装备名称: '雪无弓',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15600,
    武器伤害_最小值: 3629,
    武器伤害_最大值: 6048,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.身法, 值: 1307 },
      { 属性: 属性类型.外功基础攻击, 值: 5062 },
      { 属性: 属性类型.外功会心等级, 值: 6559 },
      { 属性: 属性类型.无双等级, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 37220,
    uid: 215023,
    装备名称: '烈希刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15600,
    武器伤害_最小值: 3629,
    武器伤害_最大值: 6048,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.力道, 值: 1307 },
      { 属性: 属性类型.外功基础攻击, 值: 5062 },
      { 属性: 属性类型.外功会心等级, 值: 6559 },
      { 属性: 属性类型.无双等级, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 37215,
    uid: 215018,
    装备名称: '书空伞',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15600,
    武器伤害_最小值: 3629,
    武器伤害_最大值: 6048,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.身法, 值: 1307 },
      { 属性: 属性类型.外功基础攻击, 值: 5062 },
      { 属性: 属性类型.外功会心等级, 值: 6559 },
      { 属性: 属性类型.无双等级, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 35820,
    uid: 212155,
    装备名称: '飞虹',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 14150,
    武器伤害_最小值: 3291,
    武器伤害_最大值: 5485,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6114 },
      { 属性: 属性类型.身法, 值: 1186 },
      { 属性: 属性类型.外功基础攻击, 值: 4591 },
      { 属性: 属性类型.外功会心等级, 值: 5949 },
      { 属性: 属性类型.破招值, 值: 6346 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 35819,
    uid: 212154,
    装备名称: '烟雨寒舟',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 14150,
    武器伤害_最小值: 3291,
    武器伤害_最大值: 5485,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6114 },
      { 属性: 属性类型.力道, 值: 1186 },
      { 属性: 属性类型.外功基础攻击, 值: 4591 },
      { 属性: 属性类型.外功会心等级, 值: 5949 },
      { 属性: 属性类型.破招值, 值: 6346 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 35814,
    uid: 212149,
    装备名称: '鲸歌海雾',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 14150,
    武器伤害_最小值: 3291,
    武器伤害_最大值: 5485,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6114 },
      { 属性: 属性类型.身法, 值: 1186 },
      { 属性: 属性类型.外功基础攻击, 值: 4591 },
      { 属性: 属性类型.外功会心等级, 值: 5949 },
      { 属性: 属性类型.破招值, 值: 6346 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38141,
    uid: 217110,
    装备名称: '陶然意·旭影弓',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 14050,
    武器伤害_最小值: 3268,
    武器伤害_最大值: 5447,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6071 },
      { 属性: 属性类型.身法, 值: 1104 },
      { 属性: 属性类型.外功基础攻击, 值: 4118 },
      { 属性: 属性类型.外功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2625 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38140,
    uid: 217109,
    装备名称: '陶然意·沉麟刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 14050,
    武器伤害_最小值: 3268,
    武器伤害_最大值: 5447,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6071 },
      { 属性: 属性类型.力道, 值: 1104 },
      { 属性: 属性类型.外功基础攻击, 值: 4118 },
      { 属性: 属性类型.外功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2625 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38136,
    uid: 217105,
    装备名称: '陶然意·弘阔伞',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 14050,
    武器伤害_最小值: 3268,
    武器伤害_最大值: 5447,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6071 },
      { 属性: 属性类型.身法, 值: 1104 },
      { 属性: 属性类型.外功基础攻击, 值: 4118 },
      { 属性: 属性类型.外功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2625 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 37441,
    uid: 215610,
    装备名称: '东方日出·欲明',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 13950,
    武器伤害_最小值: 3245,
    武器伤害_最大值: 5408,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.身法, 值: 1169 },
      { 属性: 属性类型.外功基础攻击, 值: 4527 },
      { 属性: 属性类型.外功破防等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37440,
    uid: 215609,
    装备名称: '东方日出·山中仙',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 13950,
    武器伤害_最小值: 3245,
    武器伤害_最大值: 5408,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.力道, 值: 1169 },
      { 属性: 属性类型.外功基础攻击, 值: 4527 },
      { 属性: 属性类型.外功破防等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37435,
    uid: 215604,
    装备名称: '东方日出·海底石',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 13950,
    武器伤害_最小值: 3245,
    武器伤害_最大值: 5408,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.身法, 值: 1169 },
      { 属性: 属性类型.外功基础攻击, 值: 4527 },
      { 属性: 属性类型.外功破防等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37178,
    uid: 214723,
    装备名称: '危辞弓',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 13950,
    武器伤害_最小值: 3245,
    武器伤害_最大值: 5408,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.身法, 值: 1169 },
      { 属性: 属性类型.外功基础攻击, 值: 4527 },
      { 属性: 属性类型.外功会心等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 37177,
    uid: 214722,
    装备名称: '危皓刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 13950,
    武器伤害_最小值: 3245,
    武器伤害_最大值: 5408,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.力道, 值: 1169 },
      { 属性: 属性类型.外功基础攻击, 值: 4527 },
      { 属性: 属性类型.外功会心等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 37172,
    uid: 214717,
    装备名称: '危潮伞',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 13950,
    武器伤害_最小值: 3245,
    武器伤害_最大值: 5408,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.身法, 值: 1169 },
      { 属性: 属性类型.外功基础攻击, 值: 4527 },
      { 属性: 属性类型.外功会心等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 35871,
    uid: 212350,
    装备名称: '闲观日月',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 13950,
    武器伤害_最小值: 3245,
    武器伤害_最大值: 5408,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.身法, 值: 1169 },
      { 属性: 属性类型.外功基础攻击, 值: 4527 },
      { 属性: 属性类型.外功破防等级, 值: 5865 },
      { 属性: 属性类型.破招值, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 35870,
    uid: 212349,
    装备名称: '万里风',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 13950,
    武器伤害_最小值: 3245,
    武器伤害_最大值: 5408,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.力道, 值: 1169 },
      { 属性: 属性类型.外功基础攻击, 值: 4527 },
      { 属性: 属性类型.外功破防等级, 值: 5865 },
      { 属性: 属性类型.破招值, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 35865,
    uid: 212344,
    装备名称: '万古云',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 13950,
    武器伤害_最小值: 3245,
    武器伤害_最大值: 5408,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.身法, 值: 1169 },
      { 属性: 属性类型.外功基础攻击, 值: 4527 },
      { 属性: 属性类型.外功破防等级, 值: 5865 },
      { 属性: 属性类型.破招值, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 35781,
    uid: 211984,
    装备名称: '穿叶雨',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 13950,
    武器伤害_最小值: 3245,
    武器伤害_最大值: 5408,
    装备特效: 装备特效枚举.外功_水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.身法, 值: 1169 },
      { 属性: 属性类型.外功基础攻击, 值: 4527 },
      { 属性: 属性类型.外功破防等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 35780,
    uid: 211983,
    装备名称: '疾浪远',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 13950,
    武器伤害_最小值: 3245,
    武器伤害_最大值: 5408,
    装备特效: 装备特效枚举.外功_水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.力道, 值: 1169 },
      { 属性: 属性类型.外功基础攻击, 值: 4527 },
      { 属性: 属性类型.外功破防等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 35775,
    uid: 211978,
    装备名称: '清秋海',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 13950,
    武器伤害_最小值: 3245,
    武器伤害_最大值: 5408,
    装备特效: 装备特效枚举.外功_水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.身法, 值: 1169 },
      { 属性: 属性类型.外功基础攻击, 值: 4527 },
      { 属性: 属性类型.外功破防等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 35750,
    uid: 211947,
    装备名称: '金虹弓',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 13950,
    武器伤害_最小值: 3245,
    武器伤害_最大值: 5408,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.身法, 值: 1169 },
      { 属性: 属性类型.外功基础攻击, 值: 4527 },
      { 属性: 属性类型.外功会心等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 35749,
    uid: 211946,
    装备名称: '火云刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 13950,
    武器伤害_最小值: 3245,
    武器伤害_最大值: 5408,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.力道, 值: 1169 },
      { 属性: 属性类型.外功基础攻击, 值: 4527 },
      { 属性: 属性类型.外功会心等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 35744,
    uid: 211941,
    装备名称: '萦回伞',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 13950,
    武器伤害_最小值: 3245,
    武器伤害_最大值: 5408,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.身法, 值: 1169 },
      { 属性: 属性类型.外功基础攻击, 值: 4527 },
      { 属性: 属性类型.外功会心等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 37141,
    uid: 214644,
    装备名称: '寻踪觅宝·紫竹弓',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 13750,
    武器伤害_最小值: 3198,
    武器伤害_最大值: 5330,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5942 },
      { 属性: 属性类型.身法, 值: 1152 },
      { 属性: 属性类型.外功基础攻击, 值: 4462 },
      { 属性: 属性类型.外功会心等级, 值: 5781 },
      { 属性: 属性类型.破招值, 值: 6166 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37140,
    uid: 214643,
    装备名称: '寻踪觅宝·碧晨刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 13750,
    武器伤害_最小值: 3198,
    武器伤害_最大值: 5330,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5942 },
      { 属性: 属性类型.力道, 值: 1152 },
      { 属性: 属性类型.外功基础攻击, 值: 4462 },
      { 属性: 属性类型.外功会心等级, 值: 5781 },
      { 属性: 属性类型.破招值, 值: 6166 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37135,
    uid: 214638,
    装备名称: '寻踪觅宝·飘零伞',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 13750,
    武器伤害_最小值: 3198,
    武器伤害_最大值: 5330,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5942 },
      { 属性: 属性类型.身法, 值: 1152 },
      { 属性: 属性类型.外功基础攻击, 值: 4462 },
      { 属性: 属性类型.外功会心等级, 值: 5781 },
      { 属性: 属性类型.破招值, 值: 6166 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36911,
    uid: 214434,
    装备名称: '裂风破天弓',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 13200,
    武器伤害_最小值: 3070,
    武器伤害_最大值: 5117,
    装备特效: 装备特效枚举.龙门飞剑武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5704 },
      { 属性: 属性类型.身法, 值: 1106 },
      { 属性: 属性类型.外功基础攻击, 值: 4283 },
      { 属性: 属性类型.外功破防等级, 值: 5550 },
      { 属性: 属性类型.破招值, 值: 5920 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36910,
    uid: 214433,
    装备名称: '四绝分海刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 13200,
    武器伤害_最小值: 3070,
    武器伤害_最大值: 5117,
    装备特效: 装备特效枚举.龙门飞剑武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5704 },
      { 属性: 属性类型.力道, 值: 1106 },
      { 属性: 属性类型.外功基础攻击, 值: 4283 },
      { 属性: 属性类型.外功破防等级, 值: 5550 },
      { 属性: 属性类型.破招值, 值: 5920 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36905,
    uid: 214428,
    装备名称: '风雪不归伞',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 13200,
    武器伤害_最小值: 3070,
    武器伤害_最大值: 5117,
    装备特效: 装备特效枚举.龙门飞剑武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5704 },
      { 属性: 属性类型.身法, 值: 1106 },
      { 属性: 属性类型.外功基础攻击, 值: 4283 },
      { 属性: 属性类型.外功破防等级, 值: 5550 },
      { 属性: 属性类型.破招值, 值: 5920 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36661,
    uid: 213814,
    装备名称: '弯弓弦月',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12600,
    武器伤害_最小值: 2931,
    武器伤害_最大值: 4885,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5445 },
      { 属性: 属性类型.身法, 值: 1056 },
      { 属性: 属性类型.外功基础攻击, 值: 4089 },
      { 属性: 属性类型.外功会心等级, 值: 5297 },
      { 属性: 属性类型.破招值, 值: 5651 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 34803,
    uid: 208220,
    装备名称: '凌云天',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12600,
    武器伤害_最小值: 2931,
    武器伤害_最大值: 4885,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5445 },
      { 属性: 属性类型.力道, 值: 1056 },
      { 属性: 属性类型.外功基础攻击, 值: 4089 },
      { 属性: 属性类型.外功会心等级, 值: 5297 },
      { 属性: 属性类型.破招值, 值: 5651 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 34798,
    uid: 208215,
    装备名称: '灵台御风',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12600,
    武器伤害_最小值: 2931,
    武器伤害_最大值: 4885,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5445 },
      { 属性: 属性类型.身法, 值: 1056 },
      { 属性: 属性类型.外功基础攻击, 值: 4089 },
      { 属性: 属性类型.外功会心等级, 值: 5297 },
      { 属性: 属性类型.破招值, 值: 5651 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36660,
    uid: 213813,
    装备名称: '划长虹',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12450,
    武器伤害_最小值: 2896,
    武器伤害_最大值: 4827,
    装备特效: 装备特效枚举.外功_水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.身法, 值: 1043 },
      { 属性: 属性类型.外功基础攻击, 值: 4040 },
      { 属性: 属性类型.外功破防等级, 值: 5234 },
      { 属性: 属性类型.无双等级, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36659,
    uid: 213812,
    装备名称: '势相和',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12450,
    武器伤害_最小值: 2896,
    武器伤害_最大值: 4827,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.身法, 值: 1043 },
      { 属性: 属性类型.外功基础攻击, 值: 4040 },
      { 属性: 属性类型.外功会心等级, 值: 5234 },
      { 属性: 属性类型.无双等级, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 35972,
    uid: 212547,
    装备名称: '西风北啸·林野',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12450,
    武器伤害_最小值: 2896,
    武器伤害_最大值: 4827,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.身法, 值: 1043 },
      { 属性: 属性类型.外功基础攻击, 值: 4040 },
      { 属性: 属性类型.外功破防等级, 值: 5234 },
      { 属性: 属性类型.无双等级, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 35971,
    uid: 212546,
    装备名称: '西风北啸·流湍',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12450,
    武器伤害_最小值: 2896,
    武器伤害_最大值: 4827,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.力道, 值: 1043 },
      { 属性: 属性类型.外功基础攻击, 值: 4040 },
      { 属性: 属性类型.外功破防等级, 值: 5234 },
      { 属性: 属性类型.无双等级, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 35966,
    uid: 212541,
    装备名称: '西风北啸·照清池',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12450,
    武器伤害_最小值: 2896,
    武器伤害_最大值: 4827,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.身法, 值: 1043 },
      { 属性: 属性类型.外功基础攻击, 值: 4040 },
      { 属性: 属性类型.外功破防等级, 值: 5234 },
      { 属性: 属性类型.无双等级, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 34851,
    uid: 208418,
    装备名称: '不变情',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12450,
    武器伤害_最小值: 2896,
    武器伤害_最大值: 4827,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.力道, 值: 1043 },
      { 属性: 属性类型.外功基础攻击, 值: 4040 },
      { 属性: 属性类型.外功破防等级, 值: 5234 },
      { 属性: 属性类型.破招值, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 34846,
    uid: 208413,
    装备名称: '送君行',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12450,
    武器伤害_最小值: 2896,
    武器伤害_最大值: 4827,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.身法, 值: 1043 },
      { 属性: 属性类型.外功基础攻击, 值: 4040 },
      { 属性: 属性类型.外功破防等级, 值: 5234 },
      { 属性: 属性类型.破招值, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 34765,
    uid: 208066,
    装备名称: '踏刀行',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12450,
    武器伤害_最小值: 2896,
    武器伤害_最大值: 4827,
    装备特效: 装备特效枚举.外功_水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.力道, 值: 1043 },
      { 属性: 属性类型.外功基础攻击, 值: 4040 },
      { 属性: 属性类型.外功破防等级, 值: 5234 },
      { 属性: 属性类型.无双等级, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 34760,
    uid: 208061,
    装备名称: '水色天',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12450,
    武器伤害_最小值: 2896,
    武器伤害_最大值: 4827,
    装备特效: 装备特效枚举.外功_水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.身法, 值: 1043 },
      { 属性: 属性类型.外功基础攻击, 值: 4040 },
      { 属性: 属性类型.外功破防等级, 值: 5234 },
      { 属性: 属性类型.无双等级, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 34735,
    uid: 208030,
    装备名称: '碧玉锋',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12450,
    武器伤害_最小值: 2896,
    武器伤害_最大值: 4827,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.力道, 值: 1043 },
      { 属性: 属性类型.外功基础攻击, 值: 4040 },
      { 属性: 属性类型.外功会心等级, 值: 5234 },
      { 属性: 属性类型.无双等级, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 34730,
    uid: 208025,
    装备名称: '连山岳',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12450,
    武器伤害_最小值: 2896,
    武器伤害_最大值: 4827,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.身法, 值: 1043 },
      { 属性: 属性类型.外功基础攻击, 值: 4040 },
      { 属性: 属性类型.外功会心等级, 值: 5234 },
      { 属性: 属性类型.无双等级, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38385,
    uid: 217897,
    装备名称: '流光射影',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 966 },
      { 属性: 属性类型.外功基础攻击, 值: 3605 },
      { 属性: 属性类型.加速等级, 值: 5171 },
      { 属性: 属性类型.全能等级, 值: 2298 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38384,
    uid: 217896,
    装备名称: '顾雪',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.力道, 值: 966 },
      { 属性: 属性类型.外功基础攻击, 值: 3605 },
      { 属性: 属性类型.加速等级, 值: 5171 },
      { 属性: 属性类型.全能等级, 值: 2298 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38379,
    uid: 217891,
    装备名称: '游阊阖',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 966 },
      { 属性: 属性类型.外功基础攻击, 值: 3605 },
      { 属性: 属性类型.加速等级, 值: 5171 },
      { 属性: 属性类型.全能等级, 值: 2298 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38348,
    uid: 217806,
    装备名称: '长弓白羽',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 966 },
      { 属性: 属性类型.外功基础攻击, 值: 3605 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.全能等级, 值: 2298 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38347,
    uid: 217805,
    装备名称: '急雨风',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.力道, 值: 966 },
      { 属性: 属性类型.外功基础攻击, 值: 3605 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.全能等级, 值: 2298 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38342,
    uid: 217800,
    装备名称: '飞鸟与还',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 966 },
      { 属性: 属性类型.外功基础攻击, 值: 3605 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.全能等级, 值: 2298 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 36859,
    uid: 214290,
    装备名称: '梧风棉棉钩·刀功',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36859,
    uid: 214290,
    装备名称: '梧风棉棉钩·刀功',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36858,
    uid: 214289,
    装备名称: '岚峰海坚鱼·刀功',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.力道, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36858,
    uid: 214289,
    装备名称: '岚峰海坚鱼·刀功',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.力道, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36853,
    uid: 214284,
    装备名称: '沧波渔网架·刀功',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36853,
    uid: 214284,
    装备名称: '沧波渔网架·刀功',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36653,
    uid: 213791,
    装备名称: '横素弓',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36652,
    uid: 213790,
    装备名称: '独雁弓',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36651,
    uid: 213789,
    装备名称: '宿秋弓',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.加速等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
    ],
  },
  {
    id: 36650,
    uid: 213788,
    装备名称: '涧寒弓',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36649,
    uid: 213787,
    装备名称: '戢羽',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 36648,
    uid: 213786,
    装备名称: '有触即发',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.加速等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
    ],
  },
  {
    id: 36647,
    uid: 213785,
    装备名称: '控角弓',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 35707,
    uid: 211646,
    装备名称: '寻踪觅宝·姮娥弓箭',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 35706,
    uid: 211645,
    装备名称: '寻踪觅宝·裂锦刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.力道, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 35701,
    uid: 211640,
    装备名称: '寻踪觅宝·醉缬伞',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 32693,
    uid: 189406,
    装备名称: '复影刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.力道, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 32688,
    uid: 189401,
    装备名称: '泽荣伞',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 32657,
    uid: 189316,
    装备名称: '戏涛刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.力道, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 32652,
    uid: 189311,
    装备名称: '觅时伞',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 32621,
    uid: 189226,
    装备名称: '重行刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.力道, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.加速等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
    ],
  },
  {
    id: 32585,
    uid: 189136,
    装备名称: '双横刀',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.力道, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 32580,
    uid: 189131,
    装备名称: '洛语伞',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 32525,
    uid: 188854,
    装备名称: '断扰',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.力道, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 32520,
    uid: 188849,
    装备名称: '笙潮',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 32489,
    uid: 188764,
    装备名称: '刃风',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.力道, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.加速等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
    ],
  },
  {
    id: 32484,
    uid: 188759,
    装备名称: '醉天仙',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.加速等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
    ],
  },
  {
    id: 32453,
    uid: 188674,
    装备名称: '逐锋破',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.力道, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 32448,
    uid: 188669,
    装备名称: '水流云',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    武器伤害_最小值: 2861,
    武器伤害_最大值: 4768,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.身法, 值: 1031 },
      { 属性: 属性类型.外功基础攻击, 值: 3991 },
      { 属性: 属性类型.外功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
]

export default 武器装备数据
