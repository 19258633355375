import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备类型枚举 } from '@/@types/装备'

const 项链装备数据: 装备属性信息模型[] = [
  {
    id: 39909,
    uid: 215157,
    装备名称: '无者链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2845 },
      { 属性: 属性类型.身法, 值: 552 },
      { 属性: 属性类型.外功基础攻击, 值: 895 },
      { 属性: 属性类型.外功破防等级, 值: 2768 },
      { 属性: 属性类型.破招值, 值: 2460 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 39908,
    uid: 215156,
    装备名称: '运上链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2845 },
      { 属性: 属性类型.力道, 值: 552 },
      { 属性: 属性类型.外功基础攻击, 值: 895 },
      { 属性: 属性类型.外功破防等级, 值: 2768 },
      { 属性: 属性类型.破招值, 值: 2460 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 40823,
    uid: 217131,
    装备名称: '陶然意·旭影链',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2827 },
      { 属性: 属性类型.身法, 值: 514 },
      { 属性: 属性类型.外功基础攻击, 值: 684 },
      { 属性: 属性类型.外功会心等级, 值: 2750 },
      { 属性: 属性类型.全能等级, 值: 1222 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 40822,
    uid: 217130,
    装备名称: '陶然意·沉麟链',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2827 },
      { 属性: 属性类型.力道, 值: 514 },
      { 属性: 属性类型.外功基础攻击, 值: 684 },
      { 属性: 属性类型.外功会心等级, 值: 2750 },
      { 属性: 属性类型.全能等级, 值: 1222 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 40818,
    uid: 217126,
    装备名称: '陶然意·弘阔链',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2827 },
      { 属性: 属性类型.身法, 值: 514 },
      { 属性: 属性类型.外功基础攻击, 值: 684 },
      { 属性: 属性类型.外功会心等级, 值: 2750 },
      { 属性: 属性类型.全能等级, 值: 1222 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39837,
    uid: 214973,
    装备名称: '救困链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.身法, 值: 545 },
      { 属性: 属性类型.外功基础攻击, 值: 884 },
      { 属性: 属性类型.外功会心等级, 值: 2733 },
      { 属性: 属性类型.无双等级, 值: 2429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39836,
    uid: 214972,
    装备名称: '磊落链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.力道, 值: 545 },
      { 属性: 属性类型.外功基础攻击, 值: 884 },
      { 属性: 属性类型.外功会心等级, 值: 2733 },
      { 属性: 属性类型.无双等级, 值: 2429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39819,
    uid: 214913,
    装备名称: '照耀链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.身法, 值: 545 },
      { 属性: 属性类型.外功基础攻击, 值: 884 },
      { 属性: 属性类型.外功破防等级, 值: 2733 },
      { 属性: 属性类型.无双等级, 值: 2429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39818,
    uid: 214912,
    装备名称: '如雪链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.力道, 值: 545 },
      { 属性: 属性类型.外功基础攻击, 值: 884 },
      { 属性: 属性类型.外功破防等级, 值: 2733 },
      { 属性: 属性类型.无双等级, 值: 2429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39941,
    uid: 215328,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1920 },
      { 属性: 属性类型.破招值, 值: 2071 },
      { 属性: 属性类型.外功破防等级, 值: 2515 },
      { 属性: 属性类型.无双等级, 值: 1479 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39940,
    uid: 215327,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1920 },
      { 属性: 属性类型.破招值, 值: 3107 },
      { 属性: 属性类型.外功会心等级, 值: 2959 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 39939,
    uid: 215326,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2252 },
      { 属性: 属性类型.外功破防等级, 值: 5252 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 40992,
    uid: 218234,
    装备名称: '誓愿链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2701 },
      { 属性: 属性类型.身法, 值: 524 },
      { 属性: 属性类型.外功基础攻击, 值: 850 },
      { 属性: 属性类型.外功破防等级, 值: 2628 },
      { 属性: 属性类型.无双等级, 值: 2336 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40991,
    uid: 218233,
    装备名称: '怀愿链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2701 },
      { 属性: 属性类型.力道, 值: 524 },
      { 属性: 属性类型.外功基础攻击, 值: 850 },
      { 属性: 属性类型.外功破防等级, 值: 2628 },
      { 属性: 属性类型.无双等级, 值: 2336 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39897,
    uid: 215145,
    装备名称: '无者链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 14800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2665 },
      { 属性: 属性类型.身法, 值: 517 },
      { 属性: 属性类型.外功基础攻击, 值: 838 },
      { 属性: 属性类型.外功破防等级, 值: 2593 },
      { 属性: 属性类型.破招值, 值: 2305 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 39896,
    uid: 215144,
    装备名称: '运上链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 14800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2665 },
      { 属性: 属性类型.力道, 值: 517 },
      { 属性: 属性类型.外功基础攻击, 值: 838 },
      { 属性: 属性类型.外功破防等级, 值: 2593 },
      { 属性: 属性类型.破招值, 值: 2305 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 39929,
    uid: 215286,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1813 },
      { 属性: 属性类型.破招值, 值: 1536 },
      { 属性: 属性类型.外功会心等级, 值: 2654 },
      { 属性: 属性类型.外功会心效果等级, 值: 1397 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39928,
    uid: 215285,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1813 },
      { 属性: 属性类型.破招值, 值: 2863 },
      { 属性: 属性类型.无双等级, 值: 2863 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39927,
    uid: 215284,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2126 },
      { 属性: 属性类型.外功会心等级, 值: 4958 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 38845,
    uid: 212080,
    装备名称: '逢杨链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 14150,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2548 },
      { 属性: 属性类型.身法, 值: 494 },
      { 属性: 属性类型.外功基础攻击, 值: 801 },
      { 属性: 属性类型.外功破防等级, 值: 2479 },
      { 属性: 属性类型.破招值, 值: 2203 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 38844,
    uid: 212079,
    装备名称: '客路链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 14150,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2548 },
      { 属性: 属性类型.力道, 值: 494 },
      { 属性: 属性类型.外功基础攻击, 值: 801 },
      { 属性: 属性类型.外功破防等级, 值: 2479 },
      { 属性: 属性类型.破招值, 值: 2203 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 40760,
    uid: 217131,
    装备名称: '陶然意·旭影链',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2530 },
      { 属性: 属性类型.身法, 值: 460 },
      { 属性: 属性类型.外功基础攻击, 值: 612 },
      { 属性: 属性类型.外功会心等级, 值: 2461 },
      { 属性: 属性类型.全能等级, 值: 1094 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 40759,
    uid: 217130,
    装备名称: '陶然意·沉麟链',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2530 },
      { 属性: 属性类型.力道, 值: 460 },
      { 属性: 属性类型.外功基础攻击, 值: 612 },
      { 属性: 属性类型.外功会心等级, 值: 2461 },
      { 属性: 属性类型.全能等级, 值: 1094 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 40755,
    uid: 217126,
    装备名称: '陶然意·弘阔链',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2530 },
      { 属性: 属性类型.身法, 值: 460 },
      { 属性: 属性类型.外功基础攻击, 值: 612 },
      { 属性: 属性类型.外功会心等级, 值: 2461 },
      { 属性: 属性类型.全能等级, 值: 1094 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39807,
    uid: 214678,
    装备名称: '危光链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.身法, 值: 487 },
      { 属性: 属性类型.外功基础攻击, 值: 790 },
      { 属性: 属性类型.外功破防等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39806,
    uid: 214677,
    装备名称: '危雨链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.力道, 值: 487 },
      { 属性: 属性类型.外功基础攻击, 值: 790 },
      { 属性: 属性类型.外功破防等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38773,
    uid: 211896,
    装备名称: '踏雁链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.身法, 值: 487 },
      { 属性: 属性类型.外功基础攻击, 值: 790 },
      { 属性: 属性类型.外功会心等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 38772,
    uid: 211895,
    装备名称: '素鸦链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.力道, 值: 487 },
      { 属性: 属性类型.外功基础攻击, 值: 790 },
      { 属性: 属性类型.外功会心等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 38755,
    uid: 211836,
    装备名称: '风掣链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.身法, 值: 487 },
      { 属性: 属性类型.外功基础攻击, 值: 790 },
      { 属性: 属性类型.外功破防等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38754,
    uid: 211835,
    装备名称: '凛行链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.力道, 值: 487 },
      { 属性: 属性类型.外功基础攻击, 值: 790 },
      { 属性: 属性类型.外功破防等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39885,
    uid: 215133,
    装备名称: '无者链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2485 },
      { 属性: 属性类型.身法, 值: 482 },
      { 属性: 属性类型.外功基础攻击, 值: 782 },
      { 属性: 属性类型.外功破防等级, 值: 2417 },
      { 属性: 属性类型.破招值, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 39884,
    uid: 215132,
    装备名称: '运上链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2485 },
      { 属性: 属性类型.力道, 值: 482 },
      { 属性: 属性类型.外功基础攻击, 值: 782 },
      { 属性: 属性类型.外功破防等级, 值: 2417 },
      { 属性: 属性类型.破招值, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 38881,
    uid: 212265,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1712 },
      { 属性: 属性类型.破招值, 值: 1451 },
      { 属性: 属性类型.外功会心等级, 值: 2506 },
      { 属性: 属性类型.无双等级, 值: 1451 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 38880,
    uid: 212264,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1712 },
      { 属性: 属性类型.外功破防等级, 值: 2637 },
      { 属性: 属性类型.无双等级, 值: 2769 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 38879,
    uid: 212263,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2007 },
      { 属性: 属性类型.无双等级, 值: 4681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 38833,
    uid: 212068,
    装备名称: '逢杨链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2395 },
      { 属性: 属性类型.身法, 值: 464 },
      { 属性: 属性类型.外功基础攻击, 值: 753 },
      { 属性: 属性类型.外功破防等级, 值: 2330 },
      { 属性: 属性类型.破招值, 值: 2071 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 38832,
    uid: 212067,
    装备名称: '客路链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2395 },
      { 属性: 属性类型.力道, 值: 464 },
      { 属性: 属性类型.外功基础攻击, 值: 753 },
      { 属性: 属性类型.外功破防等级, 值: 2330 },
      { 属性: 属性类型.破招值, 值: 2071 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 39873,
    uid: 215121,
    装备名称: '无者链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2305 },
      { 属性: 属性类型.身法, 值: 447 },
      { 属性: 属性类型.外功基础攻击, 值: 725 },
      { 属性: 属性类型.外功破防等级, 值: 2242 },
      { 属性: 属性类型.破招值, 值: 1993 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 39872,
    uid: 215120,
    装备名称: '运上链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2305 },
      { 属性: 属性类型.力道, 值: 447 },
      { 属性: 属性类型.外功基础攻击, 值: 725 },
      { 属性: 属性类型.外功破防等级, 值: 2242 },
      { 属性: 属性类型.破招值, 值: 1993 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 38865,
    uid: 212217,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1617 },
      { 属性: 属性类型.破招值, 值: 1744 },
      { 属性: 属性类型.外功破防等级, 值: 2118 },
      { 属性: 属性类型.无双等级, 值: 1246 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38864,
    uid: 212216,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1617 },
      { 属性: 属性类型.破招值, 值: 2616 },
      { 属性: 属性类型.外功会心等级, 值: 2491 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 38863,
    uid: 212215,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1896 },
      { 属性: 属性类型.外功破防等级, 值: 4422 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 37770,
    uid: 208146,
    装备名称: '欺林链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2269 },
      { 属性: 属性类型.身法, 值: 440 },
      { 属性: 属性类型.外功基础攻击, 值: 714 },
      { 属性: 属性类型.外功破防等级, 值: 2207 },
      { 属性: 属性类型.破招值, 值: 1962 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 37769,
    uid: 208145,
    装备名称: '定酣链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2269 },
      { 属性: 属性类型.力道, 值: 440 },
      { 属性: 属性类型.外功基础攻击, 值: 714 },
      { 属性: 属性类型.外功破防等级, 值: 2207 },
      { 属性: 属性类型.破招值, 值: 1962 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 39683,
    uid: 213705,
    装备名称: '灵空·风行链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.身法, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功破防等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39682,
    uid: 213704,
    装备名称: '灵空·撼地链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.力道, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功破防等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38821,
    uid: 212056,
    装备名称: '逢杨链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.身法, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功破防等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 38820,
    uid: 212055,
    装备名称: '客路链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.力道, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功破防等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 37812,
    uid: 208368,
    装备名称: '湖月链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.身法, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功会心等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 37811,
    uid: 208367,
    装备名称: '湖静链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.力道, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功会心等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 37702,
    uid: 207980,
    装备名称: '染辞链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.身法, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功会心等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 37701,
    uid: 207979,
    装备名称: '温刃链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.力道, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功会心等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 37684,
    uid: 207920,
    装备名称: '商野链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.身法, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功破防等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 37683,
    uid: 207919,
    装备名称: '安衿链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.力道, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功破防等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 34190,
    uid: 189026,
    装备名称: '临越链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2233 },
      { 属性: 属性类型.身法, 值: 433 },
      { 属性: 属性类型.外功基础攻击, 值: 702 },
      { 属性: 属性类型.外功破防等级, 值: 2172 },
      { 属性: 属性类型.破招值, 值: 1931 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 34189,
    uid: 189025,
    装备名称: '临邦链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2233 },
      { 属性: 属性类型.力道, 值: 433 },
      { 属性: 属性类型.外功基础攻击, 值: 702 },
      { 属性: 属性类型.外功破防等级, 值: 2172 },
      { 属性: 属性类型.破招值, 值: 1931 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 40914,
    uid: 217846,
    装备名称: '塞尘链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 403 },
      { 属性: 属性类型.外功基础攻击, 值: 536 },
      { 属性: 属性类型.外功破防等级, 值: 2155 },
      { 属性: 属性类型.全能等级, 值: 958 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 40913,
    uid: 217845,
    装备名称: '峭月链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 403 },
      { 属性: 属性类型.外功基础攻击, 值: 536 },
      { 属性: 属性类型.外功破防等级, 值: 2155 },
      { 属性: 属性类型.全能等级, 值: 958 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 40896,
    uid: 217755,
    装备名称: '奔枝链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 403 },
      { 属性: 属性类型.外功基础攻击, 值: 536 },
      { 属性: 属性类型.外功会心等级, 值: 2155 },
      { 属性: 属性类型.全能等级, 值: 958 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 40895,
    uid: 217754,
    装备名称: '环旌链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 403 },
      { 属性: 属性类型.外功基础攻击, 值: 536 },
      { 属性: 属性类型.外功会心等级, 值: 2155 },
      { 属性: 属性类型.全能等级, 值: 958 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 34262,
    uid: 189356,
    装备名称: '久念链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功会心等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 34261,
    uid: 189355,
    装备名称: '拭江链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功会心等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 34244,
    uid: 189266,
    装备名称: '风岱链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功会心等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 34243,
    uid: 189265,
    装备名称: '项昌链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功会心等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 34226,
    uid: 189176,
    装备名称: '北邱链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功破防等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 34225,
    uid: 189175,
    装备名称: '曲郦链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功破防等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 34208,
    uid: 189086,
    装备名称: '渊忱链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.加速等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 34207,
    uid: 189085,
    装备名称: '羡双链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.加速等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 34118,
    uid: 188804,
    装备名称: '忆宁链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功破防等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 34117,
    uid: 188803,
    装备名称: '忆敬链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功破防等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 34100,
    uid: 188714,
    装备名称: '盈绝链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功会心等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 34099,
    uid: 188713,
    装备名称: '垣翰链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功会心等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 34082,
    uid: 188624,
    装备名称: '潋阳链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.破招值, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 34081,
    uid: 188623,
    装备名称: '重关链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.破招值, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 37802,
    uid: 208316,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1529 },
      { 属性: 属性类型.破招值, 值: 1295 },
      { 属性: 属性类型.外功会心等级, 值: 2237 },
      { 属性: 属性类型.外功会心效果等级, 值: 1178 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 37801,
    uid: 208315,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1529 },
      { 属性: 属性类型.破招值, 值: 2414 },
      { 属性: 属性类型.无双等级, 值: 2414 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 37800,
    uid: 208314,
    装备名称: '无封项链',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1792 },
      { 属性: 属性类型.外功会心等级, 值: 4181 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
]

export default 项链装备数据
