import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 衣服装备数据: 装备属性信息模型[] = [
  {
    id: 98511,
    uid: 215199,
    装备名称: '水泉衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5689 },
      { 属性: 属性类型.身法, 值: 1103 },
      { 属性: 属性类型.外功基础攻击, 值: 1790 },
      { 属性: 属性类型.外功破防等级, 值: 5536 },
      { 属性: 属性类型.无双等级, 值: 4921 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98510,
    uid: 215198,
    装备名称: '水泽衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5689 },
      { 属性: 属性类型.力道, 值: 1103 },
      { 属性: 属性类型.外功基础攻击, 值: 1790 },
      { 属性: 属性类型.外功破防等级, 值: 5536 },
      { 属性: 属性类型.无双等级, 值: 4921 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100139,
    uid: 217067,
    装备名称: '陶然意·旭影衣',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5653 },
      { 属性: 属性类型.身法, 值: 1028 },
      { 属性: 属性类型.外功基础攻击, 值: 1368 },
      { 属性: 属性类型.外功破防等级, 值: 5501 },
      { 属性: 属性类型.全能等级, 值: 2445 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 100138,
    uid: 217066,
    装备名称: '陶然意·沉麟衣',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5653 },
      { 属性: 属性类型.力道, 值: 1028 },
      { 属性: 属性类型.外功基础攻击, 值: 1368 },
      { 属性: 属性类型.外功破防等级, 值: 5501 },
      { 属性: 属性类型.全能等级, 值: 2445 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 100134,
    uid: 217062,
    装备名称: '陶然意·弘阔衣',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5653 },
      { 属性: 属性类型.身法, 值: 1028 },
      { 属性: 属性类型.外功基础攻击, 值: 1368 },
      { 属性: 属性类型.外功破防等级, 值: 5501 },
      { 属性: 属性类型.全能等级, 值: 2445 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 98613,
    uid: 215374,
    装备名称: '月落衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.身法, 值: 1089 },
      { 属性: 属性类型.外功基础攻击, 值: 1767 },
      { 属性: 属性类型.外功会心等级, 值: 5466 },
      { 属性: 属性类型.无双等级, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 98612,
    uid: 215373,
    装备名称: '月稠衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.力道, 值: 1089 },
      { 属性: 属性类型.外功基础攻击, 值: 1767 },
      { 属性: 属性类型.外功会心等级, 值: 5466 },
      { 属性: 属性类型.无双等级, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 98439,
    uid: 214967,
    装备名称: '救困衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.身法, 值: 1089 },
      { 属性: 属性类型.外功基础攻击, 值: 1767 },
      { 属性: 属性类型.外功会心等级, 值: 5466 },
      { 属性: 属性类型.破招值, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98438,
    uid: 214966,
    装备名称: '磊落衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.力道, 值: 1089 },
      { 属性: 属性类型.外功基础攻击, 值: 1767 },
      { 属性: 属性类型.外功会心等级, 值: 5466 },
      { 属性: 属性类型.破招值, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98403,
    uid: 214907,
    装备名称: '照耀衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.身法, 值: 1089 },
      { 属性: 属性类型.外功基础攻击, 值: 1767 },
      { 属性: 属性类型.外功破防等级, 值: 5466 },
      { 属性: 属性类型.破招值, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98402,
    uid: 214906,
    装备名称: '如雪衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.力道, 值: 1089 },
      { 属性: 属性类型.外功基础攻击, 值: 1767 },
      { 属性: 属性类型.外功破防等级, 值: 5466 },
      { 属性: 属性类型.破招值, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98369,
    uid: 214873,
    装备名称: '鸿辉·眠狸衣',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5545 },
      { 属性: 属性类型.身法, 值: 1075 },
      { 属性: 属性类型.外功基础攻击, 值: 1744 },
      { 属性: 属性类型.外功会心等级, 值: 5396 },
      { 属性: 属性类型.无双等级, 值: 4796 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 98368,
    uid: 214872,
    装备名称: '鸿辉·凛霜衣',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5545 },
      { 属性: 属性类型.力道, 值: 1075 },
      { 属性: 属性类型.外功基础攻击, 值: 1744 },
      { 属性: 属性类型.外功会心等级, 值: 5396 },
      { 属性: 属性类型.无双等级, 值: 4796 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 98363,
    uid: 214867,
    装备名称: '鸿辉·霭琼衣',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5545 },
      { 属性: 属性类型.身法, 值: 1075 },
      { 属性: 属性类型.外功基础攻击, 值: 1744 },
      { 属性: 属性类型.外功会心等级, 值: 5396 },
      { 属性: 属性类型.无双等级, 值: 4796 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 96397,
    uid: 212122,
    装备名称: '风停衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5095 },
      { 属性: 属性类型.身法, 值: 988 },
      { 属性: 属性类型.外功基础攻击, 值: 1603 },
      { 属性: 属性类型.外功破防等级, 值: 4958 },
      { 属性: 属性类型.无双等级, 值: 4407 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96396,
    uid: 212121,
    装备名称: '风烈衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5095 },
      { 属性: 属性类型.力道, 值: 988 },
      { 属性: 属性类型.外功基础攻击, 值: 1603 },
      { 属性: 属性类型.外功破防等级, 值: 4958 },
      { 属性: 属性类型.无双等级, 值: 4407 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100013,
    uid: 217067,
    装备名称: '陶然意·旭影衣',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5059 },
      { 属性: 属性类型.身法, 值: 920 },
      { 属性: 属性类型.外功基础攻击, 值: 1224 },
      { 属性: 属性类型.外功破防等级, 值: 4923 },
      { 属性: 属性类型.全能等级, 值: 2188 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 100012,
    uid: 217066,
    装备名称: '陶然意·沉麟衣',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5059 },
      { 属性: 属性类型.力道, 值: 920 },
      { 属性: 属性类型.外功基础攻击, 值: 1224 },
      { 属性: 属性类型.外功破防等级, 值: 4923 },
      { 属性: 属性类型.全能等级, 值: 2188 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 100008,
    uid: 217062,
    装备名称: '陶然意·弘阔衣',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5059 },
      { 属性: 属性类型.身法, 值: 920 },
      { 属性: 属性类型.外功基础攻击, 值: 1224 },
      { 属性: 属性类型.外功破防等级, 值: 4923 },
      { 属性: 属性类型.全能等级, 值: 2188 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 98709,
    uid: 215571,
    装备名称: '东方日出·天宇衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.身法, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功破防等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98708,
    uid: 215570,
    装备名称: '东方日出·海光衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.力道, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功破防等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98217,
    uid: 214672,
    装备名称: '危光衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.身法, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.破招值, 值: 4888 },
      { 属性: 属性类型.无双等级, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98216,
    uid: 214671,
    装备名称: '危雨衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.力道, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.破招值, 值: 4888 },
      { 属性: 属性类型.无双等级, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96507,
    uid: 212311,
    装备名称: '泉幽衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.身法, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功会心等级, 值: 4888 },
      { 属性: 属性类型.无双等级, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 96506,
    uid: 212310,
    装备名称: '泉潺衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.力道, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功会心等级, 值: 4888 },
      { 属性: 属性类型.无双等级, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 96325,
    uid: 211890,
    装备名称: '踏雁衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.身法, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功会心等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96324,
    uid: 211889,
    装备名称: '素鸦衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.力道, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功会心等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96289,
    uid: 211830,
    装备名称: '风掣衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.身法, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功破防等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96288,
    uid: 211829,
    装备名称: '凛行衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.力道, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功破防等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98187,
    uid: 214611,
    装备名称: '寻踪觅宝·飞旋衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13750,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4951 },
      { 属性: 属性类型.身法, 值: 960 },
      { 属性: 属性类型.外功基础攻击, 值: 1558 },
      { 属性: 属性类型.加速等级, 值: 4817 },
      { 属性: 属性类型.破招值, 值: 4282 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98186,
    uid: 214610,
    装备名称: '寻踪觅宝·碎浪衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13750,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4951 },
      { 属性: 属性类型.力道, 值: 960 },
      { 属性: 属性类型.外功基础攻击, 值: 1558 },
      { 属性: 属性类型.加速等级, 值: 4817 },
      { 属性: 属性类型.破招值, 值: 4282 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96255,
    uid: 211796,
    装备名称: '灵源·寂林衣',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4951 },
      { 属性: 属性类型.身法, 值: 960 },
      { 属性: 属性类型.外功基础攻击, 值: 1558 },
      { 属性: 属性类型.外功会心等级, 值: 4817 },
      { 属性: 属性类型.无双等级, 值: 4282 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 96254,
    uid: 211795,
    装备名称: '灵源·休归衣',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4951 },
      { 属性: 属性类型.力道, 值: 960 },
      { 属性: 属性类型.外功基础攻击, 值: 1558 },
      { 属性: 属性类型.外功会心等级, 值: 4817 },
      { 属性: 属性类型.无双等级, 值: 4282 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 96249,
    uid: 211790,
    装备名称: '灵源·风涛衣',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4951 },
      { 属性: 属性类型.身法, 值: 960 },
      { 属性: 属性类型.外功基础攻击, 值: 1558 },
      { 属性: 属性类型.外功会心等级, 值: 4817 },
      { 属性: 属性类型.无双等级, 值: 4282 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 94494,
    uid: 208188,
    装备名称: '雪漫衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4537 },
      { 属性: 属性类型.身法, 值: 880 },
      { 属性: 属性类型.外功基础攻击, 值: 1427 },
      { 属性: 属性类型.外功破防等级, 值: 4415 },
      { 属性: 属性类型.无双等级, 值: 3924 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94493,
    uid: 208187,
    装备名称: '雪舞衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4537 },
      { 属性: 属性类型.力道, 值: 880 },
      { 属性: 属性类型.外功基础攻击, 值: 1427 },
      { 属性: 属性类型.外功破防等级, 值: 4415 },
      { 属性: 属性类型.无双等级, 值: 3924 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96603,
    uid: 212508,
    装备名称: '西风北啸·角寒衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.身法, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.外功破防等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96602,
    uid: 212507,
    装备名称: '西风北啸·砾漠衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.力道, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.外功破防等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94596,
    uid: 208362,
    装备名称: '湖月衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.身法, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.外功会心等级, 值: 4362 },
      { 属性: 属性类型.无双等级, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 94595,
    uid: 208361,
    装备名称: '湖静衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.力道, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.外功会心等级, 值: 4362 },
      { 属性: 属性类型.无双等级, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 94434,
    uid: 207974,
    装备名称: '染辞衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.身法, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.外功会心等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94433,
    uid: 207973,
    装备名称: '温刃衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.力道, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.外功会心等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94398,
    uid: 207914,
    装备名称: '商野衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.身法, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.外功破防等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94397,
    uid: 207913,
    装备名称: '安衿衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.力道, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.外功破防等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100365,
    uid: 217840,
    装备名称: '塞尘衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 805 },
      { 属性: 属性类型.外功基础攻击, 值: 1072 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.全能等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100364,
    uid: 217839,
    装备名称: '峭月衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 805 },
      { 属性: 属性类型.外功基础攻击, 值: 1072 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.全能等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100329,
    uid: 217749,
    装备名称: '奔枝衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 805 },
      { 属性: 属性类型.外功基础攻击, 值: 1072 },
      { 属性: 属性类型.外功会心等级, 值: 4309 },
      { 属性: 属性类型.全能等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100328,
    uid: 217748,
    装备名称: '环旌衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 805 },
      { 属性: 属性类型.外功基础攻击, 值: 1072 },
      { 属性: 属性类型.外功会心等级, 值: 4309 },
      { 属性: 属性类型.全能等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98157,
    uid: 214350,
    装备名称: '梧风御厨上衣·刀功',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功破防等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98156,
    uid: 214349,
    装备名称: '岚峰御厨上衣·刀功',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功破防等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98151,
    uid: 214344,
    装备名称: '沧波御厨上衣·刀功',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功破防等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 97850,
    uid: 213811,
    装备名称: '濯心·猎风衣',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功会心等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 96103,
    uid: 211613,
    装备名称: '寻踪觅宝·屠云衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96102,
    uid: 211612,
    装备名称: '寻踪觅宝·惊风衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94364,
    uid: 207880,
    装备名称: '濯心·锋虹衣',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功会心等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 94359,
    uid: 207875,
    装备名称: '濯心·盈怀衣',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功会心等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 90672,
    uid: 189350,
    装备名称: '久念衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功会心等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 90671,
    uid: 189349,
    装备名称: '拭江衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功会心等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 90636,
    uid: 189260,
    装备名称: '风岱衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功破防等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90635,
    uid: 189259,
    装备名称: '项昌衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功破防等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90600,
    uid: 189170,
    装备名称: '北邱衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功会心等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90599,
    uid: 189169,
    装备名称: '曲郦衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功会心等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90564,
    uid: 189080,
    装备名称: '渊忱衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功破防等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90563,
    uid: 189079,
    装备名称: '羡双衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功破防等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90432,
    uid: 188798,
    装备名称: '忆宁衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功破防等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90431,
    uid: 188797,
    装备名称: '忆敬衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功破防等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90396,
    uid: 188708,
    装备名称: '盈绝衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90395,
    uid: 188707,
    装备名称: '垣翰衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90360,
    uid: 188618,
    装备名称: '潋阳衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功会心等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90359,
    uid: 188617,
    装备名称: '重关衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功会心等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
]

export default 衣服装备数据
