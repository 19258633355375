import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 护腕装备数据: 装备属性信息模型[] = [
  {
    id: 98481,
    uid: 215169,
    装备名称: '水泉袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3983 },
      { 属性: 属性类型.身法, 值: 772 },
      { 属性: 属性类型.外功基础攻击, 值: 1253 },
      { 属性: 属性类型.外功破防等级, 值: 3875 },
      { 属性: 属性类型.破招值, 值: 3444 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98480,
    uid: 215168,
    装备名称: '水泽袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3983 },
      { 属性: 属性类型.力道, 值: 772 },
      { 属性: 属性类型.外功基础攻击, 值: 1253 },
      { 属性: 属性类型.外功破防等级, 值: 3875 },
      { 属性: 属性类型.破招值, 值: 3444 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100034,
    uid: 216962,
    装备名称: '陶然意·旭影护手',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3957 },
      { 属性: 属性类型.身法, 值: 719 },
      { 属性: 属性类型.外功基础攻击, 值: 958 },
      { 属性: 属性类型.外功破防等级, 值: 3850 },
      { 属性: 属性类型.全能等级, 值: 1711 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100033,
    uid: 216961,
    装备名称: '陶然意·沉麟护手',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3957 },
      { 属性: 属性类型.力道, 值: 719 },
      { 属性: 属性类型.外功基础攻击, 值: 958 },
      { 属性: 属性类型.外功破防等级, 值: 3850 },
      { 属性: 属性类型.全能等级, 值: 1711 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100029,
    uid: 216957,
    装备名称: '陶然意·弘阔护手',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3957 },
      { 属性: 属性类型.身法, 值: 719 },
      { 属性: 属性类型.外功基础攻击, 值: 958 },
      { 属性: 属性类型.外功破防等级, 值: 3850 },
      { 属性: 属性类型.全能等级, 值: 1711 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98583,
    uid: 215344,
    装备名称: '月落护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.身法, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.外功会心等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98582,
    uid: 215343,
    装备名称: '月稠护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.力道, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.外功会心等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98517,
    uid: 215236,
    装备名称: '客行江湖·纵巧护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.身法, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.外功破防等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98516,
    uid: 215235,
    装备名称: '客行江湖·之远护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.力道, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.外功破防等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98473,
    uid: 215101,
    装备名称: '夜苔护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.外功基础攻击, 值: 2664 },
      { 属性: 属性类型.破招值, 值: 4464 },
      { 属性: 属性类型.外功破防等级, 值: 4039 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98472,
    uid: 215100,
    装备名称: '入影护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.外功基础攻击, 值: 3235 },
      { 属性: 属性类型.无双等级, 值: 7121 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98457,
    uid: 215077,
    装备名称: '云貌护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3045 },
      { 属性: 属性类型.破招值, 值: 2551 },
      { 属性: 属性类型.外功破防等级, 值: 2763 },
      { 属性: 属性类型.外功会心等级, 值: 2763 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98455,
    uid: 215075,
    装备名称: '天直护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2759 },
      { 属性: 属性类型.外功破防等级, 值: 3082 },
      { 属性: 属性类型.外功会心等级, 值: 3295 },
      { 属性: 属性类型.外功会心效果等级, 值: 2126 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98454,
    uid: 215074,
    装备名称: '非色护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2759 },
      { 属性: 属性类型.外功破防等级, 值: 4251 },
      { 属性: 属性类型.无双等级, 值: 4464 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98453,
    uid: 215073,
    装备名称: '金土护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3235 },
      { 属性: 属性类型.外功会心等级, 值: 7546 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98409,
    uid: 214937,
    装备名称: '救困护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.身法, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.外功破防等级, 值: 3826 },
      { 属性: 属性类型.无双等级, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 98408,
    uid: 214936,
    装备名称: '磊落护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.力道, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.外功破防等级, 值: 3826 },
      { 属性: 属性类型.无双等级, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 98373,
    uid: 214877,
    装备名称: '照耀护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.身法, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.外功会心等级, 值: 3826 },
      { 属性: 属性类型.无双等级, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98372,
    uid: 214876,
    装备名称: '如雪护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.力道, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.外功会心等级, 值: 3826 },
      { 属性: 属性类型.无双等级, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98249,
    uid: 214753,
    装备名称: '鸿辉·眠狸护手',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3882 },
      { 属性: 属性类型.身法, 值: 753 },
      { 属性: 属性类型.外功基础攻击, 值: 1221 },
      { 属性: 属性类型.外功会心等级, 值: 3777 },
      { 属性: 属性类型.破招值, 值: 3357 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98248,
    uid: 214752,
    装备名称: '鸿辉·凛霜护手',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3882 },
      { 属性: 属性类型.力道, 值: 753 },
      { 属性: 属性类型.外功基础攻击, 值: 1221 },
      { 属性: 属性类型.外功会心等级, 值: 3777 },
      { 属性: 属性类型.破招值, 值: 3357 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98243,
    uid: 214747,
    装备名称: '鸿辉·霭琼护手',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3882 },
      { 属性: 属性类型.身法, 值: 753 },
      { 属性: 属性类型.外功基础攻击, 值: 1221 },
      { 属性: 属性类型.外功会心等级, 值: 3777 },
      { 属性: 属性类型.破招值, 值: 3357 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98567,
    uid: 215310,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2689 },
      { 属性: 属性类型.外功破防等级, 值: 3003 },
      { 属性: 属性类型.外功会心等级, 值: 3210 },
      { 属性: 属性类型.外功会心效果等级, 值: 2071 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98566,
    uid: 215309,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2689 },
      { 属性: 属性类型.外功会心等级, 值: 4246 },
      { 属性: 属性类型.外功破防等级, 值: 4246 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98565,
    uid: 215308,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3152 },
      { 属性: 属性类型.外功会心等级, 值: 7352 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98543,
    uid: 215268,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2538 },
      { 属性: 属性类型.外功会心等级, 值: 3715 },
      { 属性: 属性类型.外功会心效果等级, 值: 1955 },
      { 属性: 属性类型.无双等级, 值: 2151 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98542,
    uid: 215267,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2538 },
      { 属性: 属性类型.破招值, 值: 3910 },
      { 属性: 属性类型.外功破防等级, 值: 4106 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 98541,
    uid: 215266,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2976 },
      { 属性: 属性类型.无双等级, 值: 6941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96367,
    uid: 212092,
    装备名称: '风停袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3567 },
      { 属性: 属性类型.身法, 值: 692 },
      { 属性: 属性类型.外功基础攻击, 值: 1122 },
      { 属性: 属性类型.外功破防等级, 值: 3470 },
      { 属性: 属性类型.破招值, 值: 3085 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96366,
    uid: 212091,
    装备名称: '风烈袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3567 },
      { 属性: 属性类型.力道, 值: 692 },
      { 属性: 属性类型.外功基础攻击, 值: 1122 },
      { 属性: 属性类型.外功破防等级, 值: 3470 },
      { 属性: 属性类型.破招值, 值: 3085 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 99908,
    uid: 216962,
    装备名称: '陶然意·旭影护手',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3542 },
      { 属性: 属性类型.身法, 值: 644 },
      { 属性: 属性类型.外功基础攻击, 值: 857 },
      { 属性: 属性类型.外功破防等级, 值: 3446 },
      { 属性: 属性类型.全能等级, 值: 1531 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 99907,
    uid: 216961,
    装备名称: '陶然意·沉麟护手',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3542 },
      { 属性: 属性类型.力道, 值: 644 },
      { 属性: 属性类型.外功基础攻击, 值: 857 },
      { 属性: 属性类型.外功破防等级, 值: 3446 },
      { 属性: 属性类型.全能等级, 值: 1531 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 99903,
    uid: 216957,
    装备名称: '陶然意·弘阔护手',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3542 },
      { 属性: 属性类型.身法, 值: 644 },
      { 属性: 属性类型.外功基础攻击, 值: 857 },
      { 属性: 属性类型.外功破防等级, 值: 3446 },
      { 属性: 属性类型.全能等级, 值: 1531 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98679,
    uid: 215541,
    装备名称: '东方日出·天宇护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.身法, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功会心等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98678,
    uid: 215540,
    装备名称: '东方日出·海光护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.力道, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功会心等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96477,
    uid: 212281,
    装备名称: '泉幽护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.身法, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功会心等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96476,
    uid: 212280,
    装备名称: '泉潺护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.力道, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功会心等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96403,
    uid: 212159,
    装备名称: '客行江湖·霄月护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.身法, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功破防等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96402,
    uid: 212158,
    装备名称: '客行江湖·听钟护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.力道, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功破防等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96359,
    uid: 212024,
    装备名称: '流晖护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.外功基础攻击, 值: 2382 },
      { 属性: 属性类型.破招值, 值: 3991 },
      { 属性: 属性类型.外功破防等级, 值: 3611 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96358,
    uid: 212023,
    装备名称: '星风护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.外功基础攻击, 值: 2893 },
      { 属性: 属性类型.无双等级, 值: 6367 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96343,
    uid: 212000,
    装备名称: '灭影护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2723 },
      { 属性: 属性类型.破招值, 值: 2281 },
      { 属性: 属性类型.外功破防等级, 值: 2471 },
      { 属性: 属性类型.外功会心等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96341,
    uid: 211998,
    装备名称: '逸遥护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2468 },
      { 属性: 属性类型.外功破防等级, 值: 2756 },
      { 属性: 属性类型.外功会心等级, 值: 2946 },
      { 属性: 属性类型.外功会心效果等级, 值: 1901 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96340,
    uid: 211997,
    装备名称: '道尘护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2468 },
      { 属性: 属性类型.外功破防等级, 值: 3801 },
      { 属性: 属性类型.无双等级, 值: 3991 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96339,
    uid: 211996,
    装备名称: '暮峰护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2893 },
      { 属性: 属性类型.外功会心等级, 值: 6748 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96295,
    uid: 211860,
    装备名称: '踏雁护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.身法, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功破防等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 96294,
    uid: 211859,
    装备名称: '素鸦护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.力道, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功破防等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 96259,
    uid: 211800,
    装备名称: '风掣护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.身法, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功会心等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96258,
    uid: 211799,
    装备名称: '凛行护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.力道, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功会心等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98163,
    uid: 214587,
    装备名称: '寻踪觅宝·飞旋袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13750,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.身法, 值: 672 },
      { 属性: 属性类型.外功基础攻击, 值: 1090 },
      { 属性: 属性类型.外功破防等级, 值: 3372 },
      { 属性: 属性类型.破招值, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98162,
    uid: 214586,
    装备名称: '寻踪觅宝·碎浪袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13750,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.力道, 值: 672 },
      { 属性: 属性类型.外功基础攻击, 值: 1090 },
      { 属性: 属性类型.外功破防等级, 值: 3372 },
      { 属性: 属性类型.破招值, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96135,
    uid: 211676,
    装备名称: '灵源·寂林护手',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.身法, 值: 672 },
      { 属性: 属性类型.外功基础攻击, 值: 1090 },
      { 属性: 属性类型.外功会心等级, 值: 3372 },
      { 属性: 属性类型.破招值, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96134,
    uid: 211675,
    装备名称: '灵源·休归护手',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.力道, 值: 672 },
      { 属性: 属性类型.外功基础攻击, 值: 1090 },
      { 属性: 属性类型.外功会心等级, 值: 3372 },
      { 属性: 属性类型.破招值, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96129,
    uid: 211670,
    装备名称: '灵源·风涛护手',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.身法, 值: 672 },
      { 属性: 属性类型.外功基础攻击, 值: 1090 },
      { 属性: 属性类型.外功会心等级, 值: 3372 },
      { 属性: 属性类型.破招值, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96461,
    uid: 212247,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2397 },
      { 属性: 属性类型.破招值, 值: 2031 },
      { 属性: 属性类型.外功会心等级, 值: 3508 },
      { 属性: 属性类型.外功会心效果等级, 值: 1846 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96460,
    uid: 212246,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2397 },
      { 属性: 属性类型.外功会心等级, 值: 4616 },
      { 属性: 属性类型.外功会心效果等级, 值: 2769 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96459,
    uid: 212245,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2810 },
      { 属性: 属性类型.外功破防等级, 值: 6554 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96431,
    uid: 212193,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2264 },
      { 属性: 属性类型.外功破防等级, 值: 2529 },
      { 属性: 属性类型.外功会心等级, 值: 2703 },
      { 属性: 属性类型.外功会心效果等级, 值: 1744 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96430,
    uid: 212192,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2264 },
      { 属性: 属性类型.外功会心等级, 值: 3575 },
      { 属性: 属性类型.外功破防等级, 值: 3575 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96429,
    uid: 212191,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2655 },
      { 属性: 属性类型.外功会心等级, 值: 6191 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94464,
    uid: 208158,
    装备名称: '雪漫袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3176 },
      { 属性: 属性类型.身法, 值: 616 },
      { 属性: 属性类型.外功基础攻击, 值: 999 },
      { 属性: 属性类型.外功破防等级, 值: 3090 },
      { 属性: 属性类型.破招值, 值: 2747 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 94463,
    uid: 208157,
    装备名称: '雪舞袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3176 },
      { 属性: 属性类型.力道, 值: 616 },
      { 属性: 属性类型.外功基础攻击, 值: 999 },
      { 属性: 属性类型.外功破防等级, 值: 3090 },
      { 属性: 属性类型.破招值, 值: 2747 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96573,
    uid: 212478,
    装备名称: '西风北啸·角寒护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.身法, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功会心等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96572,
    uid: 212477,
    装备名称: '西风北啸·砾漠护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.力道, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功会心等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94566,
    uid: 208332,
    装备名称: '湖月护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.身法, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功会心等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94565,
    uid: 208331,
    装备名称: '湖静护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.力道, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功会心等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94500,
    uid: 208224,
    装备名称: '客行江湖·天配护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.身法, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功破防等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 94499,
    uid: 208223,
    装备名称: '客行江湖·梦花护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.力道, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功破防等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 94452,
    uid: 208082,
    装备名称: '夙辰护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.外功基础攻击, 值: 2582 },
      { 属性: 属性类型.破招值, 值: 5683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 94450,
    uid: 208080,
    装备名称: '零雨护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.外功基础攻击, 值: 1823 },
      { 属性: 属性类型.外功会心等级, 值: 2205 },
      { 属性: 属性类型.无双等级, 值: 5428 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94449,
    uid: 208079,
    装备名称: '制野护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.外功基础攻击, 值: 2126 },
      { 属性: 属性类型.外功破防等级, 值: 3223 },
      { 属性: 属性类型.无双等级, 值: 3562 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94448,
    uid: 208078,
    装备名称: '雨膏护腕',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.外功基础攻击, 值: 2126 },
      { 属性: 属性类型.破招值, 值: 3562 },
      { 属性: 属性类型.外功破防等级, 值: 3223 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94404,
    uid: 207944,
    装备名称: '染辞护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.身法, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功破防等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 94403,
    uid: 207943,
    装备名称: '温刃护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.力道, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功破防等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 94368,
    uid: 207884,
    装备名称: '商野护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.身法, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功会心等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94367,
    uid: 207883,
    装备名称: '安衿护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.力道, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功会心等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 90510,
    uid: 189002,
    装备名称: '临钧护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3126 },
      { 属性: 属性类型.身法, 值: 606 },
      { 属性: 属性类型.外功基础攻击, 值: 983 },
      { 属性: 属性类型.外功会心等级, 值: 3041 },
      { 属性: 属性类型.破招值, 值: 2703 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 90509,
    uid: 189001,
    装备名称: '临英护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3126 },
      { 属性: 属性类型.力道, 值: 606 },
      { 属性: 属性类型.外功基础攻击, 值: 983 },
      { 属性: 属性类型.外功会心等级, 值: 3041 },
      { 属性: 属性类型.破招值, 值: 2703 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100335,
    uid: 217810,
    装备名称: '塞尘袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 750 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100334,
    uid: 217809,
    装备名称: '峭月袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 750 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100299,
    uid: 217719,
    装备名称: '奔枝袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 750 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 100298,
    uid: 217718,
    装备名称: '环旌袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 750 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 97846,
    uid: 213807,
    装备名称: '濯心·猎风护手',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96079,
    uid: 211589,
    装备名称: '寻踪觅宝·屠云袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96078,
    uid: 211588,
    装备名称: '寻踪觅宝·惊风袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 94248,
    uid: 207764,
    装备名称: '濯心·锋虹护手',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94243,
    uid: 207759,
    装备名称: '濯心·盈怀护手',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 90642,
    uid: 189320,
    装备名称: '久念袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 90641,
    uid: 189319,
    装备名称: '拭江袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 90606,
    uid: 189230,
    装备名称: '风岱袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 90605,
    uid: 189229,
    装备名称: '项昌袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 90570,
    uid: 189140,
    装备名称: '北邱护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 90569,
    uid: 189139,
    装备名称: '曲郦护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 90534,
    uid: 189050,
    装备名称: '渊忱护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 90533,
    uid: 189049,
    装备名称: '羡双护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 90402,
    uid: 188768,
    装备名称: '忆宁护手',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 90401,
    uid: 188767,
    装备名称: '忆敬护手',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 90366,
    uid: 188678,
    装备名称: '盈绝袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.破招值, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90365,
    uid: 188677,
    装备名称: '垣翰袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.破招值, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90330,
    uid: 188588,
    装备名称: '潋阳袖',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 90329,
    uid: 188587,
    装备名称: '重关袖',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94550,
    uid: 208298,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2140 },
      { 属性: 属性类型.外功会心等级, 值: 3132 },
      { 属性: 属性类型.外功会心效果等级, 值: 1649 },
      { 属性: 属性类型.无双等级, 值: 1814 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94549,
    uid: 208297,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2140 },
      { 属性: 属性类型.破招值, 值: 3297 },
      { 属性: 属性类型.外功破防等级, 值: 3462 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 94548,
    uid: 208296,
    装备名称: '无封护臂',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2509 },
      { 属性: 属性类型.无双等级, 值: 5853 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
]

export default 护腕装备数据
