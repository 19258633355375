import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 鞋子装备数据: 装备属性信息模型[] = [
  {
    id: 98491,
    uid: 215179,
    装备名称: '水泓靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3983 },
      { 属性: 属性类型.元气, 值: 772 },
      { 属性: 属性类型.内功基础攻击, 值: 1503 },
      { 属性: 属性类型.内功破防等级, 值: 3875 },
      { 属性: 属性类型.破招值, 值: 3444 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 98490,
    uid: 215178,
    装备名称: '水川靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3983 },
      { 属性: 属性类型.根骨, 值: 772 },
      { 属性: 属性类型.内功基础攻击, 值: 1503 },
      { 属性: 属性类型.内功破防等级, 值: 3875 },
      { 属性: 属性类型.破招值, 值: 3444 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100074,
    uid: 217002,
    装备名称: '陶然意·和时靴',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3957 },
      { 属性: 属性类型.根骨, 值: 719 },
      { 属性: 属性类型.内功基础攻击, 值: 1149 },
      { 属性: 属性类型.内功破防等级, 值: 3850 },
      { 属性: 属性类型.全能等级, 值: 1711 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100073,
    uid: 217001,
    装备名称: '陶然意·星雪靴',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3957 },
      { 属性: 属性类型.元气, 值: 719 },
      { 属性: 属性类型.内功基础攻击, 值: 1149 },
      { 属性: 属性类型.内功破防等级, 值: 3850 },
      { 属性: 属性类型.全能等级, 值: 1711 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100057,
    uid: 216985,
    装备名称: '陶然意·兰谷靴',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3957 },
      { 属性: 属性类型.元气, 值: 719 },
      { 属性: 属性类型.内功基础攻击, 值: 1149 },
      { 属性: 属性类型.内功破防等级, 值: 3850 },
      { 属性: 属性类型.全能等级, 值: 1711 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98593,
    uid: 215354,
    装备名称: '月迟靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.元气, 值: 763 },
      { 属性: 属性类型.内功基础攻击, 值: 1484 },
      { 属性: 属性类型.全会心等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 98592,
    uid: 215353,
    装备名称: '月纤靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.根骨, 值: 763 },
      { 属性: 属性类型.内功基础攻击, 值: 1484 },
      { 属性: 属性类型.内功会心等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 98419,
    uid: 214947,
    装备名称: '良安靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.元气, 值: 763 },
      { 属性: 属性类型.内功基础攻击, 值: 1484 },
      { 属性: 属性类型.全会心等级, 值: 3826 },
      { 属性: 属性类型.无双等级, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98418,
    uid: 214946,
    装备名称: '情义靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.根骨, 值: 763 },
      { 属性: 属性类型.内功基础攻击, 值: 1484 },
      { 属性: 属性类型.内功会心等级, 值: 3826 },
      { 属性: 属性类型.无双等级, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98383,
    uid: 214887,
    装备名称: '宫阙靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.元气, 值: 763 },
      { 属性: 属性类型.内功基础攻击, 值: 1484 },
      { 属性: 属性类型.加速等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 98382,
    uid: 214886,
    装备名称: '绕城靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.根骨, 值: 763 },
      { 属性: 属性类型.内功基础攻击, 值: 1484 },
      { 属性: 属性类型.加速等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 98306,
    uid: 214810,
    装备名称: '鸿辉·白林靴',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3882 },
      { 属性: 属性类型.根骨, 值: 753 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功破防等级, 值: 3777 },
      { 属性: 属性类型.无双等级, 值: 3357 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98305,
    uid: 214809,
    装备名称: '鸿辉·梦辰靴',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3882 },
      { 属性: 属性类型.元气, 值: 753 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功破防等级, 值: 3777 },
      { 属性: 属性类型.无双等级, 值: 3357 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98282,
    uid: 214786,
    装备名称: '鸿辉·鹿喧靴',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3882 },
      { 属性: 属性类型.元气, 值: 753 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功破防等级, 值: 3777 },
      { 属性: 属性类型.无双等级, 值: 3357 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98576,
    uid: 215319,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3226 },
      { 属性: 属性类型.破招值, 值: 2278 },
      { 属性: 属性类型.全会心等级, 值: 3935 },
      { 属性: 属性类型.无双等级, 值: 2278 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98575,
    uid: 215318,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3226 },
      { 属性: 属性类型.内功破防等级, 值: 4142 },
      { 属性: 属性类型.无双等级, 值: 4349 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98574,
    uid: 215317,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3783 },
      { 属性: 属性类型.无双等级, 值: 7352 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98552,
    uid: 215277,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3046 },
      { 属性: 属性类型.破招值, 值: 2737 },
      { 属性: 属性类型.内功破防等级, 值: 3324 },
      { 属性: 属性类型.无双等级, 值: 1955 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98551,
    uid: 215276,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3046 },
      { 属性: 属性类型.全会心等级, 值: 4008 },
      { 属性: 属性类型.内功破防等级, 值: 4008 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98550,
    uid: 215275,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3571 },
      { 属性: 属性类型.全会心等级, 值: 6941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 96377,
    uid: 212102,
    装备名称: '风绫靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3567 },
      { 属性: 属性类型.元气, 值: 692 },
      { 属性: 属性类型.内功基础攻击, 值: 1346 },
      { 属性: 属性类型.内功破防等级, 值: 3470 },
      { 属性: 属性类型.破招值, 值: 3085 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 96376,
    uid: 212101,
    装备名称: '风轻靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3567 },
      { 属性: 属性类型.根骨, 值: 692 },
      { 属性: 属性类型.内功基础攻击, 值: 1346 },
      { 属性: 属性类型.内功破防等级, 值: 3470 },
      { 属性: 属性类型.破招值, 值: 3085 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 99948,
    uid: 217002,
    装备名称: '陶然意·和时靴',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3542 },
      { 属性: 属性类型.根骨, 值: 644 },
      { 属性: 属性类型.内功基础攻击, 值: 1028 },
      { 属性: 属性类型.内功破防等级, 值: 3446 },
      { 属性: 属性类型.全能等级, 值: 1531 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 99947,
    uid: 217001,
    装备名称: '陶然意·星雪靴',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3542 },
      { 属性: 属性类型.元气, 值: 644 },
      { 属性: 属性类型.内功基础攻击, 值: 1028 },
      { 属性: 属性类型.内功破防等级, 值: 3446 },
      { 属性: 属性类型.全能等级, 值: 1531 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 99931,
    uid: 216985,
    装备名称: '陶然意·兰谷靴',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3542 },
      { 属性: 属性类型.元气, 值: 644 },
      { 属性: 属性类型.内功基础攻击, 值: 1028 },
      { 属性: 属性类型.内功破防等级, 值: 3446 },
      { 属性: 属性类型.全能等级, 值: 1531 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98689,
    uid: 215551,
    装备名称: '东方日出·当楼靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.元气, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.全会心等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98688,
    uid: 215550,
    装备名称: '东方日出·所适靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.根骨, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.内功会心等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98197,
    uid: 214652,
    装备名称: '危影靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.元气, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.内功破防等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98196,
    uid: 214651,
    装备名称: '危音靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.根骨, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.内功破防等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96487,
    uid: 212291,
    装备名称: '泉麓靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.元气, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.全会心等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 96486,
    uid: 212290,
    装备名称: '泉合靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.根骨, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.内功会心等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 96305,
    uid: 211870,
    装备名称: '壑云靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.元气, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.全会心等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96304,
    uid: 211869,
    装备名称: '寒绡靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.根骨, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.内功会心等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96269,
    uid: 211810,
    装备名称: '开颐靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.元气, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.加速等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 96268,
    uid: 211809,
    装备名称: '扬英靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.根骨, 值: 682 },
      { 属性: 属性类型.内功基础攻击, 值: 1327 },
      { 属性: 属性类型.加速等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 98173,
    uid: 214597,
    装备名称: '寻踪觅宝·星辉靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13750,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.元气, 值: 672 },
      { 属性: 属性类型.内功基础攻击, 值: 1308 },
      { 属性: 属性类型.全会心等级, 值: 3372 },
      { 属性: 属性类型.无双等级, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98172,
    uid: 214596,
    装备名称: '寻踪觅宝·折月靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13750,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.根骨, 值: 672 },
      { 属性: 属性类型.内功基础攻击, 值: 1308 },
      { 属性: 属性类型.内功会心等级, 值: 3372 },
      { 属性: 属性类型.无双等级, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96192,
    uid: 211733,
    装备名称: '灵源·采芳靴',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.根骨, 值: 672 },
      { 属性: 属性类型.内功基础攻击, 值: 1308 },
      { 属性: 属性类型.内功破防等级, 值: 3372 },
      { 属性: 属性类型.无双等级, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96191,
    uid: 211732,
    装备名称: '灵源·沉辉靴',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.元气, 值: 672 },
      { 属性: 属性类型.内功基础攻击, 值: 1308 },
      { 属性: 属性类型.内功破防等级, 值: 3372 },
      { 属性: 属性类型.无双等级, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96168,
    uid: 211709,
    装备名称: '灵源·月胧靴',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.元气, 值: 672 },
      { 属性: 属性类型.内功基础攻击, 值: 1308 },
      { 属性: 属性类型.内功破防等级, 值: 3372 },
      { 属性: 属性类型.无双等级, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96470,
    uid: 212256,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2876 },
      { 属性: 属性类型.全会心等级, 值: 3508 },
      { 属性: 属性类型.全会心效果等级, 值: 1846 },
      { 属性: 属性类型.无双等级, 值: 2031 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96469,
    uid: 212255,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2876 },
      { 属性: 属性类型.破招值, 值: 3785 },
      { 属性: 属性类型.无双等级, 值: 3785 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96468,
    uid: 212254,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3372 },
      { 属性: 属性类型.内功破防等级, 值: 6554 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96444,
    uid: 212206,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2717 },
      { 属性: 属性类型.破招值, 值: 1918 },
      { 属性: 属性类型.全会心等级, 值: 3314 },
      { 属性: 属性类型.无双等级, 值: 1918 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96443,
    uid: 212205,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2717 },
      { 属性: 属性类型.内功破防等级, 值: 3488 },
      { 属性: 属性类型.无双等级, 值: 3662 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96442,
    uid: 212204,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3185 },
      { 属性: 属性类型.无双等级, 值: 6191 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94474,
    uid: 208168,
    装备名称: '雪洁靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3176 },
      { 属性: 属性类型.元气, 值: 616 },
      { 属性: 属性类型.内功基础攻击, 值: 1199 },
      { 属性: 属性类型.内功破防等级, 值: 3090 },
      { 属性: 属性类型.破招值, 值: 2747 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 94473,
    uid: 208167,
    装备名称: '雪满靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3176 },
      { 属性: 属性类型.根骨, 值: 616 },
      { 属性: 属性类型.内功基础攻击, 值: 1199 },
      { 属性: 属性类型.内功破防等级, 值: 3090 },
      { 属性: 属性类型.破招值, 值: 2747 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 96583,
    uid: 212488,
    装备名称: '西风北啸·离声靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.元气, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.全会心等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96582,
    uid: 212487,
    装备名称: '西风北啸·音书靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.根骨, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.内功会心等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94576,
    uid: 208342,
    装备名称: '湖烟靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.元气, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.全会心等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 94575,
    uid: 208341,
    装备名称: '湖寂靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.根骨, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.内功会心等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 94414,
    uid: 207954,
    装备名称: '沁渡靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.元气, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.全会心等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94413,
    uid: 207953,
    装备名称: '朝华靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.根骨, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.内功会心等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94378,
    uid: 207894,
    装备名称: '椴微靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.元气, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.加速等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 94377,
    uid: 207893,
    装备名称: '池泓靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.根骨, 值: 609 },
      { 属性: 属性类型.内功基础攻击, 值: 1185 },
      { 属性: 属性类型.加速等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 90520,
    uid: 189012,
    装备名称: '临虹鞋',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3126 },
      { 属性: 属性类型.元气, 值: 606 },
      { 属性: 属性类型.内功基础攻击, 值: 1180 },
      { 属性: 属性类型.全会心等级, 值: 3041 },
      { 属性: 属性类型.破招值, 值: 2703 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 90519,
    uid: 189011,
    装备名称: '临宇鞋',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3126 },
      { 属性: 属性类型.根骨, 值: 606 },
      { 属性: 属性类型.内功基础攻击, 值: 1180 },
      { 属性: 属性类型.内功会心等级, 值: 3041 },
      { 属性: 属性类型.破招值, 值: 2703 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100345,
    uid: 217820,
    装备名称: '若寓靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 900 },
      { 属性: 属性类型.内功破防等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100344,
    uid: 217819,
    装备名称: '越风靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 900 },
      { 属性: 属性类型.内功破防等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100309,
    uid: 217729,
    装备名称: '踏江履',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 900 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100308,
    uid: 217728,
    装备名称: '承康履',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 900 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96089,
    uid: 211599,
    装备名称: '寻踪觅宝·泻雨靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.全会心等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96088,
    uid: 211598,
    装备名称: '寻踪觅宝·拂雪靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功会心等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94304,
    uid: 207820,
    装备名称: '濯心·采青靴',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 94303,
    uid: 207819,
    装备名称: '濯心·天尘靴',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 94280,
    uid: 207796,
    装备名称: '濯心·松声靴',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 90652,
    uid: 189330,
    装备名称: '藏峦靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 90651,
    uid: 189329,
    装备名称: '谨峰靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 90616,
    uid: 189240,
    装备名称: '故芳靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 90615,
    uid: 189239,
    装备名称: '剪桐靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 90580,
    uid: 189150,
    装备名称: '花霭靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 90579,
    uid: 189149,
    装备名称: '途南靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 90544,
    uid: 189060,
    装备名称: '庭澜靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.全会心等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 90543,
    uid: 189059,
    装备名称: '故云靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功会心等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 90412,
    uid: 188778,
    装备名称: '忆惜履',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.全会心等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 90411,
    uid: 188777,
    装备名称: '忆安履',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功会心等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 90376,
    uid: 188688,
    装备名称: '语阔靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 90375,
    uid: 188687,
    装备名称: '擒雨靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.内功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 90340,
    uid: 188598,
    装备名称: '烟琐履',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.元气, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 90339,
    uid: 188597,
    装备名称: '德襄履',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.根骨, 值: 601 },
      { 属性: 属性类型.内功基础攻击, 值: 1170 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94559,
    uid: 208307,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2568 },
      { 属性: 属性类型.破招值, 值: 2308 },
      { 属性: 属性类型.内功破防等级, 值: 2803 },
      { 属性: 属性类型.无双等级, 值: 1649 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94558,
    uid: 208306,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2568 },
      { 属性: 属性类型.全会心等级, 值: 3380 },
      { 属性: 属性类型.内功破防等级, 值: 3380 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94557,
    uid: 208305,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3011 },
      { 属性: 属性类型.全会心等级, 值: 5853 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
]

export default 鞋子装备数据
