/**
 * @name 技能伤害dps
 * @description 技能伤害计算遵循郭氏理论
 * @url https://www.jx3box.com/bps/12752
 */
import { 自身属性系数, 每等级减伤 } from '@/数据/常量'
import { 目标属性类型 } from '@/@types/常量'
import { 技能基础数据模型 } from '@/@types/技能'
import { 角色基础属性类型 } from '@/@types/角色'
import 获取当前数据 from '@/数据/数据工具/获取当前数据'
import { 获取实际系数 } from '@/数据/数据工具/获取技能系数'

const { 功法 } = 获取当前数据()

/**
 * @name 破招基础伤害
 */
export const 破招基础伤害 = (计算系数 = 0, 人物属性: 角色基础属性类型, 技能增伤) => {
  const { 破招值 = 0 } = 人物属性
  const { 全局伤害因子 = 1 } = 技能增伤
  let 技能原始伤害 = 计算系数 * 破招值

  if (全局伤害因子 !== 1) {
    技能原始伤害 = 技能原始伤害 * 全局伤害因子
  }
  return 技能原始伤害
}

/**
 * @name 技能基础伤害
 */
export const 技能基础伤害 = (
  当前技能属性: 技能基础数据模型,
  人物属性: 角色基础属性类型,
  技能增伤
) => {
  const { 武器伤害_最小值 = 0, 武器伤害_最大值 = 0, 面板攻击 = 0 } = 人物属性
  const { 系数增伤 = 1, 全局伤害因子 = 1 } = 技能增伤
  const {
    武器伤害系数 = 0,
    基础伤害_基础值 = 0,
    基础伤害_浮动值 = 0,
    基础伤害_倍率 = undefined,
    伤害计算次数 = 1,
    DOT跳数 = 0,
    DOT生效间隔 = 0,
    技能伤害系数 = 0,
  } = 当前技能属性

  const dot参数 = DOT跳数 && DOT生效间隔 ? { dot跳数: DOT跳数, dot间隔: DOT生效间隔 } : undefined

  const 实际系数 = 获取实际系数(技能伤害系数, dot参数, 功法, 系数增伤)

  const 基础值 = 基础伤害_倍率 ? 基础伤害_基础值 * 基础伤害_倍率 : 基础伤害_基础值
  const 浮动值 = 基础伤害_倍率 ? 基础伤害_浮动值 * 基础伤害_倍率 : 基础伤害_浮动值

  const 技能基础伤害平均值 = (基础值 + 基础值 + 浮动值) / 2
  const 人物武器伤害平均值 = (武器伤害_最小值 + 武器伤害_最大值) / 2

  let 技能原始伤害 = 技能基础伤害平均值 + 面板攻击 * 实际系数 + 人物武器伤害平均值 * 武器伤害系数

  if (全局伤害因子 !== 1) {
    技能原始伤害 = 技能原始伤害 * 全局伤害因子
  }

  return 技能原始伤害 * 伤害计算次数
}

/**
 * @name 破防伤害算法
 */
export const 破防伤害算法 = (
  伤害: number,
  人物属性: 角色基础属性类型,
  当前目标: 目标属性类型,
  郭氏无视防御: number,
  郭氏属性无视防御: number
) => {
  const { 破防等级 } = 人物属性
  const { 防御点数, 防御系数 } = 当前目标

  // 两个乘区
  const 郭氏属性无视防御计算后防御点数 = 防御点数 * (1 + 郭氏属性无视防御 / 1024)
  const 郭氏无视防御计算后防御点数 = 郭氏属性无视防御计算后防御点数 * (1 - 郭氏无视防御 / 1024)

  const 计算后防御点数 = Math.max(郭氏无视防御计算后防御点数, 0)
  const 破防百分比 = 破防等级 / 自身属性系数.破防
  const 防御减伤 = 计算后防御点数 / (计算后防御点数 + 防御系数)
  return 伤害 * (1 + 破防百分比) * (1 - 防御减伤)
}

// 等级减伤dps
export const 等级减伤计算公式 = (
  伤害: number,
  人物属性: 角色基础属性类型,
  当前目标: 目标属性类型
) => {
  const 等级差 = Math.abs((人物属性?.等级 || 120) - 当前目标.等级)
  const 等级差距减伤 = 等级差 * 每等级减伤
  return 伤害 * (1 - 等级差距减伤)
}

// 无双计算后dps
export const 无双伤害计算公式 = (
  伤害: number,
  人物属性: 角色基础属性类型,
  郭氏额外无双等级: number
) => {
  const 无双百分比 = 人物属性.无双等级 / 自身属性系数.无双
  return 伤害 * (1 + 无双百分比 + 郭氏额外无双等级 / 1024)
}
