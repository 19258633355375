import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 武器装备数据: 装备属性信息模型[] = [
  {
    id: 38584,
    uid: 218227,
    装备名称: '煞·鹿王本生',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.根骨, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6651 },
      { 属性: 属性类型.内功会心等级, 值: 5474 },
      { 属性: 属性类型.内功破防等级, 值: 6488 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38583,
    uid: 218226,
    装备名称: '煞·月升海楼',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.元气, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6760 },
      { 属性: 属性类型.内功会心等级, 值: 5272 },
      { 属性: 属性类型.内功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2433 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38559,
    uid: 218202,
    装备名称: '煞·璃光浮远',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12500,
    武器伤害_最小值: 1346,
    武器伤害_最大值: 2243,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.元气, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6760 },
      { 属性: 属性类型.内功会心等级, 值: 5474 },
      { 属性: 属性类型.内功破防等级, 值: 6285 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38074,
    uid: 200992,
    装备名称: '鹿王本生',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.根骨, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6651 },
      { 属性: 属性类型.内功会心等级, 值: 5474 },
      { 属性: 属性类型.内功破防等级, 值: 6488 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38073,
    uid: 200991,
    装备名称: '月升海楼',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.元气, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6760 },
      { 属性: 属性类型.内功会心等级, 值: 5272 },
      { 属性: 属性类型.内功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2433 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38049,
    uid: 200967,
    装备名称: '璃光浮远',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12500,
    武器伤害_最小值: 1346,
    武器伤害_最大值: 2243,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.元气, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6760 },
      { 属性: 属性类型.内功会心等级, 值: 5474 },
      { 属性: 属性类型.内功破防等级, 值: 6285 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38043,
    uid: 200992,
    装备名称: '鹿王本生',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 11900,
    武器伤害_最小值: 2563,
    武器伤害_最大值: 4272,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7142 },
      { 属性: 属性类型.根骨, 值: 1385 },
      { 属性: 属性类型.内功基础攻击, 值: 6332 },
      { 属性: 属性类型.内功会心等级, 值: 5212 },
      { 属性: 属性类型.内功破防等级, 值: 6177 },
      { 属性: 属性类型.加速等级, 值: 1930 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38042,
    uid: 200991,
    装备名称: '月升海楼',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 11900,
    武器伤害_最小值: 2563,
    武器伤害_最大值: 4272,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7142 },
      { 属性: 属性类型.元气, 值: 1385 },
      { 属性: 属性类型.内功基础攻击, 值: 6436 },
      { 属性: 属性类型.内功会心等级, 值: 5019 },
      { 属性: 属性类型.内功破防等级, 值: 5791 },
      { 属性: 属性类型.加速等级, 值: 2316 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38018,
    uid: 200967,
    装备名称: '璃光浮远',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 11900,
    武器伤害_最小值: 1281,
    武器伤害_最大值: 2135,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7142 },
      { 属性: 属性类型.元气, 值: 1385 },
      { 属性: 属性类型.内功基础攻击, 值: 6436 },
      { 属性: 属性类型.内功会心等级, 值: 5212 },
      { 属性: 属性类型.内功破防等级, 值: 5984 },
      { 属性: 属性类型.加速等级, 值: 1930 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37101,
    uid: 214568,
    装备名称: '迟莲·旧歌',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 11650,
    武器伤害_最小值: 2509,
    武器伤害_最大值: 4182,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.根骨, 值: 1356 },
      { 属性: 属性类型.内功基础攻击, 值: 6199 },
      { 属性: 属性类型.内功会心等级, 值: 5102 },
      { 属性: 属性类型.内功破防等级, 值: 6047 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37100,
    uid: 214567,
    装备名称: '长生缘',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 11650,
    武器伤害_最小值: 2509,
    武器伤害_最大值: 4182,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.元气, 值: 1356 },
      { 属性: 属性类型.内功基础攻击, 值: 6300 },
      { 属性: 属性类型.内功会心等级, 值: 4913 },
      { 属性: 属性类型.内功破防等级, 值: 5669 },
      { 属性: 属性类型.加速等级, 值: 2268 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37076,
    uid: 214543,
    装备名称: '衔羽还·今夕',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 11650,
    武器伤害_最小值: 1254,
    武器伤害_最大值: 2090,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.元气, 值: 1356 },
      { 属性: 属性类型.内功基础攻击, 值: 6300 },
      { 属性: 属性类型.内功会心等级, 值: 5102 },
      { 属性: 属性类型.内功破防等级, 值: 5858 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 36596,
    uid: 200992,
    装备名称: '鹿王本生',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 11300,
    武器伤害_最小值: 2434,
    武器伤害_最大值: 4056,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6782 },
      { 属性: 属性类型.根骨, 值: 1315 },
      { 属性: 属性类型.内功基础攻击, 值: 6013 },
      { 属性: 属性类型.内功会心等级, 值: 4949 },
      { 属性: 属性类型.内功破防等级, 值: 5865 },
      { 属性: 属性类型.加速等级, 值: 1833 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 36595,
    uid: 200991,
    装备名称: '月升海楼',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 11300,
    武器伤害_最小值: 2434,
    武器伤害_最大值: 4056,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6782 },
      { 属性: 属性类型.元气, 值: 1315 },
      { 属性: 属性类型.内功基础攻击, 值: 6111 },
      { 属性: 属性类型.内功会心等级, 值: 4766 },
      { 属性: 属性类型.内功破防等级, 值: 5499 },
      { 属性: 属性类型.加速等级, 值: 2199 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 36571,
    uid: 200967,
    装备名称: '璃光浮远',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 11300,
    武器伤害_最小值: 1217,
    武器伤害_最大值: 2028,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6782 },
      { 属性: 属性类型.元气, 值: 1315 },
      { 属性: 属性类型.内功基础攻击, 值: 6111 },
      { 属性: 属性类型.内功会心等级, 值: 4949 },
      { 属性: 属性类型.内功破防等级, 值: 5682 },
      { 属性: 属性类型.加速等级, 值: 1833 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37070,
    uid: 214568,
    装备名称: '迟莲·旧歌',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 10900,
    武器伤害_最小值: 2347,
    武器伤害_最大值: 3912,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6542 },
      { 属性: 属性类型.根骨, 值: 1269 },
      { 属性: 属性类型.内功基础攻击, 值: 5800 },
      { 属性: 属性类型.内功会心等级, 值: 4774 },
      { 属性: 属性类型.内功破防等级, 值: 5658 },
      { 属性: 属性类型.加速等级, 值: 1768 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37069,
    uid: 214567,
    装备名称: '长生缘',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 10900,
    武器伤害_最小值: 2347,
    武器伤害_最大值: 3912,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6542 },
      { 属性: 属性类型.元气, 值: 1269 },
      { 属性: 属性类型.内功基础攻击, 值: 5895 },
      { 属性: 属性类型.内功会心等级, 值: 4597 },
      { 属性: 属性类型.内功破防等级, 值: 5304 },
      { 属性: 属性类型.加速等级, 值: 2122 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37045,
    uid: 214543,
    装备名称: '衔羽还·今夕',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 10900,
    武器伤害_最小值: 1174,
    武器伤害_最大值: 1956,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6542 },
      { 属性: 属性类型.元气, 值: 1269 },
      { 属性: 属性类型.内功基础攻击, 值: 5895 },
      { 属性: 属性类型.内功会心等级, 值: 4774 },
      { 属性: 属性类型.内功破防等级, 值: 5481 },
      { 属性: 属性类型.加速等级, 值: 1768 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 36566,
    uid: 200992,
    装备名称: '鹿王本生',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 10800,
    武器伤害_最小值: 2326,
    武器伤害_最大值: 3877,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6482 },
      { 属性: 属性类型.根骨, 值: 1257 },
      { 属性: 属性类型.内功基础攻击, 值: 5747 },
      { 属性: 属性类型.内功会心等级, 值: 4730 },
      { 属性: 属性类型.内功破防等级, 值: 5606 },
      { 属性: 属性类型.加速等级, 值: 1752 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 36565,
    uid: 200991,
    装备名称: '月升海楼',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 10800,
    武器伤害_最小值: 2326,
    武器伤害_最大值: 3877,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6482 },
      { 属性: 属性类型.元气, 值: 1257 },
      { 属性: 属性类型.内功基础攻击, 值: 5841 },
      { 属性: 属性类型.内功会心等级, 值: 4555 },
      { 属性: 属性类型.内功破防等级, 值: 5255 },
      { 属性: 属性类型.加速等级, 值: 2102 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 36541,
    uid: 200967,
    装备名称: '璃光浮远',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 10800,
    武器伤害_最小值: 1163,
    武器伤害_最大值: 1938,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6482 },
      { 属性: 属性类型.元气, 值: 1257 },
      { 属性: 属性类型.内功基础攻击, 值: 5841 },
      { 属性: 属性类型.内功会心等级, 值: 4730 },
      { 属性: 属性类型.内功破防等级, 值: 5431 },
      { 属性: 属性类型.加速等级, 值: 1752 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37039,
    uid: 214537,
    装备名称: '迟莲·旧歌',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 10300,
    武器伤害_最小值: 2218,
    武器伤害_最大值: 3697,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6182 },
      { 属性: 属性类型.根骨, 值: 1199 },
      { 属性: 属性类型.内功基础攻击, 值: 5481 },
      { 属性: 属性类型.内功会心等级, 值: 4511 },
      { 属性: 属性类型.内功破防等级, 值: 5346 },
      { 属性: 属性类型.加速等级, 值: 1671 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37038,
    uid: 214536,
    装备名称: '长生缘',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 10300,
    武器伤害_最小值: 2218,
    武器伤害_最大值: 3697,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6182 },
      { 属性: 属性类型.元气, 值: 1199 },
      { 属性: 属性类型.内功基础攻击, 值: 5570 },
      { 属性: 属性类型.内功会心等级, 值: 4344 },
      { 属性: 属性类型.内功破防等级, 值: 5012 },
      { 属性: 属性类型.加速等级, 值: 2005 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37014,
    uid: 214512,
    装备名称: '衔羽还·今夕',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 10300,
    武器伤害_最小值: 1109,
    武器伤害_最大值: 1848,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6182 },
      { 属性: 属性类型.元气, 值: 1199 },
      { 属性: 属性类型.内功基础攻击, 值: 5570 },
      { 属性: 属性类型.内功会心等级, 值: 4511 },
      { 属性: 属性类型.内功破防等级, 值: 5179 },
      { 属性: 属性类型.加速等级, 值: 1671 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 35625,
    uid: 200992,
    装备名称: '鹿王本生',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 10300,
    武器伤害_最小值: 2218,
    武器伤害_最大值: 3697,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6182 },
      { 属性: 属性类型.根骨, 值: 1199 },
      { 属性: 属性类型.内功基础攻击, 值: 5481 },
      { 属性: 属性类型.内功会心等级, 值: 4511 },
      { 属性: 属性类型.内功破防等级, 值: 5346 },
      { 属性: 属性类型.加速等级, 值: 1671 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 35624,
    uid: 200991,
    装备名称: '月升海楼',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 10300,
    武器伤害_最小值: 2218,
    武器伤害_最大值: 3697,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6182 },
      { 属性: 属性类型.元气, 值: 1199 },
      { 属性: 属性类型.内功基础攻击, 值: 5570 },
      { 属性: 属性类型.内功会心等级, 值: 4344 },
      { 属性: 属性类型.内功破防等级, 值: 5012 },
      { 属性: 属性类型.加速等级, 值: 2005 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 35600,
    uid: 200967,
    装备名称: '璃光浮远',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 10300,
    武器伤害_最小值: 1109,
    武器伤害_最大值: 1848,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6182 },
      { 属性: 属性类型.元气, 值: 1199 },
      { 属性: 属性类型.内功基础攻击, 值: 5570 },
      { 属性: 属性类型.内功会心等级, 值: 4511 },
      { 属性: 属性类型.内功破防等级, 值: 5179 },
      { 属性: 属性类型.加速等级, 值: 1671 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38271,
    uid: 217621,
    装备名称: '共泉声',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 10100,
    武器伤害_最小值: 2175,
    武器伤害_最大值: 3625,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6062 },
      { 属性: 属性类型.根骨, 值: 1175 },
      { 属性: 属性类型.内功基础攻击, 值: 5374 },
      { 属性: 属性类型.内功会心等级, 值: 4423 },
      { 属性: 属性类型.内功破防等级, 值: 5242 },
      { 属性: 属性类型.加速等级, 值: 1638 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38270,
    uid: 217620,
    装备名称: '万壑明',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 10100,
    武器伤害_最小值: 2175,
    武器伤害_最大值: 3625,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6062 },
      { 属性: 属性类型.元气, 值: 1175 },
      { 属性: 属性类型.内功基础攻击, 值: 5462 },
      { 属性: 属性类型.内功会心等级, 值: 4259 },
      { 属性: 属性类型.内功破防等级, 值: 4915 },
      { 属性: 属性类型.加速等级, 值: 1966 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38246,
    uid: 217596,
    装备名称: '远声樵',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 10100,
    武器伤害_最小值: 1088,
    武器伤害_最大值: 1813,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6062 },
      { 属性: 属性类型.元气, 值: 1175 },
      { 属性: 属性类型.内功基础攻击, 值: 5462 },
      { 属性: 属性类型.内功会心等级, 值: 4423 },
      { 属性: 属性类型.内功破防等级, 值: 5079 },
      { 属性: 属性类型.加速等级, 值: 1638 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37288,
    uid: 215229,
    装备名称: '云深路杳',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 15800,
    武器伤害_最小值: 2450,
    武器伤害_最大值: 4083,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6827 },
      { 属性: 属性类型.根骨, 值: 1324 },
      { 属性: 属性类型.内功基础攻击, 值: 6152 },
      { 属性: 属性类型.内功会心等级, 值: 6643 },
      { 属性: 属性类型.破招值, 值: 7086 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37287,
    uid: 215228,
    装备名称: '云霄斗柄',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 15800,
    武器伤害_最小值: 2450,
    武器伤害_最大值: 4083,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6827 },
      { 属性: 属性类型.元气, 值: 1324 },
      { 属性: 属性类型.内功基础攻击, 值: 6152 },
      { 属性: 属性类型.内功会心等级, 值: 6643 },
      { 属性: 属性类型.破招值, 值: 7086 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37263,
    uid: 215204,
    装备名称: '自笑痴',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 15800,
    武器伤害_最小值: 1225,
    武器伤害_最大值: 2042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6827 },
      { 属性: 属性类型.元气, 值: 1324 },
      { 属性: 属性类型.内功基础攻击, 值: 6152 },
      { 属性: 属性类型.内功会心等级, 值: 6643 },
      { 属性: 属性类型.破招值, 值: 7086 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38182,
    uid: 217108,
    装备名称: '陶然意·和时卷',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 15700,
    武器伤害_最小值: 2435,
    武器伤害_最大值: 4058,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6784 },
      { 属性: 属性类型.根骨, 值: 1233 },
      { 属性: 属性类型.内功基础攻击, 值: 5522 },
      { 属性: 属性类型.内功破防等级, 值: 6601 },
      { 属性: 属性类型.全能等级, 值: 2934 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38181,
    uid: 217107,
    装备名称: '陶然意·星雪灯',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 15700,
    武器伤害_最小值: 2435,
    武器伤害_最大值: 4058,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6784 },
      { 属性: 属性类型.元气, 值: 1233 },
      { 属性: 属性类型.内功基础攻击, 值: 5522 },
      { 属性: 属性类型.内功破防等级, 值: 6601 },
      { 属性: 属性类型.全能等级, 值: 2934 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38164,
    uid: 217090,
    装备名称: '陶然意·兰谷笔',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 15700,
    武器伤害_最小值: 1217,
    武器伤害_最大值: 2029,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6784 },
      { 属性: 属性类型.元气, 值: 1233 },
      { 属性: 属性类型.内功基础攻击, 值: 5522 },
      { 属性: 属性类型.内功破防等级, 值: 6601 },
      { 属性: 属性类型.全能等级, 值: 2934 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 37337,
    uid: 215410,
    装备名称: '吹落苍耳',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 15600,
    武器伤害_最小值: 2419,
    武器伤害_最大值: 4032,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.根骨, 值: 1307 },
      { 属性: 属性类型.内功基础攻击, 值: 6074 },
      { 属性: 属性类型.内功破防等级, 值: 6559 },
      { 属性: 属性类型.破招值, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37336,
    uid: 215409,
    装备名称: '望长天',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 15600,
    武器伤害_最小值: 2419,
    武器伤害_最大值: 4032,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.元气, 值: 1307 },
      { 属性: 属性类型.内功基础攻击, 值: 6074 },
      { 属性: 属性类型.内功破防等级, 值: 6559 },
      { 属性: 属性类型.破招值, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37312,
    uid: 215385,
    装备名称: '与风遥',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 15600,
    武器伤害_最小值: 1210,
    武器伤害_最大值: 2016,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.元气, 值: 1307 },
      { 属性: 属性类型.内功基础攻击, 值: 6074 },
      { 属性: 属性类型.内功破防等级, 值: 6559 },
      { 属性: 属性类型.破招值, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37249,
    uid: 215058,
    装备名称: '好春光',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 15600,
    武器伤害_最小值: 2419,
    武器伤害_最大值: 4032,
    装备特效: 装备特效枚举.内功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.根骨, 值: 1307 },
      { 属性: 属性类型.内功基础攻击, 值: 6074 },
      { 属性: 属性类型.内功破防等级, 值: 6559 },
      { 属性: 属性类型.无双等级, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37248,
    uid: 215057,
    装备名称: '天地不仁',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 15600,
    武器伤害_最小值: 2419,
    武器伤害_最大值: 4032,
    装备特效: 装备特效枚举.内功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.元气, 值: 1307 },
      { 属性: 属性类型.内功基础攻击, 值: 6074 },
      { 属性: 属性类型.内功破防等级, 值: 6559 },
      { 属性: 属性类型.无双等级, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37224,
    uid: 215033,
    装备名称: '曰皆可',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 15600,
    武器伤害_最小值: 1210,
    武器伤害_最大值: 2016,
    装备特效: 装备特效枚举.内功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.元气, 值: 1307 },
      { 属性: 属性类型.内功基础攻击, 值: 6074 },
      { 属性: 属性类型.内功破防等级, 值: 6559 },
      { 属性: 属性类型.无双等级, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37218,
    uid: 215021,
    装备名称: '何疏卷',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 15600,
    武器伤害_最小值: 2419,
    武器伤害_最大值: 4032,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.根骨, 值: 1307 },
      { 属性: 属性类型.内功基础攻击, 值: 6074 },
      { 属性: 属性类型.内功会心等级, 值: 6559 },
      { 属性: 属性类型.无双等级, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 37217,
    uid: 215020,
    装备名称: '宙合灯',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 15600,
    武器伤害_最小值: 2419,
    武器伤害_最大值: 4032,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.元气, 值: 1307 },
      { 属性: 属性类型.内功基础攻击, 值: 6074 },
      { 属性: 属性类型.内功会心等级, 值: 6559 },
      { 属性: 属性类型.无双等级, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 37193,
    uid: 214996,
    装备名称: '云儿笔',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 15600,
    武器伤害_最小值: 1210,
    武器伤害_最大值: 2016,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6741 },
      { 属性: 属性类型.元气, 值: 1307 },
      { 属性: 属性类型.内功基础攻击, 值: 6074 },
      { 属性: 属性类型.内功会心等级, 值: 6559 },
      { 属性: 属性类型.无双等级, 值: 6996 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 35817,
    uid: 212152,
    装备名称: '晴翠',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 14150,
    武器伤害_最小值: 2194,
    武器伤害_最大值: 3657,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6114 },
      { 属性: 属性类型.根骨, 值: 1186 },
      { 属性: 属性类型.内功基础攻击, 值: 5510 },
      { 属性: 属性类型.内功会心等级, 值: 5949 },
      { 属性: 属性类型.破招值, 值: 6346 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 35816,
    uid: 212151,
    装备名称: '槎舟渡云',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 14150,
    武器伤害_最小值: 2194,
    武器伤害_最大值: 3657,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6114 },
      { 属性: 属性类型.元气, 值: 1186 },
      { 属性: 属性类型.内功基础攻击, 值: 5510 },
      { 属性: 属性类型.内功会心等级, 值: 5949 },
      { 属性: 属性类型.破招值, 值: 6346 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 35792,
    uid: 212127,
    装备名称: '知晚',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 14150,
    武器伤害_最小值: 1097,
    武器伤害_最大值: 1828,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6114 },
      { 属性: 属性类型.元气, 值: 1186 },
      { 属性: 属性类型.内功基础攻击, 值: 5510 },
      { 属性: 属性类型.内功会心等级, 值: 5949 },
      { 属性: 属性类型.破招值, 值: 6346 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38139,
    uid: 217108,
    装备名称: '陶然意·和时卷',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 14050,
    武器伤害_最小值: 2179,
    武器伤害_最大值: 3631,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6071 },
      { 属性: 属性类型.根骨, 值: 1104 },
      { 属性: 属性类型.内功基础攻击, 值: 4942 },
      { 属性: 属性类型.内功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2625 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38138,
    uid: 217107,
    装备名称: '陶然意·星雪灯',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 14050,
    武器伤害_最小值: 2179,
    武器伤害_最大值: 3631,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6071 },
      { 属性: 属性类型.元气, 值: 1104 },
      { 属性: 属性类型.内功基础攻击, 值: 4942 },
      { 属性: 属性类型.内功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2625 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38121,
    uid: 217090,
    装备名称: '陶然意·兰谷笔',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 14050,
    武器伤害_最小值: 1089,
    武器伤害_最大值: 1815,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6071 },
      { 属性: 属性类型.元气, 值: 1104 },
      { 属性: 属性类型.内功基础攻击, 值: 4942 },
      { 属性: 属性类型.内功破防等级, 值: 5907 },
      { 属性: 属性类型.全能等级, 值: 2625 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 37438,
    uid: 215607,
    装备名称: '东方日出·征帆',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 13950,
    武器伤害_最小值: 2163,
    武器伤害_最大值: 3605,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.根骨, 值: 1169 },
      { 属性: 属性类型.内功基础攻击, 值: 5432 },
      { 属性: 属性类型.内功破防等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37437,
    uid: 215606,
    装备名称: '东方日出·万事尽',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 13950,
    武器伤害_最小值: 2163,
    武器伤害_最大值: 3605,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.元气, 值: 1169 },
      { 属性: 属性类型.内功基础攻击, 值: 5432 },
      { 属性: 属性类型.内功破防等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37413,
    uid: 215582,
    装备名称: '东方日出·三生梦',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 13950,
    武器伤害_最小值: 1082,
    武器伤害_最大值: 1803,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.元气, 值: 1169 },
      { 属性: 属性类型.内功基础攻击, 值: 5432 },
      { 属性: 属性类型.内功破防等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37175,
    uid: 214720,
    装备名称: '危明卷',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 13950,
    武器伤害_最小值: 2163,
    武器伤害_最大值: 3605,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.根骨, 值: 1169 },
      { 属性: 属性类型.内功基础攻击, 值: 5432 },
      { 属性: 属性类型.内功会心等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 37174,
    uid: 214719,
    装备名称: '危舞灯',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 13950,
    武器伤害_最小值: 2163,
    武器伤害_最大值: 3605,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.元气, 值: 1169 },
      { 属性: 属性类型.内功基础攻击, 值: 5432 },
      { 属性: 属性类型.内功会心等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 37150,
    uid: 214695,
    装备名称: '危枝笔',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 13950,
    武器伤害_最小值: 1082,
    武器伤害_最大值: 1803,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.元气, 值: 1169 },
      { 属性: 属性类型.内功基础攻击, 值: 5432 },
      { 属性: 属性类型.内功会心等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 35868,
    uid: 212347,
    装备名称: '碧云幽处',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 13950,
    武器伤害_最小值: 2163,
    武器伤害_最大值: 3605,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.根骨, 值: 1169 },
      { 属性: 属性类型.内功基础攻击, 值: 5432 },
      { 属性: 属性类型.内功破防等级, 值: 5865 },
      { 属性: 属性类型.破招值, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 35867,
    uid: 212346,
    装备名称: '光摇星斗',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 13950,
    武器伤害_最小值: 2163,
    武器伤害_最大值: 3605,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.元气, 值: 1169 },
      { 属性: 属性类型.内功基础攻击, 值: 5432 },
      { 属性: 属性类型.内功破防等级, 值: 5865 },
      { 属性: 属性类型.破招值, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 35843,
    uid: 212322,
    装备名称: '荡波行',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 13950,
    武器伤害_最小值: 1082,
    武器伤害_最大值: 1803,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.元气, 值: 1169 },
      { 属性: 属性类型.内功基础攻击, 值: 5432 },
      { 属性: 属性类型.内功破防等级, 值: 5865 },
      { 属性: 属性类型.破招值, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 35778,
    uid: 211981,
    装备名称: '跃灵春',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 13950,
    武器伤害_最小值: 2163,
    武器伤害_最大值: 3605,
    装备特效: 装备特效枚举.内功_水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.根骨, 值: 1169 },
      { 属性: 属性类型.内功基础攻击, 值: 5432 },
      { 属性: 属性类型.内功破防等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 35777,
    uid: 211980,
    装备名称: '观玉楼',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 13950,
    武器伤害_最小值: 2163,
    武器伤害_最大值: 3605,
    装备特效: 装备特效枚举.内功_水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.元气, 值: 1169 },
      { 属性: 属性类型.内功基础攻击, 值: 5432 },
      { 属性: 属性类型.内功破防等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 35753,
    uid: 211956,
    装备名称: '览墨书',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 13950,
    武器伤害_最小值: 1082,
    武器伤害_最大值: 1803,
    装备特效: 装备特效枚举.内功_水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.元气, 值: 1169 },
      { 属性: 属性类型.内功基础攻击, 值: 5432 },
      { 属性: 属性类型.内功破防等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 35747,
    uid: 211944,
    装备名称: '桃风卷',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 13950,
    武器伤害_最小值: 2163,
    武器伤害_最大值: 3605,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.根骨, 值: 1169 },
      { 属性: 属性类型.内功基础攻击, 值: 5432 },
      { 属性: 属性类型.内功会心等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 35746,
    uid: 211943,
    装备名称: '星旋灯',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 13950,
    武器伤害_最小值: 2163,
    武器伤害_最大值: 3605,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.元气, 值: 1169 },
      { 属性: 属性类型.内功基础攻击, 值: 5432 },
      { 属性: 属性类型.内功会心等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 35722,
    uid: 211919,
    装备名称: '山寂笔',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 13950,
    武器伤害_最小值: 1082,
    武器伤害_最大值: 1803,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6028 },
      { 属性: 属性类型.元气, 值: 1169 },
      { 属性: 属性类型.内功基础攻击, 值: 5432 },
      { 属性: 属性类型.内功会心等级, 值: 5865 },
      { 属性: 属性类型.无双等级, 值: 6256 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 37138,
    uid: 214641,
    装备名称: '寻踪觅宝·芳菲卷',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 13750,
    武器伤害_最小值: 2132,
    武器伤害_最大值: 3553,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5942 },
      { 属性: 属性类型.根骨, 值: 1152 },
      { 属性: 属性类型.内功基础攻击, 值: 5354 },
      { 属性: 属性类型.内功会心等级, 值: 5781 },
      { 属性: 属性类型.破招值, 值: 6166 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37137,
    uid: 214640,
    装备名称: '寻踪觅宝·琉璃灯',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 13750,
    武器伤害_最小值: 2132,
    武器伤害_最大值: 3553,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5942 },
      { 属性: 属性类型.元气, 值: 1152 },
      { 属性: 属性类型.内功基础攻击, 值: 5354 },
      { 属性: 属性类型.内功会心等级, 值: 5781 },
      { 属性: 属性类型.破招值, 值: 6166 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37113,
    uid: 214616,
    装备名称: '寻踪觅宝·枫岩笔',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 13750,
    武器伤害_最小值: 1066,
    武器伤害_最大值: 1777,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5942 },
      { 属性: 属性类型.元气, 值: 1152 },
      { 属性: 属性类型.内功基础攻击, 值: 5354 },
      { 属性: 属性类型.内功会心等级, 值: 5781 },
      { 属性: 属性类型.破招值, 值: 6166 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 36908,
    uid: 214431,
    装备名称: '传薪百草卷·尊道',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 13200,
    武器伤害_最小值: 2047,
    武器伤害_最大值: 3412,
    装备特效: 装备特效枚举.龙门飞剑武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5704 },
      { 属性: 属性类型.根骨, 值: 1106 },
      { 属性: 属性类型.内功基础攻击, 值: 5140 },
      { 属性: 属性类型.内功破防等级, 值: 5550 },
      { 属性: 属性类型.破招值, 值: 5920 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 36907,
    uid: 214430,
    装备名称: '三试夺魂灯',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 13200,
    武器伤害_最小值: 2047,
    武器伤害_最大值: 3412,
    装备特效: 装备特效枚举.龙门飞剑武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5704 },
      { 属性: 属性类型.元气, 值: 1106 },
      { 属性: 属性类型.内功基础攻击, 值: 5140 },
      { 属性: 属性类型.内功破防等级, 值: 5550 },
      { 属性: 属性类型.破招值, 值: 5920 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 36883,
    uid: 214406,
    装备名称: '哭佛点睛笔·山水',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 13200,
    武器伤害_最小值: 1023,
    武器伤害_最大值: 1705,
    装备特效: 装备特效枚举.龙门飞剑武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5704 },
      { 属性: 属性类型.元气, 值: 1106 },
      { 属性: 属性类型.内功基础攻击, 值: 5140 },
      { 属性: 属性类型.内功破防等级, 值: 5550 },
      { 属性: 属性类型.破招值, 值: 5920 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 34801,
    uid: 208218,
    装备名称: '自清',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12600,
    武器伤害_最小值: 1954,
    武器伤害_最大值: 3257,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5445 },
      { 属性: 属性类型.根骨, 值: 1056 },
      { 属性: 属性类型.内功基础攻击, 值: 4906 },
      { 属性: 属性类型.内功会心等级, 值: 5297 },
      { 属性: 属性类型.破招值, 值: 5651 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 34800,
    uid: 208217,
    装备名称: '润生光',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12600,
    武器伤害_最小值: 1954,
    武器伤害_最大值: 3257,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5445 },
      { 属性: 属性类型.元气, 值: 1056 },
      { 属性: 属性类型.内功基础攻击, 值: 4906 },
      { 属性: 属性类型.内功会心等级, 值: 5297 },
      { 属性: 属性类型.破招值, 值: 5651 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 34776,
    uid: 208193,
    装备名称: '愁绪难尽',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12600,
    武器伤害_最小值: 977,
    武器伤害_最大值: 1628,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5445 },
      { 属性: 属性类型.元气, 值: 1056 },
      { 属性: 属性类型.内功基础攻击, 值: 4906 },
      { 属性: 属性类型.内功会心等级, 值: 5297 },
      { 属性: 属性类型.破招值, 值: 5651 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 35969,
    uid: 212544,
    装备名称: '西风北啸·翠团',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12450,
    武器伤害_最小值: 1931,
    武器伤害_最大值: 3218,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.根骨, 值: 1043 },
      { 属性: 属性类型.内功基础攻击, 值: 4848 },
      { 属性: 属性类型.内功破防等级, 值: 5234 },
      { 属性: 属性类型.无双等级, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 35968,
    uid: 212543,
    装备名称: '西风北啸·幻尘',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12450,
    武器伤害_最小值: 1931,
    武器伤害_最大值: 3218,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.元气, 值: 1043 },
      { 属性: 属性类型.内功基础攻击, 值: 4848 },
      { 属性: 属性类型.内功破防等级, 值: 5234 },
      { 属性: 属性类型.无双等级, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 35944,
    uid: 212519,
    装备名称: '西风北啸·倦意',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12450,
    武器伤害_最小值: 965,
    武器伤害_最大值: 1609,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.元气, 值: 1043 },
      { 属性: 属性类型.内功基础攻击, 值: 4848 },
      { 属性: 属性类型.内功破防等级, 值: 5234 },
      { 属性: 属性类型.无双等级, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 34849,
    uid: 208416,
    装备名称: '百味千秋',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12450,
    武器伤害_最小值: 1931,
    武器伤害_最大值: 3218,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.根骨, 值: 1043 },
      { 属性: 属性类型.内功基础攻击, 值: 4848 },
      { 属性: 属性类型.内功破防等级, 值: 5234 },
      { 属性: 属性类型.破招值, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 34848,
    uid: 208415,
    装备名称: '长久',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12450,
    武器伤害_最小值: 1931,
    武器伤害_最大值: 3218,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.元气, 值: 1043 },
      { 属性: 属性类型.内功基础攻击, 值: 4848 },
      { 属性: 属性类型.内功破防等级, 值: 5234 },
      { 属性: 属性类型.破招值, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 34824,
    uid: 208391,
    装备名称: '如龙',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12450,
    武器伤害_最小值: 965,
    武器伤害_最大值: 1609,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.元气, 值: 1043 },
      { 属性: 属性类型.内功基础攻击, 值: 4848 },
      { 属性: 属性类型.内功破防等级, 值: 5234 },
      { 属性: 属性类型.破招值, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 34763,
    uid: 208064,
    装备名称: '浮玉池',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12450,
    武器伤害_最小值: 1931,
    武器伤害_最大值: 3218,
    装备特效: 装备特效枚举.内功_水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.根骨, 值: 1043 },
      { 属性: 属性类型.内功基础攻击, 值: 4848 },
      { 属性: 属性类型.内功破防等级, 值: 5234 },
      { 属性: 属性类型.无双等级, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 34762,
    uid: 208063,
    装备名称: '万川星',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12450,
    武器伤害_最小值: 1931,
    武器伤害_最大值: 3218,
    装备特效: 装备特效枚举.内功_水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.元气, 值: 1043 },
      { 属性: 属性类型.内功基础攻击, 值: 4848 },
      { 属性: 属性类型.内功破防等级, 值: 5234 },
      { 属性: 属性类型.无双等级, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 34738,
    uid: 208039,
    装备名称: '东风债',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12450,
    武器伤害_最小值: 965,
    武器伤害_最大值: 1609,
    装备特效: 装备特效枚举.内功_水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.元气, 值: 1043 },
      { 属性: 属性类型.内功基础攻击, 值: 4848 },
      { 属性: 属性类型.内功破防等级, 值: 5234 },
      { 属性: 属性类型.无双等级, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 34733,
    uid: 208028,
    装备名称: '流碧落',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12450,
    武器伤害_最小值: 1931,
    武器伤害_最大值: 3218,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.根骨, 值: 1043 },
      { 属性: 属性类型.内功基础攻击, 值: 4848 },
      { 属性: 属性类型.内功会心等级, 值: 5234 },
      { 属性: 属性类型.无双等级, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 34732,
    uid: 208027,
    装备名称: '列宿明',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12450,
    武器伤害_最小值: 1931,
    武器伤害_最大值: 3218,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5380 },
      { 属性: 属性类型.元气, 值: 1043 },
      { 属性: 属性类型.内功基础攻击, 值: 4848 },
      { 属性: 属性类型.内功会心等级, 值: 5234 },
      { 属性: 属性类型.无双等级, 值: 5583 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38382,
    uid: 217894,
    装备名称: '岫青',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.根骨, 值: 966 },
      { 属性: 属性类型.内功基础攻击, 值: 4326 },
      { 属性: 属性类型.加速等级, 值: 5171 },
      { 属性: 属性类型.全能等级, 值: 2298 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38381,
    uid: 217893,
    装备名称: '天路行征',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 966 },
      { 属性: 属性类型.内功基础攻击, 值: 4326 },
      { 属性: 属性类型.加速等级, 值: 5171 },
      { 属性: 属性类型.全能等级, 值: 2298 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38357,
    uid: 217869,
    装备名称: '河星稀',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 954,
    武器伤害_最大值: 1590,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 966 },
      { 属性: 属性类型.内功基础攻击, 值: 4326 },
      { 属性: 属性类型.加速等级, 值: 5171 },
      { 属性: 属性类型.全能等级, 值: 2298 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38345,
    uid: 217803,
    装备名称: '芜菁花下',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.根骨, 值: 966 },
      { 属性: 属性类型.内功基础攻击, 值: 4326 },
      { 属性: 属性类型.内功破防等级, 值: 5171 },
      { 属性: 属性类型.全能等级, 值: 2298 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38344,
    uid: 217802,
    装备名称: '经纬四极',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 966 },
      { 属性: 属性类型.内功基础攻击, 值: 4326 },
      { 属性: 属性类型.内功破防等级, 值: 5171 },
      { 属性: 属性类型.全能等级, 值: 2298 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38320,
    uid: 217778,
    装备名称: '见月惊',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 954,
    武器伤害_最大值: 1590,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 966 },
      { 属性: 属性类型.内功基础攻击, 值: 4326 },
      { 属性: 属性类型.内功破防等级, 值: 5171 },
      { 属性: 属性类型.全能等级, 值: 2298 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 36856,
    uid: 214287,
    装备名称: '迎新春饼·火候',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.根骨, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 36855,
    uid: 214286,
    装备名称: '寻龙鸳鸯镬·火候',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 36831,
    uid: 214262,
    装备名称: '翡翠玲珑筷·火候',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 954,
    武器伤害_最大值: 1590,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 35704,
    uid: 211643,
    装备名称: '寻踪觅宝·寻丹卷',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.根骨, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 35703,
    uid: 211642,
    装备名称: '寻踪觅宝·枯兰灯',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 35679,
    uid: 211618,
    装备名称: '寻踪觅宝·沅湘笔',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 954,
    武器伤害_最大值: 1590,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 32691,
    uid: 189404,
    装备名称: '冰漱卷',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.根骨, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功破防等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 32690,
    uid: 189403,
    装备名称: '虞棠灯',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功破防等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 32666,
    uid: 189379,
    装备名称: '逐波笔',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 954,
    武器伤害_最大值: 1590,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功破防等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 32655,
    uid: 189314,
    装备名称: '淳语卷',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.根骨, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 32654,
    uid: 189313,
    装备名称: '镜灵灯',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 32630,
    uid: 189289,
    装备名称: '诺枫笔',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 954,
    武器伤害_最大值: 1590,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 32619,
    uid: 189224,
    装备名称: '辞玥卷',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.根骨, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.加速等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
    ],
  },
  {
    id: 32618,
    uid: 189223,
    装备名称: '聆星灯',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.加速等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
    ],
  },
  {
    id: 32594,
    uid: 189199,
    装备名称: '捧露笔',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 954,
    武器伤害_最大值: 1590,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.加速等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
    ],
  },
  {
    id: 32583,
    uid: 189134,
    装备名称: '引湖卷',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.根骨, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 32582,
    uid: 189133,
    装备名称: '星浮灯',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 32558,
    uid: 189109,
    装备名称: '棠月笔',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 954,
    武器伤害_最大值: 1590,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 32523,
    uid: 188852,
    装备名称: '枯群',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.根骨, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 32522,
    uid: 188851,
    装备名称: '灵宪',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 32498,
    uid: 188827,
    装备名称: '碧江',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 954,
    武器伤害_最大值: 1590,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功会心等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 32487,
    uid: 188762,
    装备名称: '寄心',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.根骨, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.加速等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
    ],
  },
  {
    id: 32486,
    uid: 188761,
    装备名称: '四海声沸',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.加速等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
    ],
  },
  {
    id: 32462,
    uid: 188737,
    装备名称: '野老心',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 954,
    武器伤害_最大值: 1590,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.加速等级, 值: 5171 },
      { 属性: 属性类型.破招值, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
    ],
  },
  {
    id: 32451,
    uid: 188672,
    装备名称: '重卷空闻',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.根骨, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 32450,
    uid: 188671,
    装备名称: '淡日闲灯',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 1907,
    武器伤害_最大值: 3178,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 32426,
    uid: 188647,
    装备名称: '意非畴',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12300,
    武器伤害_最小值: 954,
    武器伤害_最大值: 1590,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5315 },
      { 属性: 属性类型.元气, 值: 1031 },
      { 属性: 属性类型.内功基础攻击, 值: 4789 },
      { 属性: 属性类型.内功破防等级, 值: 5171 },
      { 属性: 属性类型.无双等级, 值: 5516 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 32131,
    uid: 179884,
    装备名称: '银羽南徊',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 5690,
    武器伤害_最小值: 1225,
    武器伤害_最大值: 2042,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3415 },
      { 属性: 属性类型.根骨, 值: 662 },
      { 属性: 属性类型.内功基础攻击, 值: 3077 },
      { 属性: 属性类型.内功会心等级, 值: 2400 },
      { 属性: 属性类型.内功破防等级, 值: 2769 },
      { 属性: 属性类型.加速等级, 值: 1108 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 32130,
    uid: 168527,
    装备名称: '归一',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 5690,
    武器伤害_最小值: 1225,
    武器伤害_最大值: 2042,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3415 },
      { 属性: 属性类型.元气, 值: 662 },
      { 属性: 属性类型.内功基础攻击, 值: 3077 },
      { 属性: 属性类型.内功会心等级, 值: 2400 },
      { 属性: 属性类型.内功破防等级, 值: 2769 },
      { 属性: 属性类型.加速等级, 值: 1108 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
]

export default 武器装备数据
