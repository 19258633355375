import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 腰坠装备数据: 装备属性信息模型[] = [
  {
    id: 40998,
    uid: 218246,
    装备名称: '歌月坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 16000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2881 },
      { 属性: 属性类型.身法, 值: 559 },
      { 属性: 属性类型.外功基础攻击, 值: 906 },
      { 属性: 属性类型.外功破防等级, 值: 2803 },
      { 属性: 属性类型.无双等级, 值: 2491 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 40997,
    uid: 218245,
    装备名称: '齐月坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 16000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2881 },
      { 属性: 属性类型.力道, 值: 559 },
      { 属性: 属性类型.外功基础攻击, 值: 906 },
      { 属性: 属性类型.外功破防等级, 值: 2803 },
      { 属性: 属性类型.无双等级, 值: 2491 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39915,
    uid: 215163,
    装备名称: '无者坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2845 },
      { 属性: 属性类型.身法, 值: 552 },
      { 属性: 属性类型.外功基础攻击, 值: 895 },
      { 属性: 属性类型.外功会心等级, 值: 2768 },
      { 属性: 属性类型.无双等级, 值: 2460 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39914,
    uid: 215162,
    装备名称: '运上坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2845 },
      { 属性: 属性类型.力道, 值: 552 },
      { 属性: 属性类型.外功基础攻击, 值: 895 },
      { 属性: 属性类型.外功会心等级, 值: 2768 },
      { 属性: 属性类型.无双等级, 值: 2460 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40844,
    uid: 217152,
    装备名称: '陶然意·旭影坠',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2827 },
      { 属性: 属性类型.身法, 值: 514 },
      { 属性: 属性类型.外功基础攻击, 值: 684 },
      { 属性: 属性类型.外功会心等级, 值: 2750 },
      { 属性: 属性类型.全能等级, 值: 1222 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 40843,
    uid: 217151,
    装备名称: '陶然意·沉麟坠',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2827 },
      { 属性: 属性类型.力道, 值: 514 },
      { 属性: 属性类型.外功基础攻击, 值: 684 },
      { 属性: 属性类型.外功会心等级, 值: 2750 },
      { 属性: 属性类型.全能等级, 值: 1222 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 40839,
    uid: 217147,
    装备名称: '陶然意·弘阔坠',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2827 },
      { 属性: 属性类型.身法, 值: 514 },
      { 属性: 属性类型.外功基础攻击, 值: 684 },
      { 属性: 属性类型.外功会心等级, 值: 2750 },
      { 属性: 属性类型.全能等级, 值: 1222 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 39855,
    uid: 215028,
    装备名称: '天地间',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备特效: 装备特效枚举.风特效腰坠_英雄,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.身法, 值: 545 },
      { 属性: 属性类型.外功基础攻击, 值: 884 },
      { 属性: 属性类型.外功破防等级, 值: 2733 },
      { 属性: 属性类型.无双等级, 值: 2429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39854,
    uid: 215027,
    装备名称: '西窗意',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备特效: 装备特效枚举.风特效腰坠_英雄,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.力道, 值: 545 },
      { 属性: 属性类型.外功基础攻击, 值: 884 },
      { 属性: 属性类型.外功破防等级, 值: 2733 },
      { 属性: 属性类型.无双等级, 值: 2429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39843,
    uid: 214979,
    装备名称: '救困坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.身法, 值: 545 },
      { 属性: 属性类型.外功基础攻击, 值: 884 },
      { 属性: 属性类型.外功会心等级, 值: 2733 },
      { 属性: 属性类型.破招值, 值: 2429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 39842,
    uid: 214978,
    装备名称: '磊落坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.力道, 值: 545 },
      { 属性: 属性类型.外功基础攻击, 值: 884 },
      { 属性: 属性类型.外功会心等级, 值: 2733 },
      { 属性: 属性类型.破招值, 值: 2429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 39825,
    uid: 214919,
    装备名称: '照耀坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.身法, 值: 545 },
      { 属性: 属性类型.外功基础攻击, 值: 884 },
      { 属性: 属性类型.外功破防等级, 值: 2733 },
      { 属性: 属性类型.破招值, 值: 2429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 39824,
    uid: 214918,
    装备名称: '如雪坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.力道, 值: 545 },
      { 属性: 属性类型.外功基础攻击, 值: 884 },
      { 属性: 属性类型.外功破防等级, 值: 2733 },
      { 属性: 属性类型.破招值, 值: 2429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 39947,
    uid: 215334,
    装备名称: '无封腰坠',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1920 },
      { 属性: 属性类型.外功会心等级, 值: 2811 },
      { 属性: 属性类型.外功会心效果等级, 值: 1479 },
      { 属性: 属性类型.无双等级, 值: 1627 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39946,
    uid: 215333,
    装备名称: '无封腰坠',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1920 },
      { 属性: 属性类型.破招值, 值: 2959 },
      { 属性: 属性类型.外功破防等级, 值: 3107 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39945,
    uid: 215332,
    装备名称: '无封腰坠',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2252 },
      { 属性: 属性类型.无双等级, 值: 5252 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39903,
    uid: 215151,
    装备名称: '无者坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 14800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2665 },
      { 属性: 属性类型.身法, 值: 517 },
      { 属性: 属性类型.外功基础攻击, 值: 838 },
      { 属性: 属性类型.外功会心等级, 值: 2593 },
      { 属性: 属性类型.无双等级, 值: 2305 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39902,
    uid: 215150,
    装备名称: '运上坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 14800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2665 },
      { 属性: 属性类型.力道, 值: 517 },
      { 属性: 属性类型.外功基础攻击, 值: 838 },
      { 属性: 属性类型.外功会心等级, 值: 2593 },
      { 属性: 属性类型.无双等级, 值: 2305 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39935,
    uid: 215292,
    装备名称: '无封腰坠',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1813 },
      { 属性: 属性类型.外功破防等级, 值: 1955 },
      { 属性: 属性类型.外功会心等级, 值: 2235 },
      { 属性: 属性类型.无双等级, 值: 1536 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 39934,
    uid: 215291,
    装备名称: '无封腰坠',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1813 },
      { 属性: 属性类型.外功会心等级, 值: 2793 },
      { 属性: 属性类型.无双等级, 值: 2933 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 39933,
    uid: 215290,
    装备名称: '无封腰坠',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2126 },
      { 属性: 属性类型.外功破防等级, 值: 4958 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 38851,
    uid: 212086,
    装备名称: '逢杨坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 14150,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2548 },
      { 属性: 属性类型.身法, 值: 494 },
      { 属性: 属性类型.外功基础攻击, 值: 801 },
      { 属性: 属性类型.外功会心等级, 值: 2479 },
      { 属性: 属性类型.无双等级, 值: 2203 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38850,
    uid: 212085,
    装备名称: '客路坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 14150,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2548 },
      { 属性: 属性类型.力道, 值: 494 },
      { 属性: 属性类型.外功基础攻击, 值: 801 },
      { 属性: 属性类型.外功会心等级, 值: 2479 },
      { 属性: 属性类型.无双等级, 值: 2203 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40781,
    uid: 217152,
    装备名称: '陶然意·旭影坠',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2530 },
      { 属性: 属性类型.身法, 值: 460 },
      { 属性: 属性类型.外功基础攻击, 值: 612 },
      { 属性: 属性类型.外功会心等级, 值: 2461 },
      { 属性: 属性类型.全能等级, 值: 1094 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 40780,
    uid: 217151,
    装备名称: '陶然意·沉麟坠',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2530 },
      { 属性: 属性类型.力道, 值: 460 },
      { 属性: 属性类型.外功基础攻击, 值: 612 },
      { 属性: 属性类型.外功会心等级, 值: 2461 },
      { 属性: 属性类型.全能等级, 值: 1094 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 40776,
    uid: 217147,
    装备名称: '陶然意·弘阔坠',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2530 },
      { 属性: 属性类型.身法, 值: 460 },
      { 属性: 属性类型.外功基础攻击, 值: 612 },
      { 属性: 属性类型.外功会心等级, 值: 2461 },
      { 属性: 属性类型.全能等级, 值: 1094 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 39813,
    uid: 214684,
    装备名称: '危光坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.身法, 值: 487 },
      { 属性: 属性类型.外功基础攻击, 值: 790 },
      { 属性: 属性类型.外功破防等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 39812,
    uid: 214683,
    装备名称: '危雨坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.力道, 值: 487 },
      { 属性: 属性类型.外功基础攻击, 值: 790 },
      { 属性: 属性类型.外功破防等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 38791,
    uid: 211951,
    装备名称: '变星霜',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备特效: 装备特效枚举.风特效腰坠,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.身法, 值: 487 },
      { 属性: 属性类型.外功基础攻击, 值: 790 },
      { 属性: 属性类型.外功破防等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 38790,
    uid: 211950,
    装备名称: '枕槐安',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备特效: 装备特效枚举.风特效腰坠,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.力道, 值: 487 },
      { 属性: 属性类型.外功基础攻击, 值: 790 },
      { 属性: 属性类型.外功破防等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 38779,
    uid: 211902,
    装备名称: '踏雁坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.身法, 值: 487 },
      { 属性: 属性类型.外功基础攻击, 值: 790 },
      { 属性: 属性类型.外功会心等级, 值: 2444 },
      { 属性: 属性类型.破招值, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 38778,
    uid: 211901,
    装备名称: '素鸦坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.力道, 值: 487 },
      { 属性: 属性类型.外功基础攻击, 值: 790 },
      { 属性: 属性类型.外功会心等级, 值: 2444 },
      { 属性: 属性类型.破招值, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 38761,
    uid: 211842,
    装备名称: '风掣坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.身法, 值: 487 },
      { 属性: 属性类型.外功基础攻击, 值: 790 },
      { 属性: 属性类型.外功破防等级, 值: 2444 },
      { 属性: 属性类型.破招值, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 38760,
    uid: 211841,
    装备名称: '凛行坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.力道, 值: 487 },
      { 属性: 属性类型.外功基础攻击, 值: 790 },
      { 属性: 属性类型.外功破防等级, 值: 2444 },
      { 属性: 属性类型.破招值, 值: 2172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 39891,
    uid: 215139,
    装备名称: '无者坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2485 },
      { 属性: 属性类型.身法, 值: 482 },
      { 属性: 属性类型.外功基础攻击, 值: 782 },
      { 属性: 属性类型.外功会心等级, 值: 2417 },
      { 属性: 属性类型.无双等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39890,
    uid: 215138,
    装备名称: '运上坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2485 },
      { 属性: 属性类型.力道, 值: 482 },
      { 属性: 属性类型.外功基础攻击, 值: 782 },
      { 属性: 属性类型.外功会心等级, 值: 2417 },
      { 属性: 属性类型.无双等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38887,
    uid: 212271,
    装备名称: '无封腰坠',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1712 },
      { 属性: 属性类型.外功破防等级, 值: 1912 },
      { 属性: 属性类型.外功会心等级, 值: 2044 },
      { 属性: 属性类型.外功会心效果等级, 值: 1319 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 38886,
    uid: 212270,
    装备名称: '无封腰坠',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1712 },
      { 属性: 属性类型.外功会心等级, 值: 2703 },
      { 属性: 属性类型.外功破防等级, 值: 2703 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 38885,
    uid: 212269,
    装备名称: '无封腰坠',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2007 },
      { 属性: 属性类型.外功会心等级, 值: 4681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 38839,
    uid: 212074,
    装备名称: '逢杨坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2395 },
      { 属性: 属性类型.身法, 值: 464 },
      { 属性: 属性类型.外功基础攻击, 值: 753 },
      { 属性: 属性类型.外功会心等级, 值: 2330 },
      { 属性: 属性类型.无双等级, 值: 2071 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38838,
    uid: 212073,
    装备名称: '客路坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2395 },
      { 属性: 属性类型.力道, 值: 464 },
      { 属性: 属性类型.外功基础攻击, 值: 753 },
      { 属性: 属性类型.外功会心等级, 值: 2330 },
      { 属性: 属性类型.无双等级, 值: 2071 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39879,
    uid: 215127,
    装备名称: '无者坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2305 },
      { 属性: 属性类型.身法, 值: 447 },
      { 属性: 属性类型.外功基础攻击, 值: 725 },
      { 属性: 属性类型.外功会心等级, 值: 2242 },
      { 属性: 属性类型.无双等级, 值: 1993 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39878,
    uid: 215126,
    装备名称: '运上坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2305 },
      { 属性: 属性类型.力道, 值: 447 },
      { 属性: 属性类型.外功基础攻击, 值: 725 },
      { 属性: 属性类型.外功会心等级, 值: 2242 },
      { 属性: 属性类型.无双等级, 值: 1993 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38873,
    uid: 212225,
    装备名称: '无封腰坠',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1617 },
      { 属性: 属性类型.外功会心等级, 值: 2367 },
      { 属性: 属性类型.外功会心效果等级, 值: 1246 },
      { 属性: 属性类型.无双等级, 值: 1370 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 38872,
    uid: 212224,
    装备名称: '无封腰坠',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1617 },
      { 属性: 属性类型.破招值, 值: 2491 },
      { 属性: 属性类型.外功破防等级, 值: 2616 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38871,
    uid: 212223,
    装备名称: '无封腰坠',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1896 },
      { 属性: 属性类型.无双等级, 值: 4422 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 37776,
    uid: 208152,
    装备名称: '欺林坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2269 },
      { 属性: 属性类型.身法, 值: 440 },
      { 属性: 属性类型.外功基础攻击, 值: 714 },
      { 属性: 属性类型.外功会心等级, 值: 2207 },
      { 属性: 属性类型.无双等级, 值: 1962 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 37775,
    uid: 208151,
    装备名称: '定酣坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2269 },
      { 属性: 属性类型.力道, 值: 440 },
      { 属性: 属性类型.外功基础攻击, 值: 714 },
      { 属性: 属性类型.外功会心等级, 值: 2207 },
      { 属性: 属性类型.无双等级, 值: 1962 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39689,
    uid: 213711,
    装备名称: '灵空·风行坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.身法, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功会心等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39688,
    uid: 213710,
    装备名称: '灵空·撼地坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.力道, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功会心等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38827,
    uid: 212062,
    装备名称: '逢杨坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.身法, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功会心等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38826,
    uid: 212061,
    装备名称: '客路坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.力道, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功会心等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 37818,
    uid: 208374,
    装备名称: '湖月坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.身法, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功会心等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 37817,
    uid: 208373,
    装备名称: '湖静坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.力道, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功会心等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 37720,
    uid: 208034,
    装备名称: '雁无意',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备特效: 装备特效枚举.风特效腰坠,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.身法, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功破防等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 37719,
    uid: 208033,
    装备名称: '恸黄沙',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备特效: 装备特效枚举.风特效腰坠,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.力道, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功破防等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 37708,
    uid: 207986,
    装备名称: '染辞坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.身法, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功会心等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 37707,
    uid: 207985,
    装备名称: '温刃坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.力道, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功会心等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 37690,
    uid: 207926,
    装备名称: '商野坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.身法, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功破防等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 37689,
    uid: 207925,
    装备名称: '安衿坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.力道, 值: 435 },
      { 属性: 属性类型.外功基础攻击, 值: 705 },
      { 属性: 属性类型.外功破防等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 34196,
    uid: 189032,
    装备名称: '临苑坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2233 },
      { 属性: 属性类型.身法, 值: 433 },
      { 属性: 属性类型.外功基础攻击, 值: 702 },
      { 属性: 属性类型.外功会心等级, 值: 2172 },
      { 属性: 属性类型.破招值, 值: 1931 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 34195,
    uid: 189031,
    装备名称: '临梧坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2233 },
      { 属性: 属性类型.力道, 值: 433 },
      { 属性: 属性类型.外功基础攻击, 值: 702 },
      { 属性: 属性类型.外功会心等级, 值: 2172 },
      { 属性: 属性类型.破招值, 值: 1931 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 40920,
    uid: 217852,
    装备名称: '塞尘佩',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 403 },
      { 属性: 属性类型.外功基础攻击, 值: 536 },
      { 属性: 属性类型.加速等级, 值: 2155 },
      { 属性: 属性类型.全能等级, 值: 958 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 40919,
    uid: 217851,
    装备名称: '峭月佩',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 403 },
      { 属性: 属性类型.外功基础攻击, 值: 536 },
      { 属性: 属性类型.加速等级, 值: 2155 },
      { 属性: 属性类型.全能等级, 值: 958 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 40902,
    uid: 217761,
    装备名称: '奔枝佩',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 403 },
      { 属性: 属性类型.外功基础攻击, 值: 536 },
      { 属性: 属性类型.外功会心等级, 值: 2155 },
      { 属性: 属性类型.全能等级, 值: 958 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 40901,
    uid: 217760,
    装备名称: '环旌佩',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 403 },
      { 属性: 属性类型.外功基础攻击, 值: 536 },
      { 属性: 属性类型.外功会心等级, 值: 2155 },
      { 属性: 属性类型.全能等级, 值: 958 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 34268,
    uid: 189362,
    装备名称: '久念坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功会心等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 34267,
    uid: 189361,
    装备名称: '拭江坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功会心等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 34250,
    uid: 189272,
    装备名称: '风岱坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功破防等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 34249,
    uid: 189271,
    装备名称: '项昌坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功破防等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 34232,
    uid: 189182,
    装备名称: '北邱坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功会心等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 34231,
    uid: 189181,
    装备名称: '曲郦坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功会心等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 34214,
    uid: 189092,
    装备名称: '渊忱坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.加速等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 34213,
    uid: 189091,
    装备名称: '羡双坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.加速等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 34124,
    uid: 188810,
    装备名称: '忆宁坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功会心等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 34123,
    uid: 188809,
    装备名称: '忆敬坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.外功会心等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 34106,
    uid: 188720,
    装备名称: '盈绝佩',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.加速等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 34105,
    uid: 188719,
    装备名称: '垣翰佩',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.加速等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 34088,
    uid: 188630,
    装备名称: '潋阳佩',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.身法, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.破招值, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 34087,
    uid: 188629,
    装备名称: '重关佩',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.力道, 值: 429 },
      { 属性: 属性类型.外功基础攻击, 值: 697 },
      { 属性: 属性类型.破招值, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 37808,
    uid: 208322,
    装备名称: '无封腰坠',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1529 },
      { 属性: 属性类型.外功破防等级, 值: 1649 },
      { 属性: 属性类型.外功会心等级, 值: 1884 },
      { 属性: 属性类型.无双等级, 值: 1295 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 37807,
    uid: 208321,
    装备名称: '无封腰坠',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1529 },
      { 属性: 属性类型.外功会心等级, 值: 2355 },
      { 属性: 属性类型.无双等级, 值: 2473 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 37806,
    uid: 208320,
    装备名称: '无封腰坠',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1792 },
      { 属性: 属性类型.外功破防等级, 值: 4181 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
]

export default 腰坠装备数据
