// 参考魔盒计算装分js

import { 装备类型枚举, 选择装备数据类型 } from '@/@types/装备'
import { 装备品质类型 } from '../type'
import { 装备品质系数, 装备部位系数 } from './系数'
import 获取当前数据 from '@/数据/数据工具/获取当前数据'
import { 获取附魔分数 } from './附魔分数'
import { 获取镶嵌列表分数 } from './五行石分数'

const { 装备数据 } = 获取当前数据()

/**
 * 装分和品质成长
 * @param {*} 装分 原装备品质或原装备分数
 * @param {*} 精炼等级 精炼强度1-8
 * @returns {number}
 */
export function 获取装备精炼装分(装分, 精炼等级) {
  const 精炼提升 = ((精炼等级 * (0.7 + 0.3 * 精炼等级)) / 200).toFixed(6)
  return Math.round(装分 * Number(精炼提升))
}

/**
 * 装备原始分数
 * 装备分数 = 装等 * 品质系数 * 部位系数
 * @param {*} 装备品级 装等
 * @param {*} 装备质量 品质（颜色） 4 紫 5 橙
 * @param {*} 装备部位 部位
 * @return {*}
 */
export function 装备原始装分(装备品级: number, 装备质量: 装备品质类型, 装备部位) {
  return Math.round(装备品级 * 装备品质系数[装备质量] * 装备部位系数[装备部位])
}

export function 装备列表装分计算(装备列表: 选择装备数据类型[]) {
  let 装备总分 = 0
  装备列表.forEach((装备) => {
    const 当前装备数据 = 装备数据[装备?.装备部位]?.find((数据) => 数据.id === 装备.id)
    if (当前装备数据) {
      const 装备质量 = 当前装备数据.装备类型 === 装备类型枚举.橙武 ? '5' : '4'
      const 装备部位映射 = 装备位置部位枚举?.[装备?.装备部位]
      const 装备原始分数 = 装备原始装分(当前装备数据.装备品级, 装备质量, 装备部位映射)
      const 精炼分数 = 获取装备精炼装分(装备原始分数, 装备?.当前精炼等级)
      const 附魔分数 = 获取附魔分数(装备?.附魔)
      const 镶嵌分数 = 获取镶嵌列表分数(装备?.镶嵌孔数组)
      装备总分 = 装备总分 + 装备原始分数 + 精炼分数 + 附魔分数 + 镶嵌分数
    } else {
      console.warn(`装备分数计算异常，装备ID${装备.id}`)
    }
  })
  return 装备总分
}

enum 装备位置部位枚举 {
  '帽子' = '3',
  '衣服' = '2',
  '腰带' = '6',
  '护腕' = '10',
  '下装' = '8',
  '鞋子' = '9',
  '项链' = '4',
  '腰坠' = '7',
  '戒指' = '5',
  '暗器' = '1',
  '武器' = '0',
}
