/**
 * @name 心法模块-无方
 * 模块写入者：唐宋
 */
import type { 心法配置类型 } from '@/心法模块/interface'

import 背景图 from './资源/背景图'
import 默认数据 from './默认数据'
import 技能系数, { 技能增益 } from './技能系数'
import 奇穴数据 from './奇穴'
import 计算循环 from './计算循环'

const 心法配置: 心法配置类型 = {
  名称: '无方',
  数据提供: '数据：考拉 长倦 唐宋；测试：长倦',
  所属门派: '药宗',
  简写: 'wf',
  主属性: '根骨',
  功法: '内功',
  基础属性: { 基础攻击: 3808, 破防等级: 1788 },
  主属性额外加成: { 面板攻击: 1843 / 1024, 破防等级: 481 / 1024 },
  基础气血加成: 235,
  奇穴数据: 奇穴数据,
  技能系数: 技能系数,
  技能增益: 技能增益,
  计算循环: 计算循环,
  默认数据: 默认数据,
  系统配置: {
    主题色: 'rgb(0, 128, 128)',
    // 主题色: '#593391',
    // 主题色: '#9f80e0',
    // 主题色: '#483679',
    心法图标: 'https://img.jx3box.com/image/xf/10627.png',
    背景图: 背景图,
    背景色渐变: `linear-gradient(to right, rgba(0,0,0,0.6) 5%, rgba(0,128,128, 0.25) 70%)`,
    // 背景色: 'rgba(168,88,157, 0.615)',
  },
}

export default 心法配置
