import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'

export const 获取快照增益 = (功法) => {
  const 水特效 = 功法 === '外功' ? 98 * 10 : 117 * 10
  const 水特效_英雄 = 功法 === '外功' ? 111 * 10 : 134 * 10

  const 快照增益: 技能增益列表类型[] = [
    {
      增益名称: '水特效_快照',
      增益所在位置: '装备',
      依赖装备增益: '水特效武器',
      快照类型: '水特效',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.全基础攻击, 值: 水特效 }],
    },
    {
      增益名称: '水特效_英雄_快照',
      增益所在位置: '装备',
      依赖装备增益: '水特效武器_英雄',
      快照类型: '水特效',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.全基础攻击, 值: 水特效_英雄 }],
    },
    {
      增益名称: '风特效_快照',
      增益所在位置: '装备',
      依赖装备增益: '风特效腰坠',
      快照类型: '风特效',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.全破防等级, 值: 9291 }],
    },
    {
      增益名称: '风特效_英雄_快照',
      增益所在位置: '装备',
      依赖装备增益: '风特效腰坠_英雄',
      快照类型: '风特效',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.全破防等级, 值: 10573 }],
    },
    {
      增益名称: '大附魔_伤腰_快照',
      增益所在位置: '装备',
      依赖装备增益: '大附魔_伤腰',
      快照类型: '大附魔_伤腰',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: 10 / 1024 }],
    },
    {
      增益名称: '大附魔_伤腰_快照·2',
      增益所在位置: '装备',
      依赖装备增益: '大附魔_伤腰',
      快照类型: '大附魔_伤腰',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: 51 / 1024 }],
    },
  ]
  return 快照增益
}

export default 获取快照增益
