import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 戒指装备数据: 装备属性信息模型[] = [
  {
    id: 40863,
    uid: 217171,
    装备名称: '陶然意·和时戒',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2827 },
      { 属性: 属性类型.根骨, 值: 514 },
      { 属性: 属性类型.内功基础攻击, 值: 821 },
      { 属性: 属性类型.内功破防等级, 值: 2750 },
      { 属性: 属性类型.全能等级, 值: 1222 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 40862,
    uid: 217170,
    装备名称: '陶然意·星雪戒',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2827 },
      { 属性: 属性类型.元气, 值: 514 },
      { 属性: 属性类型.内功基础攻击, 值: 821 },
      { 属性: 属性类型.内功破防等级, 值: 2750 },
      { 属性: 属性类型.全能等级, 值: 1222 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 40846,
    uid: 217154,
    装备名称: '陶然意·兰谷戒',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2827 },
      { 属性: 属性类型.元气, 值: 514 },
      { 属性: 属性类型.内功基础攻击, 值: 821 },
      { 属性: 属性类型.内功破防等级, 值: 2750 },
      { 属性: 属性类型.全能等级, 值: 1222 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39919,
    uid: 215252,
    装备名称: '客行江湖·磐气戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.元气, 值: 545 },
      { 属性: 属性类型.内功基础攻击, 值: 1060 },
      { 属性: 属性类型.内功破防等级, 值: 2733 },
      { 属性: 属性类型.无双等级, 值: 2429 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39918,
    uid: 215251,
    装备名称: '客行江湖·风翎戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.根骨, 值: 545 },
      { 属性: 属性类型.内功基础攻击, 值: 1060 },
      { 属性: 属性类型.内功破防等级, 值: 2733 },
      { 属性: 属性类型.无双等级, 值: 2429 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39867,
    uid: 215107,
    装备名称: '乃书戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.内功基础攻击, 值: 2284 },
      { 属性: 属性类型.破招值, 值: 3188 },
      { 属性: 属性类型.内功破防等级, 值: 2885 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39866,
    uid: 215106,
    装备名称: '广萤戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.内功基础攻击, 值: 1957 },
      { 属性: 属性类型.全会心等级, 值: 1974 },
      { 属性: 属性类型.无双等级, 值: 4858 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39864,
    uid: 215092,
    装备名称: '东倾戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2365 },
      { 属性: 属性类型.内功破防等级, 值: 3036 },
      { 属性: 属性类型.无双等级, 值: 3188 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39860,
    uid: 215088,
    装备名称: '庆本戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2365 },
      { 属性: 属性类型.破招值, 值: 1670 },
      { 属性: 属性类型.全会心等级, 值: 2885 },
      { 属性: 属性类型.全会心效果等级, 值: 1518 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39859,
    uid: 215087,
    装备名称: '耐歌戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2365 },
      { 属性: 属性类型.全会心等级, 值: 3112 },
      { 属性: 属性类型.内功破防等级, 值: 3112 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39858,
    uid: 215086,
    装备名称: '萌音戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2773 },
      { 属性: 属性类型.无双等级, 值: 5390 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39847,
    uid: 214983,
    装备名称: '良安戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.元气, 值: 545 },
      { 属性: 属性类型.内功基础攻击, 值: 1060 },
      { 属性: 属性类型.全会心等级, 值: 2733 },
      { 属性: 属性类型.无双等级, 值: 2429 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39846,
    uid: 214982,
    装备名称: '情义戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.根骨, 值: 545 },
      { 属性: 属性类型.内功基础攻击, 值: 1060 },
      { 属性: 属性类型.内功会心等级, 值: 2733 },
      { 属性: 属性类型.无双等级, 值: 2429 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39829,
    uid: 214923,
    装备名称: '宫阙指环',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.元气, 值: 545 },
      { 属性: 属性类型.内功基础攻击, 值: 1060 },
      { 属性: 属性类型.内功破防等级, 值: 2733 },
      { 属性: 属性类型.破招值, 值: 2429 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39828,
    uid: 214922,
    装备名称: '绕城指环',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2809 },
      { 属性: 属性类型.根骨, 值: 545 },
      { 属性: 属性类型.内功基础攻击, 值: 1060 },
      { 属性: 属性类型.内功破防等级, 值: 2733 },
      { 属性: 属性类型.破招值, 值: 2429 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 40800,
    uid: 217171,
    装备名称: '陶然意·和时戒',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2530 },
      { 属性: 属性类型.根骨, 值: 460 },
      { 属性: 属性类型.内功基础攻击, 值: 735 },
      { 属性: 属性类型.内功破防等级, 值: 2461 },
      { 属性: 属性类型.全能等级, 值: 1094 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 40799,
    uid: 217170,
    装备名称: '陶然意·星雪戒',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2530 },
      { 属性: 属性类型.元气, 值: 460 },
      { 属性: 属性类型.内功基础攻击, 值: 735 },
      { 属性: 属性类型.内功破防等级, 值: 2461 },
      { 属性: 属性类型.全能等级, 值: 1094 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 40783,
    uid: 217154,
    装备名称: '陶然意·兰谷戒',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2530 },
      { 属性: 属性类型.元气, 值: 460 },
      { 属性: 属性类型.内功基础攻击, 值: 735 },
      { 属性: 属性类型.内功破防等级, 值: 2461 },
      { 属性: 属性类型.全能等级, 值: 1094 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 40867,
    uid: 217521,
    装备名称: '行雾中·誓',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.元气, 值: 487 },
      { 属性: 属性类型.内功基础攻击, 值: 948 },
      { 属性: 属性类型.内功破防等级, 值: 2444 },
      { 属性: 属性类型.破招值, 值: 2172 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 40866,
    uid: 217520,
    装备名称: '行雾中·赦',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.根骨, 值: 487 },
      { 属性: 属性类型.内功基础攻击, 值: 948 },
      { 属性: 属性类型.内功破防等级, 值: 2444 },
      { 属性: 属性类型.破招值, 值: 2172 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39793,
    uid: 214443,
    装备名称: '归朔戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.元气, 值: 487 },
      { 属性: 属性类型.内功基础攻击, 值: 948 },
      { 属性: 属性类型.内功破防等级, 值: 2444 },
      { 属性: 属性类型.破招值, 值: 2172 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39792,
    uid: 214442,
    装备名称: '青乡戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.根骨, 值: 487 },
      { 属性: 属性类型.内功基础攻击, 值: 948 },
      { 属性: 属性类型.内功破防等级, 值: 2444 },
      { 属性: 属性类型.破招值, 值: 2172 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 38855,
    uid: 212175,
    装备名称: '客行江湖·断意戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.元气, 值: 487 },
      { 属性: 属性类型.内功基础攻击, 值: 948 },
      { 属性: 属性类型.内功破防等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 38854,
    uid: 212174,
    装备名称: '客行江湖·意悠戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.根骨, 值: 487 },
      { 属性: 属性类型.内功基础攻击, 值: 948 },
      { 属性: 属性类型.内功破防等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 38803,
    uid: 212030,
    装备名称: '璨云戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.内功基础攻击, 值: 2042 },
      { 属性: 属性类型.破招值, 值: 2851 },
      { 属性: 属性类型.内功破防等级, 值: 2580 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 38802,
    uid: 212029,
    装备名称: '丰冉戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.内功基础攻击, 值: 1750 },
      { 属性: 属性类型.全会心等级, 值: 1765 },
      { 属性: 属性类型.无双等级, 值: 4344 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 38800,
    uid: 212015,
    装备名称: '峻水戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2115 },
      { 属性: 属性类型.内功破防等级, 值: 2715 },
      { 属性: 属性类型.无双等级, 值: 2851 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 38796,
    uid: 212011,
    装备名称: '希延戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2115 },
      { 属性: 属性类型.破招值, 值: 1493 },
      { 属性: 属性类型.全会心等级, 值: 2580 },
      { 属性: 属性类型.全会心效果等级, 值: 1358 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 38795,
    uid: 212010,
    装备名称: '羽容戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2115 },
      { 属性: 属性类型.全会心等级, 值: 2783 },
      { 属性: 属性类型.内功破防等级, 值: 2783 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 38794,
    uid: 212009,
    装备名称: '丹莲戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 2480 },
      { 属性: 属性类型.无双等级, 值: 4820 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 38783,
    uid: 211906,
    装备名称: '壑云戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.元气, 值: 487 },
      { 属性: 属性类型.内功基础攻击, 值: 948 },
      { 属性: 属性类型.全会心等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 38782,
    uid: 211905,
    装备名称: '寒绡戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.根骨, 值: 487 },
      { 属性: 属性类型.内功基础攻击, 值: 948 },
      { 属性: 属性类型.内功会心等级, 值: 2444 },
      { 属性: 属性类型.无双等级, 值: 2172 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 38765,
    uid: 211846,
    装备名称: '开颐指环',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.元气, 值: 487 },
      { 属性: 属性类型.内功基础攻击, 值: 948 },
      { 属性: 属性类型.内功破防等级, 值: 2444 },
      { 属性: 属性类型.破招值, 值: 2172 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 38764,
    uid: 211845,
    装备名称: '扬英指环',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2512 },
      { 属性: 属性类型.根骨, 值: 487 },
      { 属性: 属性类型.内功基础攻击, 值: 948 },
      { 属性: 属性类型.内功破防等级, 值: 2444 },
      { 属性: 属性类型.破招值, 值: 2172 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39799,
    uid: 214573,
    装备名称: '暮雨环',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2413 },
      { 属性: 属性类型.元气, 值: 468 },
      { 属性: 属性类型.内功基础攻击, 值: 911 },
      { 属性: 属性类型.全会心等级, 值: 2347 },
      { 属性: 属性类型.无双等级, 值: 2087 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39798,
    uid: 214572,
    装备名称: '暮雨戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2413 },
      { 属性: 属性类型.根骨, 值: 468 },
      { 属性: 属性类型.内功基础攻击, 值: 911 },
      { 属性: 属性类型.内功会心等级, 值: 2347 },
      { 属性: 属性类型.无双等级, 值: 2087 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 37822,
    uid: 208378,
    装备名称: '湖烟戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.元气, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.全会心等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 37821,
    uid: 208377,
    装备名称: '湖寂戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.根骨, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.内功会心等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 37780,
    uid: 208240,
    装备名称: '客行江湖·千世戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.元气, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.内功破防等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 37779,
    uid: 208239,
    装备名称: '客行江湖·渐浓戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.根骨, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.内功破防等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 37729,
    uid: 208097,
    装备名称: '解怜戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.内功基础攻击, 值: 1823 },
      { 属性: 属性类型.内功破防等级, 值: 2302 },
      { 属性: 属性类型.无双等级, 值: 2545 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 37725,
    uid: 208093,
    装备名称: '帘絮戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.内功基础攻击, 值: 1562 },
      { 属性: 属性类型.全会心等级, 值: 1575 },
      { 属性: 属性类型.无双等级, 值: 3877 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 37724,
    uid: 208092,
    装备名称: '清斗戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.内功基础攻击, 值: 1823 },
      { 属性: 属性类型.破招值, 值: 2545 },
      { 属性: 属性类型.内功破防等级, 值: 2302 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 37723,
    uid: 208091,
    装备名称: '昭月戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.内功基础攻击, 值: 2213 },
      { 属性: 属性类型.无双等级, 值: 4059 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 37712,
    uid: 207990,
    装备名称: '沁渡戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.元气, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.全会心等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 37711,
    uid: 207989,
    装备名称: '朝华戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.根骨, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.内功会心等级, 值: 2181 },
      { 属性: 属性类型.无双等级, 值: 1939 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 37694,
    uid: 207930,
    装备名称: '椴微指环',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.元气, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.内功破防等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 37693,
    uid: 207929,
    装备名称: '池泓指环',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2242 },
      { 属性: 属性类型.根骨, 值: 435 },
      { 属性: 属性类型.内功基础攻击, 值: 846 },
      { 属性: 属性类型.内功破防等级, 值: 2181 },
      { 属性: 属性类型.破招值, 值: 1939 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 34200,
    uid: 189036,
    装备名称: '临曦戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2233 },
      { 属性: 属性类型.元气, 值: 433 },
      { 属性: 属性类型.内功基础攻击, 值: 843 },
      { 属性: 属性类型.全会心等级, 值: 2172 },
      { 属性: 属性类型.无双等级, 值: 1931 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 34199,
    uid: 189035,
    装备名称: '临衣戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2233 },
      { 属性: 属性类型.根骨, 值: 433 },
      { 属性: 属性类型.内功基础攻击, 值: 843 },
      { 属性: 属性类型.内功会心等级, 值: 2172 },
      { 属性: 属性类型.无双等级, 值: 1931 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 40924,
    uid: 217856,
    装备名称: '若寓戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 403 },
      { 属性: 属性类型.内功基础攻击, 值: 643 },
      { 属性: 属性类型.加速等级, 值: 2155 },
      { 属性: 属性类型.全能等级, 值: 958 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 40923,
    uid: 217855,
    装备名称: '越风戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 403 },
      { 属性: 属性类型.内功基础攻击, 值: 643 },
      { 属性: 属性类型.加速等级, 值: 2155 },
      { 属性: 属性类型.全能等级, 值: 958 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 40906,
    uid: 217765,
    装备名称: '踏江戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 403 },
      { 属性: 属性类型.内功基础攻击, 值: 643 },
      { 属性: 属性类型.内功破防等级, 值: 2155 },
      { 属性: 属性类型.全能等级, 值: 958 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 40905,
    uid: 217764,
    装备名称: '承康戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 403 },
      { 属性: 属性类型.内功基础攻击, 值: 643 },
      { 属性: 属性类型.内功破防等级, 值: 2155 },
      { 属性: 属性类型.全能等级, 值: 958 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39788,
    uid: 214377,
    装备名称: '迎新御厨戒指·火候',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12300,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.内功会心等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39787,
    uid: 214376,
    装备名称: '寻龙御厨戒指·火候',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12300,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.内功会心等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 39764,
    uid: 214353,
    装备名称: '翡翠御厨戒指·火候',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12300,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.内功会心等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 34272,
    uid: 189366,
    装备名称: '藏峦戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.内功破防等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 34271,
    uid: 189365,
    装备名称: '谨峰戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.内功破防等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 34254,
    uid: 189276,
    装备名称: '故芳戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.全会心等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 34253,
    uid: 189275,
    装备名称: '剪桐戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.内功会心等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 34236,
    uid: 189186,
    装备名称: '花霭戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.加速等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 34235,
    uid: 189185,
    装备名称: '途南戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.加速等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 34218,
    uid: 189096,
    装备名称: '庭澜戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.破招值, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 34217,
    uid: 189095,
    装备名称: '故云戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.破招值, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 34128,
    uid: 188814,
    装备名称: '忆惜戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.全会心等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 34127,
    uid: 188813,
    装备名称: '忆安戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.内功会心等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 34110,
    uid: 188724,
    装备名称: '语阔戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.加速等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 34109,
    uid: 188723,
    装备名称: '擒雨戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.加速等级, 值: 2155 },
      { 属性: 属性类型.无双等级, 值: 1915 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 34092,
    uid: 188634,
    装备名称: '烟琐戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.元气, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.内功破防等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 34091,
    uid: 188633,
    装备名称: '德襄戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2215 },
      { 属性: 属性类型.根骨, 值: 429 },
      { 属性: 属性类型.内功基础攻击, 值: 836 },
      { 属性: 属性类型.内功破防等级, 值: 2155 },
      { 属性: 属性类型.破招值, 值: 1915 },
    ],
    镶嵌孔数组: [],
  },
]

export default 戒指装备数据
