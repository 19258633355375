export default {
  "本赛季默认品级": [
    12100,
    16000
  ],
  "本赛季橙武器默认品级": [
    10000,
    16000
  ],
  "门派列表":['通用','万灵','刀宗','蓬莱','衍天','药宗','万花'],
  "赛季英雄普通区分品级": 15000
}