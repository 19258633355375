import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 帽子装备数据: 装备属性信息模型[] = [
  {
    id: 98505,
    uid: 215193,
    装备名称: '水泉冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5121 },
      { 属性: 属性类型.身法, 值: 993 },
      { 属性: 属性类型.外功基础攻击, 值: 1611 },
      { 属性: 属性类型.外功会心等级, 值: 4982 },
      { 属性: 属性类型.破招值, 值: 4429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98504,
    uid: 215192,
    装备名称: '水泽冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5121 },
      { 属性: 属性类型.力道, 值: 993 },
      { 属性: 属性类型.外功基础攻击, 值: 1611 },
      { 属性: 属性类型.外功会心等级, 值: 4982 },
      { 属性: 属性类型.破招值, 值: 4429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100118,
    uid: 217046,
    装备名称: '陶然意·旭影帽',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5088 },
      { 属性: 属性类型.身法, 值: 925 },
      { 属性: 属性类型.外功基础攻击, 值: 1231 },
      { 属性: 属性类型.外功会心等级, 值: 4951 },
      { 属性: 属性类型.全能等级, 值: 2200 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 100117,
    uid: 217045,
    装备名称: '陶然意·沉麟帽',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5088 },
      { 属性: 属性类型.力道, 值: 925 },
      { 属性: 属性类型.外功基础攻击, 值: 1231 },
      { 属性: 属性类型.外功会心等级, 值: 4951 },
      { 属性: 属性类型.全能等级, 值: 2200 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 100113,
    uid: 217041,
    装备名称: '陶然意·弘阔帽',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5088 },
      { 属性: 属性类型.身法, 值: 925 },
      { 属性: 属性类型.外功基础攻击, 值: 1231 },
      { 属性: 属性类型.外功会心等级, 值: 4951 },
      { 属性: 属性类型.全能等级, 值: 2200 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 98607,
    uid: 215368,
    装备名称: '月落冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5056 },
      { 属性: 属性类型.身法, 值: 980 },
      { 属性: 属性类型.外功基础攻击, 值: 1590 },
      { 属性: 属性类型.外功会心等级, 值: 4919 },
      { 属性: 属性类型.破招值, 值: 4373 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98606,
    uid: 215367,
    装备名称: '月稠冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5056 },
      { 属性: 属性类型.力道, 值: 980 },
      { 属性: 属性类型.外功基础攻击, 值: 1590 },
      { 属性: 属性类型.外功会心等级, 值: 4919 },
      { 属性: 属性类型.破招值, 值: 4373 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98529,
    uid: 215248,
    装备名称: '客行江湖·纵巧冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5056 },
      { 属性: 属性类型.身法, 值: 980 },
      { 属性: 属性类型.外功基础攻击, 值: 1590 },
      { 属性: 属性类型.外功会心等级, 值: 4919 },
      { 属性: 属性类型.破招值, 值: 4373 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98528,
    uid: 215247,
    装备名称: '客行江湖·之远冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5056 },
      { 属性: 属性类型.力道, 值: 980 },
      { 属性: 属性类型.外功基础攻击, 值: 1590 },
      { 属性: 属性类型.外功会心等级, 值: 4919 },
      { 属性: 属性类型.破招值, 值: 4373 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98433,
    uid: 214961,
    装备名称: '救困冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5056 },
      { 属性: 属性类型.身法, 值: 980 },
      { 属性: 属性类型.外功基础攻击, 值: 1590 },
      { 属性: 属性类型.加速等级, 值: 4919 },
      { 属性: 属性类型.破招值, 值: 4373 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 98432,
    uid: 214960,
    装备名称: '磊落冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5056 },
      { 属性: 属性类型.力道, 值: 980 },
      { 属性: 属性类型.外功基础攻击, 值: 1590 },
      { 属性: 属性类型.加速等级, 值: 4919 },
      { 属性: 属性类型.破招值, 值: 4373 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 98397,
    uid: 214901,
    装备名称: '照耀冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5056 },
      { 属性: 属性类型.身法, 值: 980 },
      { 属性: 属性类型.外功基础攻击, 值: 1590 },
      { 属性: 属性类型.外功会心等级, 值: 4919 },
      { 属性: 属性类型.无双等级, 值: 4373 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98396,
    uid: 214900,
    装备名称: '如雪冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5056 },
      { 属性: 属性类型.力道, 值: 980 },
      { 属性: 属性类型.外功基础攻击, 值: 1590 },
      { 属性: 属性类型.外功会心等级, 值: 4919 },
      { 属性: 属性类型.无双等级, 值: 4373 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98339,
    uid: 214843,
    装备名称: '鸿辉·眠狸冠',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4991 },
      { 属性: 属性类型.身法, 值: 968 },
      { 属性: 属性类型.外功基础攻击, 值: 1570 },
      { 属性: 属性类型.外功破防等级, 值: 4856 },
      { 属性: 属性类型.破招值, 值: 4316 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98338,
    uid: 214842,
    装备名称: '鸿辉·凛霜冠',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4991 },
      { 属性: 属性类型.力道, 值: 968 },
      { 属性: 属性类型.外功基础攻击, 值: 1570 },
      { 属性: 属性类型.外功破防等级, 值: 4856 },
      { 属性: 属性类型.破招值, 值: 4316 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98333,
    uid: 214837,
    装备名称: '鸿辉·霭琼冠',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4991 },
      { 属性: 属性类型.身法, 值: 968 },
      { 属性: 属性类型.外功基础攻击, 值: 1570 },
      { 属性: 属性类型.外功破防等级, 值: 4856 },
      { 属性: 属性类型.破招值, 值: 4316 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98561,
    uid: 215304,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3814 },
      { 属性: 属性类型.破招值, 值: 3195 },
      { 属性: 属性类型.外功破防等级, 值: 3462 },
      { 属性: 属性类型.外功会心等级, 值: 3462 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98560,
    uid: 215303,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3457 },
      { 属性: 属性类型.外功会心等级, 值: 5326 },
      { 属性: 属性类型.无双等级, 值: 5592 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98559,
    uid: 215302,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 4053 },
      { 属性: 属性类型.无双等级, 值: 9453 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98537,
    uid: 215262,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3264 },
      { 属性: 属性类型.破招值, 值: 2765 },
      { 属性: 属性类型.外功会心等级, 值: 4776 },
      { 属性: 属性类型.无双等级, 值: 2765 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98536,
    uid: 215261,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3264 },
      { 属性: 属性类型.外功会心等级, 值: 6285 },
      { 属性: 属性类型.外功会心效果等级, 值: 3771 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98535,
    uid: 215260,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3826 },
      { 属性: 属性类型.外功破防等级, 值: 8924 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96391,
    uid: 212116,
    装备名称: '风停冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4586 },
      { 属性: 属性类型.身法, 值: 889 },
      { 属性: 属性类型.外功基础攻击, 值: 1443 },
      { 属性: 属性类型.外功会心等级, 值: 4462 },
      { 属性: 属性类型.破招值, 值: 3966 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96390,
    uid: 212115,
    装备名称: '风烈冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4586 },
      { 属性: 属性类型.力道, 值: 889 },
      { 属性: 属性类型.外功基础攻击, 值: 1443 },
      { 属性: 属性类型.外功会心等级, 值: 4462 },
      { 属性: 属性类型.破招值, 值: 3966 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 99992,
    uid: 217046,
    装备名称: '陶然意·旭影帽',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4553 },
      { 属性: 属性类型.身法, 值: 828 },
      { 属性: 属性类型.外功基础攻击, 值: 1102 },
      { 属性: 属性类型.外功会心等级, 值: 4430 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 99991,
    uid: 217045,
    装备名称: '陶然意·沉麟帽',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4553 },
      { 属性: 属性类型.力道, 值: 828 },
      { 属性: 属性类型.外功基础攻击, 值: 1102 },
      { 属性: 属性类型.外功会心等级, 值: 4430 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 99987,
    uid: 217041,
    装备名称: '陶然意·弘阔帽',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4553 },
      { 属性: 属性类型.身法, 值: 828 },
      { 属性: 属性类型.外功基础攻击, 值: 1102 },
      { 属性: 属性类型.外功会心等级, 值: 4430 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 98703,
    uid: 215565,
    装备名称: '东方日出·天宇冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.身法, 值: 877 },
      { 属性: 属性类型.外功基础攻击, 值: 1422 },
      { 属性: 属性类型.外功会心等级, 值: 4399 },
      { 属性: 属性类型.无双等级, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98702,
    uid: 215564,
    装备名称: '东方日出·海光冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.力道, 值: 877 },
      { 属性: 属性类型.外功基础攻击, 值: 1422 },
      { 属性: 属性类型.外功会心等级, 值: 4399 },
      { 属性: 属性类型.无双等级, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98211,
    uid: 214666,
    装备名称: '危光帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.身法, 值: 877 },
      { 属性: 属性类型.外功基础攻击, 值: 1422 },
      { 属性: 属性类型.外功破防等级, 值: 4399 },
      { 属性: 属性类型.破招值, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98210,
    uid: 214665,
    装备名称: '危雨帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.力道, 值: 877 },
      { 属性: 属性类型.外功基础攻击, 值: 1422 },
      { 属性: 属性类型.外功破防等级, 值: 4399 },
      { 属性: 属性类型.破招值, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96501,
    uid: 212305,
    装备名称: '泉幽冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.身法, 值: 877 },
      { 属性: 属性类型.外功基础攻击, 值: 1422 },
      { 属性: 属性类型.外功会心等级, 值: 4399 },
      { 属性: 属性类型.破招值, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96500,
    uid: 212304,
    装备名称: '泉潺冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.力道, 值: 877 },
      { 属性: 属性类型.外功基础攻击, 值: 1422 },
      { 属性: 属性类型.外功会心等级, 值: 4399 },
      { 属性: 属性类型.破招值, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96415,
    uid: 212171,
    装备名称: '客行江湖·霄月冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.身法, 值: 877 },
      { 属性: 属性类型.外功基础攻击, 值: 1422 },
      { 属性: 属性类型.外功会心等级, 值: 4399 },
      { 属性: 属性类型.破招值, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96414,
    uid: 212170,
    装备名称: '客行江湖·听钟冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.力道, 值: 877 },
      { 属性: 属性类型.外功基础攻击, 值: 1422 },
      { 属性: 属性类型.外功会心等级, 值: 4399 },
      { 属性: 属性类型.破招值, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96319,
    uid: 211884,
    装备名称: '踏雁冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.身法, 值: 877 },
      { 属性: 属性类型.外功基础攻击, 值: 1422 },
      { 属性: 属性类型.加速等级, 值: 4399 },
      { 属性: 属性类型.破招值, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 96318,
    uid: 211883,
    装备名称: '素鸦冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.力道, 值: 877 },
      { 属性: 属性类型.外功基础攻击, 值: 1422 },
      { 属性: 属性类型.加速等级, 值: 4399 },
      { 属性: 属性类型.破招值, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 96283,
    uid: 211824,
    装备名称: '风掣冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.身法, 值: 877 },
      { 属性: 属性类型.外功基础攻击, 值: 1422 },
      { 属性: 属性类型.外功会心等级, 值: 4399 },
      { 属性: 属性类型.无双等级, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96282,
    uid: 211823,
    装备名称: '凛行冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.力道, 值: 877 },
      { 属性: 属性类型.外功基础攻击, 值: 1422 },
      { 属性: 属性类型.外功会心等级, 值: 4399 },
      { 属性: 属性类型.无双等级, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98181,
    uid: 214605,
    装备名称: '寻踪觅宝·飞旋帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13750,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4456 },
      { 属性: 属性类型.身法, 值: 864 },
      { 属性: 属性类型.外功基础攻击, 值: 1402 },
      { 属性: 属性类型.外功会心等级, 值: 4336 },
      { 属性: 属性类型.无双等级, 值: 3854 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98180,
    uid: 214604,
    装备名称: '寻踪觅宝·碎浪帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13750,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4456 },
      { 属性: 属性类型.力道, 值: 864 },
      { 属性: 属性类型.外功基础攻击, 值: 1402 },
      { 属性: 属性类型.外功会心等级, 值: 4336 },
      { 属性: 属性类型.无双等级, 值: 3854 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96225,
    uid: 211766,
    装备名称: '灵源·寂林冠',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4456 },
      { 属性: 属性类型.身法, 值: 864 },
      { 属性: 属性类型.外功基础攻击, 值: 1402 },
      { 属性: 属性类型.外功破防等级, 值: 4336 },
      { 属性: 属性类型.破招值, 值: 3854 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96224,
    uid: 211765,
    装备名称: '灵源·休归冠',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4456 },
      { 属性: 属性类型.力道, 值: 864 },
      { 属性: 属性类型.外功基础攻击, 值: 1402 },
      { 属性: 属性类型.外功破防等级, 值: 4336 },
      { 属性: 属性类型.破招值, 值: 3854 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96219,
    uid: 211760,
    装备名称: '灵源·风涛冠',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4456 },
      { 属性: 属性类型.身法, 值: 864 },
      { 属性: 属性类型.外功基础攻击, 值: 1402 },
      { 属性: 属性类型.外功破防等级, 值: 4336 },
      { 属性: 属性类型.破招值, 值: 3854 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96455,
    uid: 212241,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3082 },
      { 属性: 属性类型.外功破防等级, 值: 3323 },
      { 属性: 属性类型.外功会心等级, 值: 3798 },
      { 属性: 属性类型.无双等级, 值: 2611 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96454,
    uid: 212240,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3082 },
      { 属性: 属性类型.破招值, 值: 4985 },
      { 属性: 属性类型.外功会心等级, 值: 4747 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 96453,
    uid: 212239,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3613 },
      { 属性: 属性类型.外功会心等级, 值: 8427 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96423,
    uid: 212185,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3212 },
      { 属性: 属性类型.破招值, 值: 2691 },
      { 属性: 属性类型.外功破防等级, 值: 2915 },
      { 属性: 属性类型.外功会心等级, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96422,
    uid: 212184,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2911 },
      { 属性: 属性类型.外功会心等级, 值: 4485 },
      { 属性: 属性类型.无双等级, 值: 4709 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96421,
    uid: 212183,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3413 },
      { 属性: 属性类型.无双等级, 值: 7960 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94488,
    uid: 208182,
    装备名称: '雪漫冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4083 },
      { 属性: 属性类型.身法, 值: 792 },
      { 属性: 属性类型.外功基础攻击, 值: 1285 },
      { 属性: 属性类型.外功会心等级, 值: 3973 },
      { 属性: 属性类型.破招值, 值: 3532 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 94487,
    uid: 208181,
    装备名称: '雪舞冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4083 },
      { 属性: 属性类型.力道, 值: 792 },
      { 属性: 属性类型.外功基础攻击, 值: 1285 },
      { 属性: 属性类型.外功会心等级, 值: 3973 },
      { 属性: 属性类型.破招值, 值: 3532 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96597,
    uid: 212502,
    装备名称: '西风北啸·角寒冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.身法, 值: 782 },
      { 属性: 属性类型.外功基础攻击, 值: 1269 },
      { 属性: 属性类型.外功会心等级, 值: 3926 },
      { 属性: 属性类型.无双等级, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96596,
    uid: 212501,
    装备名称: '西风北啸·砾漠冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.力道, 值: 782 },
      { 属性: 属性类型.外功基础攻击, 值: 1269 },
      { 属性: 属性类型.外功会心等级, 值: 3926 },
      { 属性: 属性类型.无双等级, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94590,
    uid: 208356,
    装备名称: '湖月冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.身法, 值: 782 },
      { 属性: 属性类型.外功基础攻击, 值: 1269 },
      { 属性: 属性类型.外功会心等级, 值: 3926 },
      { 属性: 属性类型.破招值, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 94589,
    uid: 208355,
    装备名称: '湖静冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.力道, 值: 782 },
      { 属性: 属性类型.外功基础攻击, 值: 1269 },
      { 属性: 属性类型.外功会心等级, 值: 3926 },
      { 属性: 属性类型.破招值, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 94512,
    uid: 208236,
    装备名称: '客行江湖·天配冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.身法, 值: 782 },
      { 属性: 属性类型.外功基础攻击, 值: 1269 },
      { 属性: 属性类型.外功会心等级, 值: 3926 },
      { 属性: 属性类型.破招值, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 94511,
    uid: 208235,
    装备名称: '客行江湖·梦花冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.力道, 值: 782 },
      { 属性: 属性类型.外功基础攻击, 值: 1269 },
      { 属性: 属性类型.外功会心等级, 值: 3926 },
      { 属性: 属性类型.破招值, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 94428,
    uid: 207968,
    装备名称: '染辞冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.身法, 值: 782 },
      { 属性: 属性类型.外功基础攻击, 值: 1269 },
      { 属性: 属性类型.加速等级, 值: 3926 },
      { 属性: 属性类型.破招值, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 94427,
    uid: 207967,
    装备名称: '温刃冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.力道, 值: 782 },
      { 属性: 属性类型.外功基础攻击, 值: 1269 },
      { 属性: 属性类型.加速等级, 值: 3926 },
      { 属性: 属性类型.破招值, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 94392,
    uid: 207908,
    装备名称: '商野冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.身法, 值: 782 },
      { 属性: 属性类型.外功基础攻击, 值: 1269 },
      { 属性: 属性类型.外功会心等级, 值: 3926 },
      { 属性: 属性类型.无双等级, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94391,
    uid: 207907,
    装备名称: '安衿冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.力道, 值: 782 },
      { 属性: 属性类型.外功基础攻击, 值: 1269 },
      { 属性: 属性类型.外功会心等级, 值: 3926 },
      { 属性: 属性类型.无双等级, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 90528,
    uid: 189020,
    装备名称: '临书帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4019 },
      { 属性: 属性类型.身法, 值: 779 },
      { 属性: 属性类型.外功基础攻击, 值: 1264 },
      { 属性: 属性类型.外功破防等级, 值: 3910 },
      { 属性: 属性类型.无双等级, 值: 3476 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 90527,
    uid: 189019,
    装备名称: '临雾帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4019 },
      { 属性: 属性类型.力道, 值: 779 },
      { 属性: 属性类型.外功基础攻击, 值: 1264 },
      { 属性: 属性类型.外功破防等级, 值: 3910 },
      { 属性: 属性类型.无双等级, 值: 3476 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100359,
    uid: 217834,
    装备名称: '塞尘帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.身法, 值: 725 },
      { 属性: 属性类型.外功基础攻击, 值: 965 },
      { 属性: 属性类型.加速等级, 值: 3879 },
      { 属性: 属性类型.全能等级, 值: 1724 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 100358,
    uid: 217833,
    装备名称: '峭月帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.力道, 值: 725 },
      { 属性: 属性类型.外功基础攻击, 值: 965 },
      { 属性: 属性类型.加速等级, 值: 3879 },
      { 属性: 属性类型.全能等级, 值: 1724 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 100323,
    uid: 217743,
    装备名称: '奔枝帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.身法, 值: 725 },
      { 属性: 属性类型.外功基础攻击, 值: 965 },
      { 属性: 属性类型.外功会心等级, 值: 3879 },
      { 属性: 属性类型.全能等级, 值: 1724 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 100322,
    uid: 217742,
    装备名称: '环旌帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.力道, 值: 725 },
      { 属性: 属性类型.外功基础攻击, 值: 965 },
      { 属性: 属性类型.外功会心等级, 值: 3879 },
      { 属性: 属性类型.全能等级, 值: 1724 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 97849,
    uid: 213810,
    装备名称: '濯心·猎风冠',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.身法, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.外功破防等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96097,
    uid: 211607,
    装备名称: '寻踪觅宝·屠云帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.身法, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.外功会心等级, 值: 3879 },
      { 属性: 属性类型.无双等级, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96096,
    uid: 211606,
    装备名称: '寻踪觅宝·惊风帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.力道, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.外功会心等级, 值: 3879 },
      { 属性: 属性类型.无双等级, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94335,
    uid: 207851,
    装备名称: '濯心·锋虹冠',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.力道, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.外功破防等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94330,
    uid: 207846,
    装备名称: '濯心·盈怀冠',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.身法, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.外功破防等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 90666,
    uid: 189344,
    装备名称: '久念冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.身法, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.外功破防等级, 值: 3879 },
      { 属性: 属性类型.无双等级, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 90665,
    uid: 189343,
    装备名称: '拭江冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.力道, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.外功破防等级, 值: 3879 },
      { 属性: 属性类型.无双等级, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 90630,
    uid: 189254,
    装备名称: '风岱冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.身法, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.外功会心等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 90629,
    uid: 189253,
    装备名称: '项昌冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.力道, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.外功会心等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 90594,
    uid: 189164,
    装备名称: '北邱冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.身法, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.加速等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 90593,
    uid: 189163,
    装备名称: '曲郦冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.力道, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.加速等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 90558,
    uid: 189074,
    装备名称: '渊忱冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.身法, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.外功会心等级, 值: 3879 },
      { 属性: 属性类型.无双等级, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 90557,
    uid: 189073,
    装备名称: '羡双冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.力道, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.外功会心等级, 值: 3879 },
      { 属性: 属性类型.无双等级, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 90426,
    uid: 188792,
    装备名称: '忆宁冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.身法, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.外功破防等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 90425,
    uid: 188791,
    装备名称: '忆敬冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.力道, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.外功破防等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 90390,
    uid: 188702,
    装备名称: '盈绝帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.身法, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.加速等级, 值: 3879 },
      { 属性: 属性类型.无双等级, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 90389,
    uid: 188701,
    装备名称: '垣翰帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.力道, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.加速等级, 值: 3879 },
      { 属性: 属性类型.无双等级, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 90354,
    uid: 188612,
    装备名称: '潋阳帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.身法, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.外功会心等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 90353,
    uid: 188611,
    装备名称: '重关帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.力道, 值: 773 },
      { 属性: 属性类型.外功基础攻击, 值: 1254 },
      { 属性: 属性类型.外功会心等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 94544,
    uid: 208292,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2752 },
      { 属性: 属性类型.破招值, 值: 2332 },
      { 属性: 属性类型.外功会心等级, 值: 4027 },
      { 属性: 属性类型.无双等级, 值: 2332 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94543,
    uid: 208291,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2752 },
      { 属性: 属性类型.外功会心等级, 值: 5299 },
      { 属性: 属性类型.外功会心效果等级, 值: 3180 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94542,
    uid: 208290,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3226 },
      { 属性: 属性类型.外功破防等级, 值: 7525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
]

export default 帽子装备数据
