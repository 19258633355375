// 参考魔盒计算装分js

import { 系数类型 } from '../type'

// 镶嵌分数
// 重制和缘起镶嵌分数计算公式完全一致，仅常量不同

// 系数 A 如无例外情况，则默认等于 8.8 （目前重制与缘起均相同）
// 系数 B 在重制与缘起不同，重制为 8.0，缘起为 2.0
// 系数 C 仅和五彩石有关，在重制与缘起不同，重制为 10.0，缘起为 2.5

export const 系数_A: 系数类型 = {
  旗舰: 8.8,
  缘起: 8.8,
}
export const 系数_B: 系数类型 = {
  旗舰: 16.0,
  缘起: 1.0,
}
export const 系数_C: 系数类型 = {
  旗舰: 20.0,
  缘起: 1.0,
}

// 怀旧服装备精炼分数随精炼等级系数表
export const 缘起精炼系数表 = [
  0, 0.009, 0.0234, 0.0432, 0.0684, 0.10971, 0.15831, 0.2142, 0.27738, 0.34785, 0.42561,
]

// 品质系数
export const 装备品质系数 = {
  '1': 0.8, //白
  '2': 1.4, //绿
  '3': 1.6, //蓝
  '4': 1.8, //紫
  '5': 2.5, //橙
}

// 部位系数
export const 装备部位系数 = {
  '0': 1.2, //武器
  '1': 0.6, //暗器
  '2': 1, //衣服
  '3': 0.9, //帽子
  '4': 0.5, //项链
  '5': 0.5, //戒指
  '6': 0.7, //腰带
  '7': 0.5, //腰坠
  '8': 1, //裤子
  '9': 0.7, //鞋子
  '10': 0.7, //护腕
}
