import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 帽子装备数据: 装备属性信息模型[] = [
  {
    id: 98503,
    uid: 215191,
    装备名称: '水泓冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5121 },
      { 属性: 属性类型.元气, 值: 993 },
      { 属性: 属性类型.内功基础攻击, 值: 1933 },
      { 属性: 属性类型.全会心等级, 值: 4982 },
      { 属性: 属性类型.破招值, 值: 4429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98502,
    uid: 215190,
    装备名称: '水川冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5121 },
      { 属性: 属性类型.根骨, 值: 993 },
      { 属性: 属性类型.内功基础攻击, 值: 1933 },
      { 属性: 属性类型.内功会心等级, 值: 4982 },
      { 属性: 属性类型.破招值, 值: 4429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100116,
    uid: 217044,
    装备名称: '陶然意·和时帽',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5088 },
      { 属性: 属性类型.根骨, 值: 925 },
      { 属性: 属性类型.内功基础攻击, 值: 1477 },
      { 属性: 属性类型.内功会心等级, 值: 4951 },
      { 属性: 属性类型.全能等级, 值: 2200 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 100115,
    uid: 217043,
    装备名称: '陶然意·星雪帽',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5088 },
      { 属性: 属性类型.元气, 值: 925 },
      { 属性: 属性类型.内功基础攻击, 值: 1477 },
      { 属性: 属性类型.内功会心等级, 值: 4951 },
      { 属性: 属性类型.全能等级, 值: 2200 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 100099,
    uid: 217027,
    装备名称: '陶然意·兰谷帽',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5088 },
      { 属性: 属性类型.元气, 值: 925 },
      { 属性: 属性类型.内功基础攻击, 值: 1477 },
      { 属性: 属性类型.内功会心等级, 值: 4951 },
      { 属性: 属性类型.全能等级, 值: 2200 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 98605,
    uid: 215366,
    装备名称: '月迟冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5056 },
      { 属性: 属性类型.元气, 值: 980 },
      { 属性: 属性类型.内功基础攻击, 值: 1908 },
      { 属性: 属性类型.全会心等级, 值: 4919 },
      { 属性: 属性类型.破招值, 值: 4373 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98604,
    uid: 215365,
    装备名称: '月纤冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5056 },
      { 属性: 属性类型.根骨, 值: 980 },
      { 属性: 属性类型.内功基础攻击, 值: 1908 },
      { 属性: 属性类型.内功会心等级, 值: 4919 },
      { 属性: 属性类型.破招值, 值: 4373 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98527,
    uid: 215246,
    装备名称: '客行江湖·磐气冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5056 },
      { 属性: 属性类型.元气, 值: 980 },
      { 属性: 属性类型.内功基础攻击, 值: 1908 },
      { 属性: 属性类型.全会心等级, 值: 4919 },
      { 属性: 属性类型.破招值, 值: 4373 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98526,
    uid: 215245,
    装备名称: '客行江湖·风翎冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5056 },
      { 属性: 属性类型.根骨, 值: 980 },
      { 属性: 属性类型.内功基础攻击, 值: 1908 },
      { 属性: 属性类型.内功会心等级, 值: 4919 },
      { 属性: 属性类型.破招值, 值: 4373 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98431,
    uid: 214959,
    装备名称: '良安冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5056 },
      { 属性: 属性类型.元气, 值: 980 },
      { 属性: 属性类型.内功基础攻击, 值: 1908 },
      { 属性: 属性类型.加速等级, 值: 4919 },
      { 属性: 属性类型.破招值, 值: 4373 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 98430,
    uid: 214958,
    装备名称: '情义冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5056 },
      { 属性: 属性类型.根骨, 值: 980 },
      { 属性: 属性类型.内功基础攻击, 值: 1908 },
      { 属性: 属性类型.加速等级, 值: 4919 },
      { 属性: 属性类型.破招值, 值: 4373 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 98395,
    uid: 214899,
    装备名称: '宫阙冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5056 },
      { 属性: 属性类型.元气, 值: 980 },
      { 属性: 属性类型.内功基础攻击, 值: 1908 },
      { 属性: 属性类型.全会心等级, 值: 4919 },
      { 属性: 属性类型.无双等级, 值: 4373 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98394,
    uid: 214898,
    装备名称: '绕城冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5056 },
      { 属性: 属性类型.根骨, 值: 980 },
      { 属性: 属性类型.内功基础攻击, 值: 1908 },
      { 属性: 属性类型.内功会心等级, 值: 4919 },
      { 属性: 属性类型.无双等级, 值: 4373 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98336,
    uid: 214840,
    装备名称: '鸿辉·白林冠',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4991 },
      { 属性: 属性类型.根骨, 值: 968 },
      { 属性: 属性类型.内功基础攻击, 值: 1884 },
      { 属性: 属性类型.内功破防等级, 值: 4856 },
      { 属性: 属性类型.破招值, 值: 4316 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98335,
    uid: 214839,
    装备名称: '鸿辉·梦辰冠',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4991 },
      { 属性: 属性类型.元气, 值: 968 },
      { 属性: 属性类型.内功基础攻击, 值: 1884 },
      { 属性: 属性类型.内功破防等级, 值: 4856 },
      { 属性: 属性类型.破招值, 值: 4316 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98312,
    uid: 214816,
    装备名称: '鸿辉·鹿喧冠',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4991 },
      { 属性: 属性类型.元气, 值: 968 },
      { 属性: 属性类型.内功基础攻击, 值: 1884 },
      { 属性: 属性类型.内功破防等级, 值: 4856 },
      { 属性: 属性类型.破招值, 值: 4316 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98558,
    uid: 215301,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4577 },
      { 属性: 属性类型.破招值, 值: 3195 },
      { 属性: 属性类型.内功破防等级, 值: 3462 },
      { 属性: 属性类型.全会心等级, 值: 3462 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98557,
    uid: 215300,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4148 },
      { 属性: 属性类型.全会心等级, 值: 5326 },
      { 属性: 属性类型.无双等级, 值: 5592 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98556,
    uid: 215299,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4863 },
      { 属性: 属性类型.无双等级, 值: 9453 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98534,
    uid: 215259,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3916 },
      { 属性: 属性类型.破招值, 值: 2765 },
      { 属性: 属性类型.全会心等级, 值: 4776 },
      { 属性: 属性类型.无双等级, 值: 2765 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98533,
    uid: 215258,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3916 },
      { 属性: 属性类型.全会心等级, 值: 6285 },
      { 属性: 属性类型.全会心效果等级, 值: 3771 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98532,
    uid: 215257,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4591 },
      { 属性: 属性类型.内功破防等级, 值: 8924 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96389,
    uid: 212114,
    装备名称: '风绫冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4586 },
      { 属性: 属性类型.元气, 值: 889 },
      { 属性: 属性类型.内功基础攻击, 值: 1731 },
      { 属性: 属性类型.全会心等级, 值: 4462 },
      { 属性: 属性类型.破招值, 值: 3966 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96388,
    uid: 212113,
    装备名称: '风轻冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4586 },
      { 属性: 属性类型.根骨, 值: 889 },
      { 属性: 属性类型.内功基础攻击, 值: 1731 },
      { 属性: 属性类型.内功会心等级, 值: 4462 },
      { 属性: 属性类型.破招值, 值: 3966 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 99990,
    uid: 217044,
    装备名称: '陶然意·和时帽',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4553 },
      { 属性: 属性类型.根骨, 值: 828 },
      { 属性: 属性类型.内功基础攻击, 值: 1322 },
      { 属性: 属性类型.内功会心等级, 值: 4430 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 99989,
    uid: 217043,
    装备名称: '陶然意·星雪帽',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4553 },
      { 属性: 属性类型.元气, 值: 828 },
      { 属性: 属性类型.内功基础攻击, 值: 1322 },
      { 属性: 属性类型.内功会心等级, 值: 4430 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 99973,
    uid: 217027,
    装备名称: '陶然意·兰谷帽',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4553 },
      { 属性: 属性类型.元气, 值: 828 },
      { 属性: 属性类型.内功基础攻击, 值: 1322 },
      { 属性: 属性类型.内功会心等级, 值: 4430 },
      { 属性: 属性类型.全能等级, 值: 1969 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 98701,
    uid: 215563,
    装备名称: '东方日出·当楼冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.元气, 值: 877 },
      { 属性: 属性类型.内功基础攻击, 值: 1707 },
      { 属性: 属性类型.全会心等级, 值: 4399 },
      { 属性: 属性类型.无双等级, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98700,
    uid: 215562,
    装备名称: '东方日出·所适冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.根骨, 值: 877 },
      { 属性: 属性类型.内功基础攻击, 值: 1707 },
      { 属性: 属性类型.内功会心等级, 值: 4399 },
      { 属性: 属性类型.无双等级, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98209,
    uid: 214664,
    装备名称: '危影帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.元气, 值: 877 },
      { 属性: 属性类型.内功基础攻击, 值: 1707 },
      { 属性: 属性类型.内功破防等级, 值: 4399 },
      { 属性: 属性类型.破招值, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98208,
    uid: 214663,
    装备名称: '危音帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.根骨, 值: 877 },
      { 属性: 属性类型.内功基础攻击, 值: 1707 },
      { 属性: 属性类型.内功破防等级, 值: 4399 },
      { 属性: 属性类型.破招值, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96499,
    uid: 212303,
    装备名称: '泉麓冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.元气, 值: 877 },
      { 属性: 属性类型.内功基础攻击, 值: 1707 },
      { 属性: 属性类型.全会心等级, 值: 4399 },
      { 属性: 属性类型.破招值, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96498,
    uid: 212302,
    装备名称: '泉合冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.根骨, 值: 877 },
      { 属性: 属性类型.内功基础攻击, 值: 1707 },
      { 属性: 属性类型.内功会心等级, 值: 4399 },
      { 属性: 属性类型.破招值, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96413,
    uid: 212169,
    装备名称: '客行江湖·断意冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.元气, 值: 877 },
      { 属性: 属性类型.内功基础攻击, 值: 1707 },
      { 属性: 属性类型.全会心等级, 值: 4399 },
      { 属性: 属性类型.破招值, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96412,
    uid: 212168,
    装备名称: '客行江湖·意悠冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.根骨, 值: 877 },
      { 属性: 属性类型.内功基础攻击, 值: 1707 },
      { 属性: 属性类型.内功会心等级, 值: 4399 },
      { 属性: 属性类型.破招值, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96317,
    uid: 211882,
    装备名称: '壑云冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.元气, 值: 877 },
      { 属性: 属性类型.内功基础攻击, 值: 1707 },
      { 属性: 属性类型.加速等级, 值: 4399 },
      { 属性: 属性类型.破招值, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 96316,
    uid: 211881,
    装备名称: '寒绡冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.根骨, 值: 877 },
      { 属性: 属性类型.内功基础攻击, 值: 1707 },
      { 属性: 属性类型.加速等级, 值: 4399 },
      { 属性: 属性类型.破招值, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 96281,
    uid: 211822,
    装备名称: '开颐冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.元气, 值: 877 },
      { 属性: 属性类型.内功基础攻击, 值: 1707 },
      { 属性: 属性类型.全会心等级, 值: 4399 },
      { 属性: 属性类型.无双等级, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96280,
    uid: 211821,
    装备名称: '扬英冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4521 },
      { 属性: 属性类型.根骨, 值: 877 },
      { 属性: 属性类型.内功基础攻击, 值: 1707 },
      { 属性: 属性类型.内功会心等级, 值: 4399 },
      { 属性: 属性类型.无双等级, 值: 3910 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98179,
    uid: 214603,
    装备名称: '寻踪觅宝·星辉帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13750,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4456 },
      { 属性: 属性类型.元气, 值: 864 },
      { 属性: 属性类型.内功基础攻击, 值: 1682 },
      { 属性: 属性类型.全会心等级, 值: 4336 },
      { 属性: 属性类型.无双等级, 值: 3854 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98178,
    uid: 214602,
    装备名称: '寻踪觅宝·折月帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13750,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4456 },
      { 属性: 属性类型.根骨, 值: 864 },
      { 属性: 属性类型.内功基础攻击, 值: 1682 },
      { 属性: 属性类型.内功会心等级, 值: 4336 },
      { 属性: 属性类型.无双等级, 值: 3854 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96222,
    uid: 211763,
    装备名称: '灵源·采芳冠',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4456 },
      { 属性: 属性类型.根骨, 值: 864 },
      { 属性: 属性类型.内功基础攻击, 值: 1682 },
      { 属性: 属性类型.内功破防等级, 值: 4336 },
      { 属性: 属性类型.破招值, 值: 3854 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96221,
    uid: 211762,
    装备名称: '灵源·沉辉冠',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4456 },
      { 属性: 属性类型.元气, 值: 864 },
      { 属性: 属性类型.内功基础攻击, 值: 1682 },
      { 属性: 属性类型.内功破防等级, 值: 4336 },
      { 属性: 属性类型.破招值, 值: 3854 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96198,
    uid: 211739,
    装备名称: '灵源·月胧冠',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4456 },
      { 属性: 属性类型.元气, 值: 864 },
      { 属性: 属性类型.内功基础攻击, 值: 1682 },
      { 属性: 属性类型.内功破防等级, 值: 4336 },
      { 属性: 属性类型.破招值, 值: 3854 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96452,
    uid: 212238,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3698 },
      { 属性: 属性类型.内功破防等级, 值: 3323 },
      { 属性: 属性类型.全会心等级, 值: 3798 },
      { 属性: 属性类型.无双等级, 值: 2611 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96451,
    uid: 212237,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3698 },
      { 属性: 属性类型.破招值, 值: 4985 },
      { 属性: 属性类型.全会心等级, 值: 4747 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 96450,
    uid: 212236,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4335 },
      { 属性: 属性类型.全会心等级, 值: 8427 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 96420,
    uid: 212182,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3855 },
      { 属性: 属性类型.破招值, 值: 2691 },
      { 属性: 属性类型.内功破防等级, 值: 2915 },
      { 属性: 属性类型.全会心等级, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96419,
    uid: 212181,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3493 },
      { 属性: 属性类型.全会心等级, 值: 4485 },
      { 属性: 属性类型.无双等级, 值: 4709 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96418,
    uid: 212180,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4096 },
      { 属性: 属性类型.无双等级, 值: 7960 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94486,
    uid: 208180,
    装备名称: '雪洁冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4083 },
      { 属性: 属性类型.元气, 值: 792 },
      { 属性: 属性类型.内功基础攻击, 值: 1541 },
      { 属性: 属性类型.全会心等级, 值: 3973 },
      { 属性: 属性类型.破招值, 值: 3532 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 94485,
    uid: 208179,
    装备名称: '雪满冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4083 },
      { 属性: 属性类型.根骨, 值: 792 },
      { 属性: 属性类型.内功基础攻击, 值: 1541 },
      { 属性: 属性类型.内功会心等级, 值: 3973 },
      { 属性: 属性类型.破招值, 值: 3532 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 96595,
    uid: 212500,
    装备名称: '西风北啸·离声冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.元气, 值: 782 },
      { 属性: 属性类型.内功基础攻击, 值: 1523 },
      { 属性: 属性类型.全会心等级, 值: 3926 },
      { 属性: 属性类型.无双等级, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96594,
    uid: 212499,
    装备名称: '西风北啸·音书冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.根骨, 值: 782 },
      { 属性: 属性类型.内功基础攻击, 值: 1523 },
      { 属性: 属性类型.内功会心等级, 值: 3926 },
      { 属性: 属性类型.无双等级, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94588,
    uid: 208354,
    装备名称: '湖烟冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.元气, 值: 782 },
      { 属性: 属性类型.内功基础攻击, 值: 1523 },
      { 属性: 属性类型.全会心等级, 值: 3926 },
      { 属性: 属性类型.破招值, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 94587,
    uid: 208353,
    装备名称: '湖寂冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.根骨, 值: 782 },
      { 属性: 属性类型.内功基础攻击, 值: 1523 },
      { 属性: 属性类型.内功会心等级, 值: 3926 },
      { 属性: 属性类型.破招值, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 94510,
    uid: 208234,
    装备名称: '客行江湖·千世冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.元气, 值: 782 },
      { 属性: 属性类型.内功基础攻击, 值: 1523 },
      { 属性: 属性类型.全会心等级, 值: 3926 },
      { 属性: 属性类型.破招值, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 94509,
    uid: 208233,
    装备名称: '客行江湖·渐浓冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.根骨, 值: 782 },
      { 属性: 属性类型.内功基础攻击, 值: 1523 },
      { 属性: 属性类型.内功会心等级, 值: 3926 },
      { 属性: 属性类型.破招值, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 94426,
    uid: 207966,
    装备名称: '沁渡冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.元气, 值: 782 },
      { 属性: 属性类型.内功基础攻击, 值: 1523 },
      { 属性: 属性类型.加速等级, 值: 3926 },
      { 属性: 属性类型.破招值, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 94425,
    uid: 207965,
    装备名称: '朝华冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.根骨, 值: 782 },
      { 属性: 属性类型.内功基础攻击, 值: 1523 },
      { 属性: 属性类型.加速等级, 值: 3926 },
      { 属性: 属性类型.破招值, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 94390,
    uid: 207906,
    装备名称: '椴微冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.元气, 值: 782 },
      { 属性: 属性类型.内功基础攻击, 值: 1523 },
      { 属性: 属性类型.全会心等级, 值: 3926 },
      { 属性: 属性类型.无双等级, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94389,
    uid: 207905,
    装备名称: '池泓冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4035 },
      { 属性: 属性类型.根骨, 值: 782 },
      { 属性: 属性类型.内功基础攻击, 值: 1523 },
      { 属性: 属性类型.内功会心等级, 值: 3926 },
      { 属性: 属性类型.无双等级, 值: 3490 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 90526,
    uid: 189018,
    装备名称: '临起帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4019 },
      { 属性: 属性类型.元气, 值: 779 },
      { 属性: 属性类型.内功基础攻击, 值: 1517 },
      { 属性: 属性类型.内功破防等级, 值: 3910 },
      { 属性: 属性类型.无双等级, 值: 3476 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 90525,
    uid: 189017,
    装备名称: '临可帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4019 },
      { 属性: 属性类型.根骨, 值: 779 },
      { 属性: 属性类型.内功基础攻击, 值: 1517 },
      { 属性: 属性类型.内功破防等级, 值: 3910 },
      { 属性: 属性类型.无双等级, 值: 3476 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100357,
    uid: 217832,
    装备名称: '若寓帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.元气, 值: 725 },
      { 属性: 属性类型.内功基础攻击, 值: 1158 },
      { 属性: 属性类型.加速等级, 值: 3879 },
      { 属性: 属性类型.全能等级, 值: 1724 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 100356,
    uid: 217831,
    装备名称: '越风帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.根骨, 值: 725 },
      { 属性: 属性类型.内功基础攻击, 值: 1158 },
      { 属性: 属性类型.加速等级, 值: 3879 },
      { 属性: 属性类型.全能等级, 值: 1724 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 100321,
    uid: 217741,
    装备名称: '踏江帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.元气, 值: 725 },
      { 属性: 属性类型.内功基础攻击, 值: 1158 },
      { 属性: 属性类型.全会心等级, 值: 3879 },
      { 属性: 属性类型.全能等级, 值: 1724 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 100320,
    uid: 217740,
    装备名称: '承康帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.根骨, 值: 725 },
      { 属性: 属性类型.内功基础攻击, 值: 1158 },
      { 属性: 属性类型.内功会心等级, 值: 3879 },
      { 属性: 属性类型.全能等级, 值: 1724 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 96095,
    uid: 211605,
    装备名称: '寻踪觅宝·泻雨帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.元气, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.全会心等级, 值: 3879 },
      { 属性: 属性类型.无双等级, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96094,
    uid: 211604,
    装备名称: '寻踪觅宝·拂雪帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.根骨, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.内功会心等级, 值: 3879 },
      { 属性: 属性类型.无双等级, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94333,
    uid: 207849,
    装备名称: '濯心·采青冠',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.根骨, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.内功破防等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94332,
    uid: 207848,
    装备名称: '濯心·天尘冠',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.元气, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.内功破防等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94309,
    uid: 207825,
    装备名称: '濯心·松声冠',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.元气, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.内功破防等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 90664,
    uid: 189342,
    装备名称: '藏峦冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.元气, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.内功破防等级, 值: 3879 },
      { 属性: 属性类型.无双等级, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 90663,
    uid: 189341,
    装备名称: '谨峰冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.根骨, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.内功破防等级, 值: 3879 },
      { 属性: 属性类型.无双等级, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 90628,
    uid: 189252,
    装备名称: '故芳冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.元气, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.全会心等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 90627,
    uid: 189251,
    装备名称: '剪桐冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.根骨, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.内功会心等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 90592,
    uid: 189162,
    装备名称: '花霭冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.元气, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.加速等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 90591,
    uid: 189161,
    装备名称: '途南冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.根骨, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.加速等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 90556,
    uid: 189072,
    装备名称: '庭澜冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.元气, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.全会心等级, 值: 3879 },
      { 属性: 属性类型.无双等级, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 90555,
    uid: 189071,
    装备名称: '故云冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.根骨, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.内功会心等级, 值: 3879 },
      { 属性: 属性类型.无双等级, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 90424,
    uid: 188790,
    装备名称: '忆惜冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.元气, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.内功破防等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 90423,
    uid: 188789,
    装备名称: '忆安冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.根骨, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.内功破防等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 90388,
    uid: 188700,
    装备名称: '语阔帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.元气, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.加速等级, 值: 3879 },
      { 属性: 属性类型.无双等级, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 90387,
    uid: 188699,
    装备名称: '擒雨帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.根骨, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.加速等级, 值: 3879 },
      { 属性: 属性类型.无双等级, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 90352,
    uid: 188610,
    装备名称: '烟琐帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.元气, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.全会心等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 90351,
    uid: 188609,
    装备名称: '德襄帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3986 },
      { 属性: 属性类型.根骨, 值: 773 },
      { 属性: 属性类型.内功基础攻击, 值: 1505 },
      { 属性: 属性类型.内功会心等级, 值: 3879 },
      { 属性: 属性类型.破招值, 值: 3448 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 94541,
    uid: 208289,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3302 },
      { 属性: 属性类型.破招值, 值: 2332 },
      { 属性: 属性类型.全会心等级, 值: 4027 },
      { 属性: 属性类型.无双等级, 值: 2332 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94540,
    uid: 208288,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3302 },
      { 属性: 属性类型.全会心等级, 值: 5299 },
      { 属性: 属性类型.全会心效果等级, 值: 3180 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94539,
    uid: 208287,
    装备名称: '无封头饰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3872 },
      { 属性: 属性类型.内功破防等级, 值: 7525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
]

export default 帽子装备数据
