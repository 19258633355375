import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'
import 大橙武技能增益 from './通用增益/大橙武技能增益'
import 小橙武技能增益 from './通用增益/小橙武技能增益'

const 行云势悟增益: 技能增益列表类型[] = [
  ...通用增益,
  ...大橙武技能增益,
  ...小橙武技能增益,
  {
    // 70188
    增益名称: '满锐增伤',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 308 / 1024 }],
  },
]

export default 行云势悟增益
