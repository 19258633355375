import { 属性类型 } from '@/@types/属性'
import { 装备增益数据类型 } from '@/@types/装备'

const 装备增益数据: 装备增益数据类型 = {
  套装会心会效: [
    { 属性: 属性类型.全会心百分比, 值: 0.04 },
    { 属性: 属性类型.郭氏全会心效果等级, 值: 41 },
  ],
  切糕会心: [{ 属性: 属性类型.全会心等级, 值: 1363 }],
  切糕无双: [{ 属性: 属性类型.无双等级, 值: 1363 }],
  切糕会心_英雄: [{ 属性: 属性类型.全会心等级, 值: 1484 }],
  切糕无双_英雄: [{ 属性: 属性类型.无双等级, 值: 1484 }],
  风特效腰坠: [{ 属性: 属性类型.全破防等级, 值: 9290 }],
  风特效腰坠_英雄: [{ 属性: 属性类型.全破防等级, 值: 10573 }],
  大附魔_伤帽: [{ 属性: 属性类型.全破防等级, 值: 1218 }],
  // 大附魔_伤帽: [{ 属性: 属性类型.全破防等级, 值: 1098 }],
  大附魔_伤腰: [{ 属性: 属性类型.通用增伤, 值: (0.038 * 8) / 30 }], // 按30% 1% 和 70% 5% 的中间值算 30秒触发一次，时常8秒
  大附魔_伤腕: [],
  大附魔_伤鞋: [],
  龙门飞剑武器: [
    { 属性: 属性类型.全会心等级, 值: (4420 * 6) / 30 },
    { 属性: 属性类型.全会心效果等级, 值: (2110 * 6) / 30 },
  ],
  冬至套装: [
    { 属性: 属性类型.全属性, 值: 305 },
    { 属性: 属性类型.全会心等级, 值: 195 },
  ],
  水特效武器: [{ 属性: 属性类型.全基础攻击, 值: 1170 }], // 1层117 10层1170 按覆盖率100%算
  水特效武器_英雄: [{ 属性: 属性类型.全基础攻击, 值: 1370 }], // 1层137 10层1370 按覆盖率100%算
  大附魔_伤衣: [{ 属性: 属性类型.全基础攻击, 值: 549 }],
}

const 功法特殊增益数据 = {
  内功: {
    水特效武器: [{ 属性: 属性类型.全基础攻击, 值: 1170 }], // 1层117 10层1170 按覆盖率100%算
    水特效武器_英雄: [{ 属性: 属性类型.全基础攻击, 值: 1370 }], // 1层137 10层1370 按覆盖率100%算
    大附魔_伤衣: [{ 属性: 属性类型.全基础攻击, 值: 655 }],
  },
  外功: {
    水特效武器: [{ 属性: 属性类型.全基础攻击, 值: 980 }], // 1层98 10层980 按覆盖率100%算
    水特效武器_英雄: [{ 属性: 属性类型.全基础攻击, 值: 1110 }], // 1层111 10层1110 按覆盖率100%算
    大附魔_伤衣: [{ 属性: 属性类型.全基础攻击, 值: 549 }],
  },
}

export { 功法特殊增益数据 }

export default 装备增益数据
