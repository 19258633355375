import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 腰带装备数据: 装备属性信息模型[] = [
  {
    id: 98487,
    uid: 215175,
    装备名称: '水泉腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3983 },
      { 属性: 属性类型.身法, 值: 772 },
      { 属性: 属性类型.外功基础攻击, 值: 1253 },
      { 属性: 属性类型.外功会心等级, 值: 3875 },
      { 属性: 属性类型.破招值, 值: 3444 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98486,
    uid: 215174,
    装备名称: '水泽腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3983 },
      { 属性: 属性类型.力道, 值: 772 },
      { 属性: 属性类型.外功基础攻击, 值: 1253 },
      { 属性: 属性类型.外功会心等级, 值: 3875 },
      { 属性: 属性类型.破招值, 值: 3444 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100055,
    uid: 216983,
    装备名称: '陶然意·旭影腰带',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3957 },
      { 属性: 属性类型.身法, 值: 719 },
      { 属性: 属性类型.外功基础攻击, 值: 958 },
      { 属性: 属性类型.外功会心等级, 值: 3850 },
      { 属性: 属性类型.全能等级, 值: 1711 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100054,
    uid: 216982,
    装备名称: '陶然意·沉麟腰带',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3957 },
      { 属性: 属性类型.力道, 值: 719 },
      { 属性: 属性类型.外功基础攻击, 值: 958 },
      { 属性: 属性类型.外功会心等级, 值: 3850 },
      { 属性: 属性类型.全能等级, 值: 1711 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100050,
    uid: 216978,
    装备名称: '陶然意·弘阔腰带',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3957 },
      { 属性: 属性类型.身法, 值: 719 },
      { 属性: 属性类型.外功基础攻击, 值: 958 },
      { 属性: 属性类型.外功会心等级, 值: 3850 },
      { 属性: 属性类型.全能等级, 值: 1711 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 98589,
    uid: 215350,
    装备名称: '月落腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.身法, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.外功会心等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98588,
    uid: 215349,
    装备名称: '月稠腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.力道, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.外功会心等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98469,
    uid: 215097,
    装备名称: '看面束腰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.外功基础攻击, 值: 2236 },
      { 属性: 属性类型.破招值, 值: 4782 },
      { 属性: 属性类型.无双等级, 值: 4782 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98468,
    uid: 215096,
    装备名称: '苏汉束腰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.外功基础攻击, 值: 2664 },
      { 属性: 属性类型.外功破防等级, 值: 4039 },
      { 属性: 属性类型.无双等级, 值: 4464 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98449,
    uid: 215069,
    装备名称: '岂邪腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3235 },
      { 属性: 属性类型.无双等级, 值: 7546 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98447,
    uid: 215067,
    装备名称: '神际腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2759 },
      { 属性: 属性类型.破招值, 值: 2338 },
      { 属性: 属性类型.外功会心等级, 值: 4039 },
      { 属性: 属性类型.无双等级, 值: 2338 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98446,
    uid: 215066,
    装备名称: '无吼腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2759 },
      { 属性: 属性类型.破招值, 值: 4251 },
      { 属性: 属性类型.外功破防等级, 值: 4464 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 98445,
    uid: 215065,
    装备名称: '若环腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3235 },
      { 属性: 属性类型.外功会心等级, 值: 7546 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98415,
    uid: 214943,
    装备名称: '救困带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.身法, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.加速等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98414,
    uid: 214942,
    装备名称: '磊落带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.力道, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.加速等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98379,
    uid: 214883,
    装备名称: '照耀腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.身法, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.外功破防等级, 值: 3826 },
      { 属性: 属性类型.无双等级, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98378,
    uid: 214882,
    装备名称: '如雪腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.力道, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.外功破防等级, 值: 3826 },
      { 属性: 属性类型.无双等级, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98279,
    uid: 214783,
    装备名称: '鸿辉·眠狸腰带',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3882 },
      { 属性: 属性类型.身法, 值: 753 },
      { 属性: 属性类型.外功基础攻击, 值: 1221 },
      { 属性: 属性类型.外功会心等级, 值: 3777 },
      { 属性: 属性类型.无双等级, 值: 3357 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98278,
    uid: 214782,
    装备名称: '鸿辉·凛霜腰带',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3882 },
      { 属性: 属性类型.力道, 值: 753 },
      { 属性: 属性类型.外功基础攻击, 值: 1221 },
      { 属性: 属性类型.外功会心等级, 值: 3777 },
      { 属性: 属性类型.无双等级, 值: 3357 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98273,
    uid: 214777,
    装备名称: '鸿辉·霭琼腰带',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3882 },
      { 属性: 属性类型.身法, 值: 753 },
      { 属性: 属性类型.外功基础攻击, 值: 1221 },
      { 属性: 属性类型.外功会心等级, 值: 3777 },
      { 属性: 属性类型.无双等级, 值: 3357 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96373,
    uid: 212098,
    装备名称: '风停腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3567 },
      { 属性: 属性类型.身法, 值: 692 },
      { 属性: 属性类型.外功基础攻击, 值: 1122 },
      { 属性: 属性类型.外功会心等级, 值: 3470 },
      { 属性: 属性类型.破招值, 值: 3085 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96372,
    uid: 212097,
    装备名称: '风烈腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3567 },
      { 属性: 属性类型.力道, 值: 692 },
      { 属性: 属性类型.外功基础攻击, 值: 1122 },
      { 属性: 属性类型.外功会心等级, 值: 3470 },
      { 属性: 属性类型.破招值, 值: 3085 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 99929,
    uid: 216983,
    装备名称: '陶然意·旭影腰带',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3542 },
      { 属性: 属性类型.身法, 值: 644 },
      { 属性: 属性类型.外功基础攻击, 值: 857 },
      { 属性: 属性类型.外功会心等级, 值: 3446 },
      { 属性: 属性类型.全能等级, 值: 1531 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 99928,
    uid: 216982,
    装备名称: '陶然意·沉麟腰带',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3542 },
      { 属性: 属性类型.力道, 值: 644 },
      { 属性: 属性类型.外功基础攻击, 值: 857 },
      { 属性: 属性类型.外功会心等级, 值: 3446 },
      { 属性: 属性类型.全能等级, 值: 1531 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 99924,
    uid: 216978,
    装备名称: '陶然意·弘阔腰带',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3542 },
      { 属性: 属性类型.身法, 值: 644 },
      { 属性: 属性类型.外功基础攻击, 值: 857 },
      { 属性: 属性类型.外功会心等级, 值: 3446 },
      { 属性: 属性类型.全能等级, 值: 1531 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 98685,
    uid: 215547,
    装备名称: '东方日出·天宇腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.身法, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功破防等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 98684,
    uid: 215546,
    装备名称: '东方日出·海光腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.力道, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功破防等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 98193,
    uid: 214648,
    装备名称: '危光带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.身法, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功会心等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98192,
    uid: 214647,
    装备名称: '危雨带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.力道, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功会心等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96483,
    uid: 212287,
    装备名称: '泉幽腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.身法, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功会心等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96482,
    uid: 212286,
    装备名称: '泉潺腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.力道, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功会心等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96355,
    uid: 212020,
    装备名称: '余弦束腰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.外功基础攻击, 值: 2000 },
      { 属性: 属性类型.破招值, 值: 4277 },
      { 属性: 属性类型.无双等级, 值: 4277 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96354,
    uid: 212019,
    装备名称: '暮舞束腰',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.外功基础攻击, 值: 2382 },
      { 属性: 属性类型.外功破防等级, 值: 3611 },
      { 属性: 属性类型.无双等级, 值: 3991 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96335,
    uid: 211992,
    装备名称: '叙尧腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2893 },
      { 属性: 属性类型.无双等级, 值: 6748 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96333,
    uid: 211990,
    装备名称: '疏绫腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2468 },
      { 属性: 属性类型.破招值, 值: 2091 },
      { 属性: 属性类型.外功会心等级, 值: 3611 },
      { 属性: 属性类型.无双等级, 值: 2091 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96332,
    uid: 211989,
    装备名称: '忆檀腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2468 },
      { 属性: 属性类型.破招值, 值: 3801 },
      { 属性: 属性类型.外功破防等级, 值: 3991 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 96331,
    uid: 211988,
    装备名称: '岳圭腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2893 },
      { 属性: 属性类型.外功会心等级, 值: 6748 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96301,
    uid: 211866,
    装备名称: '踏雁带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.身法, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.加速等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96300,
    uid: 211865,
    装备名称: '素鸦带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.力道, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.加速等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96265,
    uid: 211806,
    装备名称: '风掣腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.身法, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功破防等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96264,
    uid: 211805,
    装备名称: '凛行腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.力道, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功破防等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98169,
    uid: 214593,
    装备名称: '寻踪觅宝·飞旋腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13750,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.身法, 值: 672 },
      { 属性: 属性类型.外功基础攻击, 值: 1090 },
      { 属性: 属性类型.加速等级, 值: 3372 },
      { 属性: 属性类型.破招值, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98168,
    uid: 214592,
    装备名称: '寻踪觅宝·碎浪腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13750,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.力道, 值: 672 },
      { 属性: 属性类型.外功基础攻击, 值: 1090 },
      { 属性: 属性类型.加速等级, 值: 3372 },
      { 属性: 属性类型.破招值, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96165,
    uid: 211706,
    装备名称: '灵源·寂林腰带',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.身法, 值: 672 },
      { 属性: 属性类型.外功基础攻击, 值: 1090 },
      { 属性: 属性类型.外功会心等级, 值: 3372 },
      { 属性: 属性类型.无双等级, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96164,
    uid: 211705,
    装备名称: '灵源·休归腰带',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.力道, 值: 672 },
      { 属性: 属性类型.外功基础攻击, 值: 1090 },
      { 属性: 属性类型.外功会心等级, 值: 3372 },
      { 属性: 属性类型.无双等级, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96159,
    uid: 211700,
    装备名称: '灵源·风涛腰带',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.身法, 值: 672 },
      { 属性: 属性类型.外功基础攻击, 值: 1090 },
      { 属性: 属性类型.外功会心等级, 值: 3372 },
      { 属性: 属性类型.无双等级, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 97773,
    uid: 210975,
    装备名称: '翠林腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3176 },
      { 属性: 属性类型.身法, 值: 616 },
      { 属性: 属性类型.外功基础攻击, 值: 999 },
      { 属性: 属性类型.外功破防等级, 值: 3090 },
      { 属性: 属性类型.破招值, 值: 2747 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 97772,
    uid: 210974,
    装备名称: '静山腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3176 },
      { 属性: 属性类型.力道, 值: 616 },
      { 属性: 属性类型.外功基础攻击, 值: 999 },
      { 属性: 属性类型.外功破防等级, 值: 3090 },
      { 属性: 属性类型.破招值, 值: 2747 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 94470,
    uid: 208164,
    装备名称: '雪漫腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3176 },
      { 属性: 属性类型.身法, 值: 616 },
      { 属性: 属性类型.外功基础攻击, 值: 999 },
      { 属性: 属性类型.外功会心等级, 值: 3090 },
      { 属性: 属性类型.破招值, 值: 2747 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94469,
    uid: 208163,
    装备名称: '雪舞腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3176 },
      { 属性: 属性类型.力道, 值: 616 },
      { 属性: 属性类型.外功基础攻击, 值: 999 },
      { 属性: 属性类型.外功会心等级, 值: 3090 },
      { 属性: 属性类型.破招值, 值: 2747 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96579,
    uid: 212484,
    装备名称: '西风北啸·角寒腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.身法, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功破防等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 96578,
    uid: 212483,
    装备名称: '西风北啸·砾漠腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.力道, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功破防等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 94572,
    uid: 208338,
    装备名称: '湖月腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.身法, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功会心等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94571,
    uid: 208337,
    装备名称: '湖静腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.力道, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功会心等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94444,
    uid: 208074,
    装备名称: '泽及腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.外功基础攻击, 值: 2126 },
      { 属性: 属性类型.外功破防等级, 值: 3223 },
      { 属性: 属性类型.无双等级, 值: 3562 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94442,
    uid: 208072,
    装备名称: '沧鳞腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.外功基础攻击, 值: 1823 },
      { 属性: 属性类型.外功会心等级, 值: 2205 },
      { 属性: 属性类型.无双等级, 值: 5428 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94441,
    uid: 208071,
    装备名称: '聚远腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.外功基础攻击, 值: 2126 },
      { 属性: 属性类型.破招值, 值: 3562 },
      { 属性: 属性类型.外功破防等级, 值: 3223 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94440,
    uid: 208070,
    装备名称: '束缊腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.外功基础攻击, 值: 2582 },
      { 属性: 属性类型.无双等级, 值: 5683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94410,
    uid: 207950,
    装备名称: '染辞带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.身法, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.加速等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94409,
    uid: 207949,
    装备名称: '温刃带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.力道, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.加速等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94374,
    uid: 207890,
    装备名称: '商野腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.身法, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功破防等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94373,
    uid: 207889,
    装备名称: '安衿腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.力道, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功破防等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90516,
    uid: 189008,
    装备名称: '临夜腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3126 },
      { 属性: 属性类型.身法, 值: 606 },
      { 属性: 属性类型.外功基础攻击, 值: 983 },
      { 属性: 属性类型.外功破防等级, 值: 3041 },
      { 属性: 属性类型.无双等级, 值: 2703 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90515,
    uid: 189007,
    装备名称: '临岳腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3126 },
      { 属性: 属性类型.力道, 值: 606 },
      { 属性: 属性类型.外功基础攻击, 值: 983 },
      { 属性: 属性类型.外功破防等级, 值: 3041 },
      { 属性: 属性类型.无双等级, 值: 2703 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100341,
    uid: 217816,
    装备名称: '塞尘带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 750 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100340,
    uid: 217815,
    装备名称: '峭月带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 750 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100305,
    uid: 217725,
    装备名称: '奔枝带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 750 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100304,
    uid: 217724,
    装备名称: '环旌带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 750 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 98127,
    uid: 214320,
    装备名称: '梧风御厨腰带·刀功',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98126,
    uid: 214319,
    装备名称: '岚峰御厨腰带·刀功',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98121,
    uid: 214314,
    装备名称: '沧波御厨腰带·刀功 ',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 97847,
    uid: 213808,
    装备名称: '濯心·猎风腰带',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96085,
    uid: 211595,
    装备名称: '寻踪觅宝·屠云腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96084,
    uid: 211594,
    装备名称: '寻踪觅宝·惊风腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94277,
    uid: 207793,
    装备名称: '濯心·锋虹腰带',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94272,
    uid: 207788,
    装备名称: '濯心·盈怀腰带',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 90648,
    uid: 189326,
    装备名称: '久念腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 90647,
    uid: 189325,
    装备名称: '拭江腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 90612,
    uid: 189236,
    装备名称: '风岱腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90611,
    uid: 189235,
    装备名称: '项昌腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90576,
    uid: 189146,
    装备名称: '北邱腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 90575,
    uid: 189145,
    装备名称: '曲郦腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 90540,
    uid: 189056,
    装备名称: '渊忱腰带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 90539,
    uid: 189055,
    装备名称: '羡双腰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 90408,
    uid: 188774,
    装备名称: '忆宁带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 90407,
    uid: 188773,
    装备名称: '忆敬带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 90372,
    uid: 188684,
    装备名称: '盈绝带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90371,
    uid: 188683,
    装备名称: '垣翰带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90336,
    uid: 188594,
    装备名称: '潋阳带',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.破招值, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90335,
    uid: 188593,
    装备名称: '重关带',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.破招值, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
]

export default 腰带装备数据
