import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 下装装备数据: 装备属性信息模型[] = [
  {
    id: 98499,
    uid: 215187,
    装备名称: '水泉裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5689 },
      { 属性: 属性类型.身法, 值: 1103 },
      { 属性: 属性类型.外功基础攻击, 值: 1790 },
      { 属性: 属性类型.外功会心等级, 值: 5536 },
      { 属性: 属性类型.无双等级, 值: 4921 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98498,
    uid: 215186,
    装备名称: '水泽裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5689 },
      { 属性: 属性类型.力道, 值: 1103 },
      { 属性: 属性类型.外功基础攻击, 值: 1790 },
      { 属性: 属性类型.外功会心等级, 值: 5536 },
      { 属性: 属性类型.无双等级, 值: 4921 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100097,
    uid: 217025,
    装备名称: '陶然意·旭影裤',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5653 },
      { 属性: 属性类型.身法, 值: 1028 },
      { 属性: 属性类型.外功基础攻击, 值: 1368 },
      { 属性: 属性类型.外功会心等级, 值: 5501 },
      { 属性: 属性类型.全能等级, 值: 2445 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100096,
    uid: 217024,
    装备名称: '陶然意·沉麟裤',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5653 },
      { 属性: 属性类型.力道, 值: 1028 },
      { 属性: 属性类型.外功基础攻击, 值: 1368 },
      { 属性: 属性类型.外功会心等级, 值: 5501 },
      { 属性: 属性类型.全能等级, 值: 2445 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100092,
    uid: 217020,
    装备名称: '陶然意·弘阔裤',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5653 },
      { 属性: 属性类型.身法, 值: 1028 },
      { 属性: 属性类型.外功基础攻击, 值: 1368 },
      { 属性: 属性类型.外功会心等级, 值: 5501 },
      { 属性: 属性类型.全能等级, 值: 2445 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98601,
    uid: 215362,
    装备名称: '月落裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.身法, 值: 1089 },
      { 属性: 属性类型.外功基础攻击, 值: 1767 },
      { 属性: 属性类型.外功会心等级, 值: 5466 },
      { 属性: 属性类型.破招值, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 98600,
    uid: 215361,
    装备名称: '月稠裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.力道, 值: 1089 },
      { 属性: 属性类型.外功基础攻击, 值: 1767 },
      { 属性: 属性类型.外功会心等级, 值: 5466 },
      { 属性: 属性类型.破招值, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 98523,
    uid: 215242,
    装备名称: '客行江湖·纵巧下裳',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.身法, 值: 1089 },
      { 属性: 属性类型.外功基础攻击, 值: 1767 },
      { 属性: 属性类型.外功破防等级, 值: 5466 },
      { 属性: 属性类型.无双等级, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98522,
    uid: 215241,
    装备名称: '客行江湖·之远下裳',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.力道, 值: 1089 },
      { 属性: 属性类型.外功基础攻击, 值: 1767 },
      { 属性: 属性类型.外功破防等级, 值: 5466 },
      { 属性: 属性类型.无双等级, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98477,
    uid: 215105,
    装备名称: '微尔裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.外功基础攻击, 值: 3262 },
      { 属性: 属性类型.外功会心等级, 值: 3947 },
      { 属性: 属性类型.无双等级, 值: 9717 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98476,
    uid: 215104,
    装备名称: '水回裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.外功基础攻击, 值: 4622 },
      { 属性: 属性类型.无双等级, 值: 10172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98465,
    uid: 215085,
    装备名称: '见美裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3942 },
      { 属性: 属性类型.外功破防等级, 值: 4251 },
      { 属性: 属性类型.外功会心等级, 值: 4858 },
      { 属性: 属性类型.无双等级, 值: 3340 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98463,
    uid: 215083,
    装备名称: '罗一裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3942 },
      { 属性: 属性类型.破招值, 值: 4251 },
      { 属性: 属性类型.外功破防等级, 值: 5162 },
      { 属性: 属性类型.无双等级, 值: 3036 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98462,
    uid: 215082,
    装备名称: '木幽裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3942 },
      { 属性: 属性类型.破招值, 值: 6377 },
      { 属性: 属性类型.外功会心等级, 值: 6073 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 98461,
    uid: 215081,
    装备名称: '中空裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 4622 },
      { 属性: 属性类型.外功破防等级, 值: 10779 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98427,
    uid: 214955,
    装备名称: '救困裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.身法, 值: 1089 },
      { 属性: 属性类型.外功基础攻击, 值: 1767 },
      { 属性: 属性类型.外功破防等级, 值: 5466 },
      { 属性: 属性类型.破招值, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 98426,
    uid: 214954,
    装备名称: '磊落裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.力道, 值: 1089 },
      { 属性: 属性类型.外功基础攻击, 值: 1767 },
      { 属性: 属性类型.外功破防等级, 值: 5466 },
      { 属性: 属性类型.破招值, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 98391,
    uid: 214895,
    装备名称: '照耀裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.身法, 值: 1089 },
      { 属性: 属性类型.外功基础攻击, 值: 1767 },
      { 属性: 属性类型.加速等级, 值: 5466 },
      { 属性: 属性类型.无双等级, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98390,
    uid: 214894,
    装备名称: '如雪裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.力道, 值: 1089 },
      { 属性: 属性类型.外功基础攻击, 值: 1767 },
      { 属性: 属性类型.加速等级, 值: 5466 },
      { 属性: 属性类型.无双等级, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98573,
    uid: 215316,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3841 },
      { 属性: 属性类型.外功破防等级, 值: 4142 },
      { 属性: 属性类型.外功会心等级, 值: 4734 },
      { 属性: 属性类型.无双等级, 值: 3254 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98572,
    uid: 215315,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3841 },
      { 属性: 属性类型.破招值, 值: 6065 },
      { 属性: 属性类型.无双等级, 值: 6065 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98571,
    uid: 215314,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 4503 },
      { 属性: 属性类型.外功破防等级, 值: 10503 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98549,
    uid: 215274,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 4001 },
      { 属性: 属性类型.破招值, 值: 3352 },
      { 属性: 属性类型.外功破防等级, 值: 3631 },
      { 属性: 属性类型.外功会心等级, 值: 3631 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98548,
    uid: 215273,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3626 },
      { 属性: 属性类型.外功破防等级, 值: 5586 },
      { 属性: 属性类型.无双等级, 值: 5866 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98547,
    uid: 215272,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 4251 },
      { 属性: 属性类型.外功会心等级, 值: 9916 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96385,
    uid: 212110,
    装备名称: '风停裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5095 },
      { 属性: 属性类型.身法, 值: 988 },
      { 属性: 属性类型.外功基础攻击, 值: 1603 },
      { 属性: 属性类型.外功会心等级, 值: 4958 },
      { 属性: 属性类型.无双等级, 值: 4407 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96384,
    uid: 212109,
    装备名称: '风烈裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5095 },
      { 属性: 属性类型.力道, 值: 988 },
      { 属性: 属性类型.外功基础攻击, 值: 1603 },
      { 属性: 属性类型.外功会心等级, 值: 4958 },
      { 属性: 属性类型.无双等级, 值: 4407 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 99971,
    uid: 217025,
    装备名称: '陶然意·旭影裤',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5059 },
      { 属性: 属性类型.身法, 值: 920 },
      { 属性: 属性类型.外功基础攻击, 值: 1224 },
      { 属性: 属性类型.外功会心等级, 值: 4923 },
      { 属性: 属性类型.全能等级, 值: 2188 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 99970,
    uid: 217024,
    装备名称: '陶然意·沉麟裤',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5059 },
      { 属性: 属性类型.力道, 值: 920 },
      { 属性: 属性类型.外功基础攻击, 值: 1224 },
      { 属性: 属性类型.外功会心等级, 值: 4923 },
      { 属性: 属性类型.全能等级, 值: 2188 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 99966,
    uid: 217020,
    装备名称: '陶然意·弘阔裤',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5059 },
      { 属性: 属性类型.身法, 值: 920 },
      { 属性: 属性类型.外功基础攻击, 值: 1224 },
      { 属性: 属性类型.外功会心等级, 值: 4923 },
      { 属性: 属性类型.全能等级, 值: 2188 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98697,
    uid: 215559,
    装备名称: '东方日出·天宇裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.身法, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功破防等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 98696,
    uid: 215558,
    装备名称: '东方日出·海光裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.力道, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功破防等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 98205,
    uid: 214660,
    装备名称: '危光裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.身法, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功会心等级, 值: 4888 },
      { 属性: 属性类型.无双等级, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98204,
    uid: 214659,
    装备名称: '危雨裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.力道, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功会心等级, 值: 4888 },
      { 属性: 属性类型.无双等级, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96495,
    uid: 212299,
    装备名称: '泉幽裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.身法, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功会心等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 96494,
    uid: 212298,
    装备名称: '泉潺裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.力道, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功会心等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 96409,
    uid: 212165,
    装备名称: '客行江湖·霄月下裳',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.身法, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功破防等级, 值: 4888 },
      { 属性: 属性类型.无双等级, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96408,
    uid: 212164,
    装备名称: '客行江湖·听钟下裳',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.力道, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功破防等级, 值: 4888 },
      { 属性: 属性类型.无双等级, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96363,
    uid: 212028,
    装备名称: '燕轻裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.外功基础攻击, 值: 2917 },
      { 属性: 属性类型.外功会心等级, 值: 3530 },
      { 属性: 属性类型.无双等级, 值: 8689 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96362,
    uid: 212027,
    装备名称: '拂声裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.外功基础攻击, 值: 4133 },
      { 属性: 属性类型.无双等级, 值: 9096 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96351,
    uid: 212008,
    装备名称: '风袂裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3525 },
      { 属性: 属性类型.外功破防等级, 值: 3801 },
      { 属性: 属性类型.外功会心等级, 值: 4344 },
      { 属性: 属性类型.无双等级, 值: 2987 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96349,
    uid: 212006,
    装备名称: '含晨裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3525 },
      { 属性: 属性类型.破招值, 值: 3801 },
      { 属性: 属性类型.外功破防等级, 值: 4616 },
      { 属性: 属性类型.无双等级, 值: 2715 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96348,
    uid: 212005,
    装备名称: '向行裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3525 },
      { 属性: 属性类型.破招值, 值: 5702 },
      { 属性: 属性类型.外功会心等级, 值: 5431 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 96347,
    uid: 212004,
    装备名称: '满歌裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 4133 },
      { 属性: 属性类型.外功破防等级, 值: 9639 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96313,
    uid: 211878,
    装备名称: '踏雁裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.身法, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功破防等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 96312,
    uid: 211877,
    装备名称: '素鸦裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.力道, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.外功破防等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 96277,
    uid: 211818,
    装备名称: '风掣裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.身法, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.加速等级, 值: 4888 },
      { 属性: 属性类型.无双等级, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96276,
    uid: 211817,
    装备名称: '凛行裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.力道, 值: 974 },
      { 属性: 属性类型.外功基础攻击, 值: 1580 },
      { 属性: 属性类型.加速等级, 值: 4888 },
      { 属性: 属性类型.无双等级, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96467,
    uid: 212253,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3424 },
      { 属性: 属性类型.破招值, 值: 3692 },
      { 属性: 属性类型.外功破防等级, 值: 4484 },
      { 属性: 属性类型.无双等级, 值: 2637 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96466,
    uid: 212252,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3424 },
      { 属性: 属性类型.外功会心等级, 值: 5275 },
      { 属性: 属性类型.无双等级, 值: 5539 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96465,
    uid: 212251,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 4014 },
      { 属性: 属性类型.无双等级, 值: 9363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96439,
    uid: 212201,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3234 },
      { 属性: 属性类型.外功破防等级, 值: 3488 },
      { 属性: 属性类型.外功会心等级, 值: 3986 },
      { 属性: 属性类型.无双等级, 值: 2741 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96438,
    uid: 212200,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3234 },
      { 属性: 属性类型.破招值, 值: 5107 },
      { 属性: 属性类型.无双等级, 值: 5107 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96437,
    uid: 212199,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3792 },
      { 属性: 属性类型.外功破防等级, 值: 8845 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94482,
    uid: 208176,
    装备名称: '雪漫裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4537 },
      { 属性: 属性类型.身法, 值: 880 },
      { 属性: 属性类型.外功基础攻击, 值: 1427 },
      { 属性: 属性类型.外功会心等级, 值: 4415 },
      { 属性: 属性类型.无双等级, 值: 3924 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94481,
    uid: 208175,
    装备名称: '雪舞裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4537 },
      { 属性: 属性类型.力道, 值: 880 },
      { 属性: 属性类型.外功基础攻击, 值: 1427 },
      { 属性: 属性类型.外功会心等级, 值: 4415 },
      { 属性: 属性类型.无双等级, 值: 3924 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96591,
    uid: 212496,
    装备名称: '西风北啸·角寒裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.身法, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.外功破防等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 96590,
    uid: 212495,
    装备名称: '西风北啸·砾漠裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.力道, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.外功破防等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 94584,
    uid: 208350,
    装备名称: '湖月裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.身法, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.外功会心等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 94583,
    uid: 208349,
    装备名称: '湖静裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.力道, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.外功会心等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 94506,
    uid: 208230,
    装备名称: '客行江湖·天配下裳',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.身法, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.外功破防等级, 值: 4362 },
      { 属性: 属性类型.无双等级, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94505,
    uid: 208229,
    装备名称: '客行江湖·梦花下裳',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.力道, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.外功破防等级, 值: 4362 },
      { 属性: 属性类型.无双等级, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94460,
    uid: 208090,
    装备名称: '不暇裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.外功基础攻击, 值: 3038 },
      { 属性: 属性类型.外功破防等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 5089 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94458,
    uid: 208088,
    装备名称: '泛景裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.外功基础攻击, 值: 3688 },
      { 属性: 属性类型.破招值, 值: 8118 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 94457,
    uid: 208087,
    装备名称: '锦堂裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.外功基础攻击, 值: 3038 },
      { 属性: 属性类型.破招值, 值: 5089 },
      { 属性: 属性类型.外功破防等级, 值: 4604 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94456,
    uid: 208086,
    装备名称: '思柔裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.外功基础攻击, 值: 2604 },
      { 属性: 属性类型.外功会心等级, 值: 3150 },
      { 属性: 属性类型.无双等级, 值: 7755 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94422,
    uid: 207962,
    装备名称: '染辞裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.身法, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.外功破防等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 94421,
    uid: 207961,
    装备名称: '温刃裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.力道, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.外功破防等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 94386,
    uid: 207902,
    装备名称: '商野裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.身法, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.加速等级, 值: 4362 },
      { 属性: 属性类型.无双等级, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94385,
    uid: 207901,
    装备名称: '安衿裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.力道, 值: 869 },
      { 属性: 属性类型.外功基础攻击, 值: 1410 },
      { 属性: 属性类型.加速等级, 值: 4362 },
      { 属性: 属性类型.无双等级, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100353,
    uid: 217828,
    装备名称: '塞尘裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 805 },
      { 属性: 属性类型.外功基础攻击, 值: 1072 },
      { 属性: 属性类型.外功破防等级, 值: 4309 },
      { 属性: 属性类型.全能等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 100352,
    uid: 217827,
    装备名称: '峭月裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 805 },
      { 属性: 属性类型.外功基础攻击, 值: 1072 },
      { 属性: 属性类型.外功破防等级, 值: 4309 },
      { 属性: 属性类型.全能等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 100317,
    uid: 217737,
    装备名称: '奔枝裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 805 },
      { 属性: 属性类型.外功基础攻击, 值: 1072 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.全能等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100316,
    uid: 217736,
    装备名称: '环旌裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 805 },
      { 属性: 属性类型.外功基础攻击, 值: 1072 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.全能等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 90660,
    uid: 189338,
    装备名称: '久念裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功破防等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90659,
    uid: 189337,
    装备名称: '拭江裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功破防等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90624,
    uid: 189248,
    装备名称: '风岱裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功会心等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90623,
    uid: 189247,
    装备名称: '项昌裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功会心等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90588,
    uid: 189158,
    装备名称: '北邱裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 90587,
    uid: 189157,
    装备名称: '曲郦裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 90552,
    uid: 189068,
    装备名称: '渊忱裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功破防等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90551,
    uid: 189067,
    装备名称: '羡双裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功破防等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90420,
    uid: 188786,
    装备名称: '忆宁裳',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功会心等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90419,
    uid: 188785,
    装备名称: '忆敬裳',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功会心等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90384,
    uid: 188696,
    装备名称: '盈绝裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功破防等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90383,
    uid: 188695,
    装备名称: '垣翰裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.外功破防等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90348,
    uid: 188606,
    装备名称: '潋阳裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.身法, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 90347,
    uid: 188605,
    装备名称: '重关裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.力道, 值: 859 },
      { 属性: 属性类型.外功基础攻击, 值: 1393 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 94556,
    uid: 208304,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3374 },
      { 属性: 属性类型.破招值, 值: 2826 },
      { 属性: 属性类型.外功破防等级, 值: 3062 },
      { 属性: 属性类型.外功会心等级, 值: 3062 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 94555,
    uid: 208303,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3058 },
      { 属性: 属性类型.外功破防等级, 值: 4710 },
      { 属性: 属性类型.无双等级, 值: 4946 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94554,
    uid: 208302,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3585 },
      { 属性: 属性类型.外功会心等级, 值: 8361 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
]

export default 下装装备数据
