import { 五彩石数据类型 } from '@/@types/五彩石'
import { 属性类型 } from '@/@types/属性'
import { 五彩石增益类型枚举 } from '@/@types/枚举'

const 五彩石_六级: 五彩石数据类型[] = [
  {
    五彩石名称: '彩·真刚·锐刃·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.力道, 增益类型: 属性类型.力道 },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·真刚·锐刃·斩铁(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.力道, 增益类型: 属性类型.力道 },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·真刚·锐刃·痛击(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.力道, 增益类型: 属性类型.力道 },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·真刚·斩铁·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.力道, 增益类型: 属性类型.力道 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·真刚·斩铁·锐刃(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.力道, 增益类型: 属性类型.力道 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 1048,
        增益名称: 五彩石增益类型枚举.外功基础攻击,
        增益类型: 属性类型.外功基础攻击,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·真刚·斩铁·痛击(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.力道, 增益类型: 属性类型.力道 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·真刚·痛击·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.力道, 增益类型: 属性类型.力道 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·真刚·痛击·斩铁(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.力道, 增益类型: 属性类型.力道 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·真刚·痛击·锐刃(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.力道, 增益类型: 属性类型.力道 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 1048,
        增益名称: 五彩石增益类型枚举.外功基础攻击,
        增益类型: 属性类型.外功基础攻击,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·真刚·急速·锐刃(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.力道, 增益类型: 属性类型.力道 },
      { 增益数值: 1170, 增益名称: 五彩石增益类型枚举.加速等级, 增益类型: 属性类型.加速等级 },
      {
        增益数值: 1048,
        增益名称: 五彩石增益类型枚举.外功基础攻击,
        增益类型: 属性类型.外功基础攻击,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·瞬影·锐刃·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.身法, 增益类型: 属性类型.身法 },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·瞬影·锐刃·斩铁(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.身法, 增益类型: 属性类型.身法 },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·瞬影·锐刃·痛击(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.身法, 增益类型: 属性类型.身法 },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·瞬影·斩铁·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.身法, 增益类型: 属性类型.身法 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·瞬影·斩铁·锐刃(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.身法, 增益类型: 属性类型.身法 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 1048,
        增益名称: 五彩石增益类型枚举.外功基础攻击,
        增益类型: 属性类型.外功基础攻击,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·瞬影·斩铁·痛击(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.身法, 增益类型: 属性类型.身法 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·瞬影·痛击·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.身法, 增益类型: 属性类型.身法 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·瞬影·痛击·斩铁(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.身法, 增益类型: 属性类型.身法 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·瞬影·痛击·锐刃(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.身法, 增益类型: 属性类型.身法 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 1048,
        增益名称: 五彩石增益类型枚举.外功基础攻击,
        增益类型: 属性类型.外功基础攻击,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·瞬影·急速·锐刃(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 131, 增益名称: 五彩石增益类型枚举.身法, 增益类型: 属性类型.身法 },
      { 增益数值: 1170, 增益名称: 五彩石增益类型枚举.加速等级, 增益类型: 属性类型.加速等级 },
      {
        增益数值: 1048,
        增益名称: 五彩石增益类型枚举.外功基础攻击,
        增益类型: 属性类型.外功基础攻击,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·锐刃·斩铁·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 262, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·锐刃·斩铁·痛击(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 262, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·锐刃·痛击·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 262, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·锐刃·痛击·斩铁(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 262, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·斩铁·锐刃·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      {
        增益数值: 585,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·斩铁·锐刃·痛击(陆)',
    五彩石等级: 6,
    装备增益: [
      {
        增益数值: 585,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·斩铁·痛击·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      {
        增益数值: 585,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·斩铁·痛击·锐刃(陆)',
    五彩石等级: 6,
    装备增益: [
      {
        增益数值: 585,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 1048,
        增益名称: 五彩石增益类型枚举.外功基础攻击,
        增益类型: 属性类型.外功基础攻击,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·见切·锐刃·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      {
        增益数值: 585,
        增益名称: 五彩石增益类型枚举.会心等级,
        增益类型: 属性类型.外功会心等级,
      },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·见切·锐刃·斩铁(陆)',
    五彩石等级: 6,
    装备增益: [
      {
        增益数值: 585,
        增益名称: 五彩石增益类型枚举.会心等级,
        增益类型: 属性类型.外功会心等级,
      },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·见切·锐刃·痛击(陆)',
    五彩石等级: 6,
    装备增益: [
      {
        增益数值: 585,
        增益名称: 五彩石增益类型枚举.会心等级,
        增益类型: 属性类型.外功会心等级,
      },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·见切·斩铁·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      {
        增益数值: 585,
        增益名称: 五彩石增益类型枚举.会心等级,
        增益类型: 属性类型.外功会心等级,
      },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·见切·斩铁·锐刃(陆)',
    五彩石等级: 6,
    装备增益: [
      {
        增益数值: 585,
        增益名称: 五彩石增益类型枚举.会心等级,
        增益类型: 属性类型.外功会心等级,
      },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 1048,
        增益名称: 五彩石增益类型枚举.外功基础攻击,
        增益类型: 属性类型.外功基础攻击,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·见切·斩铁·痛击(陆)',
    五彩石等级: 6,
    装备增益: [
      {
        增益数值: 585,
        增益名称: 五彩石增益类型枚举.会心等级,
        增益类型: 属性类型.外功会心等级,
      },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·见切·痛击·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      {
        增益数值: 585,
        增益名称: 五彩石增益类型枚举.会心等级,
        增益类型: 属性类型.外功会心等级,
      },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·见切·痛击·斩铁(陆)',
    五彩石等级: 6,
    装备增益: [
      {
        增益数值: 585,
        增益名称: 五彩石增益类型枚举.会心等级,
        增益类型: 属性类型.外功会心等级,
      },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·见切·痛击·锐刃(陆)',
    五彩石等级: 6,
    装备增益: [
      {
        增益数值: 585,
        增益名称: 五彩石增益类型枚举.会心等级,
        增益类型: 属性类型.外功会心等级,
      },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 1048,
        增益名称: 五彩石增益类型枚举.外功基础攻击,
        增益类型: 属性类型.外功基础攻击,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·痛击·锐刃·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      {
        增益数值: 585,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·痛击·锐刃·斩铁(陆)',
    五彩石等级: 6,
    装备增益: [
      {
        增益数值: 585,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·痛击·斩铁·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      {
        增益数值: 585,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·痛击·斩铁·锐刃(陆)',
    五彩石等级: 6,
    装备增益: [
      {
        增益数值: 585,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 1048,
        增益名称: 五彩石增益类型枚举.外功基础攻击,
        增益类型: 属性类型.外功基础攻击,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·无双·锐刃·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.无双等级, 增益类型: 属性类型.无双等级 },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·无双·锐刃·斩铁(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.无双等级, 增益类型: 属性类型.无双等级 },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·无双·锐刃·痛击(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.无双等级, 增益类型: 属性类型.无双等级 },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·无双·斩铁·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.无双等级, 增益类型: 属性类型.无双等级 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·无双·斩铁·锐刃(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.无双等级, 增益类型: 属性类型.无双等级 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 1048,
        增益名称: 五彩石增益类型枚举.外功基础攻击,
        增益类型: 属性类型.外功基础攻击,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·无双·斩铁·痛击(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.无双等级, 增益类型: 属性类型.无双等级 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·无双·痛击·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.无双等级, 增益类型: 属性类型.无双等级 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·无双·痛击·斩铁(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.无双等级, 增益类型: 属性类型.无双等级 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·无双·痛击·锐刃(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.无双等级, 增益类型: 属性类型.无双等级 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 1048,
        增益名称: 五彩石增益类型枚举.外功基础攻击,
        增益类型: 属性类型.外功基础攻击,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·无双·急速·锐刃(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.无双等级, 增益类型: 属性类型.无双等级 },
      { 增益数值: 1170, 增益名称: 五彩石增益类型枚举.加速等级, 增益类型: 属性类型.加速等级 },
      {
        增益数值: 1048,
        增益名称: 五彩石增益类型枚举.外功基础攻击,
        增益类型: 属性类型.外功基础攻击,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·破招·锐刃·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.破招值, 增益类型: 属性类型.破招值 },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·破招·锐刃·斩铁(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.破招值, 增益类型: 属性类型.破招值 },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·破招·锐刃·痛击(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.破招值, 增益类型: 属性类型.破招值 },
      { 增益数值: 524, 增益名称: 五彩石增益类型枚举.外功基础攻击, 增益类型: 属性类型.外功基础攻击 },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·破招·斩铁·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.破招值, 增益类型: 属性类型.破招值 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·破招·斩铁·锐刃(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.破招值, 增益类型: 属性类型.破招值 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 1048,
        增益名称: 五彩石增益类型枚举.外功基础攻击,
        增益类型: 属性类型.外功基础攻击,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·破招·斩铁·痛击(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.破招值, 增益类型: 属性类型.破招值 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·破招·痛击·狂攻(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.破招值, 增益类型: 属性类型.破招值 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 1571,
        增益名称: 五彩石增益类型枚举.武器伤害,
        增益类型: 属性类型.武器伤害,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·破招·痛击·斩铁(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.破招值, 增益类型: 属性类型.破招值 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 2340,
        增益名称: 五彩石增益类型枚举.破防等级,
        增益类型: 属性类型.外功破防等级,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·破招·痛击·锐刃(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.破招值, 增益类型: 属性类型.破招值 },
      {
        增益数值: 1170,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益类型: 属性类型.外功会心效果等级,
      },
      {
        增益数值: 1048,
        增益名称: 五彩石增益类型枚举.外功基础攻击,
        增益类型: 属性类型.外功基础攻击,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·破招·急速·锐刃(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 585, 增益名称: 五彩石增益类型枚举.破招值, 增益类型: 属性类型.破招值 },
      { 增益数值: 1170, 增益名称: 五彩石增益类型枚举.加速等级, 增益类型: 属性类型.加速等级 },
      {
        增益数值: 1048,
        增益名称: 五彩石增益类型枚举.外功基础攻击,
        增益类型: 属性类型.外功基础攻击,
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·击破·强击·聚力(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1170, 增益名称: 五彩石增益类型枚举.会心等级, 增益类型: 属性类型.全会心等级 },
      { 增益数值: 81, 增益名称: 五彩石增益类型枚举.力道百分比, 增益类型: 属性类型.郭氏力道 },
    ],
    DiamondCount1: '16',
    DiamondCount2: '18',
    DiamondIntensity1: '90',
    DiamondIntensity2: '108',
  },
  {
    五彩石名称: '彩·击破·强击·强身(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1170, 增益名称: 五彩石增益类型枚举.会心等级, 增益类型: 属性类型.全会心等级 },
      { 增益数值: 81, 增益名称: 五彩石增益类型枚举.身法百分比, 增益类型: 属性类型.郭氏身法 },
    ],
    DiamondCount1: '16',
    DiamondCount2: '18',
    DiamondIntensity1: '90',
    DiamondIntensity2: '108',
  },
]

export default 五彩石_六级
