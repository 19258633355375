import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备类型枚举 } from '@/@types/装备'

const 暗器装备数据: 装备属性信息模型[] = [
  {
    id: 38162,
    uid: 217088,
    装备名称: '陶然意·旭影囊',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3392 },
      { 属性: 属性类型.身法, 值: 617 },
      { 属性: 属性类型.外功基础攻击, 值: 821 },
      { 属性: 属性类型.外功破防等级, 值: 3300 },
      { 属性: 属性类型.全能等级, 值: 1467 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 38161,
    uid: 217087,
    装备名称: '陶然意·沉麟囊',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3392 },
      { 属性: 属性类型.力道, 值: 617 },
      { 属性: 属性类型.外功基础攻击, 值: 821 },
      { 属性: 属性类型.外功破防等级, 值: 3300 },
      { 属性: 属性类型.全能等级, 值: 1467 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 38157,
    uid: 217083,
    装备名称: '陶然意·弘阔囊',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3392 },
      { 属性: 属性类型.身法, 值: 617 },
      { 属性: 属性类型.外功基础攻击, 值: 821 },
      { 属性: 属性类型.外功破防等级, 值: 3300 },
      { 属性: 属性类型.全能等级, 值: 1467 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 38591,
    uid: 218240,
    装备名称: '劲秋囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3370 },
      { 属性: 属性类型.身法, 值: 654 },
      { 属性: 属性类型.外功基础攻击, 值: 1060 },
      { 属性: 属性类型.外功破防等级, 值: 3279 },
      { 属性: 属性类型.无双等级, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 38590,
    uid: 218239,
    装备名称: '听秋囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3370 },
      { 属性: 属性类型.力道, 值: 654 },
      { 属性: 属性类型.外功基础攻击, 值: 1060 },
      { 属性: 属性类型.外功破防等级, 值: 3279 },
      { 属性: 属性类型.无双等级, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 37307,
    uid: 215380,
    装备名称: '月落囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3370 },
      { 属性: 属性类型.身法, 值: 654 },
      { 属性: 属性类型.外功基础攻击, 值: 1060 },
      { 属性: 属性类型.外功会心等级, 值: 3279 },
      { 属性: 属性类型.破招值, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 37306,
    uid: 215379,
    装备名称: '月稠囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3370 },
      { 属性: 属性类型.力道, 值: 654 },
      { 属性: 属性类型.外功基础攻击, 值: 1060 },
      { 属性: 属性类型.外功会心等级, 值: 3279 },
      { 属性: 属性类型.破招值, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 37188,
    uid: 214991,
    装备名称: '救困囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3370 },
      { 属性: 属性类型.身法, 值: 654 },
      { 属性: 属性类型.外功基础攻击, 值: 1060 },
      { 属性: 属性类型.外功会心等级, 值: 3279 },
      { 属性: 属性类型.无双等级, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 37187,
    uid: 214990,
    装备名称: '磊落囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3370 },
      { 属性: 属性类型.力道, 值: 654 },
      { 属性: 属性类型.外功基础攻击, 值: 1060 },
      { 属性: 属性类型.外功会心等级, 值: 3279 },
      { 属性: 属性类型.无双等级, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 37182,
    uid: 214931,
    装备名称: '照耀囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3370 },
      { 属性: 属性类型.身法, 值: 654 },
      { 属性: 属性类型.外功基础攻击, 值: 1060 },
      { 属性: 属性类型.加速等级, 值: 3279 },
      { 属性: 属性类型.破招值, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 37181,
    uid: 214930,
    装备名称: '如雪囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3370 },
      { 属性: 属性类型.力道, 值: 654 },
      { 属性: 属性类型.外功基础攻击, 值: 1060 },
      { 属性: 属性类型.加速等级, 值: 3279 },
      { 属性: 属性类型.破招值, 值: 2915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 37303,
    uid: 215340,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2305 },
      { 属性: 属性类型.破招值, 值: 1953 },
      { 属性: 属性类型.外功会心等级, 值: 3373 },
      { 属性: 属性类型.外功会心效果等级, 值: 1775 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 37302,
    uid: 215339,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2305 },
      { 属性: 属性类型.外功会心等级, 值: 4438 },
      { 属性: 属性类型.外功会心效果等级, 值: 2663 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 37301,
    uid: 215338,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2702 },
      { 属性: 属性类型.外功破防等级, 值: 6302 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 37297,
    uid: 215298,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2176 },
      { 属性: 属性类型.外功破防等级, 值: 2430 },
      { 属性: 属性类型.外功会心等级, 值: 2598 },
      { 属性: 属性类型.外功会心效果等级, 值: 1676 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 37296,
    uid: 215297,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2176 },
      { 属性: 属性类型.破招值, 值: 3519 },
      { 属性: 属性类型.外功会心等级, 值: 3352 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 37295,
    uid: 215296,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2551 },
      { 属性: 属性类型.无双等级, 值: 5949 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 38119,
    uid: 217088,
    装备名称: '陶然意·旭影囊',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3036 },
      { 属性: 属性类型.身法, 值: 552 },
      { 属性: 属性类型.外功基础攻击, 值: 735 },
      { 属性: 属性类型.外功破防等级, 值: 2954 },
      { 属性: 属性类型.全能等级, 值: 1313 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 38118,
    uid: 217087,
    装备名称: '陶然意·沉麟囊',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3036 },
      { 属性: 属性类型.力道, 值: 552 },
      { 属性: 属性类型.外功基础攻击, 值: 735 },
      { 属性: 属性类型.外功破防等级, 值: 2954 },
      { 属性: 属性类型.全能等级, 值: 1313 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 38114,
    uid: 217083,
    装备名称: '陶然意·弘阔囊',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3036 },
      { 属性: 属性类型.身法, 值: 552 },
      { 属性: 属性类型.外功基础攻击, 值: 735 },
      { 属性: 属性类型.外功破防等级, 值: 2954 },
      { 属性: 属性类型.全能等级, 值: 1313 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 37408,
    uid: 215577,
    装备名称: '东方日出·天宇囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.身法, 值: 584 },
      { 属性: 属性类型.外功基础攻击, 值: 948 },
      { 属性: 属性类型.外功会心等级, 值: 2933 },
      { 属性: 属性类型.无双等级, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 37407,
    uid: 215576,
    装备名称: '东方日出·海光囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.力道, 值: 584 },
      { 属性: 属性类型.外功基础攻击, 值: 948 },
      { 属性: 属性类型.外功会心等级, 值: 2933 },
      { 属性: 属性类型.无双等级, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 37145,
    uid: 214690,
    装备名称: '危光囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.身法, 值: 584 },
      { 属性: 属性类型.外功基础攻击, 值: 948 },
      { 属性: 属性类型.外功会心等级, 值: 2933 },
      { 属性: 属性类型.破招值, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 37144,
    uid: 214689,
    装备名称: '危雨囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.力道, 值: 584 },
      { 属性: 属性类型.外功基础攻击, 值: 948 },
      { 属性: 属性类型.外功会心等级, 值: 2933 },
      { 属性: 属性类型.破招值, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 35838,
    uid: 212317,
    装备名称: '泉幽囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.身法, 值: 584 },
      { 属性: 属性类型.外功基础攻击, 值: 948 },
      { 属性: 属性类型.外功会心等级, 值: 2933 },
      { 属性: 属性类型.破招值, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 35837,
    uid: 212316,
    装备名称: '泉潺囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.力道, 值: 584 },
      { 属性: 属性类型.外功基础攻击, 值: 948 },
      { 属性: 属性类型.外功会心等级, 值: 2933 },
      { 属性: 属性类型.破招值, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 35785,
    uid: 212036,
    装备名称: '卓盈囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.身法, 值: 584 },
      { 属性: 属性类型.外功基础攻击, 值: 948 },
      { 属性: 属性类型.外功破防等级, 值: 2933 },
      { 属性: 属性类型.破招值, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 35784,
    uid: 212035,
    装备名称: '越既囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.力道, 值: 584 },
      { 属性: 属性类型.外功基础攻击, 值: 948 },
      { 属性: 属性类型.外功破防等级, 值: 2933 },
      { 属性: 属性类型.破招值, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 35717,
    uid: 211914,
    装备名称: '踏雁囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.身法, 值: 584 },
      { 属性: 属性类型.外功基础攻击, 值: 948 },
      { 属性: 属性类型.外功会心等级, 值: 2933 },
      { 属性: 属性类型.无双等级, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 35716,
    uid: 211913,
    装备名称: '素鸦囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.力道, 值: 584 },
      { 属性: 属性类型.外功基础攻击, 值: 948 },
      { 属性: 属性类型.外功会心等级, 值: 2933 },
      { 属性: 属性类型.无双等级, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 35711,
    uid: 211854,
    装备名称: '风掣囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.身法, 值: 584 },
      { 属性: 属性类型.外功基础攻击, 值: 948 },
      { 属性: 属性类型.加速等级, 值: 2933 },
      { 属性: 属性类型.破招值, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 35710,
    uid: 211853,
    装备名称: '凛行囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3014 },
      { 属性: 属性类型.力道, 值: 584 },
      { 属性: 属性类型.外功基础攻击, 值: 948 },
      { 属性: 属性类型.加速等级, 值: 2933 },
      { 属性: 属性类型.破招值, 值: 2607 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 35834,
    uid: 212277,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2267 },
      { 属性: 属性类型.破招值, 值: 1899 },
      { 属性: 属性类型.外功破防等级, 值: 2057 },
      { 属性: 属性类型.外功会心等级, 值: 2057 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 35833,
    uid: 212276,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2054 },
      { 属性: 属性类型.破招值, 值: 3165 },
      { 属性: 属性类型.外功破防等级, 值: 3323 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 35832,
    uid: 212275,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2409 },
      { 属性: 属性类型.外功会心等级, 值: 5618 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 37108,
    uid: 214581,
    装备名称: '西风漫·望',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2895 },
      { 属性: 属性类型.身法, 值: 561 },
      { 属性: 属性类型.外功基础攻击, 值: 911 },
      { 属性: 属性类型.外功破防等级, 值: 2817 },
      { 属性: 属性类型.无双等级, 值: 2504 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 37107,
    uid: 214580,
    装备名称: '西风漫·聆',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2895 },
      { 属性: 属性类型.力道, 值: 561 },
      { 属性: 属性类型.外功基础攻击, 值: 911 },
      { 属性: 属性类型.外功破防等级, 值: 2817 },
      { 属性: 属性类型.无双等级, 值: 2504 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 35826,
    uid: 212233,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1941 },
      { 属性: 属性类型.破招值, 值: 1644 },
      { 属性: 属性类型.外功会心等级, 值: 2840 },
      { 属性: 属性类型.外功会心效果等级, 值: 1495 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 35825,
    uid: 212232,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1941 },
      { 属性: 属性类型.外功会心等级, 值: 3737 },
      { 属性: 属性类型.外功会心效果等级, 值: 2242 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 35824,
    uid: 212231,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2275 },
      { 属性: 属性类型.外功破防等级, 值: 5307 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 36536,
    uid: 210981,
    装备名称: '翠林囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2722 },
      { 属性: 属性类型.身法, 值: 528 },
      { 属性: 属性类型.外功基础攻击, 值: 856 },
      { 属性: 属性类型.外功破防等级, 值: 2649 },
      { 属性: 属性类型.无双等级, 值: 2354 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 36535,
    uid: 210980,
    装备名称: '静山囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2722 },
      { 属性: 属性类型.力道, 值: 528 },
      { 属性: 属性类型.外功基础攻击, 值: 856 },
      { 属性: 属性类型.外功破防等级, 值: 2649 },
      { 属性: 属性类型.无双等级, 值: 2354 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 35939,
    uid: 212514,
    装备名称: '西风北啸·角寒囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.身法, 值: 522 },
      { 属性: 属性类型.外功基础攻击, 值: 846 },
      { 属性: 属性类型.外功会心等级, 值: 2617 },
      { 属性: 属性类型.无双等级, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 35938,
    uid: 212513,
    装备名称: '西风北啸·砾漠囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.力道, 值: 522 },
      { 属性: 属性类型.外功基础攻击, 值: 846 },
      { 属性: 属性类型.外功会心等级, 值: 2617 },
      { 属性: 属性类型.无双等级, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 34819,
    uid: 208386,
    装备名称: '湖月囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.身法, 值: 522 },
      { 属性: 属性类型.外功基础攻击, 值: 846 },
      { 属性: 属性类型.外功会心等级, 值: 2617 },
      { 属性: 属性类型.破招值, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 34818,
    uid: 208385,
    装备名称: '湖静囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.力道, 值: 522 },
      { 属性: 属性类型.外功基础攻击, 值: 846 },
      { 属性: 属性类型.外功会心等级, 值: 2617 },
      { 属性: 属性类型.破招值, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 34769,
    uid: 208102,
    装备名称: '度槐囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.身法, 值: 522 },
      { 属性: 属性类型.外功基础攻击, 值: 846 },
      { 属性: 属性类型.外功破防等级, 值: 2617 },
      { 属性: 属性类型.破招值, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 34768,
    uid: 208101,
    装备名称: '微花囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.力道, 值: 522 },
      { 属性: 属性类型.外功基础攻击, 值: 846 },
      { 属性: 属性类型.外功破防等级, 值: 2617 },
      { 属性: 属性类型.破招值, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 34703,
    uid: 207998,
    装备名称: '染辞囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.身法, 值: 522 },
      { 属性: 属性类型.外功基础攻击, 值: 846 },
      { 属性: 属性类型.外功会心等级, 值: 2617 },
      { 属性: 属性类型.无双等级, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 34702,
    uid: 207997,
    装备名称: '温刃囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.力道, 值: 522 },
      { 属性: 属性类型.外功基础攻击, 值: 846 },
      { 属性: 属性类型.外功会心等级, 值: 2617 },
      { 属性: 属性类型.无双等级, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 34697,
    uid: 207938,
    装备名称: '商野囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.身法, 值: 522 },
      { 属性: 属性类型.外功基础攻击, 值: 846 },
      { 属性: 属性类型.加速等级, 值: 2617 },
      { 属性: 属性类型.破招值, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 34696,
    uid: 207937,
    装备名称: '安衿囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2690 },
      { 属性: 属性类型.力道, 值: 522 },
      { 属性: 属性类型.外功基础攻击, 值: 846 },
      { 属性: 属性类型.加速等级, 值: 2617 },
      { 属性: 属性类型.破招值, 值: 2326 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 32547,
    uid: 189044,
    装备名称: '临晚囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2679 },
      { 属性: 属性类型.身法, 值: 520 },
      { 属性: 属性类型.外功基础攻击, 值: 843 },
      { 属性: 属性类型.外功会心等级, 值: 2607 },
      { 属性: 属性类型.无双等级, 值: 2317 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 32546,
    uid: 189043,
    装备名称: '临掣囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2679 },
      { 属性: 属性类型.力道, 值: 520 },
      { 属性: 属性类型.外功基础攻击, 值: 843 },
      { 属性: 属性类型.外功会心等级, 值: 2607 },
      { 属性: 属性类型.无双等级, 值: 2317 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38352,
    uid: 217864,
    装备名称: '塞尘囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.身法, 值: 483 },
      { 属性: 属性类型.外功基础攻击, 值: 643 },
      { 属性: 属性类型.外功会心等级, 值: 2586 },
      { 属性: 属性类型.全能等级, 值: 1149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38351,
    uid: 217863,
    装备名称: '峭月囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.力道, 值: 483 },
      { 属性: 属性类型.外功基础攻击, 值: 643 },
      { 属性: 属性类型.外功会心等级, 值: 2586 },
      { 属性: 属性类型.全能等级, 值: 1149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38315,
    uid: 217773,
    装备名称: '奔枝囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.身法, 值: 483 },
      { 属性: 属性类型.外功基础攻击, 值: 643 },
      { 属性: 属性类型.外功破防等级, 值: 2586 },
      { 属性: 属性类型.全能等级, 值: 1149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 38314,
    uid: 217772,
    装备名称: '环旌囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.力道, 值: 483 },
      { 属性: 属性类型.外功基础攻击, 值: 643 },
      { 属性: 属性类型.外功破防等级, 值: 2586 },
      { 属性: 属性类型.全能等级, 值: 1149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 32697,
    uid: 189410,
    装备名称: '牵叶',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.身法, 值: 515 },
      { 属性: 属性类型.外功基础攻击, 值: 836 },
      { 属性: 属性类型.外功会心等级, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 32696,
    uid: 189409,
    装备名称: '宸风',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.力道, 值: 515 },
      { 属性: 属性类型.外功基础攻击, 值: 836 },
      { 属性: 属性类型.外功会心等级, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 32661,
    uid: 189374,
    装备名称: '久念囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.身法, 值: 515 },
      { 属性: 属性类型.外功基础攻击, 值: 836 },
      { 属性: 属性类型.外功破防等级, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 32660,
    uid: 189373,
    装备名称: '拭江囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.力道, 值: 515 },
      { 属性: 属性类型.外功基础攻击, 值: 836 },
      { 属性: 属性类型.外功破防等级, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 32625,
    uid: 189284,
    装备名称: '风岱囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.身法, 值: 515 },
      { 属性: 属性类型.外功基础攻击, 值: 836 },
      { 属性: 属性类型.加速等级, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 32624,
    uid: 189283,
    装备名称: '项昌囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.力道, 值: 515 },
      { 属性: 属性类型.外功基础攻击, 值: 836 },
      { 属性: 属性类型.加速等级, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 32589,
    uid: 189194,
    装备名称: '北邱囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.身法, 值: 515 },
      { 属性: 属性类型.外功基础攻击, 值: 836 },
      { 属性: 属性类型.外功破防等级, 值: 2586 },
      { 属性: 属性类型.破招值, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 32588,
    uid: 189193,
    装备名称: '曲郦囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.力道, 值: 515 },
      { 属性: 属性类型.外功基础攻击, 值: 836 },
      { 属性: 属性类型.外功破防等级, 值: 2586 },
      { 属性: 属性类型.破招值, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 32553,
    uid: 189104,
    装备名称: '渊忱囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.身法, 值: 515 },
      { 属性: 属性类型.外功基础攻击, 值: 836 },
      { 属性: 属性类型.破招值, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 32552,
    uid: 189103,
    装备名称: '羡双囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.力道, 值: 515 },
      { 属性: 属性类型.外功基础攻击, 值: 836 },
      { 属性: 属性类型.破招值, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 32493,
    uid: 188822,
    装备名称: '忆宁囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.身法, 值: 515 },
      { 属性: 属性类型.外功基础攻击, 值: 836 },
      { 属性: 属性类型.外功会心等级, 值: 2586 },
      { 属性: 属性类型.破招值, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 32492,
    uid: 188821,
    装备名称: '忆敬囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.力道, 值: 515 },
      { 属性: 属性类型.外功基础攻击, 值: 836 },
      { 属性: 属性类型.外功会心等级, 值: 2586 },
      { 属性: 属性类型.破招值, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 32457,
    uid: 188732,
    装备名称: '盈绝囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.身法, 值: 515 },
      { 属性: 属性类型.外功基础攻击, 值: 836 },
      { 属性: 属性类型.外功破防等级, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 32456,
    uid: 188731,
    装备名称: '垣翰囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.力道, 值: 515 },
      { 属性: 属性类型.外功基础攻击, 值: 836 },
      { 属性: 属性类型.外功破防等级, 值: 2586 },
      { 属性: 属性类型.无双等级, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 32421,
    uid: 188642,
    装备名称: '潋阳囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.身法, 值: 515 },
      { 属性: 属性类型.外功基础攻击, 值: 836 },
      { 属性: 属性类型.外功破防等级, 值: 2586 },
      { 属性: 属性类型.破招值, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 32420,
    uid: 188641,
    装备名称: '重关囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 2657 },
      { 属性: 属性类型.力道, 值: 515 },
      { 属性: 属性类型.外功基础攻击, 值: 836 },
      { 属性: 属性类型.外功破防等级, 值: 2586 },
      { 属性: 属性类型.破招值, 值: 2298 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 34815,
    uid: 208328,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1835 },
      { 属性: 属性类型.外功破防等级, 值: 2049 },
      { 属性: 属性类型.外功会心等级, 值: 2190 },
      { 属性: 属性类型.外功会心效果等级, 值: 1413 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 34814,
    uid: 208327,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 1835 },
      { 属性: 属性类型.破招值, 值: 2968 },
      { 属性: 属性类型.外功会心等级, 值: 2826 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 34813,
    uid: 208326,
    装备名称: '无封囊',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2151 },
      { 属性: 属性类型.无双等级, 值: 5017 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
]

export default 暗器装备数据
