import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 下装装备数据: 装备属性信息模型[] = [
  {
    id: 98497,
    uid: 215185,
    装备名称: '水泓裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5689 },
      { 属性: 属性类型.元气, 值: 1103 },
      { 属性: 属性类型.内功基础攻击, 值: 2148 },
      { 属性: 属性类型.全会心等级, 值: 5536 },
      { 属性: 属性类型.无双等级, 值: 4921 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 98496,
    uid: 215184,
    装备名称: '水川裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5689 },
      { 属性: 属性类型.根骨, 值: 1103 },
      { 属性: 属性类型.内功基础攻击, 值: 2148 },
      { 属性: 属性类型.内功会心等级, 值: 5536 },
      { 属性: 属性类型.无双等级, 值: 4921 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100095,
    uid: 217023,
    装备名称: '陶然意·和时裤',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5653 },
      { 属性: 属性类型.根骨, 值: 1028 },
      { 属性: 属性类型.内功基础攻击, 值: 1642 },
      { 属性: 属性类型.内功会心等级, 值: 5501 },
      { 属性: 属性类型.全能等级, 值: 2445 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100094,
    uid: 217022,
    装备名称: '陶然意·星雪裤',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5653 },
      { 属性: 属性类型.元气, 值: 1028 },
      { 属性: 属性类型.内功基础攻击, 值: 1642 },
      { 属性: 属性类型.内功会心等级, 值: 5501 },
      { 属性: 属性类型.全能等级, 值: 2445 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100078,
    uid: 217006,
    装备名称: '陶然意·兰谷裤',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5653 },
      { 属性: 属性类型.元气, 值: 1028 },
      { 属性: 属性类型.内功基础攻击, 值: 1642 },
      { 属性: 属性类型.内功会心等级, 值: 5501 },
      { 属性: 属性类型.全能等级, 值: 2445 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 98599,
    uid: 215360,
    装备名称: '月迟裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.元气, 值: 1089 },
      { 属性: 属性类型.内功基础攻击, 值: 2121 },
      { 属性: 属性类型.全会心等级, 值: 5466 },
      { 属性: 属性类型.破招值, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 98598,
    uid: 215359,
    装备名称: '月纤裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.根骨, 值: 1089 },
      { 属性: 属性类型.内功基础攻击, 值: 2121 },
      { 属性: 属性类型.内功会心等级, 值: 5466 },
      { 属性: 属性类型.破招值, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 98521,
    uid: 215240,
    装备名称: '客行江湖·磐气下裳',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.元气, 值: 1089 },
      { 属性: 属性类型.内功基础攻击, 值: 2121 },
      { 属性: 属性类型.内功破防等级, 值: 5466 },
      { 属性: 属性类型.无双等级, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 98520,
    uid: 215239,
    装备名称: '客行江湖·风翎下裳',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.根骨, 值: 1089 },
      { 属性: 属性类型.内功基础攻击, 值: 2121 },
      { 属性: 属性类型.内功破防等级, 值: 5466 },
      { 属性: 属性类型.无双等级, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 98475,
    uid: 215103,
    装备名称: '满室裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.内功基础攻击, 值: 3915 },
      { 属性: 属性类型.全会心等级, 值: 3947 },
      { 属性: 属性类型.无双等级, 值: 9717 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98474,
    uid: 215102,
    装备名称: '复粉裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.内功基础攻击, 值: 5546 },
      { 属性: 属性类型.无双等级, 值: 10172 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98464,
    uid: 215084,
    装备名称: '示曰裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4730 },
      { 属性: 属性类型.内功破防等级, 值: 4251 },
      { 属性: 属性类型.全会心等级, 值: 4858 },
      { 属性: 属性类型.无双等级, 值: 3340 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98460,
    uid: 215080,
    装备名称: '闻太裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4730 },
      { 属性: 属性类型.破招值, 值: 4251 },
      { 属性: 属性类型.内功破防等级, 值: 5162 },
      { 属性: 属性类型.无双等级, 值: 3036 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98459,
    uid: 215079,
    装备名称: '冷然裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4730 },
      { 属性: 属性类型.破招值, 值: 6377 },
      { 属性: 属性类型.全会心等级, 值: 6073 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 98458,
    uid: 215078,
    装备名称: '十珠裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15600,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 5546 },
      { 属性: 属性类型.内功破防等级, 值: 10779 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98425,
    uid: 214953,
    装备名称: '良安裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.元气, 值: 1089 },
      { 属性: 属性类型.内功基础攻击, 值: 2121 },
      { 属性: 属性类型.内功破防等级, 值: 5466 },
      { 属性: 属性类型.破招值, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 98424,
    uid: 214952,
    装备名称: '情义裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.根骨, 值: 1089 },
      { 属性: 属性类型.内功基础攻击, 值: 2121 },
      { 属性: 属性类型.内功破防等级, 值: 5466 },
      { 属性: 属性类型.破招值, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 98389,
    uid: 214893,
    装备名称: '宫阙裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.元气, 值: 1089 },
      { 属性: 属性类型.内功基础攻击, 值: 2121 },
      { 属性: 属性类型.加速等级, 值: 5466 },
      { 属性: 属性类型.无双等级, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 98388,
    uid: 214892,
    装备名称: '绕城裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5617 },
      { 属性: 属性类型.根骨, 值: 1089 },
      { 属性: 属性类型.内功基础攻击, 值: 2121 },
      { 属性: 属性类型.加速等级, 值: 5466 },
      { 属性: 属性类型.无双等级, 值: 4858 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 98570,
    uid: 215313,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4609 },
      { 属性: 属性类型.内功破防等级, 值: 4142 },
      { 属性: 属性类型.全会心等级, 值: 4734 },
      { 属性: 属性类型.无双等级, 值: 3254 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98569,
    uid: 215312,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4609 },
      { 属性: 属性类型.破招值, 值: 6065 },
      { 属性: 属性类型.无双等级, 值: 6065 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98568,
    uid: 215311,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 5404 },
      { 属性: 属性类型.内功破防等级, 值: 10503 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98546,
    uid: 215271,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4802 },
      { 属性: 属性类型.破招值, 值: 3352 },
      { 属性: 属性类型.内功破防等级, 值: 3631 },
      { 属性: 属性类型.全会心等级, 值: 3631 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 98545,
    uid: 215270,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4351 },
      { 属性: 属性类型.内功破防等级, 值: 5586 },
      { 属性: 属性类型.无双等级, 值: 5866 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 98544,
    uid: 215269,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 5102 },
      { 属性: 属性类型.全会心等级, 值: 9916 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 96383,
    uid: 212108,
    装备名称: '风绫裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5095 },
      { 属性: 属性类型.元气, 值: 988 },
      { 属性: 属性类型.内功基础攻击, 值: 1923 },
      { 属性: 属性类型.全会心等级, 值: 4958 },
      { 属性: 属性类型.无双等级, 值: 4407 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 96382,
    uid: 212107,
    装备名称: '风轻裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5095 },
      { 属性: 属性类型.根骨, 值: 988 },
      { 属性: 属性类型.内功基础攻击, 值: 1923 },
      { 属性: 属性类型.内功会心等级, 值: 4958 },
      { 属性: 属性类型.无双等级, 值: 4407 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 99969,
    uid: 217023,
    装备名称: '陶然意·和时裤',
    所属门派: '药宗',
    装备主属性: '根骨',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5059 },
      { 属性: 属性类型.根骨, 值: 920 },
      { 属性: 属性类型.内功基础攻击, 值: 1469 },
      { 属性: 属性类型.内功会心等级, 值: 4923 },
      { 属性: 属性类型.全能等级, 值: 2188 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 99968,
    uid: 217022,
    装备名称: '陶然意·星雪裤',
    所属门派: '衍天',
    装备主属性: '元气',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5059 },
      { 属性: 属性类型.元气, 值: 920 },
      { 属性: 属性类型.内功基础攻击, 值: 1469 },
      { 属性: 属性类型.内功会心等级, 值: 4923 },
      { 属性: 属性类型.全能等级, 值: 2188 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 99952,
    uid: 217006,
    装备名称: '陶然意·兰谷裤',
    所属门派: '万花',
    装备主属性: '元气',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5059 },
      { 属性: 属性类型.元气, 值: 920 },
      { 属性: 属性类型.内功基础攻击, 值: 1469 },
      { 属性: 属性类型.内功会心等级, 值: 4923 },
      { 属性: 属性类型.全能等级, 值: 2188 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 98695,
    uid: 215557,
    装备名称: '东方日出·当楼裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.元气, 值: 974 },
      { 属性: 属性类型.内功基础攻击, 值: 1896 },
      { 属性: 属性类型.内功破防等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 98694,
    uid: 215556,
    装备名称: '东方日出·所适裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.根骨, 值: 974 },
      { 属性: 属性类型.内功基础攻击, 值: 1896 },
      { 属性: 属性类型.内功破防等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 98203,
    uid: 214658,
    装备名称: '危影裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.元气, 值: 974 },
      { 属性: 属性类型.内功基础攻击, 值: 1896 },
      { 属性: 属性类型.全会心等级, 值: 4888 },
      { 属性: 属性类型.无双等级, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 98202,
    uid: 214657,
    装备名称: '危音裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.根骨, 值: 974 },
      { 属性: 属性类型.内功基础攻击, 值: 1896 },
      { 属性: 属性类型.内功会心等级, 值: 4888 },
      { 属性: 属性类型.无双等级, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 96493,
    uid: 212297,
    装备名称: '泉麓裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.元气, 值: 974 },
      { 属性: 属性类型.内功基础攻击, 值: 1896 },
      { 属性: 属性类型.全会心等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 96492,
    uid: 212296,
    装备名称: '泉合裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.根骨, 值: 974 },
      { 属性: 属性类型.内功基础攻击, 值: 1896 },
      { 属性: 属性类型.内功会心等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 96407,
    uid: 212163,
    装备名称: '客行江湖·断意下裳',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.元气, 值: 974 },
      { 属性: 属性类型.内功基础攻击, 值: 1896 },
      { 属性: 属性类型.内功破防等级, 值: 4888 },
      { 属性: 属性类型.无双等级, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 96406,
    uid: 212162,
    装备名称: '客行江湖·意悠下裳',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.根骨, 值: 974 },
      { 属性: 属性类型.内功基础攻击, 值: 1896 },
      { 属性: 属性类型.内功破防等级, 值: 4888 },
      { 属性: 属性类型.无双等级, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 96361,
    uid: 212026,
    装备名称: '语瞳裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.内功基础攻击, 值: 3501 },
      { 属性: 属性类型.全会心等级, 值: 3530 },
      { 属性: 属性类型.无双等级, 值: 8689 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96360,
    uid: 212025,
    装备名称: '朝户裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.内功基础攻击, 值: 4959 },
      { 属性: 属性类型.无双等级, 值: 9096 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96350,
    uid: 212007,
    装备名称: '韶于裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4230 },
      { 属性: 属性类型.内功破防等级, 值: 3801 },
      { 属性: 属性类型.全会心等级, 值: 4344 },
      { 属性: 属性类型.无双等级, 值: 2987 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96346,
    uid: 212003,
    装备名称: '南依裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4230 },
      { 属性: 属性类型.破招值, 值: 3801 },
      { 属性: 属性类型.内功破防等级, 值: 4616 },
      { 属性: 属性类型.无双等级, 值: 2715 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96345,
    uid: 212002,
    装备名称: '漫泠裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4230 },
      { 属性: 属性类型.破招值, 值: 5702 },
      { 属性: 属性类型.全会心等级, 值: 5431 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 96344,
    uid: 212001,
    装备名称: '雅采裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13950,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4959 },
      { 属性: 属性类型.内功破防等级, 值: 9639 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96311,
    uid: 211876,
    装备名称: '壑云裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.元气, 值: 974 },
      { 属性: 属性类型.内功基础攻击, 值: 1896 },
      { 属性: 属性类型.内功破防等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 96310,
    uid: 211875,
    装备名称: '寒绡裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.根骨, 值: 974 },
      { 属性: 属性类型.内功基础攻击, 值: 1896 },
      { 属性: 属性类型.内功破防等级, 值: 4888 },
      { 属性: 属性类型.破招值, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 96275,
    uid: 211816,
    装备名称: '开颐裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.元气, 值: 974 },
      { 属性: 属性类型.内功基础攻击, 值: 1896 },
      { 属性: 属性类型.加速等级, 值: 4888 },
      { 属性: 属性类型.无双等级, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 96274,
    uid: 211815,
    装备名称: '扬英裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5023 },
      { 属性: 属性类型.根骨, 值: 974 },
      { 属性: 属性类型.内功基础攻击, 值: 1896 },
      { 属性: 属性类型.加速等级, 值: 4888 },
      { 属性: 属性类型.无双等级, 值: 4344 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 96464,
    uid: 212250,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4109 },
      { 属性: 属性类型.破招值, 值: 3692 },
      { 属性: 属性类型.内功破防等级, 值: 4484 },
      { 属性: 属性类型.无双等级, 值: 2637 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96463,
    uid: 212249,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4109 },
      { 属性: 属性类型.全会心等级, 值: 5275 },
      { 属性: 属性类型.无双等级, 值: 5539 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96462,
    uid: 212248,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4817 },
      { 属性: 属性类型.无双等级, 值: 9363 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96436,
    uid: 212198,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3881 },
      { 属性: 属性类型.内功破防等级, 值: 3488 },
      { 属性: 属性类型.全会心等级, 值: 3986 },
      { 属性: 属性类型.无双等级, 值: 2741 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 96435,
    uid: 212197,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3881 },
      { 属性: 属性类型.破招值, 值: 5107 },
      { 属性: 属性类型.无双等级, 值: 5107 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96434,
    uid: 212196,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4551 },
      { 属性: 属性类型.内功破防等级, 值: 8845 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94480,
    uid: 208174,
    装备名称: '雪洁裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4537 },
      { 属性: 属性类型.元气, 值: 880 },
      { 属性: 属性类型.内功基础攻击, 值: 1713 },
      { 属性: 属性类型.全会心等级, 值: 4415 },
      { 属性: 属性类型.无双等级, 值: 3924 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 94479,
    uid: 208173,
    装备名称: '雪满裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4537 },
      { 属性: 属性类型.根骨, 值: 880 },
      { 属性: 属性类型.内功基础攻击, 值: 1713 },
      { 属性: 属性类型.内功会心等级, 值: 4415 },
      { 属性: 属性类型.无双等级, 值: 3924 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 96589,
    uid: 212494,
    装备名称: '西风北啸·离声裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.元气, 值: 869 },
      { 属性: 属性类型.内功基础攻击, 值: 1692 },
      { 属性: 属性类型.内功破防等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 96588,
    uid: 212493,
    装备名称: '西风北啸·音书裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.根骨, 值: 869 },
      { 属性: 属性类型.内功基础攻击, 值: 1692 },
      { 属性: 属性类型.内功破防等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 94582,
    uid: 208348,
    装备名称: '湖烟裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.元气, 值: 869 },
      { 属性: 属性类型.内功基础攻击, 值: 1692 },
      { 属性: 属性类型.全会心等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 94581,
    uid: 208347,
    装备名称: '湖寂裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.根骨, 值: 869 },
      { 属性: 属性类型.内功基础攻击, 值: 1692 },
      { 属性: 属性类型.内功会心等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 94504,
    uid: 208228,
    装备名称: '客行江湖·千世下裳',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.元气, 值: 869 },
      { 属性: 属性类型.内功基础攻击, 值: 1692 },
      { 属性: 属性类型.内功破防等级, 值: 4362 },
      { 属性: 属性类型.无双等级, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 94503,
    uid: 208227,
    装备名称: '客行江湖·渐浓下裳',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.根骨, 值: 869 },
      { 属性: 属性类型.内功基础攻击, 值: 1692 },
      { 属性: 属性类型.内功破防等级, 值: 4362 },
      { 属性: 属性类型.无双等级, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 94459,
    uid: 208089,
    装备名称: '绣思裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.内功基础攻击, 值: 3645 },
      { 属性: 属性类型.内功破防等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 5089 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94455,
    uid: 208085,
    装备名称: '贻池裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.内功基础攻击, 值: 4426 },
      { 属性: 属性类型.破招值, 值: 8118 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 94454,
    uid: 208084,
    装备名称: '晤歌裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.内功基础攻击, 值: 3645 },
      { 属性: 属性类型.破招值, 值: 5089 },
      { 属性: 属性类型.内功破防等级, 值: 4604 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94453,
    uid: 208083,
    装备名称: '执徐裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12450,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.内功基础攻击, 值: 3124 },
      { 属性: 属性类型.全会心等级, 值: 3150 },
      { 属性: 属性类型.无双等级, 值: 7755 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94420,
    uid: 207960,
    装备名称: '沁渡裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.元气, 值: 869 },
      { 属性: 属性类型.内功基础攻击, 值: 1692 },
      { 属性: 属性类型.内功破防等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 94419,
    uid: 207959,
    装备名称: '朝华裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.根骨, 值: 869 },
      { 属性: 属性类型.内功基础攻击, 值: 1692 },
      { 属性: 属性类型.内功破防等级, 值: 4362 },
      { 属性: 属性类型.破招值, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 94384,
    uid: 207900,
    装备名称: '椴微裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.元气, 值: 869 },
      { 属性: 属性类型.内功基础攻击, 值: 1692 },
      { 属性: 属性类型.加速等级, 值: 4362 },
      { 属性: 属性类型.无双等级, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 94383,
    uid: 207899,
    装备名称: '池泓裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4483 },
      { 属性: 属性类型.根骨, 值: 869 },
      { 属性: 属性类型.内功基础攻击, 值: 1692 },
      { 属性: 属性类型.加速等级, 值: 4362 },
      { 属性: 属性类型.无双等级, 值: 3877 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100351,
    uid: 217826,
    装备名称: '若寓裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.元气, 值: 805 },
      { 属性: 属性类型.内功基础攻击, 值: 1286 },
      { 属性: 属性类型.内功破防等级, 值: 4309 },
      { 属性: 属性类型.全能等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 100350,
    uid: 217825,
    装备名称: '越风裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.根骨, 值: 805 },
      { 属性: 属性类型.内功基础攻击, 值: 1286 },
      { 属性: 属性类型.内功破防等级, 值: 4309 },
      { 属性: 属性类型.全能等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 100315,
    uid: 217735,
    装备名称: '踏江裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.元气, 值: 805 },
      { 属性: 属性类型.内功基础攻击, 值: 1286 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.全能等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100314,
    uid: 217734,
    装备名称: '承康裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.根骨, 值: 805 },
      { 属性: 属性类型.内功基础攻击, 值: 1286 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.全能等级, 值: 1915 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 90658,
    uid: 189336,
    装备名称: '藏峦裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.元气, 值: 859 },
      { 属性: 属性类型.内功基础攻击, 值: 1672 },
      { 属性: 属性类型.内功破防等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90657,
    uid: 189335,
    装备名称: '谨峰裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.根骨, 值: 859 },
      { 属性: 属性类型.内功基础攻击, 值: 1672 },
      { 属性: 属性类型.内功破防等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90622,
    uid: 189246,
    装备名称: '故芳裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.元气, 值: 859 },
      { 属性: 属性类型.内功基础攻击, 值: 1672 },
      { 属性: 属性类型.全会心等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90621,
    uid: 189245,
    装备名称: '剪桐裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.根骨, 值: 859 },
      { 属性: 属性类型.内功基础攻击, 值: 1672 },
      { 属性: 属性类型.内功会心等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90586,
    uid: 189156,
    装备名称: '花霭裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.元气, 值: 859 },
      { 属性: 属性类型.内功基础攻击, 值: 1672 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 90585,
    uid: 189155,
    装备名称: '途南裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.根骨, 值: 859 },
      { 属性: 属性类型.内功基础攻击, 值: 1672 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 90550,
    uid: 189066,
    装备名称: '庭澜裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.元气, 值: 859 },
      { 属性: 属性类型.内功基础攻击, 值: 1672 },
      { 属性: 属性类型.内功破防等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 90549,
    uid: 189065,
    装备名称: '故云裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.根骨, 值: 859 },
      { 属性: 属性类型.内功基础攻击, 值: 1672 },
      { 属性: 属性类型.内功破防等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 90418,
    uid: 188784,
    装备名称: '忆惜裳',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.元气, 值: 859 },
      { 属性: 属性类型.内功基础攻击, 值: 1672 },
      { 属性: 属性类型.全会心等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 90417,
    uid: 188783,
    装备名称: '忆安裳',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.根骨, 值: 859 },
      { 属性: 属性类型.内功基础攻击, 值: 1672 },
      { 属性: 属性类型.内功会心等级, 值: 4309 },
      { 属性: 属性类型.无双等级, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 90382,
    uid: 188694,
    装备名称: '语阔裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.元气, 值: 859 },
      { 属性: 属性类型.内功基础攻击, 值: 1672 },
      { 属性: 属性类型.内功破防等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90381,
    uid: 188693,
    装备名称: '擒雨裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.根骨, 值: 859 },
      { 属性: 属性类型.内功基础攻击, 值: 1672 },
      { 属性: 属性类型.内功破防等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 90346,
    uid: 188604,
    装备名称: '烟琐裤',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.元气, 值: 859 },
      { 属性: 属性类型.内功基础攻击, 值: 1672 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 90345,
    uid: 188603,
    装备名称: '德襄裤',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4429 },
      { 属性: 属性类型.根骨, 值: 859 },
      { 属性: 属性类型.内功基础攻击, 值: 1672 },
      { 属性: 属性类型.加速等级, 值: 4309 },
      { 属性: 属性类型.破招值, 值: 3831 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 94553,
    uid: 208301,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4049 },
      { 属性: 属性类型.破招值, 值: 2826 },
      { 属性: 属性类型.内功破防等级, 值: 3062 },
      { 属性: 属性类型.全会心等级, 值: 3062 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 94552,
    uid: 208300,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 3669 },
      { 属性: 属性类型.内功破防等级, 值: 4710 },
      { 属性: 属性类型.无双等级, 值: 4946 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 94551,
    uid: 208299,
    装备名称: '无封裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.内功基础攻击, 值: 4302 },
      { 属性: 属性类型.全会心等级, 值: 8361 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
]

export default 下装装备数据
