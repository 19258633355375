import { 属性类型 } from '@/@types/属性'
import { 技能基础数据模型 } from '@/@types/技能'
import { 计算公式计算类型 } from '@/@types/伤害计算'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'

import 通用增益 from './技能增益/通用增益/通用增益'
import 判官笔法增益 from './技能增益/判官笔法'
import 快雪时晴增益 from './技能增益/快雪时晴'
import 阳明指增益 from './技能增益/阳明指'
import 芙蓉并蒂增益 from './技能增益/芙蓉并蒂'
import 钟林毓秀增益 from './技能增益/钟林毓秀'
import 故幽增益 from './技能增益/故幽'
import 玉石俱焚增益 from './技能增益/玉石俱焚'

const 兰摧玉折基础数据: Partial<技能基础数据模型> = {
  统计名称: '兰摧玉折(DOT)',
  DOT生效间隔: 48,
  DOT跳数: 6, // 基础6跳，秘籍+1跳，系数按6算
  基础伤害_基础值: 30,
  技能伤害系数: 388.5461300469288,
  技能增益列表: 通用增益,
}

const 钟林毓秀基础数据: Partial<技能基础数据模型> = {
  统计名称: '钟林毓秀(DOT)',
  DOT生效间隔: 48,
  DOT跳数: 6, // 基础6跳，秘籍+1跳，系数按6算
  基础伤害_基础值: 38,
  技能伤害系数: 348.12573807128905,
  技能增益列表: 钟林毓秀增益,
}

const 商阳指基础数据: Partial<技能基础数据模型> = {
  统计名称: '商阳指(DOT)',
  DOT生效间隔: 48,
  DOT跳数: 6, // 基础6跳，秘籍+1跳，系数按6算
  基础伤害_基础值: 50,
  技能伤害系数: 305.0244562148438,
  技能增益列表: 通用增益,
}

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能名称: '判官笔法', // 16
    伤害类型: '外功',
    技能伤害系数: 16,
    武器伤害系数: 1,
    技能增益列表: 判官笔法增益,
  },
  {
    技能名称: '破', // 32467
    统计名称: '破',
    技能伤害系数: 获取破招实际系数(-787585.1316625408),
    技能增益列表: 通用增益,
    技能等级数据: {
      2: { 技能伤害系数: 获取破招实际系数(-735386.957995049) },
      3: { 技能伤害系数: 获取破招实际系数(-683188.7843275571) },
      4: { 技能伤害系数: 获取破招实际系数(-630990.6106600652) },
      5: { 技能伤害系数: 获取破招实际系数(-578792.4369925734) },
      6: { 技能伤害系数: 获取破招实际系数(-526594.2633250817) },
      7: { 技能伤害系数: 获取破招实际系数(-474396.0896575897) },
    },
  },
  {
    技能名称: '玉石俱焚', // 182
    技能伤害系数: 73.6,
    基础伤害_基础值: 81,
    基础伤害_浮动值: 10,
    技能增益列表: 玉石俱焚增益,
  },
  {
    技能名称: '芙蓉并蒂', // 186
    技能伤害系数: 73.6,
    基础伤害_基础值: 76,
    基础伤害_浮动值: 5,
    技能增益列表: [...芙蓉并蒂增益, ...故幽增益],
  },
  {
    技能名称: '商阳指', // 6693
    技能伤害系数: 73.6,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '阳明指', // 14941
    技能伤害系数: 282.837555,
    基础伤害_基础值: 155,
    基础伤害_浮动值: 28,
    技能增益列表: 阳明指增益,
  },
  {
    技能名称: '兰摧玉折·神兵', // 25768
    技能伤害系数: 100,
    基础伤害_基础值: 0,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '折花', // 32501
    统计名称: '折花',
    技能伤害系数: 304,
    基础伤害_基础值: 155,
    基础伤害_浮动值: 28,
    技能增益列表: 通用增益,
    技能等级数据: {
      4: { 技能伤害系数: 334 },
    },
  },
  {
    技能名称: '快雪时晴', // 33222
    技能伤害系数: 123.511311,
    基础伤害_基础值: 65,
    基础伤害_浮动值: 10,
    技能增益列表: 快雪时晴增益,
  },
  {
    技能名称: '快雪时晴(DOT)', // 快雪dot dotID 24158 触发ID 32481
    统计名称: '快雪时晴(DOT)',
    DOT生效间隔: 64,
    DOT跳数: 7,
    技能伤害系数: 50.748882291843756,
    基础伤害_基础值: 38,
    技能增益列表: [...通用增益, ...故幽增益],
  },
  {
    技能名称: '快雪时晴(DOT)·芙蓉', // 快雪dot dotID 24158 触发ID 32409
    统计名称: '快雪时晴(DOT)',
    DOT生效间隔: 64,
    DOT跳数: 7,
    技能伤害系数: 50.748882291843756,
    基础伤害_基础值: 38,
    技能增益列表: [...通用增益, ...故幽增益],
  },
  {
    技能名称: '墨海·一', // 37270/1
    统计名称: '墨海',
    技能伤害系数: 1980,
    基础伤害_基础值: 34,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '墨海·二', // 37270/3
    统计名称: '墨海',
    技能伤害系数: 495,
    基础伤害_基础值: 34,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '临源·一', // 37270/2
    统计名称: '临源',
    技能伤害系数: 4950,
    基础伤害_基础值: 34,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '临源·二', // 37270/4
    统计名称: '临源',
    技能伤害系数: 1237.5,
    基础伤害_基础值: 34,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '钟灵', // 37525
    技能伤害系数: 328,
    基础伤害_基础值: 121,
    基础伤害_浮动值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能名称: '兰摧玉折(DOT)', // 普通 兰摧玉折 dotID 711 触发ID 18730
    ...兰摧玉折基础数据,
  },
  {
    技能名称: '兰摧玉折(DOT)·阳明', // 乱撒阳明 兰摧玉折 dotID 711 触发ID 13848
    ...兰摧玉折基础数据,
  },
  {
    技能名称: '兰摧玉折(DOT)·芙蓉', // 刷新 兰摧玉折 dotID 711 触发ID 6136
    ...兰摧玉折基础数据,
    技能增益列表: [...通用增益, ...故幽增益],
  },
  {
    技能名称: '钟林毓秀(DOT)', // 普通 钟林毓秀 dotID 714 触发ID 285
    ...钟林毓秀基础数据,
  },
  {
    技能名称: '钟林毓秀(DOT)·阳明', // 乱撒阳明 钟林毓秀 dotID 714 触发ID 13847
    ...钟林毓秀基础数据,
  },
  {
    技能名称: '钟林毓秀(DOT)·橙武', // 乱撒阳明 钟林毓秀 dotID 714 触发ID 3086
    ...钟林毓秀基础数据,
  },
  {
    技能名称: '钟林毓秀(DOT)·芙蓉', // 刷新 钟林毓秀 dotID 714 触发ID 6135
    ...钟林毓秀基础数据,
    技能增益列表: [...钟林毓秀增益, ...故幽增益],
  },
  {
    技能名称: '商阳指(DOT)', // 普通 商阳指 dotID 666 触发ID 180
    ...商阳指基础数据,
  },
  {
    技能名称: '商阳指(DOT)·阳明', // 乱撒阳明 商阳指 dotID 666 触发ID 13849
    ...商阳指基础数据,
  },
  {
    技能名称: '商阳指(DOT)·芙蓉', // 刷新 商阳指 dotID 666 触发ID 6134
    ...商阳指基础数据,
    技能增益列表: [...通用增益, ...故幽增益],
  },
  {
    技能名称: '吞海', // 601
    技能伤害系数: 16.0,
    技能增益列表: 通用增益,
  },
  // --------------
  {
    技能名称: '逐云寒蕊',
    技能伤害系数: 384,
    基础伤害_基础值: 40,
    基础伤害_浮动值: 17,
    伤害计算次数: 1,
    技能增益列表: [
      ...通用增益,
      {
        增益名称: '飘黄',
        增益所在位置: '技能',
        增益类型: '全局启用',
        增益启用: true,
        增益集合: [{ 属性: 属性类型.郭氏内功基础防御, 值: -1024 }],
      },
    ],
  },
  {
    // 伤腕
    技能名称: '昆吾·弦刃',
    真实伤害: 145300, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
    伤害计算类型标记: [计算公式计算类型.真实伤害, 计算公式计算类型.等级减伤, 计算公式计算类型.易伤],
  },
  {
    // 伤鞋
    技能名称: '刃凌',
    真实伤害: 96900, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
    伤害计算类型标记: [计算公式计算类型.真实伤害, 计算公式计算类型.等级减伤, 计算公式计算类型.易伤],
  },
  {
    // 龙门武器
    技能名称: '剑风',
    基础伤害_基础值: 3950,
    技能增益列表: 通用增益,
  },
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
  判官笔法增益,
  快雪时晴增益,
  阳明指增益,
  芙蓉并蒂增益,
  钟林毓秀增益,
  故幽增益,
}

export { 技能增益 }
