import { 装备信息数据类型, 选择装备数据类型 } from '@/@types/装备'
import 获取当前数据 from '@/数据/数据工具/获取当前数据'

const { 装备数据 } = 获取当前数据()

/**
 * 获取大附魔分数
 * 大附魔分数为固定值，直接取数据中的附魔数值
 * @param {装备信息数据类型} 装备信息
 * @return {number}
 */
export function 获取大附魔分数(装备信息: 装备信息数据类型) {
  const { 装备列表, 装备增益 } = 装备信息

  let 大附魔总分 = 0
  Object.keys(装备增益).forEach((key) => {
    if (key?.includes('大附魔_') && 装备增益[key]) {
      const 当前大附魔分数 = 获取单大附魔分数(key, 装备列表) || 0
      大附魔总分 += 当前大附魔分数
    }
  })

  return 大附魔总分
}

export function 获取单大附魔分数(大附魔名称, 装备列表: 选择装备数据类型[]): number {
  let 装备分数 = 0
  const 大附魔数据 = 大附魔枚举?.[大附魔名称]
  const 当前对应装备 = 装备列表?.find((item) => item?.装备部位 === 大附魔数据?.部位)
  const 当前装备数据 = 装备数据[大附魔数据?.部位]?.find((数据) => 数据.id === 当前对应装备?.id)
  if (当前装备数据) {
    const 装备品级 = 当前装备数据?.装备品级 || 0
    const 判断英雄和普通 = 装备品级 > 大附魔区分英雄品 ? '英雄' : '普通'
    const 对应分数 = 大附魔数据?.[判断英雄和普通]
    if (对应分数) {
      装备分数 += 对应分数
    }
  }
  return 装备分数
}

const 大附魔区分英雄品 = 15000

const 大附魔枚举 = {
  大附魔_伤帽: { 部位: '帽子', 英雄: 2716, 普通: 2461 },
  大附魔_伤衣: { 部位: '衣服', 英雄: 3020, 普通: 2737 },
  大附魔_伤腰: { 部位: '腰带', 英雄: 2112, 普通: 1914 },
  大附魔_伤腕: { 部位: '护腕', 英雄: 2112, 普通: 1914 },
  大附魔_伤鞋: { 部位: '鞋子', 英雄: 2112, 普通: 1914 },
}
