import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 鞋子装备数据: 装备属性信息模型[] = [
  {
    id: 98493,
    uid: 215181,
    装备名称: '水泉靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3983 },
      { 属性: 属性类型.身法, 值: 772 },
      { 属性: 属性类型.外功基础攻击, 值: 1253 },
      { 属性: 属性类型.外功破防等级, 值: 3875 },
      { 属性: 属性类型.破招值, 值: 3444 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98492,
    uid: 215180,
    装备名称: '水泽靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15800,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3983 },
      { 属性: 属性类型.力道, 值: 772 },
      { 属性: 属性类型.外功基础攻击, 值: 1253 },
      { 属性: 属性类型.外功破防等级, 值: 3875 },
      { 属性: 属性类型.破招值, 值: 3444 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100076,
    uid: 217004,
    装备名称: '陶然意·旭影靴',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3957 },
      { 属性: 属性类型.身法, 值: 719 },
      { 属性: 属性类型.外功基础攻击, 值: 958 },
      { 属性: 属性类型.外功破防等级, 值: 3850 },
      { 属性: 属性类型.全能等级, 值: 1711 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100075,
    uid: 217003,
    装备名称: '陶然意·沉麟靴',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3957 },
      { 属性: 属性类型.力道, 值: 719 },
      { 属性: 属性类型.外功基础攻击, 值: 958 },
      { 属性: 属性类型.外功破防等级, 值: 3850 },
      { 属性: 属性类型.全能等级, 值: 1711 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100071,
    uid: 216999,
    装备名称: '陶然意·弘阔靴',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15700,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3957 },
      { 属性: 属性类型.身法, 值: 719 },
      { 属性: 属性类型.外功基础攻击, 值: 958 },
      { 属性: 属性类型.外功破防等级, 值: 3850 },
      { 属性: 属性类型.全能等级, 值: 1711 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98595,
    uid: 215356,
    装备名称: '月落靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.身法, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.外功会心等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98594,
    uid: 215355,
    装备名称: '月稠靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.力道, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.外功会心等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 98421,
    uid: 214949,
    装备名称: '救困靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.身法, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.外功会心等级, 值: 3826 },
      { 属性: 属性类型.无双等级, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98420,
    uid: 214948,
    装备名称: '磊落靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.力道, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.外功会心等级, 值: 3826 },
      { 属性: 属性类型.无双等级, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98385,
    uid: 214889,
    装备名称: '照耀靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.身法, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.加速等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 98384,
    uid: 214888,
    装备名称: '如雪靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3932 },
      { 属性: 属性类型.力道, 值: 763 },
      { 属性: 属性类型.外功基础攻击, 值: 1237 },
      { 属性: 属性类型.加速等级, 值: 3826 },
      { 属性: 属性类型.破招值, 值: 3401 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 98309,
    uid: 214813,
    装备名称: '鸿辉·眠狸靴',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3882 },
      { 属性: 属性类型.身法, 值: 753 },
      { 属性: 属性类型.外功基础攻击, 值: 1221 },
      { 属性: 属性类型.外功破防等级, 值: 3777 },
      { 属性: 属性类型.无双等级, 值: 3357 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98308,
    uid: 214812,
    装备名称: '鸿辉·凛霜靴',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3882 },
      { 属性: 属性类型.力道, 值: 753 },
      { 属性: 属性类型.外功基础攻击, 值: 1221 },
      { 属性: 属性类型.外功破防等级, 值: 3777 },
      { 属性: 属性类型.无双等级, 值: 3357 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98303,
    uid: 214807,
    装备名称: '鸿辉·霭琼靴',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 15400,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3882 },
      { 属性: 属性类型.身法, 值: 753 },
      { 属性: 属性类型.外功基础攻击, 值: 1221 },
      { 属性: 属性类型.外功破防等级, 值: 3777 },
      { 属性: 属性类型.无双等级, 值: 3357 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98579,
    uid: 215322,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2689 },
      { 属性: 属性类型.破招值, 值: 2278 },
      { 属性: 属性类型.外功会心等级, 值: 3935 },
      { 属性: 属性类型.无双等级, 值: 2278 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98578,
    uid: 215321,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2689 },
      { 属性: 属性类型.外功破防等级, 值: 4142 },
      { 属性: 属性类型.无双等级, 值: 4349 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98577,
    uid: 215320,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 15200,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 3152 },
      { 属性: 属性类型.无双等级, 值: 7352 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98555,
    uid: 215280,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2538 },
      { 属性: 属性类型.破招值, 值: 2737 },
      { 属性: 属性类型.外功破防等级, 值: 3324 },
      { 属性: 属性类型.无双等级, 值: 1955 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98554,
    uid: 215279,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2538 },
      { 属性: 属性类型.外功会心等级, 值: 4008 },
      { 属性: 属性类型.外功破防等级, 值: 4008 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 98553,
    uid: 215278,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 14350,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2976 },
      { 属性: 属性类型.外功会心等级, 值: 6941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96379,
    uid: 212104,
    装备名称: '风停靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3567 },
      { 属性: 属性类型.身法, 值: 692 },
      { 属性: 属性类型.外功基础攻击, 值: 1122 },
      { 属性: 属性类型.外功破防等级, 值: 3470 },
      { 属性: 属性类型.破招值, 值: 3085 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96378,
    uid: 212103,
    装备名称: '风烈靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 14150,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3567 },
      { 属性: 属性类型.力道, 值: 692 },
      { 属性: 属性类型.外功基础攻击, 值: 1122 },
      { 属性: 属性类型.外功破防等级, 值: 3470 },
      { 属性: 属性类型.破招值, 值: 3085 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 99950,
    uid: 217004,
    装备名称: '陶然意·旭影靴',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3542 },
      { 属性: 属性类型.身法, 值: 644 },
      { 属性: 属性类型.外功基础攻击, 值: 857 },
      { 属性: 属性类型.外功破防等级, 值: 3446 },
      { 属性: 属性类型.全能等级, 值: 1531 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 99949,
    uid: 217003,
    装备名称: '陶然意·沉麟靴',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3542 },
      { 属性: 属性类型.力道, 值: 644 },
      { 属性: 属性类型.外功基础攻击, 值: 857 },
      { 属性: 属性类型.外功破防等级, 值: 3446 },
      { 属性: 属性类型.全能等级, 值: 1531 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 99945,
    uid: 216999,
    装备名称: '陶然意·弘阔靴',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 14050,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3542 },
      { 属性: 属性类型.身法, 值: 644 },
      { 属性: 属性类型.外功基础攻击, 值: 857 },
      { 属性: 属性类型.外功破防等级, 值: 3446 },
      { 属性: 属性类型.全能等级, 值: 1531 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98691,
    uid: 215553,
    装备名称: '东方日出·天宇靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.身法, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功会心等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98690,
    uid: 215552,
    装备名称: '东方日出·海光靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.力道, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功会心等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98199,
    uid: 214654,
    装备名称: '危光靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.身法, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功破防等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 98198,
    uid: 214653,
    装备名称: '危雨靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.力道, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功破防等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96489,
    uid: 212293,
    装备名称: '泉幽靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.身法, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功会心等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96488,
    uid: 212292,
    装备名称: '泉潺靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.力道, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功会心等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96307,
    uid: 211872,
    装备名称: '踏雁靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.身法, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功会心等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96306,
    uid: 211871,
    装备名称: '素鸦靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.力道, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.外功会心等级, 值: 3421 },
      { 属性: 属性类型.无双等级, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96271,
    uid: 211812,
    装备名称: '风掣靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.身法, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.加速等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 96270,
    uid: 211811,
    装备名称: '凛行靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13950,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3516 },
      { 属性: 属性类型.力道, 值: 682 },
      { 属性: 属性类型.外功基础攻击, 值: 1106 },
      { 属性: 属性类型.加速等级, 值: 3421 },
      { 属性: 属性类型.破招值, 值: 3041 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 98175,
    uid: 214599,
    装备名称: '寻踪觅宝·飞旋靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 13750,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.身法, 值: 672 },
      { 属性: 属性类型.外功基础攻击, 值: 1090 },
      { 属性: 属性类型.外功会心等级, 值: 3372 },
      { 属性: 属性类型.无双等级, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 98174,
    uid: 214598,
    装备名称: '寻踪觅宝·碎浪靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 13750,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.力道, 值: 672 },
      { 属性: 属性类型.外功基础攻击, 值: 1090 },
      { 属性: 属性类型.外功会心等级, 值: 3372 },
      { 属性: 属性类型.无双等级, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96195,
    uid: 211736,
    装备名称: '灵源·寂林靴',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.身法, 值: 672 },
      { 属性: 属性类型.外功基础攻击, 值: 1090 },
      { 属性: 属性类型.外功破防等级, 值: 3372 },
      { 属性: 属性类型.无双等级, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96194,
    uid: 211735,
    装备名称: '灵源·休归靴',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.力道, 值: 672 },
      { 属性: 属性类型.外功基础攻击, 值: 1090 },
      { 属性: 属性类型.外功破防等级, 值: 3372 },
      { 属性: 属性类型.无双等级, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96189,
    uid: 211730,
    装备名称: '灵源·风涛靴',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 13750,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3466 },
      { 属性: 属性类型.身法, 值: 672 },
      { 属性: 属性类型.外功基础攻击, 值: 1090 },
      { 属性: 属性类型.外功破防等级, 值: 3372 },
      { 属性: 属性类型.无双等级, 值: 2998 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96473,
    uid: 212259,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2397 },
      { 属性: 属性类型.外功会心等级, 值: 3508 },
      { 属性: 属性类型.外功会心效果等级, 值: 1846 },
      { 属性: 属性类型.无双等级, 值: 2031 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96472,
    uid: 212258,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2397 },
      { 属性: 属性类型.破招值, 值: 3785 },
      { 属性: 属性类型.无双等级, 值: 3785 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96471,
    uid: 212257,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 13550,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2810 },
      { 属性: 属性类型.外功破防等级, 值: 6554 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96447,
    uid: 212209,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2264 },
      { 属性: 属性类型.破招值, 值: 1918 },
      { 属性: 属性类型.外功会心等级, 值: 3314 },
      { 属性: 属性类型.无双等级, 值: 1918 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96446,
    uid: 212208,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2264 },
      { 属性: 属性类型.外功破防等级, 值: 3488 },
      { 属性: 属性类型.无双等级, 值: 3662 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 96445,
    uid: 212207,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12800,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2655 },
      { 属性: 属性类型.无双等级, 值: 6191 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94476,
    uid: 208170,
    装备名称: '雪漫靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3176 },
      { 属性: 属性类型.身法, 值: 616 },
      { 属性: 属性类型.外功基础攻击, 值: 999 },
      { 属性: 属性类型.外功破防等级, 值: 3090 },
      { 属性: 属性类型.破招值, 值: 2747 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94475,
    uid: 208169,
    装备名称: '雪舞靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12600,
    装备特效: 装备特效枚举.切糕_普通,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3176 },
      { 属性: 属性类型.力道, 值: 616 },
      { 属性: 属性类型.外功基础攻击, 值: 999 },
      { 属性: 属性类型.外功破防等级, 值: 3090 },
      { 属性: 属性类型.破招值, 值: 2747 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 96585,
    uid: 212490,
    装备名称: '西风北啸·角寒靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.身法, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功会心等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96584,
    uid: 212489,
    装备名称: '西风北啸·砾漠靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.力道, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功会心等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94578,
    uid: 208344,
    装备名称: '湖月靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.身法, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功会心等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94577,
    uid: 208343,
    装备名称: '湖静靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.力道, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功会心等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 94416,
    uid: 207956,
    装备名称: '染辞靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.身法, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功会心等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94415,
    uid: 207955,
    装备名称: '温刃靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.力道, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.外功会心等级, 值: 3053 },
      { 属性: 属性类型.无双等级, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94380,
    uid: 207896,
    装备名称: '商野靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.身法, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.加速等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 94379,
    uid: 207895,
    装备名称: '安衿靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12450,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3138 },
      { 属性: 属性类型.力道, 值: 609 },
      { 属性: 属性类型.外功基础攻击, 值: 987 },
      { 属性: 属性类型.加速等级, 值: 3053 },
      { 属性: 属性类型.破招值, 值: 2714 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 90522,
    uid: 189014,
    装备名称: '临旭鞋',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3126 },
      { 属性: 属性类型.身法, 值: 606 },
      { 属性: 属性类型.外功基础攻击, 值: 983 },
      { 属性: 属性类型.外功会心等级, 值: 3041 },
      { 属性: 属性类型.破招值, 值: 2703 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90521,
    uid: 189013,
    装备名称: '临晨鞋',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12400,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3126 },
      { 属性: 属性类型.力道, 值: 606 },
      { 属性: 属性类型.外功基础攻击, 值: 983 },
      { 属性: 属性类型.外功会心等级, 值: 3041 },
      { 属性: 属性类型.破招值, 值: 2703 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100347,
    uid: 217822,
    装备名称: '塞尘靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 750 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100346,
    uid: 217821,
    装备名称: '峭月靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 750 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.额外气血上限 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100311,
    uid: 217731,
    装备名称: '奔枝履',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 750 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100310,
    uid: 217730,
    装备名称: '环旌履',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 750 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.全能等级, 值: 1341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 97848,
    uid: 213809,
    装备名称: '濯心·猎风靴',
    所属门派: '万灵',
    装备主属性: '身法',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 96091,
    uid: 211601,
    装备名称: '寻踪觅宝·屠云靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 96090,
    uid: 211600,
    装备名称: '寻踪觅宝·惊风靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94306,
    uid: 207822,
    装备名称: '濯心·锋虹靴',
    所属门派: '刀宗',
    装备主属性: '力道',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 94301,
    uid: 207817,
    装备名称: '濯心·盈怀靴',
    所属门派: '蓬莱',
    装备主属性: '身法',
    装备品级: 12300,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 90654,
    uid: 189332,
    装备名称: '久念靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 90653,
    uid: 189331,
    装备名称: '拭江靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 90618,
    uid: 189242,
    装备名称: '风岱靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90617,
    uid: 189241,
    装备名称: '项昌靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90582,
    uid: 189152,
    装备名称: '北邱靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 90581,
    uid: 189151,
    装备名称: '曲郦靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 90546,
    uid: 189062,
    装备名称: '渊忱靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 90545,
    uid: 189061,
    装备名称: '羡双靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 90414,
    uid: 188780,
    装备名称: '忆宁履',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90413,
    uid: 188779,
    装备名称: '忆敬履',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功会心等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90378,
    uid: 188690,
    装备名称: '盈绝靴',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90377,
    uid: 188689,
    装备名称: '垣翰靴',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.外功破防等级, 值: 3017 },
      { 属性: 属性类型.破招值, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 90342,
    uid: 188600,
    装备名称: '潋阳履',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.身法, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 90341,
    uid: 188599,
    装备名称: '重关履',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 12300,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 3100 },
      { 属性: 属性类型.力道, 值: 601 },
      { 属性: 属性类型.外功基础攻击, 值: 975 },
      { 属性: 属性类型.加速等级, 值: 3017 },
      { 属性: 属性类型.无双等级, 值: 2681 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94562,
    uid: 208310,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2140 },
      { 属性: 属性类型.破招值, 值: 2308 },
      { 属性: 属性类型.外功破防等级, 值: 2803 },
      { 属性: 属性类型.无双等级, 值: 1649 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 94561,
    uid: 208309,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2140 },
      { 属性: 属性类型.外功会心等级, 值: 3380 },
      { 属性: 属性类型.外功破防等级, 值: 3380 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 94560,
    uid: 208308,
    装备名称: '无封鞋',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 12100,
    装备类型: 装备类型枚举.试炼精简,
    装备增益: [
      { 属性: 属性类型.外功基础攻击, 值: 2509 },
      { 属性: 属性类型.外功会心等级, 值: 5853 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
]

export default 鞋子装备数据
